.artist {

	&__tit {
		text-align: center;
		color:#000;
		padding-top:30px;padding-bottom:30px;
		border-bottom: 2px solid #000;
		margin-bottom:70px;
		font-weight: bold;
		font-size:25px;
		position: relative;
		& > a {
			display:block;
			height:40px;line-height: 40px;width:35px;text-align: center;
			border-radius: 10px;
			transition: all 0.3s ease 0s;
			&:hover,
			&:focus {background-color: #eee;}
		}
		&__left {
			position: absolute;
			top:30px;
			left:5px;
		}
		&__right {
			position: absolute;
			top:30px;
			right:5px;
		}
	}
	&__top {
		@include floatWrap;
		padding-bottom: 40px;
		margin-bottom: 70px;
		&__image {
			float: left;
			width: 36%;
			img {
				max-width: 100%;
			}
		}
		&__right {
			float: right;
			width: 58%;
			&__ul{
				font-size:16px;
				font-size:1.6rem;
				margin-bottom:20px;
				&__li {
					padding-top: 14px;
					padding-bottom: 14px;
					position: relative;
					padding-left:100px;
					&__tit {
						position: absolute;
						top:14px;
						left:0;
					}
				}
			}
			&__sns {
				padding-top: 28px;
				padding-bottom: 28px;
				position: relative;
				padding-left:100px;
				border-top:1px solid #000;
				border-bottom:1px solid #000;
				font-size:16px;
				font-size:1.6rem;
				&__tit {
					position: absolute;
					top:28px;
					left:0;}
				&__cont {
					position: relative;
					&__btn {
						position: absolute;
						top:0px;
						right:0;
						@include btn9;
					}
				}
			}
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			margin-bottom:30px;
		}
	}

	&__mid {
		&__tit {
			color:#000;
			font-size:22px;
			font-weight: bold;
			margin-bottom:20px;
		}
		&__cont {
			line-height: 1.7;
			color:#666;
			margin-bottom:70px;
		}
		&__add-file {
			border-top:1px solid #d7d7d7;
			border-bottom:1px solid #d7d7d7;
			@include floatWrap;
			background-color:#f7f7f7;
			&__tit {
				float:left;
				width:20%;
				box-sizing: border-box;
				padding:20px 20px 20px 20px;
				display:inline-block;
				vertical-align: middle;
				@media screen and (min-width:1px) and (max-width:768px) {
					width:35%;
					padding:10px 10px 10px 20px;
				}
			}
			& > a {
				display:block;
				background-color: #fff;
				padding:20px;
				float: right;
				box-sizing: border-box;
				white-space:nowrap;
				width:80%;
				overflow: hidden;
				text-overflow: ellipsis;

				@media screen and (min-width:1px) and (max-width:768px) {
					width:65%;
					padding:10px;
				}
			}
		}
	}
}

@media screen and (min-width:901px) and (max-width:1200px) {

}

@media screen and (min-width:1px) and (max-width:900px) {

}
@media screen and (min-width:1px) and (max-width:800px) {
	.artist__top__image {
		float:none;
		width:auto;
		max-width:360px;
		text-align: center;
		margin:0 auto;
		margin-bottom:30px;
	}
	.artist__top__right {
		float:none;
		width:auto;
	}
}
@media screen and (min-width:1px) and (max-width:460px) {
	.artist__top__right__ul__li {padding-left:0px;}
	.artist__top__right__ul__li:last-child {border-bottom:0px;}
	.artist__top__right__ul__li__tit {position: relative;top:0;}
}
