.guide-box {
	&__table {
		@include basicTable1;
		table {
			colgroup {
				.col-1 {width:40%;}
				.col-2 {width:30%;}
				.col-3 {width:30%;}
			}
			td {text-align:left;}
		}
	}
	&__box {
		border:1px solid #ddd;
		border-top:1px solid #000;
		border-bottom:1px solid #000;
		padding:20px;
		margin-bottom:20px;
		&__item {
			border:1px solid #ddd;
			margin-bottom:20px;
			padding:20px;
			&:last-child {margin-bottom:0px;}
		}
	}
}
.h3Type1 {
	@include h3Type1;
}
.h3Type2 {
	@include h3Type2;
}
.h3Type3 {
	@include h3Type3;
}
.h4Type1 {
	@include h4Type1;
}
.h4Type2 {
	@include h4Type2;
}
.btn1 {
	@include btn1;
}
.btn2 {
	@include btn2;
}
.btn3 {
	@include btn3;
}
.btn4 {
	@include btn4;
}
.btn5 {
	@include btn5(200px);
}
.btn6 {
	@include btn6(200px);
}
.btn7 {
	@include btn7(200px);
}
.btn8 {
	@include btn8;
}
.btn9 {
	@include btn9;
}
.btn10 {
	@include btn10;
}
.btn11 {
	@include btn11;
}
.downBtn1 {
	@include downBtn1;
}
.downBtn2 {
	@include downBtn2;
}
.btnSmall-1 {
	@include btnSmall-1;
}
.btnSmall-2 {
	@include btnSmall-2;
}
.basicList {
	@include basicList;
}
.basicList1 {
	@include basicList1;
}
.basicList2 {
	@include basicList2;
}
.basicList3 {
	@include basicList3;
}

.basicTable1 {
	@include basicTable1;
}
.basicTable1_1 {
	@include basicTable1_1;
}
.basicTable2 {
	@include basicTable2;
}
.basicTable3 {
	@include basicTable3;
}
.basicTable4 {
	@include basicTable4;
}
.basicTable5 {
	@include basicTable5;
}
.basicTable6 {
	@include basicTable6;
}
.basicTable7 {
	@include basicTable7;
}