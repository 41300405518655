

/*	메인페이지
===========================*/

// 양친체 추가
@font-face {
    font-family: 'yangjin';
    src: url('https://fastly.jsdelivr.net/gh/supernovice-lab/font@0.9/yangjin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.inner {
	position: relative;
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 20px;
	box-sizing: border-box;

	@media (max-width: 768px) {
		padding: 0 12px;
	}
}

.main-section {position:relative;
	background-color:#fff;
	z-index: 8;
}
.main-section__in {position:relative;min-height: 500px;width:1400px;margin:0 auto;
	background-color:#fff;
}

@media screen and (min-width:1px) and (max-width:1200px) {
	.main-section__in {width:auto;}
}

@media screen and (min-width:1px) and (max-width:768px) {
	.main-section {padding-top:51px;}
}


.aos-item {height:150px;margin-bottom:40px;border:1px solid #000}

.main-visual{
	img{
		// border-radius: 20px;;
		// border-bottom-left-radius:0;
		// border-bottom-right-radius:0;
		// @include respond-to(sc2) {border-radius: 0}

	}
	.sub-slider-next{
		@include sprite($popup-next);
		font-size: 0;
		// text-indent:-99999;
	}
	.sub-slider-prev{
		@include sprite($popup-prev);
		font-size: 0;
		// text-indent:-99999;
	}
} 


.kit{
	padding-top: 50px;
	&_tag{
		width: 400px;
		margin: 0 auto;
		font-family: $font3;
		@include respond-to(sc4) {width: 90%;  margin: 0 auto; }
		&_list{
			overflow: hidden;
			&:after{
				content: '';
				clear: both;
			}
			&_li{
				float: left;
				width: (100%/5);
				box-sizing: border-box;
				margin-bottom: 15px;
				a{
					font-size: 24px;
					display: block;
					text-align: center;
					color: #fff;
					@include respond-to(sc4) {font-size: 18px;}
					&:hover,&:focus{text-decoration: underline; ;}
				}
			}
		}
	}
	&_search{
		width: 600px;
		margin: 0 auto;
		margin-top: 20px;
		position: relative;
		font-family: $font2;
		@include respond-to(sc3) {width: 90%; margin: 0 auto; }
		.kit_search_search-in_box{border: 0; width: 100%; border-radius: 5px; height: 40px; line-height: 40px;color: #dd6803; caret-color: #dd6803; font-size: 18px;}
		.kit_search_search-in_box::placeholder{color: #dd6803; padding-left: 10px;}
		.kit_search_search-in_box_bt{position: absolute; z-index: 9; top: 9px; right: 10px; padding-left: 10px; border-left: 1px solid #ccc;}
		
	}
}

.main-link {
	position: relative;

	.inner {
		@media (max-width: 768px) {
			background-color: #f15922;
			padding: 10px 12px;
		}
	}

	&_wrap {
		position: relative;
		width: 100%;
	}

	@include respond-to(sc1) {width: 100%; }

	@media only screen and (max-width: 910px) {padding-top: 0; }
	&_quick {
		width: 100%;
		overflow: hidden;
		font-family: $font2;
		ul{
			li{
				float: left;
				width: (100%/4);
				@include respond-to(sc3) {width: (100%/2);margin-bottom: 40px; }
				a{
					display: block;
					text-align: center;
					transform: scale(0.9);
					transition: all 0.2s ease 0s;
					img{
						vertical-align: middle;
					}
					span{
						display: block;
						margin-top: 10px;
						font-size: 18px;
						font-weight: 700;
					}
					&:hover,&:focus{
						transform: scale(1);
						transition: all 0.2s ease 0s;
					}
				}
			}
		}
	}
	
	&_button {
		width: 100%; position: absolute; top: calc(-180px - 75px);

		ul{
			overflow: hidden; display: flex; justify-content: space-between;

			li{
				display: block;
				// width: 180px;
				width: 13%;
				height: 180px;
				font-family: $font3;
				@include respond-to(sc3) {width: (100%/3);}
				@include respond-to(sc4) {width: (100%/2);}
			
				a{
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					background-color: rgba(0, 0, 0, 0.5);
					border-radius: 20px;
					-webkit-transition: all 0.3s ease 0s;
					transition: all 0.3s ease 0s;
					padding: 0 5px;
					@include respond-to(sc4) {font-size: 16px; font-size: 1.6rem;}
					&:hover,
					&:focus {background: linear-gradient(-140deg, rgba(209,24,32,0.7) 0%, rgba(255,124,0,0.7) 100%); border: none;}

					span {
						font-size: 18px;
						line-height: 20px;
						font-weight: bold;
						color: #fff;
						text-align: center;
						padding-top: 20px;
					}
				}
			}
		}

		@media (max-width: 1300px) {
			ul {

				li {
					width: 14%;
					height: 140px;

					a {
						img {
							max-width: 50%;
						}

						span {
							font-size: 14px;
							line-height: 16px;
						}
					}
				}
			}
	
		}

		@media (max-width: 1000px) {
			ul {

				li {
					width: 15%;
					height: 140px;

					a {
						img {
							height: 40px;
						}

						span {
							font-size: 14px;
							line-height: 16px;
						}
					}
				}
			}
	
		}

		@media (max-width: 768px) {
			position: relative;
			top: inherit;

			ul {
				flex-wrap: wrap;

				li {
					width: 30%;
					height: 120px;
					margin: 5px 0;

					a {
						background-color: #dc4813;

						span {
							font-size: 16px;
							padding-top: 10px;
							line-height: 1.2;
						}
					}
				}
			}

		}

		@media (max-width: 500px) {

			ul {
				li {
					height: 100px;
					
					a {
						background-color: #dc4813;

						img {
							height: 30px;;
						}

						span {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}

.section3{

}

.news {
	padding-top: 80px;
	padding-bottom: 70px;

	@media (max-width: 768px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.news_wrap {
		display: flex;
		justify-content: space-between;

		.news_cont {
			width: calc(100% - 324px);
			padding-right: 60px;
			box-sizing: border-box;

			.news-tab {

				// 상단 목록
				.news_cate_box {
					position: relative;
					z-index: 99;
					display: flex;
					justify-content: space-between;

					&.on {
						z-index: 101;
					}
				}

				&__h3 {
					position: relative; font-size: 35px; color: #111; font-family: $font4; z-index: 1;

					&::before {
						content: ''; display: block; width: 46px; height: 46px; background-color: #faee00; border-radius: 50%; position: absolute; top: -18px; left: -20px; z-index: -1;
					}
				}

				&__cont {
					z-index: 100;
				}

				&__label {
					display: none;
				}

				&__ul {
					position: relative;
					display: flex;
					padding-right: 70px;

					&::before {
						content: '';
						display: block;
						width: 200px;
						height: 1px;
						background-color: #ddd;
						position: absolute;
						top: 16px;
						left: -200px;

						@media (max-width: 1500px) {
							display: none;
						}
					}


					&__li {
						padding-left: 28px;

						a {
							position: relative;
							font-size: 22px;
							font-weight: 600;
							letter-spacing: -2px;
							color: #555;

							&:focus {border: none;}

							&::before {
								content: ''; display: block; width: 5px; height: 5px; background-color: #c7c7c7; border-radius: 50%; position: absolute; top: calc(50% - 2px); left: -10px;
							}
						}

						&.on, :hover {

							a {
								font-weight: 900;
								color: #f04c1b;

								&:focus {border: none;}

								&::before {
									background-color: #f04c1b;
								}
							}
						}

						&:hover {
							
						}
					}
				}

				@media (max-width: 768px) {
					&__h3 {
						font-size: 28px;
						max-width: 200px;

						&::before {
							width: 30px;
							height: 30px;
							top: -10px;
							left: -10px;
						}
					}
				}

				// 하단 내용
				&__cont {

					&__more {
						position: absolute;
						top: -30px;
						right: 0;

						&:focus {
							border: none;
						}

						&::before {
							content: '';
							display: block;
							background-image: url(../../resources/images/site/ico_news_more.png);
							background-repeat: no-repeat;
							background-position: 50% 0%;
							background-size: cover;
							width: 19px;
							height: 19px;
							position: absolute;
							top: -8px;
							right: 6px;
						}
					}

					&__ul {
						display: flex;
						justify-content: space-between;
						padding-top: 38px;

						&::after {display: none;}

						&__li {
							width: 27%;

							a {
								
								.news-category {
									display: inline-block;
									background-color: #f3ede6;
									border-radius: 14px;
									padding: 0 16px;
									margin-bottom: 20px;

									span {
										font-size: 16px;
										font-weight: 700;
										font-family: $font3;
										line-height: 29px;
										color: #f04c1b;
									}
								}
								.news-tit {
									font-size: 25px;
									font-weight: 600;
									font-family: $font3;
									line-height: 1.2;
									letter-spacing: -1px;
									color: #111;
									margin-bottom: 30px;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									white-space: break-spaces;
								}
								.news-txt {
									font-size: 18px;
									font-weight: 400;
									font-family: $font3;
									line-height: 1.2;
									letter-spacing: -1px;
									color: #444;
									margin-bottom: 30px;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 3;
									-webkit-box-orient: vertical;
									white-space: break-spaces;
								}
								.news-date {
									position: relative;
									font-size: 17px;
									font-weight: 600;
									font-family: $font3;
									color: #777;
									padding-left: 26px;

									&::before {
										content: '';
										display: block;
										background-image: url(../../resources/images/site/ico_date.png);
										background-repeat: no-repeat;
										background-position: 50% 0%;
										background-size: cover;
										width: 18px;
										height: 18px;
										position: absolute;
										top: 4px;
										left: 0;
									}
								}
							}
						}
					}
				}

			}
		}
	}

	@media screen and (min-width: 1001px) and (max-width: 1300px) {
		
	
	}

	@media screen and (min-width: 769px) and (max-width: 1000px) {
		
	
	}

	@media (max-width: 1300px) {
		.news_wrap {
			flex-direction: column;

			.news_cont {
				width: 100%; padding-right: 0;
			}

			.side_link {
				flex-direction: column;
				width: 100%;
				margin-top: 30px;

				.right_bg_item {
					&_ul {
						&_li {
							width: 24%;
						}
					}
				}
			}
		}
	
	}

	@media (max-width: 1000px) {
		.news_wrap {
			.news_cont {
				.news-tab {
					&__ul {
						&__li {
							a {
								font-size: 18px;
							}
						} 
					}

					&__cont {
						&__more {
							top: -34px;
						}

						&__ul {
							&__li {
								a {
									.news-category {
										span {
											font-size: 14px;
										}
									}

									.news-tit {
										font-size: 20px;
									}

									.news-txt {
										font-size: 16px;
									}

									.news-date {
										font-size: 14px;

										&::before {
											top: 2px;
										}
									}
								}
							}
						}
					}
				}
			}

			.side_link {
				.right_bg_item {
					&_ul {
						&_li {
							width: 24%;
						}
					}
				}
			}
		}
	
	}

	.news-tab__select_mob {
		display: none;
	}

	@media (max-width: 768px) {
		.news_wrap {

			.news_cont {

				.news-tab {

					.news_cate_box {
						flex-direction: column;
					}

					&__h3 {

					}

					.news-tab__select * {box-sizing: border-box;}
					.news-tab__select {
						position: relative;
						width: 100%;
						height: 60px;
						border-radius: 10px;
						background-color: #ebebeb;
						border: none;
						cursor: pointer;
						margin-top: 30px;
						display: none;
					}

					.news-tab__select:after {
						content: '';
						display: block; 
						width: 15px;
						height: 9px; 
						position: absolute; 
						top: 50%; 
						transform: translateY(calc(-50% + 0.5px));
						right: 20px;
						background-image: url(../../resources/images/site/ico_select_box_arr.png);
						background-repeat: no-repeat;
						background-position: 50% 0%;
						background-size: 100% 100%;
						transition: .3s ease-in;
					}

					.news-tab__select.active:after {
						transform: rotate(180deg) translateY(calc(50% - 0.5px));
					}

					.news-tab__select .news-tab__label {
						display: flex;
						align-items: center;
						width: inherit;
						height: inherit;
						border: 0 none;
						outline: 0 none;
						padding-left: 15px;
						background: transparent;
						cursor: pointer;
						font-size: 22px;
						font-weight: 700;
						font-family: $font3;
						color: #111;
					}

					.news-tab__select .news-tab__ul {
						position: absolute; 
						top: 64px;
						left: 0;
						width: 100%;
						background: #fff;
						color: #fff;
						list-style-type: none;
						padding: 0;
						border: 2px solid transparent;
						border-radius: 6px;
						overflow: hidden;
						max-height: 0;
						transition: .3s ease-in;
					}

					.news-tab__select.active .news-tab__ul {
						border: 2px solid #999;
					}

					.news-tab__select .news-tab__ul::-webkit-scrollbar {width: 6px;}
					.news-tab__select .news-tab__ul::-webkit-scrollbar-track {background: transparent; }
					.news-tab__select .news-tab__ul::-webkit-scrollbar-thumb {background: #303030; border-radius: 45px;}
					.news-tab__select .news-tab__ul::-webkit-scrollbar-thumb:hover {background: #303030;}

					.news-tab__select.active .news-tab__ul {
						max-height: 500px;
					}

					.news-tab__select .optionItem {
						border-bottom: 1px dashed rgb(0, 0, 0);
						padding: 10px 15px 10px;
						transition: .1s;
					}

					.news-tab__select .optionItem:hover {
						background: #fff;
					}

					.news-tab__select .optionItem:last-child {
						border-bottom: 0 none;
					}

					.news-tab__select_mob {
						display: block;

						position: relative;
						width: 100%;
						height: 40px;
						margin-top: 30px;

						select {
							width: inherit;
							height: inherit;
							background: #ebebeb;
							border: 0 none;
							outline: 0 none;
							padding: 0 10px;
							position: relative;
							border-radius: 5px;
							z-index: 3; 
						}

						option {
							background: #ddd;
							color: #000;
							padding: 3px 0;
							font-size: 16px;
						}
					}


					&__label {
						display: block;
					}

					&__ul {
						width: 100%;
						flex-direction: column;
						padding-right: 0;

						&::before {
							display: block;
						}

						&__li {
							width: 100%;
						} 
					}

					&__cont {
						z-index: 100;

						&__more {
							top: -110px;

							&::before {
								top: 12px;
							}
						}
						
						&__ul {
							padding-top: 30px;

							&__li {
								width: 50%;
								padding: 0 20px;

								&:nth-child(3) {
									display: none;
								}
								
								a {
									.news-tit {
										font-size: 18px;
										margin-bottom: 20px;
									}

									.news-txt {
										font-size: 14px;
										margin-bottom: 20px;
									}

									.news-date {
										
									}
								}
							}
						}
					}
				}
			}

			.side_link {
				.right_bg_item {
					&_ul {
						&_li {
							width: 49%;

							a {
								height: auto;
								padding: 30px 0;

								span {
									padding-top: 20px;
								}
							}
						}
					}
				}
			}
		}
	
	}

	@media (max-width: 450px) {
		.news_wrap {

			.news_cont {

				.news-tab {

					.news-tab__select {
						height: 40px;
						margin-top: 20px;

						.news-tab__label {
							font-size: 16px;
						}
					}

					
					
					.news-tab__select * { box-sizing: border-box; }
					.news-tab__select {
						
					}

					.news-tab__select:after {
						
					}

					.news-tab__select .news-tab__label {
						
					}

					.news-tab__select .news-tab__ul {
						top: 46px;
					}

					.news-tab__select.active .news-tab__ul {
						border: 2px solid #999;
					}

					.news-tab__select .optionItem {
						border-bottom: 1px dashed rgb(0, 0, 0);
						padding: 10px 15px 10px;
						transition: .1s;
					}
					
					&__ul {

						&::before {

						}

						&__li {

							a {
								font-size: 14px;
							}
						} 
					}

					&__cont {

						&__ul {
							padding-top: 30px;

							&__li {
								padding: 0 10px 0 0;

								&:nth-child(3) {
									display: none;
								}

								a {

									.news-category {

										span {
											
										}
									}

									.news-tit {
										font-size: 16px;
									}

									.news-txt {
										font-size: 12px;
									}

									.news-date {
										font-size: 12px;

										&::before {
											width: 14px;
											height: 14px;
										}
									}
								}
							}
						}
					}
				}
			}

			.side_link {

				.right_bg_item {

					&_ul {

						&_li {
							width: 49%;

							a {
								padding: 15px 0;

								span {
									font-size: 16px;
									padding-top: 15px;
								}
							}
						}
					}
				}
			}
		}
	
	}
}

.side_link {
	display: flex;
	width: 324px;

	.right_bg_item {

		&_ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			&_li {
				margin-bottom: 7px;
				width: 48.5%;

				a {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 168px;
					border-radius: 5px;

					img {

					}

					span {
						font-size: 20px;
						font-family: $font4;
						color: #fff;
						padding-top: 30px;
					}
				}

				&:nth-child(1) {
					a {background-color: #4767b4;}
				}
				&:nth-child(2) {
					a {background-color: #2d97be;}
				}
				&:nth-child(3) {
					a {background-color: #ee6c6c;}
				}
				&:nth-child(4) {
					a {background-color: #f4bc10;}
				}
			}
		}
	}
}


.map-info {
	background-color: #f5e9d9; background-image: url("../../resources/images/site/bg_map_info_01.png"); background-repeat: no-repeat; background-position: 50% 50%; background-size: cover; padding-top: 80px; padding-bottom: 160px; margin-bottom: 10px;

	@media (max-width: 1300px) {
		margin-bottom: 0;
	}

	@media (max-width: 768px) {
		padding-top: 50px; padding-bottom: 50px;
	}

	&:before {
		content: '';
		display: block;
		background-image: url("../../resources/images/site/bg_map_info_02.png");
		background-repeat: no-repeat;
		background-position: 50% 0%;
		background-size: cover;
		width: 100%;
		height: 140px;
		position: absolute;
		bottom: 0;
	}

	.map_info_wrap {display: flex;}
	.map_info_left {width: 50%; padding-right: 74px;}
	.map_info_right {width: 50%;}
	.map_info_txt_box h3 {position: relative; font-size: 35px; color: #111; font-family: $font4; z-index: 1;}
	.map_info_txt_box h3:before {content: ''; display: block; width: 46px; height: 46px; background-color: #faee00; border-radius: 50%; position: absolute; top: -18px; left: -20px; z-index: -1;}
	.map_info_txt_box p {font-size: 18px; color: #888; padding-top: 20px; padding-bottom: 30px; font-family: $font3;}
	.map_info_data_wrap {background-color: #fff; border-radius: 37px; padding: 30px; -webkit-box-shadow: 7px 7px 9px 0 rgba(0, 0, 0, 0.1); box-shadow: 7px 7px 9px 0 rgba(0, 0, 0, 0.1);}

	.map_info_value_box {display: flex; justify-content: space-between;}
	.map_value {display: flex; justify-content: center; align-items: center; flex-direction: column; width: 31.8%; background-color: #f5f5f5; border-radius: 25px; padding-top: 30px; padding-bottom: 20px; cursor: pointer;}
	.map_value h4 {font-size: 20px; font-weight: bold; line-height: 1.0; color: #777; padding-bottom: 20px;}
	.map_value p {font-size: 18px; font-weight: bold; line-height: 1.0; color: #ee6c6c;}
	.map_value p strong {font-size: 40px; font-weight: 900;}
	.map_value.active {background-color: #ee6c6c;}
	.map_value.active h4 {color: #fff;}
	.map_value.active p {color: #fff;}

	.map_info_data_txt {position: relative; border-bottom: 1px solid #9e9e9e; padding-bottom: 14px; margin-top: 30px; margin-bottom: 18px;
	
		h5 {font-size: 26px; font-weight: 700; line-height: 1.4; color: #111;}
		a {position: absolute; top: 6px; right: 0;

			&:focus {border: none;}
		
			span {position: relative; font-size: 16px; font-weight: 800; font-family: $font3; color: #ba4f5f; padding-right: 20px;
				&::before {
					content: '';
					display: block;
					width: 15px;
					height: 14px;
					background-image: url(../../resources/images/site/ico_map_info_more.png);
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-position: 50% 50%;
					position: absolute;
					top: calc(50% - 7px);
					right: 0px;
				}
			}
		}
	}

	.map_info_data_box {}
	.map_graph {display: none;}
	.map_graph.active {display: block;}
	.map_graph img {}
	.map_info_img_box {padding-top: 50px; text-align: right;}
	.map_img {display: none; text-align: right;}
	.map_img.active {display: block;}
	.map_graph img {}

	@media (max-width: 1300px) {
		&:before {
			display: none;
		}
	}

	@media screen and (min-width: 769px) and (max-width: 1000px) {
		padding-top: 50px;
		padding-bottom: 50px;

		.map_info_txt_box {
			h3 {
				font-size: 28px;
			}

			p {
				font-size: 14px;
			}
		}

		.map_value {

			h4 {
				font-size: 16px;
			}

			p {
				font-size: 14px;

				strong {
					font-size: 30px;
				}
			}
		}
	}

	@media (max-width: 768px) {
		.map_info_wrap {
			flex-direction: column;
		}

		.map_info_left {
			width: 100%;
			padding-right: 0;
		}

		.map_info_right {
			width: 100%;
		}

		.map_img {
			text-align: center;
		}

		.map_info_txt_box {

			h3 {
				font-size: 28px;
	
				&::before {
					width: 30px;
					height: 30px;
					top: -10px;
					left: -10px;
				}
			}

			p {
				font-size: 16px;
			}
		}
	}

	@media (max-width: 450px) {
		.map_info_txt_box {

			p {
				font-size: 14px;
			}
		}
	
		.map_info_data_wrap {
			border-radius: 30px;
			padding: 10px;
		}

		.map_value {
			padding: 15px;
			box-sizing: border-box;

			h4 {
				font-size: 16px;
				padding-bottom: 10px;
			}

			p {
				font-size: 14px;

				strong {
					font-size: 30px;
				}
			}
		}

		.map_info_data_txt {
			margin-top: 20px;
			margin-bottom: 10px;

			h5 {
				font-size: 20px;
			}

			a {

				span {
					font-size: 14px;
				}
			}
		}
	}
}