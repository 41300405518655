.survey-type {
	td.result {padding:5px;}
	.result__btn {
		@include btn5(100px);
		font-size:15px !important;
		background-color: #da4453 !important;
		height: 45px !important;
		line-height: 45px !important;

	}
	.stat .ing {color:#1062dc;}
	.stat .comp {color:#111}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.board_list.survey-type tbody td {margin-right:20px;}
	.board_list.survey-type tbody td:before {display:none}
	.board_list.survey-type tbody td.result {width:100%;}
}
.survey {
	width:80%;
	margin:0 auto;
	&__top {
		border:3px solid #e9ecf0;
		padding:40px 120px 40px 120px;
		margin-bottom:50px;
		font-size:19px;
		font-weight: bold;
		color:#000;
		&__in {
			position: relative;
			&:before {
				@include sprite($notice);
				position: absolute;
				top:-5px;
				left:-60px;
			}
		}

	}
	@media screen and (min-width:1px) and (max-width:768px) {
		&__top {padding:20px;

			&__in {
				&:before {
					display:none;
				}
			}
		}
	}
	&__cont {
		width:80%;
		margin:0 auto;
		&__ul {
			&__li {
				&__title {
					font-size: 22px;
					font-weight: bold;
					margin-bottom:10px;
				}
				&__choose {
					margin-left:10px;
					margin-bottom:30px;

					&__item {
						padding:10px;
						border:1px solid #fff;
						border-radius: 4px;
						&.on {
							background-color:#e9ecf0;
							border:1px solid #d5d9dd;
						}
					}
				}
			}
		}
	}
	&__bottom {
		text-align: center;
		&__btn {
			@include btn3;
		}
	}
}

@media screen and (min-width:1px) and (max-width:1000px) {
	.survey {width:auto;}
	.survey__cont__ul__li__title {font-size:16px;}
	.survey__cont {width:auto;}
}


// 그래프
.survey-result-total {
	width:80%;
	margin:0 auto;
	box-sizing: border-box;
	background-color: #f5f5f5;
	border:1px solid #ddd;
	text-align:center;
	padding-top:10px;
	padding-bottom:10px;
	&__cont {
		position: relative;
		&:before {
			content:' ';
			@include sprite($smile);
			position: absolute;
			top:2px;left:-22px;
		}
	}
}
.survey-result {
	width:80%;
	margin:0 auto;
	&__grph {
		padding: 15px;
		border:1px solid #ddd;
		border-top:0px;
		position: relative;
		&__tit {margin-bottom:7px;}
		&__num {
			position: absolute;
			top:15px;
			right:15px;
			&__per {}
		}
		&__bar {
			background-color:#f5f5f5;
			border:1px solid #ddd;
			height:28px;
			&__in {
				width:0px;
				transition: all 2s ease 0s;
				height:30px;
				background-color:#ed374c;
				margin-top:-1px;

			}
		}

	}
}

.survey-result__grph:nth-child(2) .survey-result__grph__bar__in {background-color:#e77224;}
.survey-result__grph:nth-child(3) .survey-result__grph__bar__in {background-color:#ffc516;}
.survey-result__grph:nth-child(4) .survey-result__grph__bar__in {background-color:#5db75d;}
.survey-result__grph:nth-child(5) .survey-result__grph__bar__in {background-color:#2769ee;}
.survey-result__grph:nth-child(6) .survey-result__grph__bar__in {background-color:#14367b;}
.survey-result__grph:nth-child(7) .survey-result__grph__bar__in {background-color:#b42fec;}
.survey-result__grph:nth-child(8) .survey-result__grph__bar__in {background-color:#e63871;}
.survey-result__grph:nth-child(9) .survey-result__grph__bar__in {background-color:#29dba7;}
.survey-result__grph:nth-child(10) .survey-result__grph__bar__in {background-color:#3a8871;}



@media screen and (min-width:1px) and (max-width:1000px) {
	.survey-result-total {width:auto;}
	.survey-result {width:auto;}
}
@media screen and (min-width:1px) and (max-width:560px) {
	.survey-result__grph__num {position: relative; top:0; right:0;margin-bottom:5px;display:block;}
}

/*input checkbox 설문조사쪽 디자인 설정*/
/*체크박스*/
input[class="check-type-survey"] + label {
	display: inline-block;
	width: 17px;
	height: 17px;
	border: 2px solid #cccccc;
	background-color:#dddddd;
	cursor: pointer;
	border-radius: 30px;
	position: relative;
	font-size: 0;
	text-indent: -9999px;
	&:before {
		content:' ';
		@include sprite($check-type);
		position: absolute;
		top:3px;left:3px;
	}
}

input[class="check-type-survey"]:checked + label {
	background-color: #666666;
}

input[class="check-type-survey"]:focus + label {
	border: 2px solid gold;
}
input[class="check-type-survey"] {
	position: absolute;
	left: -9999px;
}
/*라디오버튼*/
input[class="radio-type-survey"] + label {
	display: inline-block;
	width: 17px;
	height: 17px;
	border: 0;
	cursor: pointer;
	position: relative;
	font-size: 0;
	text-indent: -9999px;
	&:before {
		content:' ';
		@include sprite($radio-type-off);
		position: absolute;
		top:3px;left:4px;
	}

}

input[class="radio-type-survey"]:checked + label {
	&:before {
		@include sprite($radio-type-on);
	}
}

input[class="radio-type-survey"]:focus + label {}
input[class="radio-type-survey"] {
	position: absolute;
	left: -9999px;
}
.choose-label-text {
	margin-left:5px;
	color:#7d7d7d;
	font-weight:bold;
	font-size:16px;
	padding:5px;
}
.choose-textarea {
	display: block;
	box-sizing: border-box;
	border:1px solid #d5d9dd;
	background-color: #e9ecf0;
	padding:20px;
	height: 100px;
	font-size:16px;
	margin-top:10px;
	width:100%;
	color:#768393;
	&.long {height:150px;}
}