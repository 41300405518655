
/*약관 동의 페이지*/

.apply-agree {

	&__tit {
		margin-bottom: 20px;
		& .label-text {
			font-size: 16px;
			margin-left: 5px;
		}

	}
	&__tit.allcheck {
		padding-bottom: 20px;
		border-bottom:1px solid #eee;
		margin-bottom:60px;
	}

	&__textbox {
		height: 330px;
		overflow: auto;
		padding-top: 20px;
		padding-bottom: 20px;
		margin-bottom: 30px;
		border: 1px solid #eee;
		padding-left: 20px;
		padding-right: 20px;
		border-top: 1px solid #000;
		color:#666;
	}
	.tips{margin-bottom: 20px;}
}


@media screen and (min-width:1px) and (max-width:768px) {

}
