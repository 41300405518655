.main-search {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	// background: url(../../resources/images/basic/opa2.png);
	background-color: rgba(0,0,0,0.4);
	z-index: 999999;

	* {
		box-sizing: border-box;
	}

	.kit_search_search-in_box{width: 85%;;}


	&-container {
		background-color: rgba(198,69,22,0.87);
		padding-top: 90px;
		padding-bottom: 42px;

		&-wrap {
			position: relative;
			width: 100%;
			max-width: 1440px;
			margin: 0 auto;
			padding: 0 20px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;

			h3 {
				font-size: 50px;
				font-weight: 700;
				letter-spacing: -1px;
				font-family: $font3;
				color: #fff;
				padding-bottom: 30px;
			}
			
			&-box {
				position: relative;
				// top: 50%;
				// left: 50%;
				// transform: translate(-50%, -50%);
				display: flex;
				align-items: center;
				justify-content: center;
				width: 66%;
				white-space: nowrap;

				@media screen and (min-width:1px) and (max-width:450px) {
					width:90%
				}

				form {
					width: 100%;
				}
			}
		}
	}

	input[type="search"] {
		font-family: $font3;
		border: 3px solid #f15922;
		background: #fff;
		color: #999;
		font-size: 20px;
		font-weight: 600;
		border-radius: 31px;
		padding: 21px 10px 21px 40px;

		&::placeholder {
			color: #999;
		}
	
		&:focus {
			outline: none;
		}
		&:active{
			outline: none;
		}
	}
	input[type="image"] {
		position: absolute;
		top: 50%;
		right: 60px;
		-webkit-transform: translateY(calc(-50% + 0.5px));
		-ms-transform: translateY(calc(-50% + 0.5px));
		transform: translateY(calc(-50% + 0.5px));
		font-size: 0;

		&:focus {
			border:2px solid #fff;
			border-radius: 4px;
		}
	}

	button {
		background-color: transparent;
		border: none;
	}

	.sc_close_bt{
		position: absolute;
		top: -20px;
		right: 75px;
		display: block;
		cursor: pointer;
		color: #fff;
		font-size: 0;
		&:focus{border: 1px solid; outline: 3px tan;}

		.icon-search-close {
			content: '';
			vertical-align: middle;
			display: inline-block;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-image: url("../../resources/images/site/ico_search_close.png");
			width: 42px;
			height: 42px;
		}
	}

	&.on{
		display: block;
	}


	@media (max-width: 1000px) {
		.kit_search_search-in_box{width: 90%;}

		&-container {
			padding-top: 60px;
			padding-bottom: 30px;
	
			&-wrap {
	
				h3 {
					font-size: 40px;
					padding-bottom: 30px;
				}
			}
		}	
		
		input[type="search"] {
			border: 2px solid #f15922;
			font-size: 18px;
			padding: 15px 10px 15px 30px;
		}

		input[type="image"] {
			right: 0px;
			width: 50px;
		}
	}

	@media (max-width: 768px) {
		.kit_search_search-in_box{width: 85%;}

		&-container {
			padding-top: 50px;
			padding-bottom: 30px;
	
			&-wrap {
	
				h3 {
					font-size: 30px;
					padding-bottom: 20px;
				}
			}
		}	
		
		input[type="search"] {
			border: 2px solid #f15922;
			font-size: 16px;
			padding: 10px 10px 10px 30px;
		}

		input[type="image"] {
			width: 50px;
		}	

		.sc_close_bt{
			top: -20px;
			right: 40px;
	
			.icon-search-close {
				width: 30px;
				height: 30px;
				background-size: 100% 100%;
			}
		}
	}

	@media (max-width: 450px) {
		.kit_search_search-in_box{width: 85%;}

		&-container {
			padding-top: 50px;
			padding-bottom: 30px;
	
			&-wrap {
	
				h3 {
					font-size: 24px;
				}
			}
		}	
		
		input[type="search"] {
			font-size: 14px;
			padding: 10px 10px 10px 20px;
		}

		input[type="image"] {
			width: 40px;
		}
	}
}