.main-sns {

	&__in {
		@include floatWrap;
		& > img {
			width:100%;

		}

		&__box {
			&__tit {
				height:35px;
				font-family: 'Do Hyeon','Noto Sans KR', sans-serif; font-size:28px;
				position: relative;
				margin-bottom: 40px;

				@media screen and (min-width:1px) and (max-width:1200px) {
					& {margin-bottom:20px;}
				}

				&__a{
					display:inline-block;
					width: auto;
					height: 35px;

					//text-indent:-9999px;
					&--1 {
						&:before {
							@include sprite($main-sns-fb);
							margin-right:5px;
						}

					}
					&--2 {
						&:before {
							@include sprite($main-sns-insta);
							margin-right:5px;
						}

					}
				}
			}
		}
		&__box1 {
			float:left;
			width:50%;
		}

		&__box2 {
			float:right;
			width:50%;

		}
	}
}

/*facebook*/
.variable-box {
	position: relative;
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-bottom: 114.3%; /*16:9*/
	background-color:#fff;
	color:#fff;


	&__in {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&__loading {
			display:block;
			width:106px;
			height:106px;
			background-image: url('../../resources/images/basic/loading.gif');
			position: absolute;
			top:90px;
			left:50%;
			transform: translateX(-50%);
		}
		iframe { width: 100%; height: 100%}
	}
}

.fb_iframe_widget span {width:100% !important;height:100% !important;vertical-align: top !important;}
@media screen and (min-width:1px) and (max-width:1500px) {
	.fb_iframe_widget {width:100% !important;}
	.fb-page iframe {width:100% !important;max-width: 100%;}
}

/*instagram*/
.instagram {
	overflow: hidden;
	//width: 100%;
	padding-left:9px;
	&__item {
		float: left;
		width: 33.33%;

		& > a {
			display: block;
			width: 100%;
			padding-top: 115%;
			position: relative;
			background: no-repeat center center;
			background-size: cover;

			&:before {content: '';position: absolute;left: 0;right: 0;top: 0;bottom: 0;background: #000000;background: rgba(0, 0, 0, .8);background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .8) 100%);background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .8) 100%);background: radial-gradient(ellipse at center, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .8) 100%);z-index: 1;opacity: 0;-webkit-transition: opacity .55s cubic-bezier(.215, .61, .355, 1);transition: opacity .55s cubic-bezier(.215, .61, .355, 1);}

			&:hover:before {opacity: 1;}
			&:hover p {opacity: 1;}
		}
		& p {width: 100%;padding: 19px 5px;position: absolute;left: 0;top: 50%;font-size: 15px;font-weight: 400;text-align: center;color: #fff;transform: translateY(-50%);z-index: 2;opacity: 0;box-sizing:border-box;-webkit-transition: opacity .55s cubic-bezier(.215,.61,.355,1);transition: opacity .55s cubic-bezier(.215,.61,.355,1);}
	}
}

/*
@media screen and (min-width:1px) and (max-width:1500px) {
	.instagram__item > a {padding-top:118.7%;}
	.fb_iframe_widget {height:100% !important;}
	.fb_iframe_widget iframe  {height:100% !important;}
}
@media screen and (min-width:1px) and (max-width:560px) {
	.main-sns__in__box1 {float:none;width:100%;margin-bottom:2%;}
	.main-sns__in__box2 {float:none;width:100%;}

	.instagram__item > a {padding-top:100%;}


	.variable-box {height:auto;padding-bottom:0;}
	.variable-box__in {position: relative;}
	.instagram {padding-left:0;}
	.fb_iframe_widget iframe {position: relative !important;}
	.fb_iframe_widget span {width:auto !important;height:auto !important;display: block !important;margin:0 auto;width:370px !important}
}
*/

@media screen and (min-width:1px) and (max-width:1500px) {
	.instagram__item > a {padding-top:118.7%;}
}
@media screen and (min-width:1px) and (max-width:560px) {
	.main-sns__in__box1 {float:none;width:100%;margin-bottom:2%;}
	.main-sns__in__box2 {float:none;width:100%;}

	.instagram__item > a {padding-top:100%;}

	.variable-box {height:auto;padding-bottom:0;}
	.variable-box__in {position: relative;}
	.instagram {padding-left:0;}
	.fb_iframe_widget {height:100%;}
	.fb_iframe_widget iframe {position: relative !important;}
	.fb_iframe_widget span {width:auto !important;height:auto !important;display: block !important;margin:0 auto;width:355px !important}
}