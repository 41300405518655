.color_1{color: #e3006a;}

// 다운로드

.download{
		overflow: hidden;
		margin-top: 50px;
		margin-bottom: 50px;
		text-align: center;
		a{
			border: 1px solid;
			border-top-left-radius: 15px;
			border-top-right-radius: 30px;
			border-bottom-right-radius: 15px;
			border-bottom-left-radius: 30px;
			background: #f36d20;
			color: #fff;
			min-width: 200px;
			line-height: 60px;
			height: 60px;
			text-align: center;
			display: inline-block;
			font-size: 21px;
			transform: scale(0.97);transition: all 0.3s ease 0s;
			@include respond-to(sc3) {
				width: 70%;
				font-size: 18px;
			}
			&:hover,&:focus{transform: scale(1);transition: all 0.3s ease 0s; background: #e71c0f;}
			&:nth-child(1){
				padding-left: 50px;
				@include respond-to(sc3) { padding-left: 30px;}
				&:after {
					position: absolute;
					top:50%;
					transform: translateY(-50%);
					left:40px;
					@include sprite($dn_ok);
				}
			}
			&:nth-child(2){
				margin-left: 40px;
				padding-left: 50px;
				@include respond-to(sc3) {margin-left: 0px; padding-left: 30px;}
				&:after {
					position: absolute;
					top:50%;
					transform: translateY(-50%);
					left:40px;
					@include sprite($dn_go);
				}
			}
		}
}
// 인사말
.greeting{
	font-family: $font3;
	&_wrap{
		overflow: hidden;
		&_left{
			float: left;
			width: 40%;
			@include respond-to(sc2) {width: 100%; float: none; text-align: center;}
		}
		&_right{
			float: right;
			width: 60%;
			@include respond-to(sc2) {width: 100%;float: none;}
			h2{font-size: 15px;padding-bottom: 40px; padding-top:30px;
			// background: url(../../resources/images/site/greeting_bg.png) no-repeat;
				background-size: 100% ; background-position:  center; line-height: 1.5; font-weight: 600;}
			p{margin-bottom: 20px; color: #0e0e0e; font-weight: 400; line-height: 1.6; font-size: 14px;}
		}
	}
}
// 연혁
.history{
	&_wrap{
		&_ul{
			&_li{padding: 8px 0 60px 120px; position: relative; 
				b{position: absolute;left: 0;top: -4px;font-size: 21px;	letter-spacing: -1px;font-weight: 700;color: #fa783c; font-size: 30px;;}
				span{display: inline-block;margin-right: 10px;	font-size: 16px;color: #000; min-width: 60px;}
				&:before{display: block;position: absolute;left: 0;top: 12px;	z-index: 2;	width: 15px;height: 15px;border-radius: 50%;border: 4px solid #fa7b41;background: #fff;box-sizing: border-box;content: '';left:85px;}
				&:after{content: ''; background: #e0e0e0; width: 3px; height: 100%;position: absolute; top: 14px; left: 91px;}
				&:last-child:after{display: none;}
				ul{
					li{font-size: 16px; color: #333; margin-bottom: 5px;}
				}
			}
		}
	}
}

// 고객서비스헌장

.hservice{
	&_wrap{
		color: #484848;
		font-size: 16px;
		&_cont{margin-top: 50px; margin-bottom: 50px;}
	}
}

// CI 소개

.ci{
	&_wrap{
		&_s1{width: 500px;  margin: 0 auto; text-align: center;
			@include respond-to(sc4) {width: 100%;}
			&_cont{margin-top: 30px; margin-bottom: 30px; line-height: 1.5;}
			&_btn{
				overflow: hidden;
				a{
					border: 1px solid;
					border-top-left-radius: 15px;
					border-top-right-radius: 30px;
					border-bottom-right-radius: 15px;
					border-bottom-left-radius: 30px;
					background: #f36d20;
					color: #fff;
					min-width: 200px;
					line-height: 50px;
					height: 50px;
					text-align: center;
					font-size: 21px;
					transform: scale(0.97);transition: all 0.3s ease 0s;
					&:hover,&:focus{transform: scale(1);transition: all 0.3s ease 0s; background: #e71c0f;}
					@include respond-to(sc4) {float: none  !important; display: block; font-size: 16px;}
				}
				a:nth-child(1){float: left;}
				a:nth-child(2){float: right;}
			}
		}
		&_s2{
			overflow: hidden;
			margin-top: 100px;
			&_col2{float: left; width: 50%; @include respond-to(sc3) {width: 100%; float: none; margin-top: 40px;}}
		}
		&_s3{
			margin-top: 100px;
			@include respond-to(sc3) {margin-top: 40px;}
		}
		&_s4{
			margin-top: 100px;
			@include respond-to(sc3) {margin-top: 40px;}
			&_col2{float: left; width: 50%;  @include respond-to(sc3) {width: 100%; float: none; margin-top: 40px;}}
		}
	}
}

.merchant{
	&_wrap{
		&_cont{
			margin-bottom: 50px;
			margin-top: 50px;
		}
		.basicList{ margin-top: 50px;}
	}
}


.custom{
	&_wrap{
		&_cont{
			margin-bottom: 50px;
			margin-top: 50px;
		}
		.basicList{ margin-top: 50px;}
	}
}

.graduate{
	&_wrap{
		&_cont{
			margin-bottom: 50px;
			margin-top: 50px;
		}
		.basicList{ margin-top: 50px;}
	}
}

.business{
	&_wrap{
		&_cont{
			margin-bottom: 50px;
			margin-top: 50px;
		}
		.basicList{ margin-top: 50px;}
	}
}
.commercial_wrap{
	overflow: hidden;
	margin-bottom: 100px;
	ul{
		li{
			width: (100%/3);
			float: left;
			display: table;
			@include respond-to(sc3) {width: (100%/2);}
			a{
				display: table-cell;
				vertical-align: middle;
				height: 80px;
				line-height: 1.5;
				text-align: center;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				font-size: 14px;
				background-color: #3b3d4a;
				color: #fff;
				font-weight: bold;
				border-right: 3px solid #fff;
				border-top: 3px solid #fff;
				-webkit-transition: all 0.3s ease 0s;
				transition: all 0.3s ease 0s;
				&:hover,&:focus{background: #e26223; }
			}
		}
	}
}

// 구글차트 css
.se_1 > div{
	float:left;min-height:250px;
	@media (max-width:768px) {width:100% !important; float:none;  padding-bottom: 30px; padding-top:30px;border-bottom: 1px solid #ddd;
		div > div > div{margin: 0 auto;}
		div > div {margin: 0 auto;}
	}
	> div{margin: 0 auto;}
}
.se_1 .col1{width:33.33%}
.se_1 .col2{width:33.33%}
.se_1 .col3{width:33.33%}

.se_2 > div{float:left; min-height:250px;
	@media (max-width:768px) {width:100% !important; float:none;padding-bottom: 30px; padding-top:30px;border-bottom: 1px solid #ddd;
		div > div > div{margin: 0 auto;}
		div > div {margin: 0 auto;}
	}
	> div{margin: 0 auto;}
}
.se_2 .img{width:30%}
.se_2 .col1{width:70%}
.se_2 .col2{width:50%}
.se_2 .col3{width:50%}
.se_2 .col4{width:48%}

.se_3 > div{float:left; min-height:250px;
	@media (max-width:768px) {width:100% !important; float:none; padding-bottom: 30px; padding-top:30px;border-bottom: 1px solid #ddd;
		div > div > div{margin: 0 auto;}
		div > div {margin: 0 auto;}
	}
}
.se_3 .col1{width:25%}
.se_3 .col2{width:25%}

.se_4 > div{float:left; min-height:250px;
	@media (max-width:768px) {width:100% !important; float:none; padding-bottom: 30px;padding-top:30px; border-bottom: 1px solid #ddd;
		div > div > div{margin: 0 auto;}
		div > div {margin: 0 auto;}
	}
	
}
.se_4 .col1{width:50%}
.se_4 .col2{width:50%}

.se_5 > div{float:left; min-height:250px;
	@media (max-width:768px) {width:100% !important; float:none; padding-bottom: 30px; padding-top:30px;border-bottom: 1px solid #ddd;
		div > div > div{margin: 0 auto;}
		div > div {margin: 0 auto;}
	}
}
.se_5 .col1{width: 50%;}
.se_5 .col2{width: 50%;}
.se_5 .col3{width: 50%;}
.se_5 .col4{width: 50%;}

.unit{font-size: 12px;margin-top: 5px;}
// 상권현황 고지서 부분
.i_right{
	//아이콘  위아래 간격맞추기
	span{
		margin-left: 10px;
		a{
			font-size: 0;
			@include sprite($print2);
			&:hover,&:focus{
				@include sprite($print);
			}
		}
	}
	&:before{top:10px !important}
	
}
.year{
	margin-bottom: 30px;
	position: relative;
	@include respond-to(sc3) {margin-top: 30px;}
	&_wrap{
		&_s1{ 
			width: 100px ; 
			@include respond-to(sc3) {width: 48%;}
		}
		&_s2{
			 width: 150px ;
			 @include respond-to(sc3) {width: 48%;}
		}
		&_s3{ 
			width: 100px ;
			@include respond-to(sc3) {width: 48%;}
		}
		&_s4{ 
			width: 150px ;
			@include respond-to(sc3) {width: 48%;}
		}
		&_mo{
			text-align: center; margin-left: 10px ;margin-right:10px; font-size: 24px; vertical-align:middle;
			@include respond-to(sc3) {width: 95%;display: block; margin-left: 0;}
		}
		
	}
	&_text{
		margin-bottom: 30px;
		&_ul{
			&_li{float:left; width: 180px;; border: 1px solid #999; margin-left: 10px ; text-align: center;min-height: 35px; line-height: 35px;
				@include respond-to(sc3) {
					width: 50%; box-sizing: border-box; margin-left: 0;
					&:first-child{border-right: 0;}
					&:last-child{width: 100%;; border-top: 0;}
				}
				&:first-child{margin-left: 0;}
			}
		}
		&_s1{
			padding-left: 15px; width: 130px;
			@include respond-to(sc3) {display: block;margin-bottom: 10px; width: 90%;}
		}
		&_s2{
			padding-left: 15px;
			@include respond-to(sc3) {display: block;margin-bottom: 10px;width: 90%;}
		}
		&_s3{
			padding-left: 15px;
			@include respond-to(sc3) {display: block;margin-bottom: 10px;width: 90%;}
		}
		input{min-height: 35px; border: 1px solid #999;margin-right: 5px;}
		
	}
	select {
		border: 1px solid #999;
		font-family: inherit;
		padding-right: 30px;
		padding-left: 10px;
		min-height: 35px;
		background: url('../../resources/images/basic/select_arrow.png') no-repeat 95% 50%;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	select::-ms-expand {
		display: none;
	}
	input::placeholder{
		color: #333;
	}
	input:focus {outline:none;}
}


	
// 고지서 영수증
.bill{
	width: 90%;
	margin: 0 auto;
	@media print {width: 100%;}
	.basicTable5{
		.bill_title{
			background: #e26223; color: #fff; font-size: 24px; font-size: 2.4rem; font-weight: 700;
			@media print {font-size: 18px;}
		}
		.name{
			span{font-weight: bold; color: #e26223;}
		}
		.list{
			ul{margin-top: 10px;}
			li{margin-top: 3px;}
			span{  color: #e26223; }
		}
		.last_title{
			font-size: 24px; font-size: 2.4rem; font-weight: 700; line-height: 80px;;
			@media print {font-size: 18px;}
			text-align: center;
			img{margin-left: 10px;}
		}
		.table_all{
			width: 95%;
			margin: 0 auto;
			@media print {width: 90% !important;}
			@media (max-width:768px) {width: 100%;}
			.in_table{
				@media (max-width:768px) {padding-top: 0;}
				tr:nth-child(1){border-top: 1px solid #333; }
				tr:nth-child(3){border-top: 1px solid #333; margin-top: 30px;}
				tr:nth-child(5){border-top: 1px solid #333; margin-top: 30px;}
				tr:nth-child(7){border-top: 1px solid #333; margin-top: 30px;}
				
			}
		}
	}
}
.receipt{
	width: 90%;
	margin: 0 auto;
	@media print {width: 100%;}
	.basicTable5{
		.bill_title{
			background: #e26223; color: #fff; font-size: 24px; font-size: 2.4rem; font-weight: 700;
			@media print {font-size: 18px;}
			span{display: block; font-size: 14px; font-size: 1.4rem;}
		}
		.name{
			span{font-weight: bold; color: #e26223;}
		}
		.list{
			ul{margin-top: 10px;}
			li{margin-top: 3px;}
			span{  color: #e26223; }
		}
		.last_title{
			font-size: 24px; font-size: 2.4rem; font-weight: 700; line-height: 80px;;
			text-align: center;
			@media print {font-size: 18px;}
			img{margin-left: 10px;}
		}
		.receipt_wrap{
			width: 95%;
			margin: 0 auto;
			@media (max-width:768px) {width: 100%;}
			.receipt_wrap_total{
				text-align: left;
				margin-top: 10px;
				margin-bottom: 10px;
				@media (max-width:768px) {margin-bottom: 0;}
				span{color: #e26223; font-weight: 700;}
			}
			.in_table{
				@media print {width: 90% !important;	margin: 0 auto;margin-bottom:20px;}
				@media (max-width:768px) {padding-top: 0;}
				tr td:nth-child(1){
					span.colors{color: #e26223;font-weight: 700;}
					@media (max-width:768px) {margin-top: 20px;  border-top: 1px solid #999;}
				}
				tr td:nth-child(4){
					span.colors{color: #e26223;font-weight: 700;}
					@media (max-width:768px) {margin-top: 20px; border-top: 1px solid #999;}
				}
				.noclor tr td{color: #333;}
			}
		}
		.text_day{
			text-align: center;
			.text{ font-size: 18px; font-size: 1.8rem; font-weight: 500; margin-bottom: 40px; margin-top: 50px;}
			.day{ font-size: 24px; font-size: 2.4rem; font-weight: 700; margin-bottom: 40px; }
		}
	}
}



// 설립취지
.purport{
	&_wrap{
		margin-bottom: 50px;
		&_col2{
			@include respond-to(sc2) {margin-bottom: 50px;}
			&.img{
				float: right; width: 35%;; margin-left: 2%; margin-top: 40px;
				@include respond-to(sc2) {float:none; width: 100%; text-align: center;}
			}
			&.table{
				float: left; width: 60%;
				@include respond-to(sc2) {float:none; width: 100%; text-align: center;}
			}
			&_in{
				// overflow: auto;
				&_c{font-size: 11px; font-size: 1.1rem; text-align: left;}
			}
		}
		&_col4{
			float: left;
			width: (100%/2);
			@include respond-to(sc3) {width: (100%/1); margin-left: 0px;}
			&:nth-child(2n){
				.purport_wrap_col4_in{margin-left: 0;}
			}
			&_in{
				margin-left: 20px;
				margin-bottom: 30px;
				border: 1px solid #ddd;
				text-align: center;
				padding-top: 30px;
				padding-bottom: 30px;
				background-image: url("../images/site/purport_col4_bg.png");
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				@include respond-to(sc3) {margin-left: 0;}
			}
		}
		&_swot{
			background-image: url("../images/site/purport_07.png");
			background-position: center top;
			background-repeat: no-repeat;
			min-height: 792px;
			padding-top:25px;
			@media (max-width:880px) {width: 100%; background:none;}
			&_col4{
				width: 50%;
				@media (max-width:880px) {
					width: 100% !important; margin-left: 0 !important; border-radius: 0 !important; border: 5px solid #9ee8ca !important;;    box-sizing: border-box; padding-top: 33px; padding-bottom: 30px !important;; margin-top:50px !important ;
					ul{padding-left: 30px !important;}
				}
				&:nth-child(1){
					margin-left: 48.7%;
					border: 5px solid #b5bbef;
					border-left:0;
					margin-top: -2px;
					padding-top: 31px;
					padding-bottom: 37px;
					border-top-left-radius: 20px;
					border-bottom-left-radius: 20px;
					ul{ padding-left: 100px;}
				}
				&:nth-child(2){
					border: 5px solid #9ee8ca;
					width: 50.3%;
					border-right: 0;
					padding-top: 34px;
					padding-bottom: 34px;
					margin-top: 35px;
					border-top-right-radius: 20px;
					border-bottom-right-radius: 20px;
					ul{ padding-left: 60px;}
				}
				&:nth-child(3){
					margin-left: 48.7%;
					margin-top: 35px;
					border: 5px solid #b5bbef;
					border-left:0;
					padding-top: 22px;
					padding-bottom: 23px;
					border-top-left-radius: 20px;
					border-bottom-left-radius: 20px;
					ul{ padding-left: 100px;}
				}
				&:nth-child(4){
					border: 5px solid #9ee8ca;
					width: 50.3%;
					border-right: 0;
					padding-top: 36px;
					padding-bottom: 34px;
					margin-top: 34px;
					border-top-right-radius: 20px;
					border-bottom-right-radius: 20px;
					ul{ padding-left: 60px;}
				}
			}
		}
		&_co2{
			float: left;
			width: 50%;
			@media (max-width:900px) {width: 100%; }
			&_title{
				font-size: 21px;
				font-weight: 700;
				background: #c3c1b8;
				padding-top: 20px;
				padding-bottom: 20px;
				width: 80%;
				margin: 0 auto;
				text-align: center;
				margin-bottom: 30px;
			}
			&_in{
				position: relative;
				&:last-child{
					.purport_wrap_co2_in_cont{background: no-repeat;}
				}
				&_img{
					position: absolute;     
					left: 0; 
					top: 40%;
					transform: translateY(-40%);
					@media (max-width:900px) {width: 100%; position: relative; top: 0; transform: translateY(0); text-align: center; margin-bottom: 20px;}

				}
				&_cont{
					margin-left: 140px;  background-image: url("../images/site/swot_li_bg.png"); background-position: -12px bottom;background-size: contain; background-repeat: no-repeat;padding-bottom:11px;
					@media (max-width:900px) {width: 100%; background: none; margin-left: 0;}

					ul{
						background-color: #86e2bd; padding:20px 15px; border-bottom-right-radius: 20px;border-bottom-left-radius: 20px;
						@media (max-width:900px) {border-radius: 20px;}
						li{
							font-size: 13px;font-size: 1.3rem;
							&::before{top: 8px;}
						}
					}
				}
			}
			&_t2{
				position: relative;
				width: 90%;
				margin: 0 auto;				
				background-image: url("../images/site/swot_li_bg_t2.png"); background-position: -6px bottom;background-size: contain; background-repeat: no-repeat;padding-bottom:17px;
				@media (max-width:900px) {width: 100%; background: none;}

				&:last-child{background:none ;	}
				&_title{
					position: absolute;
					top: 35%;
					transform: translateY(-35%);
					width: 140px;
					@media (max-width:900px) {width: 100%; position: relative; top: 0; transform: translateY(0); text-align: center; }
					h2{
						text-align: center;
						@media (max-width:900px) {border: 3px solid #86e2bd ; padding-top: 20px; padding-bottom: 20px; font-weight: 700;}
					}
				}
				&_cont{
					background-color: #86e2bd; border-bottom-right-radius: 20px;border-bottom-left-radius: 20px; padding: 20px 20px 20px 140px;
					@media (max-width:900px) {padding: 20px;}
					ul{
						li{
							font-size: 13px;font-size: 1.3rem;
							&::before{top: 8px;}
						}
					}
				}
			}
			
		}
	}
	&:last-child .basicTable4{
		tr:last-child td{
			vertical-align:top;
			.basicList1 li::before{top: -7px;background: none; content: '·'; font-size: 21px;}
		}
	}
}

// 조직도
.organ{
	&_img{
		margin-bottom: 100px;
	}
}

// 골목상권
.alley{
	&_wrap{
		@media (max-width:960px) {overflow: scroll;;}
		&_img{
			background-image: url('../../resources/images/site/alley_map.png') ;
			background-position: center top; 
			background-repeat: no-repeat;
			min-height: 912px;
			margin-bottom: 100px;
			width: 920px;
			display: block;
			@media (max-width:960px) {display: none;}
			&_ping{
				position: relative;
				&_ul{
					&_li{
						position: absolute;
						text-align: center;
						
						&.on{
							.alley_wrap_img_ping_ul_li_block{visibility: visible;}
						}
						&_block{
							visibility: hidden;
							padding: 10px;
							background: #fff;
							border: 3px solid #0079b7;
							position: relative;
							text-align: left;
							z-index: 10;
							&::after{
								content: "";
								position: absolute;
								width: 0;
								height: 0;
								top: 100%;
								right: 50%;
								transform: translateX(50%);
								border: 9px solid;
								border-color: #0079b7 transparent transparent transparent;
								z-index: 8;
							}
							h2{font-size: 14px; color: #0079b7; font-weight: 700;}
							p{font-size: 12px; color: #333;}
						}
						&_a{
							display: inline-block; 
							margin-top: 15px;
							z-index: 9; 
							position: relative;
							width: 16px; 
							height: 15px;
							color: #fff;
							background-image: url("../images/site/map_ping.png");
							background-repeat: no-repeat;
							line-height: 28px;
							text-align: center;
							font-size: 0;
						}
						&:nth-child(1){left: 442px; top: 124px;} //으뜸길 상권
						&:nth-child(2){left: 399px; top: 138px;} //번성로 상권
						&:nth-child(3){left: 504px; top: 103px;} //풍물길 상권
						&:nth-child(4){left: 486px; top: 78px;} //신흥2동 상권
						&:nth-child(5){left: 504px; top: 94px;} //종합상가 상권
						&:nth-child(6){left: 461px; top: 80px;} //시범길 상권
						&:nth-child(7){left: 442px; top: 100px;} //장터길 상권
						&:nth-child(8){left: 429px; top: -46px;} //복정동 상권
						&:nth-child(9){left: 609px; top: -36px;} //민속마을(닭죽촌) 상권
						&:nth-child(10){left: 523px; top: -34px;} //위례서일로 상권
						&:nth-child(11){left: 540px; top: -61px;} //위례광장로 상권
						&:nth-child(12){left: 50px; top: 221px;} //청계산 상권
						&:nth-child(13){left: 554px; top: -64px;} //위례보미리즌빌상가 상권
						&:nth-child(14){left: 540px; top: 121px;} //위례순환로 상권
						&:nth-child(15){left: 624px; top: 57px;} //금광2동 상인회
						// 여기서 문제
						&:nth-child(16){left: 636px; top: 129px;} //상대원1동 해피마을상인회
						&:nth-child(17){left: 381px; top: 173px;} //성남동 상권
						&:nth-child(18){left: 652px; top: -34px;} //성남동모란상가번영회
						&:nth-child(19){left: 565px; top: 108px;} //성호시장연합상인회
						&:nth-child(20){left: 452px; top: 149px;} //은행2동상가번영회
						&:nth-child(21){left: 550px; top: 121px;} //중앙동상인회
						&:nth-child(22){left: 400px; top: 243px;} //여수동 상권
						&:nth-child(23){left: 550px; top: 243px;} //도촌동 상권
						&:nth-child(24){left: 550px; top: 121px;} //오리역상가번영회
						&:nth-child(25){left: 355px; top: 759px;} //까치마을먹자골목상인회
						&:nth-child(26){left: 368px; top: 736px;} //백현동 카페거리 번영회
						&:nth-child(27){left: 448px; top: 483px;} //율동상인회
						&:nth-child(28){left: 481px; top: 507px;} //서현시범먹자상인회
						&:nth-child(29){left: 369px; top: 517px;} //미래그린시장
						&:nth-child(30){left: 452px; top: 337px;} //야탑동상인회
						&:nth-child(31){left: 492px; top: 387px;} //야탑3동상인회
						&:nth-child(32){left: 333px; top: 615px;} //정자1동 상인회
						&:nth-child(33){left: 343px; top: 645px;} //정자동KT상가번영회
						&:nth-child(34){left: 67px; top: 443px;} //운중동먹거리촌
						&:nth-child(35){left: 268px; top: 466px;} //판교역로1번가 상인회
						&:nth-child(36){left: 291px; top: 443px;} //판교테크노밸리상인연합회
					}
				}
			}
		}
	}
	&_table{
		thead tr th{background:#5e5e5e; color: #fff;}
		tbody tr td{background: #fff; transition: 0.3s all;}
		tbody tr.on td{background: #ffe8bd; transition: 0.3s all;}
	}
	
}
// 전통시장
.alley2{
	&_wrap{
		@media (max-width:960px) {overflow: scroll;;}
		&_img{
			background-image: url('../../resources/images/site/alley_map.png') ;
			background-position: center top; 
			background-repeat: no-repeat;
			min-height: 912px;
			margin-bottom: 100px;
			width: 920px;
			display: block;
			@media (max-width:960px) {display: none;}
			&_ping{
				position: relative;
				&_ul{
					&_li{
						position: absolute;
						text-align: center;
						&.on{
							.alley2_wrap_img_ping_ul_li_block{visibility: visible;}
						}
						&_block{
							visibility: hidden;
							padding: 10px;
							background: #fff;
							border: 3px solid #0079b7;
							position: relative;
							text-align: left;
							z-index: 10;
							&::after{
								content: "";
								position: absolute;
								width: 0;
								height: 0;
								top: 100%;
								right: 50%;
								transform: translateX(50%);
								border: 9px solid;
								border-color: #0079b7 transparent transparent transparent;
								z-index: 8;
							}
							h2{font-size: 14px; color: #0079b7; font-weight: 700;}
							p{font-size: 12px; color: #333;}
						}
						&_a{
							display: inline-block; 
							margin-top: 15px;
							z-index: 9; 
							position: relative;
							width: 16px; 
							height: 15px;
							clear: both;
							color: #fff;
							background-image: url("../images/site/map_ping_2.png");
							background-repeat: no-repeat;
							line-height: 28px;
							text-align: center;
							font-size: 0;
							text-indent: -9999px;
							&:focus {
								border:2px solid gold;
							}
						}
						&:nth-child(1){left: 520px; top: 105px;} //신흥시장
						&:nth-child(2){left: 387px; top: 105px;} //현대시장
						&:nth-child(3){left: 576px; top: 91px;} //단대마트시장
						&:nth-child(4){left: 551px; top: 151px;} //상대원시장
						&:nth-child(5){left: 355px; top: 189px;} //모란전통기름시장
						&:nth-child(6){left: 380px; top: 178px;} //모란시장
						&:nth-child(7){left: 622px; top: 25px;} //은행시장
						&:nth-child(8){left: 619px; top: -0px;} //성남남한산성시장
						&:nth-child(9){left: 472px; top: 151px;} //성남중앙지하상가시장
						&:nth-child(10){left: 616px; top: 58px; }//금광시장
						&:nth-child(11){left: 495px; top: 141px; } // 성호시장
						&:nth-child(12){left: 391px; top: 195px; } //모란가축시장
						&:nth-child(13){left: 332px; top: 733px; } //미금현대벤처빌시장
						&:nth-child(14){left: 453px; top: 571px;} //범한프라자시장
						&:nth-child(15){left: 333px; top: 512px;}//청구문화시장
						&:nth-child(16){left: 333px; top: 512px;}//금호행복시장
						&:nth-child(17){left: 379px; top: 564px;}//돌고래시장
						&:nth-child(18){left: 364px; top: 564px;}//코끼리상가시장
						&:nth-child(19){left: 355px; top: 550px;} //동신종합시장

						&:nth-child(20){left: 417px; top: 532px;} //현대시장
						&:nth-child(21){left: 495px; top: 474px;} //코코프라자시장
						&:nth-child(22){left: 437px; top: 540px;} //미래그린시장
						&:nth-child(23){left: 470px; top: 554px;} //분당우성시장
						&:nth-child(24){left: 490px; top: 570px;} //미래타운제2종합시장
						&:nth-child(25){left: 448px; top: 574px;} //현대프라자시장
						&:nth-child(26){left: 429px; top: 595px;} //분당종합시장
						&:nth-child(27){left: 358px; top: 550px; display: none;} //아탑에이스코아시장
					}
				}
			}
		}
	}
	&_table{
		thead tr th{background:#5e5e5e; color: #fff;}
		tbody tr td{background: #fff; transition: 0.3s all;}
		tbody tr.on td{background: #ffe8bd; transition: 0.3s all;}
	}
}
// 전통시장
.alley3{
	&_wrap{
		@media (max-width:960px) {overflow: scroll;;}
		&_img{
			background-image: url('../../resources/images/site/alley_map.png') ;
			background-position: center top; 
			background-repeat: no-repeat;
			min-height: 912px;
			margin-bottom: 100px;
			width: 920px;
			display: block;
			@media (max-width:960px) {display: none;}
			&_ping{
				position: relative;
				&_ul{
					&_li{
						position: absolute;
						text-align: center;
						&.on{
							.alley3_wrap_img_ping_ul_li_block{visibility: visible;}
						}
						&_block{
							visibility: hidden;
							padding: 10px;
							background: #fff;
							border: 3px solid #0079b7;
							position: relative;
							text-align: left;
							z-index: 10;
							&::after{
								content: "";
								position: absolute;
								width: 0;
								height: 0;
								top: 100%;
								right: 50%;
								transform: translateX(50%);
								border: 9px solid;
								border-color: #0079b7 transparent transparent transparent;
								z-index: 8;
							}
							h2{font-size: 14px; color: #0079b7; font-weight: 700;}
							p{font-size: 12px; color: #333;}
						}
						&_a{
							display: inline-block; 
							margin-top: 15px;
							z-index: 9; 
							position: relative;
							width: 16px; 
							height: 15px;
							clear: both;
							color: #fff;
							background-image: url("../images/site/map_ping_3.png");
							background-repeat: no-repeat;
							line-height: 28px;
							text-align: center;
							font-size: 0;
							text-indent: -9999px;
						}
						&:nth-child(1){left: 440px; top: 100px;}
						&:nth-child(2){left: 423px; top: 110px;}
						&:nth-child(3){left: 341px;top: 192px;}
					}
				}
			}
		}
	}
	&_table{
		thead tr th{background:#5e5e5e; color: #fff;}
		tbody tr td{background: #fff; transition: 0.3s all;}
		tbody tr.on td{background: #ffe8bd; transition: 0.3s all;}
	}
}

.alley_wrap_img_mo{
	display: none;
	@media (max-width:960px) {display: block; margin-top: 20px; margin-bottom: 20px;}
}
// 대관신청
.rent_table{
	& .red{color: red;  margin-left: 5px;}
	.next_go{text-align: center;}
	& .basicTable2{
		border:0;
	}
	&_input{
		border:1px solid #d8d8d8;
	}
	&_textarea{
		border: 1px solid #d8d8d8;
		width: 90%;
		height: 200px;
	}
}
.last_rent{
	text-align: center;
	border: 1px solid #d8d8d8;
	padding: 20px;
	&_img{margin-top: 30px; margin-bottom: 30px;}
	&_cont{margin-bottom: 20px; font-size: 18px; color:#484848 ;}
	&_bt{
		.btn3{font-size: 16px; padding:10px 60px; margin: 0 auto;}
	}
}


// 개인정보처리방침,ul리스트
.comm_list{
	line-height: 1.6;
	&_box1{padding: 40px; border: 3px dotted #ddd;line-height: 1.8; margin-bottom: 40px;}
	&_p{}
	&_info{
		h2{font-weight: bold; margin-top: 30px;margin-bottom: 10px;font-size: 21px; font-size: 2.1rem; color: #e26223;} 
		h3{font-weight: bold;margin-bottom: 20px; color: #e26223;}
		p{margin-bottom: 20px;}
		.basicList1 > li{margin-bottom: 10px;}
		ul > li{margin-bottom: 10px;}
	}
	a:focus {
		border:2px solid gold;
		text-decoration: underline;
	}
}

// 대관안내
.rent_guid{
	&_wrap{
		margin-bottom: 80px;
	}
	&_list{
		text-align: center;
		> li{
			float:left;text-align: center;width: (100%/4); 
			@include respond-to(sc3) {width: (100%/2);  margin-bottom: 30px;}
		}
	}
}

.rent_place{
	&_list{
		margin-bottom: 80px;;
		>ul{
			>li{
				float: left;
				width: (100%/3);
				display: table;
				&.on{
					a{background: #e95025; transition: 0.3s all;}
				}
				a{
				    display: table-cell;
					vertical-align: middle;
					height: 80px;
					line-height: 1.5;
					text-align: center;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					font-size: 14px;
					background-color: #3b3d4a;
					color: #fff;
					font-weight: bold;
					border-right: 3px solid #fff;
					border-top: 3px solid #fff;
					-webkit-transition: all 0.3s ease 0s;
					transition: all 0.3s ease 0s;
				}
			}
		}
	}
	&_map{
		margin-bottom: 60px;
	}
}

// 상인대학 온라인신청서

.online_print{

	width: 98%; margin: 0 auto;
	@media print {
		width: 21cm;
		min-height: 29.7cm;
		padding: 2cm;
		margin: 0 auto;
		background:#fff;
	}
	h2{
		font-size: 24px; border-top: 2px solid #c1c1c1; border-bottom: 2px solid #c1c1c1; text-align: center; padding-top: 10px;padding-bottom:10px; margin-bottom: 20px;
		font-weight: bold;
		span{margin-right: 10px;}
	}
	.d_1{
		border: 1px solid #ddd;margin-bottom: 10px; padding-top: 10px;padding-bottom: 10px; padding-right: 15px; padding-left: 15px;
		.align_right{
			@include respond-to(sc3) {display: block; text-align: center; }
		}
	}
	.d_2{
		border: 1px solid #ddd;margin-bottom: 10px; padding-top: 10px;padding-bottom: 10px; padding-right: 15px; padding-left: 15px;
		.align_right{
			@include respond-to(sc3) {display: block; text-align: center; }
		}
	}
	.d_mo_title{
		@include respond-to(sc3) {width: 100% ; display:block;}
	}
	.d_mo{
		@include respond-to(sc3) {display: inline-block; width: 49%;; }
	}
	.chk_box{border: 1px solid #ddd;
		@media print {border: none; color: #333;}
	}
	.chk_box.num{width: 25px;
		@media print {border: none; color: #333;}
	}
	.chk_box.text{width: 80%;
		@media print {border: none; color: #333; }
	}
	.f_l{font-size: 21px; font-weight: bold;}
	.apply-box__ok{
		margin: 0 auto;;
		display: block;
		max-width: 150px;
		@media print {display: none; }
	}
	.basicTable6 tr.left-td td, .basicTable6 td.left-td{padding: 10px 10px;}
	
}


.online_print2{
	width: 21cm;
	min-height: 29.7cm;
	padding: 2cm;
	margin: 0 auto;
	background:#fff;
	h2{
		font-size: 24px; border-top: 2px solid #c1c1c1; border-bottom: 2px solid #c1c1c1; text-align: center; padding-top: 10px;padding-bottom:10px; margin-bottom: 10px; 
		font-weight: bold;
		span{margin-right: 10px;}
	}
	p.las_p{margin-bottom: 10px; text-decoration: underline;}
}

.online_print_t2{
	padding: 30px;
	&_title{
		h2{
			font-size: 24px; border-top: 2px solid #c1c1c1; border-bottom: 2px solid #c1c1c1; text-align: center; padding-top: 10px;padding-bottom:10px; margin-bottom: 20px;font-weight: bold;
		}
	}
	&_cont{
		h3{font-weight: bold;}
		p{margin-bottom: 20px;}
		ul{
			li{margin-bottom: 20px;}
		}
	}
}
.online_print_t3{
	padding: 30px;
	&_title{
		h2{
			font-size: 24px;  text-align: left; padding-top: 10px;padding-bottom:10px; margin-bottom: 20px;font-weight: bold;
		}
	}
	&_cont{
		border: 1.5px solid;
		padding: 20px;
		.list_input{margin-bottom: 10px;
			label{width: 55px; display: inline-block;}
			input{
				border: 1px solid #ddd;
				@media print {display: none;}
			}
		}
		h3{font-weight: bold; font-size: 32px; text-align: center; margin-top: 30px; margin-bottom: 30px;}
		h2{font-size: 24px; font-weight: bold;}
		p{margin-bottom: 20px;}
		ul{
			li{margin-bottom: 20px;}
		}
	}
}

/* 구글챠트 대체 상권현황 탭 */
.subTab{
	&__list{
		margin-bottom: 100px;
		&.type1{
			li{
				width: (100%/2);
				float: left;
				display: table;
				@include respond-to(sc3) {
					width: (100%/2);
				}
			}
		}
		&.type2{
				li{
					width: (100%/3);
					float: left;
					display: table;
					@include respond-to(sc3) {
						width: (100%/2);
				}
			}
		}
		&.type3{
			li{
				width: (100%/4);
				float: left;
				display: table;
				@include respond-to(sc3) {
					width: (100%/2);
				}
			}
		}
		li{
			a{
				display: table-cell;
				vertical-align: middle;
				height: 80px;
				line-height: 1.5;
				text-align: center;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				font-size: 14px;
				background-color: #3b3d4a;
				color: #fff;
				font-weight: bold;
				border-right: 3px solid #fff;
				border-top: 3px solid #fff;
				-webkit-transition: all 0.3s ease 0s;
				transition: all 0.3s ease 0s;
				&:hover,&:focus{background: #e26223;}
			}
			&.on a{
				background: #e26223;
			}
		}
	}
	&__cont{
		&__box{
			display:none;
			&.on{
				display:block;
			}
		}
	}
}

