
/*		sidebar
=========================*/
/* sidebar */
.sidebar {width:220px;float:left;padding-bottom:40px;font-family: 'Noto Sans KR';}
.sidebar__wrap {background-color:#fff;}
.sidebar__h2 {text-align:center;padding-top:75px;font-size:32px;font-size:3.2rem;
	position:relative;padding-bottom:75px;color:#fff;
	background-image: url("../../resources/images/site/menu_sbj.png");
	background-repeat: no-repeat;
	position: relative;
	// &:after{
	// 	content: '';
	// 	position: absolute;
	// 	width: 30px;
	// 	height: 3px;
	// 	left: 0;
	// 	background: #fff;
	// 	margin: 0 auto;
	// }
	}
	.sidebar__h2 span {display:block;font-size:12px;color:#fff;text-align:center;}
	.sidebar__h2 a {display:none;}
	/*메뉴 이름 길경우*/
	.sidebar__h2.long-type {font-size:20px;padding-top:75px;}

.side-list {}
.side-list__li {}
.side-list__li > span > a {
	display:block;
	padding-left:20px;
	height:50px;
	line-height:50px;
	border-bottom:1px solid #e0e0e0;
	background-color:#fff;color:#000;
	font-size:16px;
	font-weight:normal;
	transition: all 0.3s ease 0s;
	background-position: 213px center;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}
.side-list__li:first-child > span > a {border-top:1px solid #e0e0e0;}
.side-list__li.open-type > span > a {
	position: relative;
	&:after {
		@include sprite($side-list-li-off);
		position: absolute;
		top:50%;
		right:20px;
		transform:translateY(-50%)
	}

}
.side-list__li.open-type > span > a:hover,
.side-list__li.open-type > span > a:focus,
.side-list__li.open-type > span.on > a,
.side-list__li.open-type > span > a.on {
	&:after {
		@include sprite($side-list-li-on);
		position: absolute;
		top:50%;
		transform: translateY(-50%);
	}
}
.side-list__li.open-type.on > span > a {
	&:after {
		@include sprite($side-list-li-on);
		position: absolute;
	}
}


.side-list__li.on > span {
	display:block;
}
.side-list__li.on > span a {color:#fff;}
.side-list__li > span > a:hover,
.side-list__li > span > a:focus,
.side-list__li > span.on > a,
.side-list__li > span > a.on,
.side-list__li.selected > span > a {font-weight:normal;opacity:0.9;color:#e26223;border-bottom:1px solid #e26223}

.side-list__li.on > span > a {font-weight:normal;opacity:0.9;color:#e26223;border-bottom:1px solid #e26223;}


.side-list__li__inbox {display:none;}

.open-type.on > .side-list__li__inbox {
	display:block;
	background-color:#fff;
	padding:10px;
	border-bottom:1px solid #e0e0e0
	}

.spp__in > a{
	display:block;
	font-size:15px;
	color:#565656;
	padding-top:8px;
	padding-bottom:8px;
	padding-left:13px;
/*
	&:before {
		content: '';
		display: inline-block;
		@include sprite($side-list-inbox);
		padding-right:8px;
	}
*/
}
.spp__in > a:hover,
.spp__in > a:focus,
.spp__in.on > a,
.spp__in > .on,
.spp__in.selected > a,
.spp__in > .selected {
	color:#e26223;

	// &:before {
	// 	content: '';
	// 	display: inline-block;
	// 	@include sprite($side-list-inbox_on);
	// }
}
	.spp__in__small {display:none;}
	.on > .spp__in__small {display:block;padding:3px 10px 10px 15px;}

		.spp__in__small a{
			display:block;
			padding-top:5px;
			padding-bottom:5px;
			padding-left:15px;
			font-size:13px;
			font-size:1.3rem;

		}
		.spp__in__small .on,
		.spp__in__small a:hover,
		.spp__in__small a:focus {text-decoration:underline;}

		.sp-open-type {
			position:relative;
			&:after {
				content:'';
				display: inline-block;
				@include sprite($open-close);
				position: absolute;
				top:13px;
				right:15px;
			}
		}
		.sp-open-type.on {
			&:after {
				content:'';
				display: inline-block;
				@include sprite($open-done);
				position: absolute;
				top:15px;
				right:15px;
			}
		}

			/*4차*/
			.spp__in__small__4th {display:none;}
			.on > .spp__in__small__4th {display:block;padding:10px 5px 10px 10px;background-color:#eff3f7}

			.spp__in__small__4th a{display:block;padding:3px 0;min-height:17px;font-size:13px;font-size:1.3rem; font-weight: normal;padding-left:15px;}

			.spp__in__small__4th > li.on > a,
			.spp__in__small__4th > li > .on,
			.spp__in__small__4th a:hover,
			.spp__in__small__4th a:focus {text-decoration:underline;}


/*2019 사이드 메뉴 수정 추가 */
.sidebar {position:relative; top: -100px;}
.mobile-submenu-btn {display:none;background-color:#f05023;padding:5px;text-align:center;color:#fff;
	position:absolute; top:3px;right:3px;z-index:98;
}
.mobile-submenu-btn .type1 {display:block;}
.mobile-submenu-btn .type2 {display:none;}
.selected.mobile-submenu-btn .type1 {display:none;}
.selected.mobile-submenu-btn .type2 {display:block;}
@media screen and (min-width:1px) and (max-width:1200px) {

	.sidebar {position:relative;z-index:1;margin-top:0px;float:none;width:100%;padding-bottom:0; top: 0;}

	.sidebar.selected {border-bottom:2px solid #013f88}
	.side_in_wrap{display:none;border-bottom:2px solid #009590;opacity:0;}
		.sidebar h2{
			display: block;
		text-align:left;background-image:none;background-color:#f79723;padding-left:10px;font-size:1.5rem;height:32px;
		padding-top:5px;line-height:1.6
		}
	.sidebar h2 {color:#fff;min-height:10px;height:30px;border-radius:0px;}
	.sidebar h2 a{display:none;}
	.sidebar__h2{padding-bottom:0px;}
	.sidebar__h2.long-type{padding-bottom:0px;font-size: 16px;padding-top:5px;height:32px;}
	.sidebar h2 {text-align:left;padding-left:20px;font-size:16px;}
	.side-list__li > span > a {height:30px;line-height:30px;}
	.side-list__li.open-type > span > a {background-position:96% center}
	.side-list__li.open-type.on > span > a {background-position:96% center}
	.sp-open-type {background-position:97% center}
	.sp-open-type.on {background-position:97% center}


	.side-list__li:first-child > span > a {border-top:0px;}

	/**/
	.sidebar__wrap {opacity:1.0 !important;margin-left:0px !important;}

	.side_in_wrap{display:block;border-bottom:2px solid #000;opacity:1;}
	.side_in_wrap li.selected span a {
		background-color: #fff !important;
		color: #f6804e !important;
	}
	.mobile-submenu-btn {display:block;}
	.open-type.on > .side-list__li__inbox {padding:0 10px;background-color:#eff3f7}

	.sidebar .side-list > li {overflow:hidden;height:0;}
	/*.sidebar .side-list > li.on {display:block;}*/


	.sidebar .side-list > li.on {height:auto;}
	.sidebar.selected .side-list > li {height:auto;}
	.side-list__li > span > a {padding:0px 0 0px 20px;
		transition: all 0.3s ease 0s;
	}
	.sidebar.selected .side-list__li > span > a {padding:5px 0 5px 20px}
	.sidebar.selected .side-list > li.open-type {height:auto;}

	.spp__in > a {padding-top:5px;padding-bottom:5px;padding-left:18px;}
	.spp__in > a:hover, .spp__in > a:focus, .spp__in.on > a, .spp__in > .on, .spp__in.selected > a, .spp__in > .selected {color:#013f88}
}
