
.sisul {
	&__top {
		margin-bottom: 50px;
		text-align:center;
	}
	&__tit {
		@include h3Type3;
		&--1 {margin-bottom:50px !important;}
	}
	&__tit-little {
		@include h4Type2;
	}

	&__table {
		&--1 {
			@include basicTable1;
			& tbody tr td {vertical-align: middle;}
			.basic-list {
				@include basicList;
				margin-left:20px;
			}

		}
		&--2 {
			@include basicTable1;
			margin-bottom:50px !important;
			& tbody tr th {background-color:#fff; font-weight: bold;padding-top:30px;padding-bottom:30px;}
			& tbody tr td {vertical-align: middle;padding-top:30px;padding-bottom:30px;}

		}
		&--3 {
			@include basicTable1;
			margin-bottom:50px !important;
			& tbody tr th {background-color:#fff; font-weight: bold;padding-top:30px;padding-bottom:30px;}
			& tbody tr td {vertical-align: middle;padding-top:30px;padding-bottom:30px;}

		}
		&--4 {
			@include basicTable1;
			margin-bottom:50px !important;
			& tbody tr th {background-color:#fff; font-weight: bold;padding-top:30px;padding-bottom:30px;}
			& tbody tr td {vertical-align: middle;padding-top:30px;padding-bottom:30px;}

		}
	}
	&__strong {font-size:15px;margin-bottom:8px;display: block;}
	&__list {
		@include basicList3;
		margin-bottom:50px !important;
	}
	&__box {
		padding-left:20px;
		font-size:16px;
		padding-top:10px;
		margin-bottom:30px;
		&__down {
			@include downBtn2;
		}
	}

	.basic-list {
		@include basicList;
		margin-left:20px;
	}
	.basic-list3 {
		@include basicList3;
		margin-left:20px;
		margin-bottom:50px !important;
	}

	&__ol-list {
		margin-bottom: 70px;
		@include floatWrap;
		margin-left:-20px;
		&__li {
			position: relative;
			width: 20%;
			float: left;
			&:before {
				position: absolute;
				top:50%;
				left:7px;
				transform: translateY(-50%);
				@include sprite($ol);
			}

			&:first-child:before {display: none;}

			&__in {
				border:1px solid #dddddd;
				margin-left:20px;
				padding-left:30px;
				padding-top:15px;
				padding-bottom:15px;
				min-height:30px;
				font-size:15px;color:#000;
				font-weight: bold;
				strong {display:block;font-size:12px;color:#3a8dcb;text-transform :uppercase;margin-bottom:4px;}
			}
			.ver2 &__in{
				min-height:75px;
			}
		}
	}

	&__down-list {
		@include floatWrap;
		margin-left:-20px;
		padding-top:20px;
		&__li {
			float: left;
			width: 25%;
			a {
				background-color: #fff;
				transition: all 0.3s ease 0s;
				&:hover,
				&:focus {
					background-color: #ededed;
				}
				display: block;
				text-align:center;
				padding:25px;
				min-height:200px;
				margin-left: 20px;
				border: 1px solid #ddd;
				strong {
					font-size:18px;
					display: block;
					padding-bottom: 150px;
					position: relative;
					&:after {
						position: absolute;
						top: 60px;
						left: 50%;
						transform: translateX(-50%);
					}
					&.type1:after {
						@include sprite($sisuldown-icon1);
					}
					&.type2:after {
						@include sprite($sisuldown-icon2);
					}
					&.type3:after {
						@include sprite($sisuldown-icon3);
					}
					&.type4:after {
						@include sprite($sisuldown-icon4);
					}
				}


				span {
					color:#fff;
					font-size:14px;
					font-weight: bold;
					width:65%;
					height:40px;
					line-height: 40px;
					display: block;
					margin:0 auto;
					background-color: #000;
				}

			}


		}
	}
}

@media screen and (min-width:1px) and (max-width:1000px) {
	.sisul__down-list__li {width:50%;margin-bottom:20px;}

}

@media screen and (min-width:1px) and (max-width:768px) {
	.sisul__ol-list {padding:30px;}
	.sisul__ol-list__li {width:50%;margin-bottom:20px;}
}
@media screen and (min-width:1px) and (max-width:560px) {

	.sisul__down-list__li {width:100%;margin-bottom:20px;}
}