.main-visual{
	// background-image:url("../../resources/images/site/section_01_bg.png");
	background-repeat: no-repeat; background-size: 100%; background-color: #fff;
	@include respond-to(sc0) {background-size: cover; }
	@media only screen and (max-width: 910px) {height: auto;}


	&_wrap {
		width: 100%; margin: 0 auto;
		position: relative;
		@include respond-to(sc1) {width: 100%; }
		@media only screen and (max-width: 910px) {margin-top: 0; margin-bottom: 0;}

	}

	.main-slide {
			width: 100%; position: relative; 
		@include respond-to(sc1) {}
		@media only screen and (max-width: 910px){width: 100%; float: none;}

		// @include respond-to(sc1) {margin-top: 20px;}
	}
}
/*메인슬라이드*/
.main-visual {position: relative;width:100%;
	.slick-arrow {border:0px;top:220px;}
	.slick-prev {position: absolute;left:10px;z-index: 1;display:none !important;}
	.slick-next {position: absolute;right:10px;z-index: 1;display:none !important;}
}


.main-visual {position: relative;width:100%;
	.slick-arrow {border:0px;top:220px;}
	.slick-prev {position: absolute;left:10px;z-index: 1;display:none !important;}
	.slick-next {position: absolute;right:10px;z-index: 1;display:none !important;}
}

/*이미지의 상하사이즈로 설정*/
	.main-slide__item {
		height: 800px;
		background-position: 15% 0%;
		background-repeat: no-repeat;
		
		&.main-slide_01 {background-image:url("../../resources/images/site/section_main_bg_01_pc.png");}
		&.main-slide_02 {background-image:url("../../resources/images/site/section_main_bg_01_pc.png");}
	}
	.main-slide__item a:focus {
		border:1px solid #000;
		display: block;
		box-sizing: border-box;
	}
	.slick-dots-wrap-outwrap {
		width: 100%; position: absolute; bottom: 320px;
		@include respond-to(sc1) {width: 100%;}
		@media only screen and (max-width: 910px) { text-align: center; top: auto; position: relative; height: 0;}
		
	}
	.slick-dots-wrap {
		position: relative; width: 100%; max-width: 1440px; margin: 0 auto; padding: 0 20px; box-sizing: border-box;
		@media only screen and (max-width: 910px) {margin-left: 0; border-radius: 0;}

	}
	.myslickcarousel {display:inline-block;}
	.slick-dots-on-off {display:inline-block;
		li {
			background: #e26223;;
			width: 26px;
			line-height: 23px;
			height: 26px;
			border-radius: 30px;
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			a {color:gold;}
			&:focus,&:hover{opacity: 0.8;}
		}

	}

	*+ html {
		.myslickcarousel {display:inline;}
		.slick-dots-on-off {display:inline;}
		.slick-dots-on-off li {display:inline;}
	}


	.slick-dots-list {
		display:inline-block !important;
		vertical-align: middle;
		li {
			display:inline-block;margin-right:5px;
			button { background-color:#fff;display:inline-block;
				vertical-align: middle;
				border: 1px solid #1d5280;
				overflow: visible;width:21px;height:21px;line-height:22px;border-radius:22px;font-size:0;
			}

		}

		li.slick-active button {background-color:#1d5280;}
		li button:focus {background-color:#fff; box-sizing: border-box;border:3px solid #1d5280}
		li button:hover {background-color:#1d5280;}
	}


	*+ html .slick-dots-list li {display:inline;}
	*+ html .slick-dots-list li button {display:inline;}

	.slick-dots-on-off img {vertical-align:middle;}

	.main-slide-prev {
		display: block;
		width: 26px;
		height: 26px;
		font-size: 0;
		text-indent: -9999px;
		position: relative;
		&:before {
			position: absolute;
			top:50%;
			left:50%;
			transform: translate(-50%, -50%);
			@include sprite($visual_prev2);
		}
		&:focus {
			background-color: gold;
			border-radius: 20px;
		}
	}

	.main-slide-next {
		display: block;
		width: 26px;
		height: 26px;
		font-size: 0;
		text-indent: -9999px;
		position: relative;
		&:before {
			position: absolute;
			top:50%;
			left:50%;
			transform: translate(-50%, -50%);
			@include sprite($visual_next2);
		}
		&:focus {
			background-color: gold;
			border-radius: 20px;
		}
	}

	.main-slide-pause {
		display:block; width:26px;height:26px;font-size:0;text-indent: -9999px;
		position: relative;
		&:before {
			position: absolute;
			top:50%;
			left:50%;
			transform: translate(-50%, -50%);
			@include sprite($visual_pause2);
		}
		&:focus {
			background-color: gold;
			border-radius: 20px;
		}
	}
	.main-slide-play {
		display:block; width:22px;height:22px;font-size:0;text-indent: -9999px;
		vertical-align: top;
		@include sprite($visual_play);
	}

	.slick-dots-wrap-outwrap {display:none; }
	.slick-dots-wrap-outwrap.on {display:block;}

@media screen and (min-width:1px) and (max-width:1500px) {
	.main-visual {width:100%;padding-top:0;}
	.main-visual {}
	.main-visual .slick-arrow {top:150px;}
	.main-slide__item > img {width:1500px}
}
@media screen and (min-width:901px) and (max-width:1200px) {
	.main-slide__item > img {width:1500px}
}
@media screen and (min-width:769px) and (max-width:900px) {
	.main-slide__item > img {width:1250px}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.main-visual {margin-top:0;}
	.main-visual .slick-arrow {top:70px;}
	.main-slide__item {
		height: 476px;
		background-position: 0% 0%;
		background-size: cover;
		
		&.main-slide_01 {background-image:url("../../resources/images/site/section_main_bg_01_mob.png");}
		&.main-slide_02 {background-image:url("../../resources/images/site/section_main_bg_01_mob.png");}
	}
	.main-slide__item > img {width:100%}
}

@media (max-width:500px) {
	.main-slide__item {
        height: 300px;
    }

	.main_visual__info {
        top: 250px !important;
    }
}



/*video*/
.main-visual {overflow:hidden;}
.video-box {position: relative;}
.video-box__over {
	position: absolute;
	top:0;left:0;width:100%;
	height:100%;
	background: {
		image: url('../../resources/images/basic/video-over-img.png');
		repeat: repeat;
		position: 0 0;
	}

}
.video-box video {width:1900px;}
.video-box__pause {
	position: absolute;
	top:825px;
	right:50%;
	margin-right:-740px;
	z-index: 99999;
	background-color:#fff;
	font-weight: bold;
	display:block;
	padding:3px;
	border-radius:10px;
	border:2px solid #000;
}
.video-box__pause:focus {
	background-color:gold
}


.main_visual__content .main_visual__content_wrap .slide-controls .top-wrapper .slider-progress__container {
    width: 200px;
    display: inline-block;
    margin-right: 15px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.main_visual__content .main_visual__content_wrap .slide-controls .top-wrapper .slider-progress__container .slider-progress {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.3);
    display: block;
}
.main_visual__content .main_visual__content_wrap .slide-controls .top-wrapper .slider-progress__container .slider-progress .progress {
    display: flex;
    width: 0%;
    height: 2px;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #15baff;
    border-radius: 0.25rem;
}
.main_visual__content .main_visual__content_wrap .slide-controls .top-wrapper .section-slide__paging span {
    font-size: 16px;
    color: #c1c1c1;
}
.main_visual__content .main_visual__content_wrap .slide-controls .top-wrapper .section-slide__paging .paging_active {
    color: #fff;
}
ccc

.slick-slide {

}

.main_visual__info__wrap {
	position: relative;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
	z-index: 1;
}

.main_visual__info {
	position: absolute;
	top: 460px;
	display: flex;
    align-items: center;

	.slick-dots {
		display: flex !important;
		margin-right: 40px;

		@media (max-width: 500px) {
					margin-right: 20px;
		}

		li {
			width: auto;
			height: auto;
			margin: 0;

			button {
				display: inline-block;
				vertical-align: middle;
				width: 27px;
				font-size: 16px;
				font-weight: 300;
				font-family: $font3;
				color: #fff;
				padding: 0;
				outline: none;
				background: none;
			}

			&.slick-active {

				.progressBar {
					width: 100px;
					margin-right: 6px;
					margin-left: 6px;

					@media (max-width: 500px) {
						width: 60px;
					}
				}
			}
		}

		.progressBar {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 0;
			height: 3px;
			background-color: rgba(255, 255, 255, 0.3);
			margin-left: 0;
			overflow: hidden;
			-moz-transition: 0.3s all ease-in-out;
			-o-transition: 0.3s all ease-in-out;
			-webkit-transition: 0.3s all ease-in-out;
			transition: 0.3s all ease-in-out;

			.inProgress {
				position: absolute;
				width: 0;
				height: 100%;
				background-color: #fff;
			}
		}
	}
	

	.slick-arrow {
		display: block;
		width: 7px;
		height: 12px;
		background-repeat: no-repeat;
		background-size: 100% 100%;

		&.main_visual__prev {
			background-image: url(../../resources/images/site/btn_slide_prev.png);
			margin-right: 20px;
		}
		
		&.main_visual__next {
			background-image: url(../../resources/images/site/btn_slide_next.png);
			margin-left: 20px;
		}

		@media (max-width: 500px) {
			&.main_visual__prev {
				margin-right: 15px;
			}
			
			&.main_visual__next {
				margin-left: 15px;
			}	
		}
	}

	.main_visual__btn_pause, .main_visual__btn_play {
		display: block;
		width: 9px;
		height: 12px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-size: 0;
	}

	.main_visual__btn_pause {
		background-image: url(../../resources/images/site/btn_slide_pause.png);
	}
	
	.main_visual__btn_play {
		background-image: url(../../resources/images/site/btn_slide_play.png);
	}


	@media (max-width:768px) {
		top: 400px;
	}
}



@media screen and (min-width:1px) and (max-width:1500px) {
	.video-box video {width:1500px}
	.video-box__pause {
		top:800px;
		margin-right:-42%;
	}
}
@media screen and (min-width:901px) and (max-width:1200px) {
	.video-box video  {width:1200px}
	.video-box__pause {
		top:620px;
		margin-right:-40%;
	}
}
@media screen and (min-width:769px) and (max-width:900px) {
	.video-box video  {width:900px}
	.video-box__pause {
		top:460px;
		margin-right:-40%;
	}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.video-box video  {width:100%}
	.video-box__over {display:none;}
	.video-box__pause {
		display:none;
		top:unset;
		bottom:10%;
		margin-right:-44%;
	}
	// .slick-dots-wrap-outwrap {
	// 	bottom:45px;
	// 	left:11px;
	// 	width:auto;
	// }
}
