

/*전체메뉴보기*/

.all_menu_wrap__outwrap {
	position: relative; width: 100%; margin: 0 auto; 
	// display: none;

	&.selected {
		width: 100%;
		z-index: 10001;
		position:fixed;
		top:0;
		height:100%;
		background: {
			image: url('../images/basic/opa2.png');
			position: 0 0;
			repeat:repeat;
		}
	}
}


.btn_all{
	//position: absolute;
	//right: 0;
	//top: 30px;
	display:block;
	border:0px solid #ddd;
	width:49px;height:49px;
	margin:1px;
	transition: all 0.3s ease 0s;
}
.mainpage .btn_all {top:50px;}
.btn_all span {display:block;font-size:0;

	//	@include sprite($all-menu-btn);
		margin:0 auto;
		margin-top:15px;

}
.btn_all:hover {opacity:0.8;}
.btn_all:focus {opacity:0.8;border:1px solid #fff;margin:0px;}

.all_menu_wrap {
	position: relative;
	width:100%;
	z-index:9999;
	background-color: #fff;
	//box-shadow: 0px 6px 31px -4px rgba(0,0,0,0.75);
	/*
	height:0;
	overflow:hidden;
	visibility: hidden;*/
	transition: all 0.7s ease 0s;
	// opacity:0;
	// display:none;
	top: -1px;
	border-top: 1px solid #ddd;



	&__h2 {
		height: 110px;
		color:#fff;
		text-align:center;
		font-size:35px;
		padding-top:60px;
		font-weight: 800;
		position: relative;

		&:before {
			position:absolute;
			top:20px;left:20px;
			@include sprite($title)


		}
	}
}
.selected .all_menu_wrap {display: block;
	height: auto;
	opacity: 1;
	visibility: visible;
	display: block;
}

.all_menu_in {
	position: relative; width: 100%; max-width: 1440px; padding: 0 20px; margin: 0 auto; box-sizing: border-box;
	.gnb-menu > a > span {color: #fff; font-size: 35px; white-space: nowrap;}
	.selected & .gnb-menu {border-right: 0;}
	.gnb-menu > a {margin-left: 0; width: auto; margin-left: 10px; margin-right: 10px;
		&:focus span {color: gold;}
	}

	.all_menu_close{
		color: #333;
		display: block;
		font-size: 0;
		position: absolute;
		right: 40px;
		top: -85px;z-index: 9999;
		width:30px;height:30px;
		@include sprite($all_menu_close2);
	}
	.gnb-menu {
		display: flex; width: calc(100% - 40px); position: absolute;
		&.on {z-index: 1;}
		&:before {content: ''; display: block; background-color: #fbf9f8; width: 50%; height: 100%; position: absolute; top: 0; left: calc(-50% + 20px);}
	}
	.gnb-menu > a {text-align:center; font-size:15px; color:#323551; font-weight:bold; margin-bottom:15px;}

	.sub-head {
		position: relative; width: 290px; min-height: 430px; padding-top: 53px; box-sizing: border-box; overflow: hidden; background-color: #fbf9f8;

		&:nth-child(1) {
			&:before {content: ''; display: block; width: 314px; height: 422px; background-image: url(../../resources/images/site/menu_img_01_pc.png); background-repeat: no-repeat;  background-size: 100% 100%; position: absolute; bottom: -135px; right: -50px;}
		}
		&__tit {font-size: 30px; font-weight: 800; color: #111; padding-bottom: 20px;}
		&__txt {font-size: 14px;  color: #666;}
	}

	.sub-nav {
		width: calc(100% - 290px); height: auto; visibility: visible; background-color: #fff; padding-top: 53px;

		&__ul {
			display: flex; flex-wrap: wrap; padding-left: 23px;

			.sub-nav__box__list {
				width: 25%; padding-bottom: 20px; padding-left: 17px; box-sizing: border-box;

				ul {

					li {
						position: relative;
						padding-left: 30px;

						a {
							position: relative;
							font-size: 14px;
							font-weight: 400;
							color: #666;

							&:before {
								content: '';
								display: block;
								width: 3px;
								height: 3px;
								background-color: #acacb2;
								position: absolute;
								top: 9px;
								left: -10px;
							}
						}
					}
				}
			}
		}
	}
	.sub-nav__box__list > a {display: block; height: 58px; line-height: 58px; border: 1px solid #e5dadc; border-radius: 5px; padding: 0 20px 6px 20px; color: #111; font-size: 16px; font-weight: 700; text-align: left; margin-bottom: 18px;}
	.sub-nav__box__list > a:hover {
		background-image: url(../../resources/images/basic/bg_depth_02.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}
	// .sub-nav__box__list > a:hover,
	// .sub-nav__box__list > a:focus {color:#fff;}

	.sub-nav__box__list > a:hover {color:#fff;}

	.gnb-ul {width: 100%; height: 430px;}
	.gnb-menu:first-child {border-left: 0px;}


	.sub-nav__3rd {text-align: left;padding-left:20px;padding-top:0px;padding-bottom:10px;}
	.sub-nav__3rd a {display:block;margin-bottom:12px;}

}

@media (max-width: 1200px) {
	.all_menu_in {

		.sub-nav__ul {

			.sub-nav__box__list {
				padding-left: 10px;

				ul {

					li {
						position: relative;
						padding-left: 20px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
	.all_menu_in {

		.sub-nav__ul {

			.sub-nav__box__list {
				padding-left: 10px;

				ul {

					li {
						position: relative;
						padding-left: 20px;
					}
				}
			}
		}
	}
}


@media screen and (min-width: 769px) and (max-width: 1000px) {
	.all_menu_in {

		.sub-nav {
			width: calc(100% - 200px);

			&__ul {
				padding-left: 10px;
	
				.sub-nav__box__list {
					padding-left: 10px;

					> a {
						height: 40px;
						line-height: 40px;
						font-size: 14px;
						padding: 0 10px 6px 10px;
						margin-bottom: 10px;
					}
	
					ul {
	
						li {
							position: relative;
							padding-left: 20px;

							a {
								font-size: 12px;
							}
						}
					}
				}
			}
		}

		.sub-head {
			width: 200px;

			&:nth-child(1) {

				&::before {
					background-size: 70% 70% !important;
					bottom: -200px !important;
					right: -130px;
				}
			}

			.sub-head__tit {
				font-size: 24px;
			}

			.sub-head__txt {
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 768px) {
	.all_menu_wrap__outwrap{display: none !important;}
}

.btn_all .type1{display:block;}
.btn_all .type2{display:none;}
.btn_all.selected .type1{display:none;}
.btn_all.selected .type2{display:block;}
