
/*faq*/

.faq-box {
	width:80%;
	margin:0 auto;
	&__list {
		margin-top:-1px;
		&:first-child {margin-top:0px;}
		&__tit {
			padding:30px;
			border:1px solid #000;
			font-size:18px;
			position: relative;
			&__num {font-weight:bold;display: inline-block;margin-right:35px;margin-left:30px;text-align: center;}
			&__much {font-weight:bold;display: inline-block; width:120px}
			&__sbj {}
			&__opener {
				@include btn2;
				position: absolute;
				right:30px;
				top:20px;
				height:51px !important;
				line-height: 51px !important;
				&.type1 {display:block;}
				&.type2 {display:none;}
			}
		}

		&.on .faq-box__list__tit__opener.type1 {display:none;}
		&.on .faq-box__list__tit__opener.type2 {display:block;}

		&__answer {
			display: none;
			border:1px solid #000;
			border-top: 0px;
			background-color: #f7f7f7;
			padding:30px 40px;;
			font-size: 16px;
		}
	}

}

@media screen and (min-width:1px) and (max-width:1000px) {
	.faq-box {width:auto;}
	.faq-box__list__tit {padding:10px 20px;font-size:15px;}
	.faq-box__list__tit__num {margin-right:20px;margin-left:10px;}
	.faq-box__list__tit__much {margin-right:20px;}
	.faq-box__list__tit__opener {position: relative;top:0;right:0;height:35px !important;line-height: 35px !important;background-color: #666;margin-top:4px;}
	.faq-box__list__tit__sbj {display: block;font-size:16px;margin-top:4px;margin-left:5px;}

}
@media screen and (min-width:1px) and (max-width:560px) {
}