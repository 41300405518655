.sub-visual {
	height:195px;
	transition: all 0.3s ease 0s;
	background: {
		image: url("../../resources/images/site/visual-1.png");
		repeat: repeat;
		position: center top;
		size: auto 100%;
	}
	font-family: $font1;

	&__tit {
		color: #fff;
		font-size: 60px;
		font-weight: bold;
		padding-top: 100px;
		text-align:center;
	}
	&__desc {
		color: #fff;
		font-size: 15px;
		padding-top: 10px;
		text-align:center;
	}
}

@media screen and (min-width:769px) and (max-width:1200px) {
	.sub-visual__tit {font-size: 40px;}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.sub-visual {display: none;}
}