/*신청서 시작화면*/

.facility-first {
	&__item {
		border:1px solid #cccccc;
		border-top: 4px solid #333;
		padding-top:30px;
		padding-bottom:30px;
		margin-bottom:20px;
		position:relative;
		width: 48%;
		float: left;
		margin-left: 1%;
		min-height: 220px;
		box-sizing: border-box;
		@include respond-to(sc4) {width: 100%; float: none; margin-left: 0;}
		&:nth-child(2n+1){margin-left: 0;}
		&__tit {
			font-weight: bold;
			font-size: 18px;
			height: 23px;
			line-height: 23px;
			padding-left: 30px;
			margin-bottom: 15px;
		}
		&__info {padding-left:30px;
			font-size:16px;
			margin-bottom:10px;
			&.type2 {display:none;}
			.imposb &.type1 {display:none;}
			.imposb &.type2 {display:block;font-size:16px;}
		}
		&__date-tit {margin-left:30px;
			color:#fff;
			display:inline-block;
			padding:2px 10px;
			font-size:14px;
			font-weight: bold;
			border-radius: 20px;
			background-color: #000;
			.imposb & {display: none;}
		}
		&__date {padding-left:30px;
			.imposb & {display: none;}
		}
		&__btn {
			position: absolute;
			top:15px;
			right:15px;
			height:40px;
			line-height: 40px;
			font-size:15px;
			font-weight:bold;
			text-align: center;
			min-width: 150px;;
			&.type1 {display:block;
				color:#fff;
				border:1px solid #f56d36;
				background: #f56d36;
				box-sizing: border-box;
				.imposb &.type1 {display: none;}

				&:hover,
				&:focus {color:#fff;
					background: #f56d36;
					border:1px solid #f56d36;}
			}
			&.type2 {display:none;
				color:#000;
				border:1px solid #000;
				box-sizing: border-box;
				.imposb &.type2 {display: block;}
			}
		}

		&.imposb {
			background-color:#f4f4f4;
		}
	}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.facility-first__item__tit {padding-left:20px;}
	.facility-first__item__info {padding-left:20px;padding-right:10px;}
	.facility-first__item__date-tit {display:block;width:100px;text-align: center;
	margin-bottom:10px;margin-left:20px;}
	.facility-first__item__date {margin-left:20px;margin-bottom:10px;display: block;padding-left:0;}
	.facility-first__item__btn {position: relative;top:0;right:0;transform: translateY(0);margin-right:20px;margin-left:20px;}
}

/*신청서*/
.apply-box {
	text-align:center;
	&__tit {@include h3Type1}
	&__ok {
		@include btn3;
		margin: 0 auto;
	}
	&__radiobox-list {
		@include floatWrap;

		li {
			float:left;
			margin-right:25px;
			@media screen and (min-width:1px) and (max-width:768px) {
				& {float:none;margin-right:0;}
			}
		}
	}
	&__table {
		@include basicTable1;
		td {text-align:left; padding-left:20px;}

		&__input {
			@include inText(40%)
		}
		&__input2 {
			@include inText(60%)
		}
		&__input-small {
			@include inText(120px);
			height:30px !important;
		}
		&__textarea {
			@include inTextarea(80%)
		}
		&__select {
			@include selectBox(300px)
		}

		@media (max-width:800px) {
			td:before {
				display:block;
				content:attr(data-th) "  ";
				margin-bottom:10px;
			}

			&__input {
				@include inText(95%)
			}
			&__input2 {
				@include inText(95%)
			}
			&__textarea {
				@include inTextarea(95%)
			}
		}
	}
	&__mid {
		border:1px solid #ddd;
		padding:20px;
		text-align: left;
		margin-bottom:30px;
		&__tit {margin-bottom:30px;}
	}
	&__bottom {
		border:1px solid #ddd;
		padding:20px 40px 20px 20px;
		text-align: right;
		margin-bottom:30px;
		font-size:20px;
		&__cost {
			strong {display:inline-block;min-width:150px;font-size: 24px;}
		}
	}
}
