.sitemap {

	&__box {
		@include floatWrap;
		border-top:1px solid #c6c6c6;
		padding-bottom: 40px;
		min-height:300px;
		&__tit {
			border-top: 1px solid #000;
			width: 23%;
			float: left;
			margin-top: -1px;
			padding-top: 40px;
			font-size: 40px;
			font-weight: 600;
		}
		&__2nd {
			float: left;
			width: 77%;
			padding-top: 50px;
			@include floatWrap;
			display:flex;
			flex-wrap: wrap;
			&__li {
				float: left;
				flex: 1;
				padding-left:20px;
				min-width: 150px;
				padding-bottom:40px;
				&__a {
					font-weight: 500;
					font-size:18px;
					margin-bottom:10px;
					display:block;
					color:#000;

				}
				&__a:focus {
					font-weight: 700;
				}
				&__3rd {
					&__li {

						&__a {
							padding-left:10px;
							position: relative;
							&:before {
								position: absolute;
								top:8px;
								left:0;
								@include sprite($sitemap-bul);
							}
							&:hover,
							&:focus {text-decoration: underline;}
						}
					}
				}
			}
		}
	}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.sitemap__box__tit {width:auto;padding-left:10px;float:none;}
	.sitemap__box__2nd {width:auto;padding-left:0px;float:none;}
	.sitemap__box__2nd__li {min-width: 120px;;}
}