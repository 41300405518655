
/*sub-page-container*/
.sub-page-container {}
.sub-page-container__in {width:1200px;margin:0 auto;position: relative;background-color:#fff;}
.sub-contents {float:right;width:940px;padding-bottom:50px; padding-top: 40px;}

.cont-in {padding-bottom:40px; font-family: 'noto sans kr';}

.cont-in img {
	max-width: 100%;
	height: auto !important;
}

@media screen and (min-width:1px) and (max-width:1200px) {

	.sub-page-container__in{width:auto;}
	.sub-contents {width:auto;float:none; padding:10px;padding-top:5px;}
	.cont-in img {
		height: auto !important;
	}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.sub-page-container {padding-top:51px;}
}
.sub-contents__top {position:relative;border-bottom:2px dotted #e0e0e0;font-family: 'Noto Sans KR';
	/*background-image:url("../../commons/images/global/sub-visual-bg1.jpg");*/
	background-position:right top;
	background-repeat:no-repeat;
	margin-bottom:40px;
	}

.sub-contents__top h2.sub-tit {font-size:30px;color:#222;padding-top:5px;margin-bottom:0;height:60px;font-weight: 700;}

/* location */
.location-box {position:absolute;right: 0; top: 15px;}
.location-box__cont {
	// position: absolute;
		// right: 0;
		// width:1200px;
		// text-align:center;
		// top: -98px;

		span {display:inline-block;font-size:14px;font-size:1.4rem;height:28px;line-height:28px;

			&:before {
				content:'';
				display:inline-block;font-size:0;
				@include sprite($location-icon);
				margin:0 17px;
				@media screen and (min-width:1px) and (max-width:480px) {
					& {margin:0 8px;}
				}
			}

			color:#666;
			&:first-child::before {
				display:none;
			}
		}
	}
.location-box__cont .loc-home {display:block;padding-left:30px;font-size:14px;font-size:1.4rem;height:28px;line-height:27px;
	background-image:url("../../resources/images/icons/home.png");
	background-position:0 center;
	background-repeat:no-repeat;
	color:#666;
	&:focus {
		border:2px solid gold
	}
	@media screen and (min-width:1px) and (max-width:480px) {
		& {padding-left:20px;}
	}

	}

/* 프린트, url 버튼 등*/
.special-box {
	position: absolute;
	top:10px;right:0;
	&__print {
		margin-right:4px;
		text-indent: -9999px;
	}
	&__url {
		text-indent: -9999px;
	}
}

@media screen and (min-width:769px) and (max-width:1200px) {
	.location-box__cont {position:relative;top:3px;width:auto;text-align:right ;}
	.special-box {top:38px;}
}
@media screen and (min-width:1px) and (max-width:768px) {
		.sub-contents__top {background-image:none !important;margin-bottom:10px;}
		.sub-contents__top h2.sub-tit {font-size:21px;margin-bottom:8px;padding-top:5px;height:auto;}
		.location-box {position:relative;top:0;right:0;padding-bottom:0px;}
		.location-box__cont {position:relative;white-space:normal;width:auto;text-align: left;overflow:visible;top:0;left:0;padding-top:0px;}
		.special-box {display:none;}
}


/* checkbox */

input[class="check-type"] + label {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 2px solid #cccccc;
	background-color:#dddddd;
	cursor: pointer;
	border-radius: 30px;
	position: relative;

	&:before {
		content:' ';
		@include sprite($check-type);
		position: absolute;
		top:10px;left:10px;
	}
}

input[class="check-type"]:checked + label {
	background-color: #666666;
}

input[class="check-type"]:focus + label {
	border: 2px solid gold;
}
input[class="check-type"] {
	position: absolute;
	left: -9999px;
}


/* 2 */
input[class="check-type2"] + label {
	display: inline-block;
	width: 12px;
	height: 12px;
	cursor: pointer;
	position: relative;
	border:1px solid #ddd;
}

input[class="check-type2"]:checked + label {
	&:before {
		content:' ';
		@include sprite($check-type2-on);
		position: absolute;
		top:0px;left:0px;
	}
}

input[class="check-type2"]:focus + label {
	border:1px solid #bbbbbb;
}
input[class="check-type2"] {
	position: absolute;
	left: -9999px;
}


// 동영상 게시물 상세에서 아이프레임 있을경우
.editor_view.on {
	& .editor_view__cont {
		position: relative;
		width: 100%;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%; /*16:9*/
		background-color:#00235d;
		color:#fff;
		> p {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			iframe { width: 100%; height: 100%}
		}
	}
}

/*box style*/
.box-style01__wrap {position: relative;margin: 0 auto;max-width: 900px; }
.box-style01__wrap:before {content: '';background-image: url(../../resources/images/cont/box-img1.png);z-index: 9;
    background-position: top center;background-repeat: no-repeat;top: 0;left: 0;height: 50px;width: 100%;position: absolute;}
.box-style01 {padding: 40px;border: 6px solid #ebebeb;border-top: 0; border-radius: 10px; }


@media screen and (min-width:1px) and (max-width:600px) {	
	.box-style01 {padding: 20px 15px }
}


