
/*header*/
.header {position: relative; z-index: 99; width: 100%; z-index: 9;
    font-family: $font3;
    /*헤더 위 링크들*/
    &__links {
        position: relative; width: 100%; max-width: 1440px; padding: 0 20px; margin: 0 auto; box-sizing: border-box;

        &__1 {
            position: absolute; top: 30px; right: 0px; z-index: 10; white-space: nowrap; transition: all 0.3s ease 0s; padding-bottom: 30px;
            & > a {position: relative; margin-left: 20px; color:#666; line-height: 30px;
                &:focus {
                    border: none;
                    border-radius: 4px;
                }

            }

            &__icon1 {
                top: 2px;

                &:before {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 25px;
                    background-color: #ddd;
                    position: absolute;
                    top: -6px;
                    left: -12px;
                }
            }
            &__icon2 {
                &:before {

                    position: absolute;
                    top:0;left:0;
                    display:block;
                    width:25px;height:25px;
                }
            }
            &__icon3 {
                &:before {

                    position: absolute;
                    top:0;left:0;
                    display:block;
                    width:25px;height:25px;
                }
            }
            &__icon4 {
                &:before {

                    position: absolute;
                    top:0;left:0;
                    display:block;
                    width:25px;height:25px;
                }
            }
            &__icon5 {
                padding-left:0 !important;
                margin-left:20px;
                display: inline-block;
                height:26px;
                img {
                    vertical-align: top;
                }
                &:before {

                    position: absolute;
                    top:0;left:0;
                    display:block;
                    width:25px;height:25px;
                }
            }

        }


        &__1 > a:hover {opacity:0.8; color: #dd6803;}
        &__1 > a:focus { color: #dd6803;}
        .add-search-btn &__1 {right: 20px;}

        @media screen and (min-width:1px) and (max-width:768px) {
            &__1 > a {font-size:13px;width:auto !important;margin-left:10px !important;color:#000;
            padding-left:0;
            }
            // .header__links__1__icon5 {display:none;}
            &__1 > a:before {display:none !important;}

            &__1 {
                &__icon1 {
                    display: none;
                }
            }
        }

    }

    &__wrap {
        position: relative; background-color: #fff;

        &__h1 {position: relative; z-index: 999; width: 100%; max-width: 1440px; padding: 0 20px; margin: 0 auto; box-sizing: border-box;
            
            h1 {position: absolute; left: 20px; top: 23px;
                a {
                    //width:240px;
                    //height:55px;
                    //line-height:55px;
                    display: block;
                    text-align: center;
                    //font-size:20px;
                    //@include sprite($title);
                    font-size: 0;
                    img {font-size: 10px; width: 290px;}
                    &:focus {
                        border:1px solid #cb760e;
                        border-radius: 4px;
                    }
                }

            }

        }
    }
    @media screen and (min-width:1px) and (max-width:1200px) {
        &__links { width: auto;}
        &__wrap__h1 {width: auto; margin: 0 auto;}
        h1 a img {max-height:45px;}
    }
    @media screen and (min-width:1px) and (max-width:768px) {
        &__wrap__h1 { width: 200px;}
       
    }


	/*header search*/
	/*
	&__search {position:absolute;top:0px;left:50%;margin-left:-70px;}
	&__search__in {border:1px solid #333;background-color:#fff;}
	&__search-in_box {border:0px;padding:0;height:32px;width:150px;line-height: 32px; vertical-align: top;}
    */

    &__search {position: relative; width: 1200px; margin: 0 auto;

	    &__in {position: absolute; top: 0; right: 0; border: 1px solid #333; background-color: #fff;}
	    &-in_box {border: 0px; padding: 0; padding-left: 10px; height: 32px; width: 140px; line-height: 32px; vertical-align: top;}
    }

	&-search-btn {display: none;}
	.add-search-btn &__search__in {right: 50%; margin-right: -70px;}
	.add-search-btn &__search {display: none;}
	.add-search-btn &__search.on {display: block;}
	.add-search-btn &-search-btn {display: inline-block;}
		*+ html .add-search-btn &-search-btn {display: inline;}

	@media screen and (min-width:769px) and (max-width:1200px) {
		&__search {width: auto;}
	}
	@media screen and (min-width:1px) and (max-width:768px) {
        &__search {display: none;
            &__in {top: 50px;}
            .add-search-btn &__in {right: 0; margin-right: 0;}
        }
		&__search.on {display: block; width: auto;}

		&-search-btn {display: none !important}
	}

}


@media screen and (min-width: 1px) and (max-width: 1200px) {
    .header {}
}
/*gnb*/
.gnb {
	background: {
        color: #212336;
        repeat: repeat-x;
        position: center top;
    }
    transition: all 0.3s ease 0s;
	box-shadow: 0px 7px 11px -13px rgba(0,0,0,0.75);
    position: relative;
    width: 100%;
    max-width: 1440px; padding: 0 20px; margin: 0 auto; box-sizing: border-box;

    /*한개씩 떨어짐*/
    .gnb-ul {display: block; width: calc(100% - 520px); padding-left: 420px; padding-right: 100px; margin:0 auto;}
    .gnb-menu {}
    .gnb-menu > a {
        display: block;
        text-align: center;
        color: #000;
        height: 82px;
        line-height: 82px;
        text-decoration: none;
        font: {
            size: 24px;
            weight:700;
        }
    }
    .gnb-menu.over > a,
    .gnb-menu.focus > a,
    .gnb-menu > a:hover,
    .gnb-menu > a:focus {color:#f04c1b; border: none;}

    .gnb-menu {position: relative;}
    .sub-nav {visibility:hidden;height:0;position:absolute;top:60px;left:0;width:100%;z-index: 98;background-color:#fff;padding-top:0;opacity:0;
    transition: all 0.3s ease 0s;}
    .gnb-menu.over .sub-nav,
    &.selected .gnb-menu.focus .sub-nav {visibility:visible;height:auto;opacity:1;}
    .sub-nav__box {border:2px solid #00235d;border-top:0px;padding-left:20px;padding-top:10px;padding-bottom: 10px;transition: all 0.3s ease 0s;
        &__list > a {display: block;padding:8px 0 8px 10px;}
        &__list > a:hover,
        &__list > a:focus {text-decoration: underline;color:#000;}
    }
    .gnb-menu.over .sub-nav__box,
    &.selected .gnb-menu.focus .sub-nav__box {padding-bottom: 10px;padding-top:15px;}

}

*+ html .gnb {position:absolute;top:0;padding-top:0;}



/* all 2nd menu down
===============================*/
.down-all .gnb {
    background-image:url("../../resources/images/basic/header-bg-ver1.png");
    background-color:#fff;
    .sub-nav {
        display: block;
        text-align: center;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        position: relative;
        top: 0;
        left: 0;
        opacity: 1;
        background: #2a2933;
        display: none;

        &__box {
            border: 0px;
            padding: {
                left:0;
                top:10px;
                bottom:10px;
            }

            &__list a {
                display: block;
                width: 100%;
                padding: 8px 0;
                color: #fff;
                font: {
                    family: $font1;
                    weight:700;
                }
				/*
                &:hover,
				&:focus {}
				*/
            }
        }

    }

    &.selected .gnb-ul .sub-nav {
        height: auto;
        visibility: visible;
        padding: {
            top: 10px;
            bottom: 10px;
        }
        transition: all 0s ease 0s;
    }

    .gnb-menu.over {
        .sub-nav {
            background-color:transparent;
            &__box__list a {color:#fff;}
            &__box__list a:hover,
            &__box__list a:focus {color:#fff;}
        }

    }


    .gnb-menu > a {
        background: {
            color:#fff;
            repeat: repeat-x;
            position: left bottom;
        }
    }
    .gnb-menu.over > a,
    .gnb-menu.focus > a {
        background: {
       // image:url("../../resources/images/basic/header-gnb-bg-on.png");
        //color:#212336;
        position:left bottom;
        repeat: repeat-x;
       }
    }

	.gnb-menu:first-child > a > span {background-image: none;}
}


    /* 2차메뉴 균일 박스 디자인 적용시 */
    .down-all .gnb {
        .gnb-ul {display: flex; justify-content: space-between;}
        .gnb-menu {}
        .gnb-menu.over {background-color: #8c4437}
    }

	/* 2차메뉴 보더적용시 주석 해제 */

	.down-all .gnb-menu:first-child {border-left:0px solid #eee}
	.down-all .gnb-menu:last-child {border-right:0px solid #eee !important}
	.down-all .gnb-menu {padding-right:1px;}
	.down-all .selected .gnb-menu {border-right:1px solid #eee;padding-right:0;}
	.down-all .gnb-menu:first-child {}
	.down-all .gnb-menu > a {
		display: block;
		margin-left: -1px;
		position: relative;
		text-align: center;
		width: 102%;
		}


		/*ie7*/
		*+ html .gnb {top:90px;}
		*+ html .down-all .gnb .gnb {position:absolute;top:34px;}
		*+ html .down-all .gnb .gnb-ul {display:block;}
		*+ html .down-all .gnb .gnb-menu {display:block;float:left;}
		*+ html .down-all .gnb .gnb-menu.over {background-color:#f4fcfb}
		*+ html .down-all .gnb .gnb-menu.over .sub-nav {background-color:#f4fcfb;}
		*+ html .down-all .gnb .gnb-menu.over > a {background-color: #f4fcfb;color:#333;}
		*+ html .down-all .gnb .gnb-menu:first-child {border-left:0px solid #acc1bf}
		*+ html .down-all .gnb .gnb-menu {border-right:0px solid #f4fcfb;border-left:0px}
		*+ html .down-all .gnb .gnb-menu > a {width:100%;margin-left:0;}
		*+ html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a {color:#333;}
		*+ html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a:hover,
		*+ html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a:focus	{color:#333;}
		*+ html .down-all .gnb .selected .gnb-menu {border-right:0;}



/*모드별 디자인
	ver2
	down-all
	add-all-menu
	add-search-btn
--------------*/

/*ver2*/
.ver2 {
    .header__wrap__h1 h1 {top:20px;z-index: 9999;}

    .gnb {
        top:0;
        .gnb-ul {width:950px;padding-left:250px;}
        .gnb-menu > a {height:109px;line-height: 109px;}
        .sub-nav {top:109px;}
    }
    &.down-all .gnb {
        background-image:url("../../resources/images/basic/header-bg-ver2.png");
     //   background-color:#212336;

        .sub-nav {top:0;}
    }
   // &.down-all .gnb.selected {background-color:#fff;}
}
/*서브페이지 투명도*/
.subpage .gnb {
    opacity: 0.95;
}
.subpage .header__wrap {height:109px;background-color:#fff}
.subpage.ver-mini .header__wrap{height:auto;background-color: transparent;}
.subpage.ver-mini .gnb {opacity: 1;}
@media screen and (min-width:1px) and (max-width:1200px) {
    .subpage .gnb {
        opacity: 1;
    }
    .subpage .header__wrap {height:auto;background-color:#fff;}
}
@media screen and (min-width:769px) and (max-width:1200px) {
.ver2 .gnb .sub-nav {top:60px;}
}


/*down-all*/


/* add-all-menu */
.add-all-menu {
    .all_menu_wrap__outwrap {display: none; position: fixed; height: 100%; background-color: rgba(0, 0, 0, 0.5); border-top: 1px solid #ddd;
        &.active {display: block;}
    }
    .gnb .gnb-ul {}
    // padding-right:50px;
}

/*ver2 + add-all-menu*/
.ver2.add-all-menu .gnb .gnb-ul {
    width:800px;
    padding-left:300px;
    padding-right:400px;}


@media screen and (min-width:1001px) and (max-width: 1300px) {
    .header__links__1 {top: 18px;}

	.header__wrap__h1 h1 {width: 200px; top: 20px;}
	.gnb {
        
        .gnb-ul {
            width: calc(80%);
            padding-left: 20%;
            padding-right: 10%;
            box-sizing: border-box;
        }
	    .gnb-menu > a { height: 60px !important; line-height: 54px !important;
            span {font-size: 16px;}
        }
    }

}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .header__links__1 {top: 18px;}

	.header__wrap__h1 h1 {width: 180px; top: 20px;}
	.gnb {
        
        .gnb-ul {
            width: calc(90%);
            padding-left: 20%;
            padding-right: 10%;
            box-sizing: border-box;
        }
	    .gnb-menu > a { height: 60px !important; line-height: 54px !important;
            span {font-size: 14px;}
        }
    }

}


/*메인페이지용 gnb*/
.mainpage {
    .header {background-color:#fff;}
    .header__wrap__h1 h1 {}
    // .header__links__1 {top:68px;}
    .gnb {
        transition: all 0.0s ease 0.0s;
        // box-shadow: 0px 0px 0px 0px #000 !important;
        // background-image: url(../../resources/images/basic/header-bg-ver1.png);
        background: {
            color:#fff;
            repeat:no-repeat;
            position:-2px 0;
        }

        & .gnb-menu {transition: all 0s ease 0s;}
        & .gnb-menu > a {background-color:#fff; border: none !important;}
        & .gnb-menu.focus > a {text-decoration: underline;}
            
    }

    .down-all .gnb.selected {transition: all 0s ease 0s;
        // opacity: 0.94;
        // box-shadow: 0px 7px 11px -13px #000 !important;
        // background: {
        //     color:#2a2933;
        //     repeat:repeat-x;
        // }

        & .gnb-menu {border-right:1px solid #ddd;}
        // & .gnb-menu > a {background-color:#212336;}
    }


    @media screen and (min-width:769px) and (max-width:1200px) {
        .header__links__1 {}
    }
    @media screen and (min-width:769px) and (max-width:1000px) {
        .header__links__1 {
            right: 20px;
            .header__links__1__icon1 {}
        }
    }
}


/*서브페이지용 gnb*/
.subpage {
    .header {background-color:#fff;}
    .header__wrap__h1 h1 {top:25px;}
    // .header__links__1 {top:68px;}
    .gnb {
        transition: all 0.0s ease 0.0s;
        box-shadow: 0px 0px 0px 0px #000 !important;
        background-image: url(../../resources/images/basic/header-bg-ver1.png);
        background: {
            color:#fff;
            repeat:no-repeat;
            position:-2px 0;
        }

        & .gnb-menu {transition: all 0s ease 0s;}
        & .gnb-menu > a {background-color:#fff;}
        & .gnb-menu.focus > a {text-decoration: underline;}
        & .gnb-menu.on > a {
            span {
                color:#cb760e;
            }
        }
    }

    .down-all .gnb.selected {transition: all 0s ease 0s;
        // opacity: 0.94;
        box-shadow: 0px 7px 11px -13px #000 !important;
        background: {
            color:#2a2933;
            repeat:repeat-x;
        }

        & .gnb-menu {border-right:1px solid #ddd;}
        // & .gnb-menu > a {background-color:#212336;}
    }


    @media screen and (min-width:769px) and (max-width:1200px) {
        .header__links__1 {top:37px;}
    }
    @media screen and (min-width:1px) and (max-width:768px) {
        .header__links__1 {top:-41px;}
    }
}



.ver-mini{
    .gnb{}
}



// sns_link
.header__sns {
    background-color: #f6f6f6;
    border-bottom: 1px solid #ddd;

    &__wrap {
        position: relative;
        width: 100%;
        max-width: 1440px;
        padding: 0 20px;
        margin: 0 auto;
        box-sizing: border-box;

        ul {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            li {
                display: inline-block;
                width: 30px;
                height: 30px;
                margin-left: 12px;

                a {
                    font-size: 0;

                    &::before {
                        content: '';
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-size: 30px 30px;
                        display: block;
                        width: 30px;
                        height: 30px;
                    }
                }

                &.sns_naverband {
                    a {
                        &::before {
                            content: '';
                            background-image: url(../../resources/images/site/ico_sns_naverband.png)
                        }
                    }
                }
                &.sns_instagram {
                    a {
                        &::before {
                            content: '';
                            background-image: url(../../resources/images/site/ico_sns_instagram.png)
                        }
                    }
                }
                &.sns_facebook {
                    a {
                        &::before {
                            content: '';
                            background-image: url(../../resources/images/site/ico_sns_facebook.png)
                        }
                    }
                }
            }
        }
    }

    @media (max-width:1000px) {
        display: none;
    }
}