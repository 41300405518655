/*	footer
==========*/

#footer {
  color: #333;
  background-color: #ffffff;
  position: relative;
  font-family: 'Noto Sans KR';
  // background-image: url("../../resources/images/basic/foot-bg.png");
  // background-position: 0 top;
  // background-repeat: repeat-x;
}

.footer_top {
  border-bottom: 1px solid #e5e5e5;
  
  .footer_cont {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1440px;
    height: 66px;
    padding: 0 20px;
    margin: 0 auto;
    box-sizing: border-box;

    .copyright {
      position: relative;
    }
    
    .foot_img {
      position: relative;
      display: inline-block;
    
      img {
        width: 258px;
      }
    }
  }
}

.footer_bottom {

  .footer_cont {
    position: relative;
    width: 100%;
    max-width: 1440px;
    height: 156px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    address {
      
      ul {
        display: flex;
        flex-direction: column;

        li {
          font-size: 15px;
          font-weight: 400;
          color: #888;
          font-family: $font3;
          text-transform: uppercase;

          strong {
            display: block;
            color: #444;
            padding-bottom: 8px;
          }
        }
      }
    }

    @media (max-width: 1300px) {
      flex-direction: column;
      padding: 30px 0;

      div:last-child {
        display: none;
      }

      address {
        padding-bottom: 20px;
      }
    
    }

    @media (max-width: 768px) {
      address {
        ul {
  
          li {
            font-size: 12px;
            text-align: center;
          }
        }
      }       
    }
  }
}

.footer_in_wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .footer_in_wrap {
  display: inline-block;
}

.copy_box {
  position: relative;
  display: inline-block;

  li {
    float: left;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    font-family: $font3;
    padding-left: 40px;

    &:before {
      position: absolute;
      left: 10px;
      top: 7px;
      @include sprite($copy_li_bg);
    }

    &:first-child:before {
      display: none;
    }
  }

  li:first-child a {
    background-image: none;
  }

  li.persnal-rule a {
    color: #f04c1b;
  }

  li.sns-li {
    background-image: none;
  }
}

.copy_box a {
  font-size: 18px;
  color: #111;
}

.copy_box a strong {
  color: #000;
}

.copy_box a:hover,
.copy_box2 a:hover,
.copy_box a:focus,
.copy_box2 a:focus {
  text-decoration: underline;
}

.copy_box2 {
  position: relative;
  width: auto;
  border-top: 0px solid #e7e7e7;
  padding-top: 20px;
  font-size: 12px;
}


.copy_box2 .item1 {
  margin-bottom: 5px;
  font-style: normal;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.5;
  color: #000;
}

.copy_box2 .item1 h3 {
  float: left;
  width: 65px;
  height: 26px;
  padding-top: 9px;
  margin-right: 5px;
  background-color: #676767;
  color: #000;
  text-align: center;
}

.copy_box2 .item1 li:first-child {
  padding-left: 0;
  background-image: none;
}

.copy_box2 .item1 li strong {
  color: #666;
  margin-right: 4px;
  display: inline-block;
  min-width: 62px;
  @media screen and (min-width:1px) and (max-width:768px) {
    display: block;
  }
}

.copy_box2 .item2 {
  font-style: normal;
  margin-top: 5px;
  margin-bottom: 4px;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.6;
  color: #000;
}

.copy_box3 {
  position: absolute;
  right: 20px;
  top: 13px;
}

.footer-select {
  display: inline-block;
}

.sel_go_btn {
  border-left: 0px;
  &:focus {
    text-decoration: underline;
  }
}

.footer_sns {
  position: absolute;
  right: 0;
  top: 50px;
  padding-top: 20px;

  a {
    text-indent: -9999px;
    margin-left: 5px;

    img {
      vertical-align: middle;
    }
  }
}

/*통계*/
.total-count {
  background-color: #399e90;
  padding: 10px 30px 10px 10px;
  border-radius: 30px;

  &__dt {
    display: inline-block;
    color: #fff;
    margin-right: 5px;
    padding-left: 20px;

    &:before {
      content: '';
      padding-right: 10px;
      padding-left: 14px;
      display: inline-block;
      @include sprite($total-count-bul);

    }

    &:first-child:before {
      display: none;
    }
  }

  &__dd {
    display: inline-block;
    color: #fff;
  }

}

/*푸더 셀렉트*/
.footer-select {
  background-color: #fff;

  &__item {

    select {}
  }
}

.footer-select__item {
  @include selectBox3(200px);
}

* + html .sel_go_btn {
  display: inline;
}

/*푸더 셀렉트*/
.footer-select {}

.sel_go_btn {
  display: inline-block;
  height: 40px;
  width: 50px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background-color: #6a6a6a;
  border-left: 1px solid #717171;
  border-radius: 5px;
}

* + html .sel_go_btn {
  display: inline;
}

.footer-select .select_design {
  border: 0px;
  background-color: transparent;

  float: left;
  margin-top: 6px;
}

.footer-select .select_design select {
  color: #bababa;
  font-size: 13px;
}

.footer-select .select_design select option {
  color: #000
}

.lte-ie9 .footer-select .select_design select option {
  color: #949494
}


/* 유관기관 바로가기 */
.foot-links {
  width: 230px;
  position: relative;
  height: 52px;
  z-index: 999;

  &__a {
    height: 52px;
    line-height: 52px;
    padding-left: 20px;
    font-size: 16px;
    color: #fff;
    display: block;
    background-color: #1b5394;

    &:focus {
      color: gold
    }

    position: relative;

    &:after {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }

  &__list {
    position: absolute;
    bottom: 52px;
    left: 0;
    background-color: #1b5394;
    width: 1200px;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
    display: none;

    &.on {
      display: block;
    }

    a {
      color: #fff;
      display: block;
      float: left;
      width: 25%;
      box-sizing: border-box;
      padding: 8px 20px 3px 20px;

      &:hover,
      &:focus {
        color: gold
      }

      @media screen and (min-width: 1px) and (max-width: 768px) {
        width: 50%;
      }
      @media screen and (min-width: 1px) and (max-width: 480px) {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 1300px) {

  /*footer*/
  #footer {
    height: auto;
    min-height: 100px;
    background-image: none;
    position: relative;
    z-index: 3;
  }
  .footer_top .footer_cont {
    height: auto;
    flex-direction: column;
    padding: 30px 0;
  }
  .footer_bottom .footer_cont {
    height: auto;
    flex-direction: column;
    padding: 30px 0;
  }
  .footer_in_wrap {
    width: 100%;
  }
  .foot_img {
    display: none;
  }
  .copy_box {
    padding-top: 20px;
  }
  .copy_box li {
    background-image: none;
    padding: 4px 10px;

    &:before {
      display: none
    }
  }
  .copy_box2 {
    position: relative;
    left: 0;
    top: 0;
    padding-top: 10px;
  }
  .select_bottom {
    position: relative;
    right: 0;
    top: 0;
  }
  .select_bottom p {
    float: left;
  }
  .copy_box2 .item1 li {
    padding-left: 0;
    background-image: none;
    float: none;
  }

  .copy_box3 {
    display: none;
  }

  .copy_box4 {
    position: relative;
    top: 0;
  }
  .total-count {
    padding: 0;
    border-radius: 0;
    padding: 5px;
  }
  .total-count__dt:first-child {
    padding-left: 0;
  }

  .footer-select {
    display: inline-block;
  }
  .sel_go_btn {
    border-left: 0px;
  }

  .footer_sns {
    top: 90px;
  }
  /*유관기관바로가기*/
  .foot-links {
    width: auto;
  }
  .foot-links__a {
    border-radius: 10px;
  }
  .foot-links__list {
    border-radius: 10px 10px 0 0;
    bottom: 44px;
  }

  .foot-links {

    &__list {
      width: 100%;
    }
  }
}

@media screen and (min-width: 851px) and (max-width: 1000px) {


  /*footer*/
  .copy_box4 {
    position: absolute;
    top: 39px;
    right: 10px;
  }
  .copy_box4 p {
    float: none;
  }

}

@media screen and (min-width: 1px) and (max-width: 768px) {
  #footer {
    padding-top: 10px;
  }
  .copy_box a {
    font-size: 14px;
  }
  .copy_box2 .item1 li strong {
    margin-top: 5px;
  }
  .footer_sns {
    bottom: 0px;
    top: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 470px) {
  .copy_box li {
    padding: 0;
    float: left;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .footer_sns {
    display: none;
  }

  .copy_box2 .item1 li {
    padding-left: 0 !important;
    background-image: none;
    width: 100%;
  }

  .footer_cont {
    padding: 30px 12px !important;
  }
}


.total_list{
	position: absolute;
	right: 30px;
	bottom: 20px;
  @media screen and (min-width: 1px) and (max-width: 550px) {position: relative;right: auto; bottom: auto}
  ul{
    display: flex;
    li{
      color: #888;    font-size: 1.3rem;
      & ~ li{margin-left: 20px;}
    }
  }
}