
.orgchart {
	position: relative;
	.orgchart_top_right{
		position: absolute;
		right: 20px;
		.top{font-weight: bold}
		@include respond-to(sc3) {
			position: relative;
			right: 0;
			margin: 0 auto;
			width: 112px;
		}

	}
	&_list {
		&_item {
			&_t1 {
				display: block;
				margin: 0 auto;
				position: relative;
				width: 300px;
				text-align: center;
				font-weight: 700;
				z-index: 2;
				&_in{
					border: 2px solid #f3653a;
					&_top{
						background: #f3653a;
						color: #fff;
						font-size: 18px;
						padding-top: 10px;
						padding-bottom: 10px;
						text-align: center;
					}
					&_bottom{
						color: #333;
						padding-top: 10px;
						padding-bottom: 10px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 16px;
						span{color: #666666; margin-right: 10px;}
					}
				}
				/*&:before {
					position: absolute;
					display: block;
					content: "";
					background-image: url(../../resources/images/site/orgchart_bg.png);
					width: 264px;
					height: 247px;
					top: -9999px;
					bottom: -9999px;
					left: -9999px;
					right: -9999px;
					margin: auto;
					z-index: 1;
				}*/
			}

			&_t2 {
				display: flex;
				justify-content: space-around;
				padding-top: 50px;
				position: relative;

				&:before {
					display: block;
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					width: 1px;
					background-color: #ccc;
				}

				&_cont {
					position: relative;
					width: 100%;
					box-sizing: border-box;

					&:before {
						display: block;
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						width: 50%;
						height: 1px;
						background-color: #ccc;
						margin: auto;
						z-index: 1;
					}

					&:first-child {
						a {
							border: 2px solid #308146;
							color: #308146;
						}

						&:before {
							width: 50%;
							left: 50%;
						}
					}

					&:last-child {
						a {
							border: 2px solid #0154b0;
							color: #0154b0;
						}

						&:before {
							width: 50%;
							right: 50%;
						}
					}

					a {
						display: block;
						padding: 15px;
						width: 190px;
						margin: 0 auto;
						text-align: center;
						border: 1px solid;
						position: relative;
						z-index: 1;
						font-size: 21px;
						font-width: 700;
						background: #fff;
						@include respond-to(sc4) {
							width: 120px;
							padding: 10px;
						}

					}
				}
			}

			&_t3 {
				display: flex;
				justify-content: space-around;
				position: relative;

				&:before {
					display: block;
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					width: 1px;
					background-color: #ccc;
				}

				&_cont {
					width: 100%;

					> a {
						width: 190px;
						margin: 0 auto;
						background: #f27a2a;
						display: block;
						position: relative;
						z-index: 2;
						font-size: 21px;
						font-width: 700;
						text-align: center;
						color: #fff;
						padding: 15px;
					}

					&:before {
						display: block;
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						width: 50%;
						height: 1px;
						background-color: #ccc;
						margin: auto;
						z-index: -1;
					}
				}
			}

			&_t4 {
				position: relative;
				width: 100%;
				margin-top: 80px;
				padding-top: 15px;
				position: relative;
				display: flex;
				background: #fff;
				justify-items: center;
				justify-content: center;
				@include respond-to(sc3) {
					flex-wrap: wrap;
					padding-top: 0;
				}

				&_cont {
					position: relative;
					width: 100%;
					@include respond-to(sc3) {
						margin-bottom: 10px;
					}

					&:last-child .orgchart_list_item_t4_cont_wrap:after {
						width: 0%;
					}

					&_wrap {
						width: 80%;
						margin: 0 auto;
						padding-top: 50px;
						@include respond-to(sc3) {
							padding-top: 0;
						}
						&_link{
							&_in{
								border: 2px solid #2c2c61;
								position: relative;
								&:before{
									content: '';
									position: absolute;
									top: -12px;
									left: 50%;
									transform: translateX(-50%);
									width: 17px;
									height: 17px;
									background: #2c2c61;
									border-radius: 100%;
									border: 3px solid #fff;
									box-sizing: border-box;
								}
								&_top{
									background: #2c2c61;
									color: #fff;
									font-size: 18px;
									padding-top: 10px;
									padding-bottom: 10px;
									text-align: center;
									> a:focus{
										border:2px dashed #fff;
									}
								}
								&_bottom{
									color: #333;
									padding-top: 10px;
									padding-bottom: 10px;
									display: flex;
									justify-content: center;
									align-items: center;
									font-size: 16px;
									span{color: #666666; margin-right: 10px;}
								}
							}
						}
						> a {
							display: block;
						}

						> div {
							ul {
								li {
									margin-top: 10px;
									padding: 10px;
									text-align: center;
									font-size: 14px;
									border: 1px solid #ddd;
									span{color: #666;margin-right: 5px;}
									a{
										display: block;
									}
								}
							}
						}

						&:before {
							display: block;
							content: "";
							position: absolute;
							top: -1.5rem;
							left: 1px;
							right: 0;
							width: 1px;
							height: 5.5rem;
							margin: auto;
							background-color: #ccc;
							@include respond-to(sc3) {
								display: none;
							}
						}

						&:after {
							display: block;
							content: "";
							position: absolute;
							top: -15px;
							left: 50%;
							width: 100%;
							height: 1px;
							background-color: #ccc;
							z-index: 1;
							@include respond-to(sc3) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

.organ{
	margin-top: 50px;
}

.orgchart_top{

}