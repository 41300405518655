@charset "UTF-8";
/*SCSS*/
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400|Noto+Serif+KR&display=swap");
@import url("https://fonts.googleapis.com/css?family=Do+Hyeon&display=swap");
.icon-alert-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -673px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 16px;
}

.icon-all-menu-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -471px -368px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
}

.icon-all_menu_close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -169px -668px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-all_menu_close2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -489px -668px;
  background-image: url("../images/sprite.png");
  width: 29px;
  min-width: 29px;
  height: 29px;
}

.icon-check-type {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -580px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 9px;
}

.icon-check-type2-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -513px -826px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 11px;
}

.icon-confirm {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -383px;
  background-image: url("../images/sprite.png");
  width: 49px;
  min-width: 49px;
  height: 71px;
}

.icon-copy_li_bg {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -60px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 13px;
}

.icon-copy_li_bg2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px 0px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.icon-date {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -65px -826px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 14px;
}

.icon-dn_go {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -752px;
  background-image: url("../images/sprite.png");
  width: 31px;
  min-width: 31px;
  height: 24px;
}

.icon-dn_ok {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -668px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 34px;
}

.icon-down-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -454px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.icon-down-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -517px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.icon-foot_img {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-image: url("../images/sprite.png");
  width: 421px;
  min-width: 421px;
  height: 51px;
}

.icon-footer-select-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -687px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 8px;
}

.icon-footer_logo {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -189px;
  background-image: url("../images/sprite.png");
  width: 323px;
  min-width: 323px;
  height: 38px;
}

.icon-h3-type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px 0px;
  background-image: url("../images/sprite.png");
  width: 19px;
  min-width: 19px;
  height: 19px;
}

.icon-h4-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -407px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-header-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -387px;
  background-image: url("../images/sprite.png");
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.icon-header-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -462px;
  background-image: url("../images/sprite.png");
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.icon-header-icon3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -537px;
  background-image: url("../images/sprite.png");
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.icon-header-icon4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -612px;
  background-image: url("../images/sprite.png");
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.icon-home {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -891px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 12px;
}

.icon-ico-roll-left {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -568px -668px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -646px -668px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-plus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px 0px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -78px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-start {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -312px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 25px;
}

.icon-info-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -471px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 21px;
}

.icon-intro-box1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -154px -440px;
  background-image: url("../images/sprite.png");
  width: 71px;
  min-width: 71px;
  height: 55px;
}

.icon-intro-box2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px 0px;
  background-image: url("../images/sprite.png");
  width: 51px;
  min-width: 51px;
  height: 83px;
}

.icon-intro-box3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -471px -251px;
  background-image: url("../images/sprite.png");
  width: 91px;
  min-width: 91px;
  height: 67px;
}

.icon-intro-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -299px -752px;
  background-image: url("../images/sprite.png");
  width: 29px;
  min-width: 29px;
  height: 12px;
}

.icon-intro-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -440px;
  background-image: url("../images/sprite.png");
  width: 104px;
  min-width: 104px;
  height: 60px;
}

.icon-join-icon-1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -133px;
  background-image: url("../images/sprite.png");
  width: 51px;
  min-width: 51px;
  height: 73px;
}

.icon-join-icon-2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -471px -129px;
  background-image: url("../images/sprite.png");
  width: 88px;
  min-width: 88px;
  height: 72px;
}

.icon-join-step-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -456px -752px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 1px;
}

.icon-join-step-now {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -129px -826px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-list-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -355px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.icon-list-bul1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -953px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.icon-list-bul2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -56px -953px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.icon-location-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -123px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 7px;
}

.icon-login-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -180px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.icon-main-notice {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -136px;
  background-image: url("../images/sprite.png");
  width: 10px;
  min-width: 10px;
  height: 16px;
}

.icon-main-sns-fb {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -249px -668px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-main-sns-insta {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -329px -668px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-main-youtube {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -409px -668px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-mainpage-header-bg {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -719px 0px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 109px;
}

.icon-menu_open {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -103px -550px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-menu_search {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -209px -550px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-mobile-bul-minus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -378px -752px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 2px;
}

.icon-mobile-bul-plus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -156px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-mobile-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -315px -550px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-month-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px 0px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 18px;
}

.icon-month-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -68px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 18px;
}

.icon-nav-3rd-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -814px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 4px;
}

.icon-notice {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -521px -550px;
  background-image: url("../images/sprite.png");
  width: 47px;
  min-width: 47px;
  height: 47px;
}

.icon-ol {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -63px -891px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 9px;
}

.icon-open-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -756px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.icon-open-done {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -638px -826px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
}

.icon-point-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -460px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

*:active > .icon-popup,
*:hover > .icon-popup {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -81px -752px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 23px;
}

.icon-popup-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -202px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 14px;
}

.icon-popup-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -266px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 14px;
}

.icon-popup_bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -154px -752px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 23px;
}

.icon-popup_pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -739px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 19px;
}

.icon-popup_play {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -234px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-print {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -374px -101px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.icon-print2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -373px -189px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.icon-radio-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -330px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-radio-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -392px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-radio-type-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -192px -826px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-radio-type-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -255px -826px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-reback {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -542px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 16px;
}

.icon-search-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -421px -550px;
  background-image: url("../images/sprite.png");
  width: 50px;
  min-width: 50px;
  height: 50px;
}

.icon-seat-complete {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -318px -826px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 11px;
}

.icon-seat-imposible {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -383px -826px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 11px;
}

.icon-seat-none {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -608px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 15px;
}

.icon-seat-posible {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -448px -826px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 11px;
}

.icon-select-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.icon-select_arrow {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -576px -826px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 4px;
}

.icon-side-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -700px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 6px;
}

.icon-side-list-inbox {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -566px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 2px;
}

.icon-side-list-inbox_on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -618px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 2px;
}

.icon-side-list-li-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -639px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 11px;
}

.icon-side-list-li-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -696px -826px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
}

.icon-sisuldown-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -326px -277px;
  background-image: url("../images/sprite.png");
  width: 64px;
  min-width: 64px;
  height: 70px;
}

.icon-sisuldown-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -471px 0px;
  background-image: url("../images/sprite.png");
  width: 97px;
  min-width: 97px;
  height: 79px;
}

.icon-sisuldown-icon3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -256px;
  background-image: url("../images/sprite.png");
  width: 47px;
  min-width: 47px;
  height: 77px;
}

.icon-sisuldown-icon4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -550px;
  background-image: url("../images/sprite.png");
  width: 53px;
  min-width: 53px;
  height: 68px;
}

.icon-sitemap-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -513px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-smile {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -826px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 15px;
}

.icon-step-final {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -275px -440px;
  background-image: url("../images/sprite.png");
  width: 63px;
  min-width: 63px;
  height: 46px;
}

.icon-step-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -84px -668px;
  background-image: url("../images/sprite.png");
  width: 35px;
  min-width: 35px;
  height: 33px;
}

.icon-step-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -550px;
  background-image: url("../images/sprite.png");
  width: 43px;
  min-width: 43px;
  height: 33px;
}

.icon-sub-nav_3rd_bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -670px;
  background-image: url("../images/sprite.png");
  width: 2px;
  min-width: 2px;
  height: 2px;
}

.icon-title {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -101px;
  background-image: url("../images/sprite.png");
  width: 324px;
  min-width: 324px;
  height: 38px;
}

.icon-total-count-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -240px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.icon-visual_next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -69px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_next2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -136px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -543px -368px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
}

.icon-visual_pause2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -203px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_play {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -227px -752px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
}

.icon-visual_play2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -270px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -337px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_prev2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -404px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-way-big1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -277px;
  background-image: url("../images/sprite.png");
  width: 113px;
  min-width: 113px;
  height: 113px;
}

.icon-way-big2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -163px -277px;
  background-image: url("../images/sprite.png");
  width: 113px;
  min-width: 113px;
  height: 113px;
}

.icon-way-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -300px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 5px;
}

.icon-way-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -388px -440px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.icon-way-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -476px -440px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  word-break: keep-all;
}

button,
input,
select,
textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;
}

a:focus {
  outline: 0;
  border: 1px dotted #333;
  box-sizing: border-box;
}

a:hover, a:active {
  outline: 0;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  text-decoration: underline;
}

del {
  text-decoration: line-through;
}

mark {
  background: #ff0;
  font-style: italic;
}

pre,
code,
kbd,
samp {
  font-family: monospace;
  _font-family: monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

p {
  margin: 0;
}

q {
  quotes: none;
}

q:before,
q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

dl,
dt,
dd {
  margin: 0;
}

img {
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: top;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  display: none;
  border: 0;
  *margin-left: -7px;
  padding: 0;
}

label {
  cursor: pointer;
  box-sizing: border-box;
}

label:focus {
  box-sizing: border-box;
  border: 1px dotted #333;
}

button {
  padding: 0;
  outline: 0;
  border: none;
  background: transparent;
  box-sizing: border-box;
}

button,
input,
select,
textarea {
  font-size: 100%;
  font-family: inherit;
  margin: 0;
  /*vertical-align: baseline;*/
  vertical-align: middle;
  border-radius: 0;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  box-sizing: border-box;
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

button:focus, input:focus {
  box-sizing: border-box;
  border: 1px dotted #333;
}

table button,
table input {
  *overflow: auto;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid,
textarea:invalid {
  border-color: #C82E2D;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input::-ms-clear {
  display: none !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

address,
em,
i {
  font-style: normal;
}

caption {
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
  font-size: 0;
}

/*
-----------*/
.in_length30 {
  width: 30px;
}

.in_length40 {
  width: 40px;
}

.in_length50 {
  width: 50px;
}

.in_length60 {
  width: 60px;
}

.in_length70 {
  width: 70px;
}

.in_length80 {
  width: 80px;
}

.in_length90 {
  width: 90px;
}

.in_length100 {
  width: 100px;
}

.in_length110 {
  width: 110px;
}

.in_length120 {
  width: 120px;
}

.in_length130 {
  width: 130px;
}

.in_length140 {
  width: 140px;
}

.in_length150 {
  width: 150px;
}

.in_length160 {
  width: 160px;
}

.in_length170 {
  width: 170px;
}

.in_length180 {
  width: 180px;
}

.in_length190 {
  width: 190px;
}

.in_length200 {
  width: 200px;
}

.in_length250 {
  width: 250px;
}

.in_length300 {
  width: 300px;
}

.in_length350 {
  width: 350px;
}

.in_length400 {
  width: 400px;
}

.in_length450 {
  width: 450px;
}

.in_length500 {
  width: 500px;
}

.in_length600 {
  width: 600px;
}

.in_length98pro {
  width: 98%;
}

.float_wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .float_wrap {
  display: inline-block;
}

* html .float_wrap {
  height: 1%;
}

.float-wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float-wrap {
  display: inline-block;
}

.align_right {
  text-align: right;
}

.align_left {
  text-align: left;
}

.align_center {
  text-align: center;
}

.hidden_word {
  position: absolute;
  top: -9999px;
  left: -9999px;
  text-indent: -9999px;
  opacity: 0;
}

.mgn_b5 {
  margin-bottom: 5px;
}

.mgn_b8 {
  margin-bottom: 8px;
}

.mgn_b10 {
  margin-bottom: 10px;
}

.mgn_b15 {
  margin-bottom: 15px;
}

.mgn_b18 {
  margin-bottom: 18px;
}

.mgn_b20 {
  margin-bottom: 20px;
}

.mgn_b25 {
  margin-bottom: 25px;
}

.mgn_b30 {
  margin-bottom: 30px;
}

.mgn_b40 {
  margin-bottom: 40px;
}

.mgn_b50 {
  margin-bottom: 50px;
}

.mgn_t5 {
  margin-top: 5px;
}

.mgn_t8 {
  margin-top: 8px;
}

.mgn_t10 {
  margin-top: 10px;
}

.mgn_t15 {
  margin-top: 15px;
}

.mgn_t18 {
  margin-top: 18px;
}

.mgn_t20 {
  margin-top: 20px;
}

.mgn_t25 {
  margin-top: 25px;
}

.mgn_t30 {
  margin-top: 30px;
}

.mgn_t40 {
  margin-top: 40px;
}

.mgn_t50 {
  margin-top: 50px;
}

.mgn_t70 {
  margin-top: 70px;
}

.mgn_t80 {
  margin-top: 80px;
}

.mgn_t105 {
  margin-top: 105px;
}

.mgn_l10 {
  margin-left: 10px;
}

.mgn_l15 {
  margin-left: 15px;
}

.mgn_l20 {
  margin-left: 20px;
}

.mgn_l30 {
  margin-left: 30px;
}

.mgn_r10 {
  margin-right: 10px;
}

.mgn_r20 {
  margin-right: 20px;
}

.mgn_r30 {
  margin-right: 30px;
}

.mgn_r40 {
  margin-right: 40px;
}

.left_10 {
  padding-left: 10px;
}

.left_15 {
  padding-left: 15px;
}

.left_20 {
  padding-left: 20px;
}

.left_30 {
  padding-left: 30px;
}

.left_40 {
  padding-left: 40px;
}

.left_50 {
  padding-left: 50px;
}

.left_100 {
  padding-left: 100px;
}

.left_150 {
  padding-left: 150px;
}

.right_10 {
  padding-right: 10px;
}

.right_20 {
  padding-right: 20px;
}

.right_30 {
  padding-right: 30px;
}

.right_50 {
  padding-right: 50px;
}

.right_100 {
  padding-right: 100px;
}

.right_150 {
  padding-right: 150px;
}

.col_brown {
  color: #996630;
}

.line_14 {
  line-height: 1.4;
}

.line_16 {
  line-height: 1.6;
}

.line_18 {
  line-height: 1.8;
}

.col_red {
  color: #8b1b2b;
}

.col_gold {
  color: #CD883D;
}

.col_black {
  color: #000000;
}

.col_org {
  color: #f05914;
}

.col_gray {
  color: #818283;
}

.col_black {
  color: #000;
}

.col_pul {
  color: #3c5e80;
}

.col_blue {
  color: #21539b;
}

.col_d_green {
  color: #0ba3ac;
}

.col_l_green {
  color: #5d9b21;
}

.col_gold {
  color: #999900;
}

.col_yellow {
  color: #fff585;
}

.font_bold {
  font-weight: bold;
}

.font_11 {
  font-size: 11px;
  font-size: 1.1rem;
}

.font_12 {
  font-size: 12px;
  font-size: 1.2rem;
}

.font_13 {
  font-size: 13px;
  font-size: 1.3rem;
}

.font_14 {
  font-size: 14px;
  font-size: 1.4rem;
}

.font_15 {
  font-size: 15px;
  font-size: 1.5rem;
}

.font_16 {
  font-size: 16px;
  font-size: 1.6rem;
}

.font_17 {
  font-size: 17px;
  font-size: 1.7rem;
}

.font_18 {
  font-size: 18px;
  font-size: 1.8rem;
}

.font_20 {
  font-size: 20px;
  font-size: 2.0rem;
}

.font_22 {
  font-size: 22px;
  font-size: 2.2rem;
}

.chk_box {
  height: 13px;
  margin: 0;
}

.chk_box, label {
  vertical-align: middle;
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.eot);
  src: local("NanumGothic"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot);
  src: local("NanumGothicBold"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 800;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.eot);
  src: local("NanumGothicExtraBold"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyhttp://127.0.0.1:8092/access/nanumgothic/v3/NanumGothic-ExtraBold.ttf) format("truetype");
}

/* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 100; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 300; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 400; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 500; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 700; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 900; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format("opentype"); }
*/
/*
@font-face {
  font-family: 'LotteMartDream';
  font-style: normal;
  font-weight: 400;
  src: url('//cdn.jsdelivr.net/korean-webfonts/1/corps/lottemart/LotteMartDream/LotteMartDreamMedium.woff2') format('woff2'), url('//cdn.jsdelivr.net/korean-webfonts/1/corps/lottemart/LotteMartDream/LotteMartDreamMedium.woff') format('woff');
}
@font-face {
  font-family: 'LotteMartDream';
  font-style: normal;
  font-weight: 700;
  src: url('//cdn.jsdelivr.net/korean-webfonts/1/corps/lottemart/LotteMartDream/LotteMartDreamBold.woff2') format('woff2'), url('//cdn.jsdelivr.net/korean-webfonts/1/corps/lottemart/LotteMartDream/LotteMartDreamBold.woff') format('woff');
}
@font-face {
  font-family: 'LotteMartDream';
  font-style: normal;
  font-weight: 300;
  src: url('//cdn.jsdelivr.net/korean-webfonts/1/corps/lottemart/LotteMartDream/LotteMartDreamLight.woff2') format('woff2'), url('//cdn.jsdelivr.net/korean-webfonts/1/corps/lottemart/LotteMartDream/LotteMartDreamLight.woff') format('woff');
}
.lottemartdream * {
 font-family: 'LotteMartDream', sans-serif;
}
*/
html {
  font-size: 62.5%;
}

html.websize-1 {
  font-size: 62.5%;
}

html.websize-2 {
  font-size: 68.5%;
}

html.websize-3 {
  font-size: 72.5%;
}

html.websize-4 {
  font-size: 78.5%;
}

html.websize-5 {
  font-size: 82.5%;
}

.float-wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float-wrap {
  display: inline-block;
}

.float_wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float_wrap {
  display: inline-block;
}

/**/
.size-realtime {
  display: none;
  font-size: 12px;
  padding-left: 0 !important;
}

html.websize-1 .size-realtime.s1 {
  display: inline-block;
}

html.websize-2 .size-realtime.s2 {
  display: inline-block;
}

html.websize-3 .size-realtime.s3 {
  display: inline-block;
}

html.websize-4 .size-realtime.s4 {
  display: inline-block;
}

html.websize-5 .size-realtime.s5 {
  display: inline-block;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**/
html body {
  font-size: 15px;
  font-size: 1.5rem;
}

/*사이즈 확인*/
.resol {
  position: fixed;
  bottom: 0;
  right: 0;
  border: 3px solid red;
  background-color: #fff;
  padding: 5px;
}

/*숫자클래스 삽입*/
.menu-num-1 > ul > li {
  width: 100%;
}

.menu-num-2 > ul > li {
  width: 50%;
}

.menu-num-3 > ul > li {
  width: 33.33%;
}

* + html .menu-num-3 > ul > li {
  width: 33%;
}

.menu-num-4 > ul > li {
  width: 25%;
}

.menu-num-5 > ul > li {
  width: 20%;
}

.menu-num-6 > ul > li {
  width: 16.66%;
}

* + html .menu-num-6 > ul > li {
  width: 16%;
}

.menu-num-7 > ul > li {
  width: 14%;
}

.menu-num-8 > ul > li {
  width: 12.5%;
}

.menu-num-9 > ul > li {
  width: 11.20%;
}

* + html .menu-num-9 > ul > li {
  width: 11%;
}

.menu-num-10 > ul > li {
  width: 10%;
}

.menu-num-11 > ul > li {
  width: 9.09%;
}

* + html .menu-num-11 > ul > li {
  width: 9%;
}

.menu-num-12 > ul > li {
  width: 8.3%;
}

* + html .menu-num-12 > ul > li {
  width: 8%;
}

.menu-num-13 > ul > li {
  width: 7.5%;
}

* + html .menu-num-13 > ul > li {
  width: 7%;
}

/*유동적 가로세로 비율 박스*/
#movie-player-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  /*16:9*/
  background-color: #00235d;
  color: #fff;
}

#movie-player-wrapper .mov-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#movie-player-wrapper iframe {
  width: 100%;
  height: 100%;
}

/*표 */
.test-table {
  margin-bottom: 30px;
}

.test-table table {
  border-collapse: collapse;
  width: 100%;
}

.test-table thead th {
  border-bottom: 0px;
}

.test-table th {
  border: 3px solid #fff;
  padding: 13px 0;
  background-color: #e26223;
  color: #fff;
}

.test-table td {
  border: 3px solid #fff;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff4ec;
}

.test-table tr.left-td td,
.test-table td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .test-table {
    padding-top: 10px;
  }
  .test-table colgroup {
    display: none;
  }
  .test-table thead {
    display: none;
  }
  .test-table tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #fff;
  }
  .test-table th {
    display: none;
  }
  .test-table td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #fff;
  }
  .test-table td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.test-table2 {
  margin-bottom: 30px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.test-table2 table {
  border-collapse: collapse;
  width: 100%;
}

.test-table2 th {
  border: 1px solid #dcdcdc;
  border-left: 0;
  padding: 13px 0;
  background-color: #f4f4f4;
  text-align: left;
  padding-left: 30px;
}

.test-table2 td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 13px 5px;
  text-align: left;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .test-table2 {
    padding-top: 10px;
  }
  .test-table2 colgroup {
    display: none;
  }
  .test-table2 thead {
    display: none;
  }
  .test-table2 tr {
    display: block;
  }
  .test-table2 th {
    display: none;
  }
  .test-table2 td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    color: #333;
    position: relative;
  }
  .test-table2 td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) "*";
    display: block;
    margin-bottom: 10px;
  }
}

/*select 설정 */
.select_design {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  background-color: #fff;
  background-image: url("../../resources/images/icons/select_arrow.png");
  background-position: right center;
  background-repeat: no-repeat;
  text-align: left;
  border: 1px solid #e5e5e5;
}

* + html .select_design {
  display: inline;
}

.select_design select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  vertical-align: middle;
  color: #585858;
  font-size: 11px;
  height: 25px;
  margin-top: 0;
  padding: 2px;
  position: relative;
  border: 0px;
  font-family: 'Nanum Gothic','나눔고딕';
}

/* ie등에 디자인 접목시 주석 해제*/
.select_design.in_length20 {
  width: 20px;
}

.select_design.in_length30 {
  width: 30px;
}

.select_design.in_length40 {
  width: 40px;
}

.select_design.in_length50 {
  width: 50px;
}

.select_design.in_length60 {
  width: 60px;
}

.select_design.in_length70 {
  width: 70px;
}

.select_design.in_length80 {
  width: 80px;
}

.select_design.in_length90 {
  width: 90px;
}

.select_design.in_length100 {
  width: 100px;
}

.select_design.in_length110 {
  width: 110px;
}

.select_design.in_length120 {
  width: 120px;
}

.select_design.in_length130 {
  width: 130px;
}

.select_design.in_length140 {
  width: 140px;
}

.select_design.in_length150 {
  width: 150px;
}

.select_design.in_length160 {
  width: 160px;
}

.select_design.in_length170 {
  width: 170px;
}

.select_design.in_length180 {
  width: 180px;
}

.select_design.in_length190 {
  width: 190px;
}

.select_design.in_length200 {
  width: 200px;
}

.select_design.in_length20 select {
  width: 45px;
}

.select_design.in_length30 select {
  width: 55px;
}

.select_design.in_length40 select {
  width: 65px;
}

.select_design.in_length50 select {
  width: 75px;
}

.select_design.in_length60 select {
  width: 85px;
}

.select_design.in_length70 select {
  width: 95px;
}

.select_design.in_length80 select {
  width: 105px;
}

.select_design.in_length90 select {
  width: 115px;
}

.select_design.in_length100 select {
  width: 125px;
}

.select_design.in_length110 select {
  width: 135px;
}

.select_design.in_length120 select {
  width: 145px;
}

.select_design.in_length130 select {
  width: 155px;
}

.select_design.in_length140 select {
  width: 165px;
}

.select_design.in_length150 select {
  width: 175px;
}

.select_design.in_length160 select {
  width: 185px;
}

.select_design.in_length170 select {
  width: 200px;
}

.select_design.in_length180 select {
  width: 205px;
}

.select_design.in_length190 select {
  width: 215px;
}

.select_design.in_length200 select {
  width: 225px;
}

@media print {
  #footer {
    display: none;
  }
  #cont-sbj {
    display: none;
  }
  #sidebar {
    display: none;
  }
  #header {
    display: none;
  }
  .sub-page-container {
    padding-top: 0px !important;
  }
  .sub-visual {
    display: none;
  }
  .location-box__cont {
    display: none;
  }
  .print-btn {
    display: none !important;
  }
}

/*skip navigation*/
.skiptoContent {
  position: fixed;
  left: 50%;
  top: -30px;
  height: 30px;
  line-height: 30px;
  background-color: #00235d;
  width: 360px;
  margin-left: -180px;
  text-align: center;
  transition: all 0.3s ease 0s;
  z-index: 999;
}

.skiptoContent.on {
  top: 0;
}

.skiptoContent strong {
  font-size: 0;
}

.skiptoContent a {
  color: #fff;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

.skiptoContent a:focus {
  color: gold;
}

/*header*/
.header {
  position: relative;
  z-index: 99;
  width: 100%;
  z-index: 9;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  /*헤더 위 링크들*/
  /*header search*/
  /*
	&__search {position:absolute;top:0px;left:50%;margin-left:-70px;}
	&__search__in {border:1px solid #333;background-color:#fff;}
	&__search-in_box {border:0px;padding:0;height:32px;width:150px;line-height: 32px; vertical-align: top;}
    */
}

.header__links {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.header__links__1 {
  position: absolute;
  top: 30px;
  right: 0px;
  z-index: 10;
  white-space: nowrap;
  transition: all 0.3s ease 0s;
  padding-bottom: 30px;
}

.header__links__1 > a {
  position: relative;
  margin-left: 20px;
  color: #666;
  line-height: 30px;
}

.header__links__1 > a:focus {
  border: none;
  border-radius: 4px;
}

.header__links__1__icon1 {
  top: 2px;
}

.header__links__1__icon1:before {
  content: '';
  display: block;
  width: 1px;
  height: 25px;
  background-color: #ddd;
  position: absolute;
  top: -6px;
  left: -12px;
}

.header__links__1__icon2:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
}

.header__links__1__icon3:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
}

.header__links__1__icon4:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
}

.header__links__1__icon5 {
  padding-left: 0 !important;
  margin-left: 20px;
  display: inline-block;
  height: 26px;
}

.header__links__1__icon5 img {
  vertical-align: top;
}

.header__links__1__icon5:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
}

.header__links__1 > a:hover {
  opacity: 0.8;
  color: #dd6803;
}

.header__links__1 > a:focus {
  color: #dd6803;
}

.add-search-btn .header__links__1 {
  right: 20px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__1 > a {
    font-size: 13px;
    width: auto !important;
    margin-left: 10px !important;
    color: #000;
    padding-left: 0;
  }
  .header__links__1 > a:before {
    display: none !important;
  }
  .header__links__1__icon1 {
    display: none;
  }
}

.header__wrap {
  position: relative;
  background-color: #fff;
}

.header__wrap__h1 {
  position: relative;
  z-index: 999;
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.header__wrap__h1 h1 {
  position: absolute;
  left: 20px;
  top: 23px;
}

.header__wrap__h1 h1 a {
  display: block;
  text-align: center;
  font-size: 0;
}

.header__wrap__h1 h1 a img {
  font-size: 10px;
  width: 290px;
}

.header__wrap__h1 h1 a:focus {
  border: 1px solid #cb760e;
  border-radius: 4px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .header__links {
    width: auto;
  }
  .header__wrap__h1 {
    width: auto;
    margin: 0 auto;
  }
  .header h1 a img {
    max-height: 45px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__wrap__h1 {
    width: 200px;
  }
}

.header__search {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}

.header__search__in {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #333;
  background-color: #fff;
}

.header__search-in_box {
  border: 0px;
  padding: 0;
  padding-left: 10px;
  height: 32px;
  width: 140px;
  line-height: 32px;
  vertical-align: top;
}

.header-search-btn {
  display: none;
}

.add-search-btn .header__search__in {
  right: 50%;
  margin-right: -70px;
}

.add-search-btn .header__search {
  display: none;
}

.add-search-btn .header__search.on {
  display: block;
}

.add-search-btn .header-search-btn {
  display: inline-block;
}

* + html .add-search-btn .header-search-btn {
  display: inline;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .header__search {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__search {
    display: none;
  }
  .header__search__in {
    top: 50px;
  }
  .add-search-btn .header__search__in {
    right: 0;
    margin-right: 0;
  }
  .header__search.on {
    display: block;
    width: auto;
  }
  .header-search-btn {
    display: none !important;
  }
}

/*gnb*/
.gnb {
  background-color: #212336;
  background-repeat: repeat-x;
  background-position: center top;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 7px 11px -13px rgba(0, 0, 0, 0.75);
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
  /*한개씩 떨어짐*/
}

.gnb .gnb-ul {
  display: block;
  width: calc(100% - 520px);
  padding-left: 420px;
  padding-right: 100px;
  margin: 0 auto;
}

.gnb .gnb-menu > a {
  display: block;
  text-align: center;
  color: #000;
  height: 82px;
  line-height: 82px;
  text-decoration: none;
  font-size: 24px;
  font-weight: 700;
}

.gnb .gnb-menu.over > a,
.gnb .gnb-menu.focus > a,
.gnb .gnb-menu > a:hover,
.gnb .gnb-menu > a:focus {
  color: #f04c1b;
  border: none;
}

.gnb .gnb-menu {
  position: relative;
}

.gnb .sub-nav {
  visibility: hidden;
  height: 0;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: 98;
  background-color: #fff;
  padding-top: 0;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.gnb .gnb-menu.over .sub-nav,
.gnb.selected .gnb-menu.focus .sub-nav {
  visibility: visible;
  height: auto;
  opacity: 1;
}

.gnb .sub-nav__box {
  border: 2px solid #00235d;
  border-top: 0px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s ease 0s;
}

.gnb .sub-nav__box__list > a {
  display: block;
  padding: 8px 0 8px 10px;
}

.gnb .sub-nav__box__list > a:hover,
.gnb .sub-nav__box__list > a:focus {
  text-decoration: underline;
  color: #000;
}

.gnb .gnb-menu.over .sub-nav__box,
.gnb.selected .gnb-menu.focus .sub-nav__box {
  padding-bottom: 10px;
  padding-top: 15px;
}

* + html .gnb {
  position: absolute;
  top: 0;
  padding-top: 0;
}

/* all 2nd menu down
===============================*/
.down-all .gnb {
  background-image: url("../../resources/images/basic/header-bg-ver1.png");
  background-color: #fff;
}

.down-all .gnb .sub-nav {
  display: block;
  text-align: center;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  background: #2a2933;
  display: none;
}

.down-all .gnb .sub-nav__box {
  border: 0px;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.down-all .gnb .sub-nav__box__list a {
  display: block;
  width: 100%;
  padding: 8px 0;
  color: #fff;
  font-family: "Nanum Gothic";
  font-weight: 700;
  /*
                &:hover,
				&:focus {}
				*/
}

.down-all .gnb.selected .gnb-ul .sub-nav {
  height: auto;
  visibility: visible;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0s ease 0s;
}

.down-all .gnb .gnb-menu.over .sub-nav {
  background-color: transparent;
}

.down-all .gnb .gnb-menu.over .sub-nav__box__list a {
  color: #fff;
}

.down-all .gnb .gnb-menu.over .sub-nav__box__list a:hover,
.down-all .gnb .gnb-menu.over .sub-nav__box__list a:focus {
  color: #fff;
}

.down-all .gnb .gnb-menu > a {
  background-color: #fff;
  background-repeat: repeat-x;
  background-position: left bottom;
}

.down-all .gnb .gnb-menu.over > a,
.down-all .gnb .gnb-menu.focus > a {
  background-position: left bottom;
  background-repeat: repeat-x;
}

.down-all .gnb .gnb-menu:first-child > a > span {
  background-image: none;
}

/* 2차메뉴 균일 박스 디자인 적용시 */
.down-all .gnb .gnb-ul {
  display: flex;
  justify-content: space-between;
}

.down-all .gnb .gnb-menu.over {
  background-color: #8c4437;
}

/* 2차메뉴 보더적용시 주석 해제 */
.down-all .gnb-menu:first-child {
  border-left: 0px solid #eee;
}

.down-all .gnb-menu:last-child {
  border-right: 0px solid #eee !important;
}

.down-all .gnb-menu {
  padding-right: 1px;
}

.down-all .selected .gnb-menu {
  border-right: 1px solid #eee;
  padding-right: 0;
}

.down-all .gnb-menu > a {
  display: block;
  margin-left: -1px;
  position: relative;
  text-align: center;
  width: 102%;
}

/*ie7*/
* + html .gnb {
  top: 90px;
}

* + html .down-all .gnb .gnb {
  position: absolute;
  top: 34px;
}

* + html .down-all .gnb .gnb-ul {
  display: block;
}

* + html .down-all .gnb .gnb-menu {
  display: block;
  float: left;
}

* + html .down-all .gnb .gnb-menu.over {
  background-color: #f4fcfb;
}

* + html .down-all .gnb .gnb-menu.over .sub-nav {
  background-color: #f4fcfb;
}

* + html .down-all .gnb .gnb-menu.over > a {
  background-color: #f4fcfb;
  color: #333;
}

* + html .down-all .gnb .gnb-menu:first-child {
  border-left: 0px solid #acc1bf;
}

* + html .down-all .gnb .gnb-menu {
  border-right: 0px solid #f4fcfb;
  border-left: 0px;
}

* + html .down-all .gnb .gnb-menu > a {
  width: 100%;
  margin-left: 0;
}

* + html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a {
  color: #333;
}

* + html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a:hover,
* + html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a:focus {
  color: #333;
}

* + html .down-all .gnb .selected .gnb-menu {
  border-right: 0;
}

/*모드별 디자인
	ver2
	down-all
	add-all-menu
	add-search-btn
--------------*/
/*ver2*/
.ver2 .header__wrap__h1 h1 {
  top: 20px;
  z-index: 9999;
}

.ver2 .gnb {
  top: 0;
}

.ver2 .gnb .gnb-ul {
  width: 950px;
  padding-left: 250px;
}

.ver2 .gnb .gnb-menu > a {
  height: 109px;
  line-height: 109px;
}

.ver2 .gnb .sub-nav {
  top: 109px;
}

.ver2.down-all .gnb {
  background-image: url("../../resources/images/basic/header-bg-ver2.png");
}

.ver2.down-all .gnb .sub-nav {
  top: 0;
}

/*서브페이지 투명도*/
.subpage .gnb {
  opacity: 0.95;
}

.subpage .header__wrap {
  height: 109px;
  background-color: #fff;
}

.subpage.ver-mini .header__wrap {
  height: auto;
  background-color: transparent;
}

.subpage.ver-mini .gnb {
  opacity: 1;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .subpage .gnb {
    opacity: 1;
  }
  .subpage .header__wrap {
    height: auto;
    background-color: #fff;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .ver2 .gnb .sub-nav {
    top: 60px;
  }
}

/*down-all*/
/* add-all-menu */
.add-all-menu .all_menu_wrap__outwrap {
  display: none;
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-top: 1px solid #ddd;
}

.add-all-menu .all_menu_wrap__outwrap.active {
  display: block;
}

/*ver2 + add-all-menu*/
.ver2.add-all-menu .gnb .gnb-ul {
  width: 800px;
  padding-left: 300px;
  padding-right: 400px;
}

@media screen and (min-width: 1001px) and (max-width: 1300px) {
  .header__links__1 {
    top: 18px;
  }
  .header__wrap__h1 h1 {
    width: 200px;
    top: 20px;
  }
  .gnb .gnb-ul {
    width: calc(80%);
    padding-left: 20%;
    padding-right: 10%;
    box-sizing: border-box;
  }
  .gnb .gnb-menu > a {
    height: 60px !important;
    line-height: 54px !important;
  }
  .gnb .gnb-menu > a span {
    font-size: 16px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  .header__links__1 {
    top: 18px;
  }
  .header__wrap__h1 h1 {
    width: 180px;
    top: 20px;
  }
  .gnb .gnb-ul {
    width: calc(90%);
    padding-left: 20%;
    padding-right: 10%;
    box-sizing: border-box;
  }
  .gnb .gnb-menu > a {
    height: 60px !important;
    line-height: 54px !important;
  }
  .gnb .gnb-menu > a span {
    font-size: 14px;
  }
}

/*메인페이지용 gnb*/
.mainpage .header {
  background-color: #fff;
}

.mainpage .gnb {
  transition: all 0.0s ease 0.0s;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: -2px 0;
}

.mainpage .gnb .gnb-menu {
  transition: all 0s ease 0s;
}

.mainpage .gnb .gnb-menu > a {
  background-color: #fff;
  border: none !important;
}

.mainpage .gnb .gnb-menu.focus > a {
  text-decoration: underline;
}

.mainpage .down-all .gnb.selected {
  transition: all 0s ease 0s;
}

.mainpage .down-all .gnb.selected .gnb-menu {
  border-right: 1px solid #ddd;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  .mainpage .header__links__1 {
    right: 20px;
  }
}

/*서브페이지용 gnb*/
.subpage .header {
  background-color: #fff;
}

.subpage .header__wrap__h1 h1 {
  top: 25px;
}

.subpage .gnb {
  transition: all 0.0s ease 0.0s;
  box-shadow: 0px 0px 0px 0px #000 !important;
  background-image: url(../../resources/images/basic/header-bg-ver1.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: -2px 0;
}

.subpage .gnb .gnb-menu {
  transition: all 0s ease 0s;
}

.subpage .gnb .gnb-menu > a {
  background-color: #fff;
}

.subpage .gnb .gnb-menu.focus > a {
  text-decoration: underline;
}

.subpage .gnb .gnb-menu.on > a span {
  color: #cb760e;
}

.subpage .down-all .gnb.selected {
  transition: all 0s ease 0s;
  box-shadow: 0px 7px 11px -13px #000 !important;
  background-color: #2a2933;
  background-repeat: repeat-x;
}

.subpage .down-all .gnb.selected .gnb-menu {
  border-right: 1px solid #ddd;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .subpage .header__links__1 {
    top: 37px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .subpage .header__links__1 {
    top: -41px;
  }
}

.header__sns {
  background-color: #f6f6f6;
  border-bottom: 1px solid #ddd;
}

.header__sns__wrap {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.header__sns__wrap ul {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header__sns__wrap ul li {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 12px;
}

.header__sns__wrap ul li a {
  font-size: 0;
}

.header__sns__wrap ul li a::before {
  content: '';
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 30px 30px;
  display: block;
  width: 30px;
  height: 30px;
}

.header__sns__wrap ul li.sns_naverband a::before {
  content: '';
  background-image: url(../../resources/images/site/ico_sns_naverband.png);
}

.header__sns__wrap ul li.sns_instagram a::before {
  content: '';
  background-image: url(../../resources/images/site/ico_sns_instagram.png);
}

.header__sns__wrap ul li.sns_facebook a::before {
  content: '';
  background-image: url(../../resources/images/site/ico_sns_facebook.png);
}

@media (max-width: 1000px) {
  .header__sns {
    display: none;
  }
}

/*상단배너*/
.top-banner {
  min-height: 30px;
}

.top-banner__in {
  min-height: 30px;
  position: relative;
  width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 770px) {
  .top-banner__in {
    width: 100%;
  }
}

.top-banner__in > div:nth-child(1) {
  min-height: 30px;
}

.top-banner__in__link {
  display: block;
  text-align: center;
  margin: 0 auto;
  max-width: 1500px;
  color: #fff;
  font-size: 17px;
}

.top-banner__in__link__tit {
  color: #f2cb8e;
  font-weight: normal;
  padding-right: 10px;
}

.top-banner__in img {
  max-width: 100%;
}

.top-banner__in p {
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  overflow: hidden;
  width: 0;
  height: 0;
}

.top-banner__in .top-banner-close {
  background-color: #2b3353;
  border-radius: 5px;
  padding: 2px 5px;
  position: absolute;
  bottom: 14px;
  right: 20px;
  text-align: right;
}

.top-banner__in .top-banner-close a,
.top-banner__in .top-banner-close label {
  vertical-align: middle;
  color: #fff;
}

body .top-banner {
  display: block;
  position: relative;
  z-index: 9;
}

body.pop-closed .top-banner {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  body .top-banner {
    display: none;
  }
}

/*모바일메뉴*/
.mobile_top_nav {
  display: none;
}

#gnb_nav_mobile {
  height: auto;
  display: none;
}

/*모바일 메뉴 닫기*/
.mobile-close {
  position: fixed;
  left: -30px;
  top: 10px;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.mobile_on_off a {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mobile_on_off a {
    display: block;
  }
}

.mobile_top_nav.selected .mobile-close {
  opacity: 1;
  left: 285px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header {
    overflow: visible;
    position: fixed;
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding-bottom: 0px;
    height: 50px !important;
    background-color: #fff;
    background-image: none;
  }
  .header.selected {
    height: 100%;
  }
  .header__wrap__h1 h1 {
    margin-left: 0;
    left: 0;
    position: relative;
    top: 14px !important;
    width: 100%;
    text-align: center;
    padding-top: 0px;
  }
  .header__wrap__h1 h1 a {
    /*
						@include sprite($title,1.8);

						margin: 0 auto;

						img {
							display: none;
						}*/
  }
  .header__wrap__h1 h1 a img {
    max-height: 34px;
  }
  .ver2 .header__wrap__h1 h1 {
    top: 9px !important;
  }
  .header__wrap .gnb {
    display: none;
  }
  .mainpage .header {
    background-color: #fff;
  }
  [class*="header__wrap"] {
    height: 50px;
  }
  /*모바일 메뉴*/
  .mobile_top_nav {
    display: block;
    z-index: 100000;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    background-image: url("../../resources/images/basic/opa.png");
    background-repeat: repeat;
    background-position: 0 0;
  }
  .mobile_top_nav .m_s_btn {
    position: absolute;
    top: 8px;
    right: 15px;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 0;
    text-indent: -9999px;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -122.94118px -323.52941px;
    background-image: url("../images/sprite.png");
    background-size: 578.82353px auto;
    width: 32.94118px;
    min-width: 32.94118px;
    height: 32.94118px;
  }
  .mobile_top_nav .m_open_btn {
    position: absolute;
    top: 14px;
    left: 15px;
    display: block;
    width: 26px;
    height: 21px;
    font-size: 0;
    text-indent: -9999px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url("../../resources/images/basic/ico_list_mob.png");
    background-size: 26px 21px;
    width: 26px;
    height: 21px;
  }
  .mobile_top_nav__in {
    left: -320px;
    width: 320px;
    position: fixed;
    top: 0;
    height: 100%;
    background-color: #fff;
    transition: all 0.3s ease 0s;
    overflow: auto;
  }
  .mobile_top_nav.selected {
    height: 100%;
    overflow: auto;
    width: 100%;
    left: 0px;
  }
  .mobile_top_nav.selected .m_s_btn {
    display: none;
  }
  .mobile_top_nav.selected .mobile_top_nav__in {
    left: 0;
    box-shadow: 3px 4px 18px -7px rgba(0, 0, 0, 0.75);
  }
  #gnb_nav_mobile {
    opacity: 0;
    position: relative;
    padding-top: 0px;
    z-index: 9999;
    background-color: #fff;
    border-top: 1px solid #d1d1d6;
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu {
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu.over {
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu:first-child {
    border-left: 0px;
  }
  #gnb_nav_mobile li.gnb-menu > a {
    width: auto;
    display: block;
    text-shadow: none;
    padding: 0px 10px 0px 20px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 700;
    color: #111;
    text-align: left;
    border-top: 1px solid #d1d1d6;
  }
  #gnb_nav_mobile li.gnb-menu > a > span {
    background-image: none;
  }
  #gnb_nav_mobile .sub-nav__box__ul {
    background-color: #fbf9f8;
    padding: 20px 0;
  }
  #gnb_nav_mobile .sub-nav__box__list a {
    position: relative;
    padding: 0px 10px 0px 25px;
    width: auto;
    height: 38px;
    font-size: 14px;
    line-height: 38px;
    font-weight: bold;
    display: block;
    color: #333;
    text-shadow: 0px 0px 0px;
  }
  #gnb_nav_mobile .sub-nav__box__list a:after {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(../../resources/images/site/arr_menu_mob_sub.png);
    background-size: 100% 100%;
    width: 8px;
    height: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% + 0.5px));
    right: 20px;
  }
  /*불켜짐*/
  #gnb_nav_mobile .gnb-menu > a {
    position: relative;
  }
  #gnb_nav_mobile .gnb-menu > a:after {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(../../resources/images/site/arr_menu_mob_gray.png);
    background-size: 100% 100%;
    width: 24px;
    height: 14px;
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% + 0.5px));
    right: 14px;
    transition: .3s ease-in;
  }
  #gnb_nav_mobile .gnb-menu.selected > a,
  #gnb_nav_mobile .gnb-menu.on > a {
    background-color: #f15922;
    color: #fff;
  }
  #gnb_nav_mobile .gnb-menu.selected > a:after,
  #gnb_nav_mobile .gnb-menu.on > a:after {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(../../resources/images/site/arr_menu_mob_white.png);
    background-size: 100% 100%;
    width: 24px;
    height: 14px;
    position: absolute;
    top: 50%;
    transform: rotate(180deg) translateY(calc(50% - 0.5px));
    right: 14px;
  }
  #gnb_nav_mobile .gnb-menu.no-has-menu:after {
    display: none;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a {
    color: #333;
    text-decoration: underline;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a span {
    color: #333;
    text-decoration: underline;
  }
  /*3rd*/
  #gnb_nav_mobile .sub-nav__3rd {
    position: relative;
  }
  #gnb_nav_mobile .sub-nav__3rd a {
    color: #666;
    border-bottom: 0px;
    font-size: 13px;
    font-weight: normal;
    width: auto;
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 35px;
  }
  #gnb_nav_mobile .sub-nav__3rd a:after {
    content: '';
    display: inline-block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -979px -670px;
    background-image: url("../images/sprite.png");
    width: 2px;
    min-width: 2px;
    height: 2px;
    position: absolute;
    top: 20px;
    left: 25px;
  }
  #gnb_nav_mobile .sub-nav__3rd li:first-child {
    border-top: 0px;
  }
  /*#gnb_nav_mobile .gnb-menu.nav-item.on .sub-nav{height:auto;		visibility: visible;}*/
  #gnb_nav_mobile .gnb-menu.nav-item.on .sub-nav {
    height: auto;
    visibility: visible;
    display: block;
  }
  /*sldieup*/
  #gnb_nav_mobile .gnb-menu.nav-item .sub-nav {
    display: none;
    height: auto;
    visibility: visible;
  }
  /*sldieup*/
  #gnb_nav_mobile .gnb-menu.nav-item.on > a {
    color: #087f97;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a span {
    color: #087f97;
  }
  #gnb_nav_mobile .sub-nav__3rd .on a {
    color: #000;
    font-weight: bold;
  }
  #gnb_nav_mobile .sub-nav__3rd a.on {
    color: #000;
    font-weight: bold;
  }
  /*모바일 메뉴 닫기*/
  .mobile-close {
    position: fixed;
    left: -30px;
    top: 9px;
    opacity: 0;
    transition: all 0.3s ease 0s;
  }
  .mobile_menu_open .mobile-close {
    left: 275px;
    z-index: 10000;
    opacity: 1;
  }
  .mobile-close a {
    font-size: 0;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
    display: block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -157.5px -275px;
    background-image: url("../images/sprite.png");
    background-size: 492px auto;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
  .mobile_top_nav.selected .mobile-close {
    opacity: 1;
    left: 285px;
    position: relative;
    left: inherit;
    top: inherit;
  }
  /*gnb 방식변경*/
  .gnb-ul {
    width: 100%;
    display: block;
    padding-right: 0;
  }
  .gnb-menu {
    width: 100%;
    display: block;
    border-right: 0px;
  }
  .gnb-menu > a {
    text-align: left;
    line-height: 30px;
    height: 30px;
    padding-left: 15px;
  }
  .sub-nav {
    width: auto;
    border: 0;
    background-color: #f7f7f7;
    text-align: left;
  }
  .sub-nav__box__list a span {
    color: #666;
    background-image: none !important;
    font-size: 20px;
  }
  #gnb_nav_mobile li.gnb-menu.selected .sub-nav {
    height: auto;
    visibility: visible;
  }
  #gnb_nav_mobile.selected {
    display: block;
    opacity: 1;
  }
  .gnb.selected {
    display: block;
    opacity: 1;
  }
  .header__links {
    z-index: 99999;
  }
  .header__links__1 {
    top: 12px;
    transition: all 0.3s ease 0s;
  }
  #gnb_nav_mobile .gnb_nav_mobile_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 10px;
  }
  #gnb_nav_mobile .gnb_nav_mobile_head h1 a {
    display: block;
    font-size: 0;
  }
  #gnb_nav_mobile .gnb_nav_mobile_head .header__sns__wrap {
    padding: 0 10px;
  }
  #gnb_nav_mobile .gnb_nav_mobile_head .header__sns__wrap ul {
    height: auto;
  }
  #gnb_nav_mobile .gnb_nav_mobile_head .header__sns__wrap ul li {
    margin: 0 5px;
  }
}

/*전체메뉴보기*/
.all_menu_wrap__outwrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.all_menu_wrap__outwrap.selected {
  width: 100%;
  z-index: 10001;
  position: fixed;
  top: 0;
  height: 100%;
  background-image: url("../images/basic/opa2.png");
  background-position: 0 0;
  background-repeat: repeat;
}

.btn_all {
  display: block;
  border: 0px solid #ddd;
  width: 49px;
  height: 49px;
  margin: 1px;
  transition: all 0.3s ease 0s;
}

.mainpage .btn_all {
  top: 50px;
}

.btn_all span {
  display: block;
  font-size: 0;
  margin: 0 auto;
  margin-top: 15px;
}

.btn_all:hover {
  opacity: 0.8;
}

.btn_all:focus {
  opacity: 0.8;
  border: 1px solid #fff;
  margin: 0px;
}

.all_menu_wrap {
  position: relative;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
  /*
	height:0;
	overflow:hidden;
	visibility: hidden;*/
  transition: all 0.7s ease 0s;
  top: -1px;
  border-top: 1px solid #ddd;
}

.all_menu_wrap__h2 {
  height: 110px;
  color: #fff;
  text-align: center;
  font-size: 35px;
  padding-top: 60px;
  font-weight: 800;
  position: relative;
}

.all_menu_wrap__h2:before {
  position: absolute;
  top: 20px;
  left: 20px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -101px;
  background-image: url("../images/sprite.png");
  width: 324px;
  min-width: 324px;
  height: 38px;
}

.selected .all_menu_wrap {
  display: block;
  height: auto;
  opacity: 1;
  visibility: visible;
  display: block;
}

.all_menu_in {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.all_menu_in .gnb-menu > a > span {
  color: #fff;
  font-size: 35px;
  white-space: nowrap;
}

.selected .all_menu_in .gnb-menu {
  border-right: 0;
}

.all_menu_in .gnb-menu > a {
  margin-left: 0;
  width: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.all_menu_in .gnb-menu > a:focus span {
  color: gold;
}

.all_menu_in .all_menu_close {
  color: #333;
  display: block;
  font-size: 0;
  position: absolute;
  right: 40px;
  top: -85px;
  z-index: 9999;
  width: 30px;
  height: 30px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -489px -668px;
  background-image: url("../images/sprite.png");
  width: 29px;
  min-width: 29px;
  height: 29px;
}

.all_menu_in .gnb-menu {
  display: flex;
  width: calc(100% - 40px);
  position: absolute;
}

.all_menu_in .gnb-menu.on {
  z-index: 1;
}

.all_menu_in .gnb-menu:before {
  content: '';
  display: block;
  background-color: #fbf9f8;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(-50% + 20px);
}

.all_menu_in .gnb-menu > a {
  text-align: center;
  font-size: 15px;
  color: #323551;
  font-weight: bold;
  margin-bottom: 15px;
}

.all_menu_in .sub-head {
  position: relative;
  width: 290px;
  min-height: 430px;
  padding-top: 53px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fbf9f8;
}

.all_menu_in .sub-head:nth-child(1):before {
  content: '';
  display: block;
  width: 314px;
  height: 422px;
  background-image: url(../../resources/images/site/menu_img_01_pc.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: -135px;
  right: -50px;
}

.all_menu_in .sub-head__tit {
  font-size: 30px;
  font-weight: 800;
  color: #111;
  padding-bottom: 20px;
}

.all_menu_in .sub-head__txt {
  font-size: 14px;
  color: #666;
}

.all_menu_in .sub-nav {
  width: calc(100% - 290px);
  height: auto;
  visibility: visible;
  background-color: #fff;
  padding-top: 53px;
}

.all_menu_in .sub-nav__ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 23px;
}

.all_menu_in .sub-nav__ul .sub-nav__box__list {
  width: 25%;
  padding-bottom: 20px;
  padding-left: 17px;
  box-sizing: border-box;
}

.all_menu_in .sub-nav__ul .sub-nav__box__list ul li {
  position: relative;
  padding-left: 30px;
}

.all_menu_in .sub-nav__ul .sub-nav__box__list ul li a {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.all_menu_in .sub-nav__ul .sub-nav__box__list ul li a:before {
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  background-color: #acacb2;
  position: absolute;
  top: 9px;
  left: -10px;
}

.all_menu_in .sub-nav__box__list > a {
  display: block;
  height: 58px;
  line-height: 58px;
  border: 1px solid #e5dadc;
  border-radius: 5px;
  padding: 0 20px 6px 20px;
  color: #111;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 18px;
}

.all_menu_in .sub-nav__box__list > a:hover {
  background-image: url(../../resources/images/basic/bg_depth_02.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.all_menu_in .sub-nav__box__list > a:hover {
  color: #fff;
}

.all_menu_in .gnb-ul {
  width: 100%;
  height: 430px;
}

.all_menu_in .gnb-menu:first-child {
  border-left: 0px;
}

.all_menu_in .sub-nav__3rd {
  text-align: left;
  padding-left: 20px;
  padding-top: 0px;
  padding-bottom: 10px;
}

.all_menu_in .sub-nav__3rd a {
  display: block;
  margin-bottom: 12px;
}

@media (max-width: 1200px) {
  .all_menu_in .sub-nav__ul .sub-nav__box__list {
    padding-left: 10px;
  }
  .all_menu_in .sub-nav__ul .sub-nav__box__list ul li {
    position: relative;
    padding-left: 20px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .all_menu_in .sub-nav__ul .sub-nav__box__list {
    padding-left: 10px;
  }
  .all_menu_in .sub-nav__ul .sub-nav__box__list ul li {
    position: relative;
    padding-left: 20px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  .all_menu_in .sub-nav {
    width: calc(100% - 200px);
  }
  .all_menu_in .sub-nav__ul {
    padding-left: 10px;
  }
  .all_menu_in .sub-nav__ul .sub-nav__box__list {
    padding-left: 10px;
  }
  .all_menu_in .sub-nav__ul .sub-nav__box__list > a {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 10px 6px 10px;
    margin-bottom: 10px;
  }
  .all_menu_in .sub-nav__ul .sub-nav__box__list ul li {
    position: relative;
    padding-left: 20px;
  }
  .all_menu_in .sub-nav__ul .sub-nav__box__list ul li a {
    font-size: 12px;
  }
  .all_menu_in .sub-head {
    width: 200px;
  }
  .all_menu_in .sub-head:nth-child(1)::before {
    background-size: 70% 70% !important;
    bottom: -200px !important;
    right: -130px;
  }
  .all_menu_in .sub-head .sub-head__tit {
    font-size: 24px;
  }
  .all_menu_in .sub-head .sub-head__txt {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .all_menu_wrap__outwrap {
    display: none !important;
  }
}

.btn_all .type1 {
  display: block;
}

.btn_all .type2 {
  display: none;
}

.btn_all.selected .type1 {
  display: none;
}

.btn_all.selected .type2 {
  display: block;
}

.main-search {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999999;
}

.main-search * {
  box-sizing: border-box;
}

.main-search .kit_search_search-in_box {
  width: 85%;
}

.main-search-container {
  background-color: rgba(198, 69, 22, 0.87);
  padding-top: 90px;
  padding-bottom: 42px;
}

.main-search-container-wrap {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-search-container-wrap h3 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -1px;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  color: #fff;
  padding-bottom: 30px;
}

.main-search-container-wrap-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66%;
  white-space: nowrap;
}

@media screen and (min-width: 1px) and (max-width: 450px) {
  .main-search-container-wrap-box {
    width: 90%;
  }
}

.main-search-container-wrap-box form {
  width: 100%;
}

.main-search input[type="search"] {
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  border: 3px solid #f15922;
  background: #fff;
  color: #999;
  font-size: 20px;
  font-weight: 600;
  border-radius: 31px;
  padding: 21px 10px 21px 40px;
}

.main-search input[type="search"]::placeholder {
  color: #999;
}

.main-search input[type="search"]:focus {
  outline: none;
}

.main-search input[type="search"]:active {
  outline: none;
}

.main-search input[type="image"] {
  position: absolute;
  top: 50%;
  right: 60px;
  -webkit-transform: translateY(calc(-50% + 0.5px));
  -ms-transform: translateY(calc(-50% + 0.5px));
  transform: translateY(calc(-50% + 0.5px));
  font-size: 0;
}

.main-search input[type="image"]:focus {
  border: 2px solid #fff;
  border-radius: 4px;
}

.main-search button {
  background-color: transparent;
  border: none;
}

.main-search .sc_close_bt {
  position: absolute;
  top: -20px;
  right: 75px;
  display: block;
  cursor: pointer;
  color: #fff;
  font-size: 0;
}

.main-search .sc_close_bt:focus {
  border: 1px solid;
  outline: 3px tan;
}

.main-search .sc_close_bt .icon-search-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("../../resources/images/site/ico_search_close.png");
  width: 42px;
  height: 42px;
}

.main-search.on {
  display: block;
}

@media (max-width: 1000px) {
  .main-search .kit_search_search-in_box {
    width: 90%;
  }
  .main-search-container {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  .main-search-container-wrap h3 {
    font-size: 40px;
    padding-bottom: 30px;
  }
  .main-search input[type="search"] {
    border: 2px solid #f15922;
    font-size: 18px;
    padding: 15px 10px 15px 30px;
  }
  .main-search input[type="image"] {
    right: 0px;
    width: 50px;
  }
}

@media (max-width: 768px) {
  .main-search .kit_search_search-in_box {
    width: 85%;
  }
  .main-search-container {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .main-search-container-wrap h3 {
    font-size: 30px;
    padding-bottom: 20px;
  }
  .main-search input[type="search"] {
    border: 2px solid #f15922;
    font-size: 16px;
    padding: 10px 10px 10px 30px;
  }
  .main-search input[type="image"] {
    width: 50px;
  }
  .main-search .sc_close_bt {
    top: -20px;
    right: 40px;
  }
  .main-search .sc_close_bt .icon-search-close {
    width: 30px;
    height: 30px;
    background-size: 100% 100%;
  }
}

@media (max-width: 450px) {
  .main-search .kit_search_search-in_box {
    width: 85%;
  }
  .main-search-container {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .main-search-container-wrap h3 {
    font-size: 24px;
  }
  .main-search input[type="search"] {
    font-size: 14px;
    padding: 10px 10px 10px 20px;
  }
  .main-search input[type="image"] {
    width: 40px;
  }
}

/*	메인페이지
===========================*/
@font-face {
  font-family: 'yangjin';
  src: url("https://fastly.jsdelivr.net/gh/supernovice-lab/font@0.9/yangjin.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.inner {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .inner {
    padding: 0 12px;
  }
}

.main-section {
  position: relative;
  background-color: #fff;
  z-index: 8;
}

.main-section__in {
  position: relative;
  min-height: 500px;
  width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .main-section__in {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-section {
    padding-top: 51px;
  }
}

.aos-item {
  height: 150px;
  margin-bottom: 40px;
  border: 1px solid #000;
}

.main-visual .sub-slider-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -202px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 14px;
  font-size: 0;
}

.main-visual .sub-slider-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -266px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 14px;
  font-size: 0;
}

.kit {
  padding-top: 50px;
}

.kit_tag {
  width: 400px;
  margin: 0 auto;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
}

@media only screen and (max-width: 550px) {
  .kit_tag {
    width: 90%;
    margin: 0 auto;
  }
}

.kit_tag_list {
  overflow: hidden;
}

.kit_tag_list:after {
  content: '';
  clear: both;
}

.kit_tag_list_li {
  float: left;
  width: 20%;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.kit_tag_list_li a {
  font-size: 24px;
  display: block;
  text-align: center;
  color: #fff;
}

@media only screen and (max-width: 550px) {
  .kit_tag_list_li a {
    font-size: 18px;
  }
}

.kit_tag_list_li a:hover, .kit_tag_list_li a:focus {
  text-decoration: underline;
}

.kit_search {
  width: 600px;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
}

@media only screen and (max-width: 770px) {
  .kit_search {
    width: 90%;
    margin: 0 auto;
  }
}

.kit_search .kit_search_search-in_box {
  border: 0;
  width: 100%;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  color: #dd6803;
  caret-color: #dd6803;
  font-size: 18px;
}

.kit_search .kit_search_search-in_box::placeholder {
  color: #dd6803;
  padding-left: 10px;
}

.kit_search .kit_search_search-in_box_bt {
  position: absolute;
  z-index: 9;
  top: 9px;
  right: 10px;
  padding-left: 10px;
  border-left: 1px solid #ccc;
}

.main-link {
  position: relative;
}

@media (max-width: 768px) {
  .main-link .inner {
    background-color: #f15922;
    padding: 10px 12px;
  }
}

.main-link_wrap {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .main-link {
    width: 100%;
  }
}

@media only screen and (max-width: 910px) {
  .main-link {
    padding-top: 0;
  }
}

.main-link_quick {
  width: 100%;
  overflow: hidden;
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
}

.main-link_quick ul li {
  float: left;
  width: 25%;
}

@media only screen and (max-width: 770px) {
  .main-link_quick ul li {
    width: 50%;
    margin-bottom: 40px;
  }
}

.main-link_quick ul li a {
  display: block;
  text-align: center;
  transform: scale(0.9);
  transition: all 0.2s ease 0s;
}

.main-link_quick ul li a img {
  vertical-align: middle;
}

.main-link_quick ul li a span {
  display: block;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
}

.main-link_quick ul li a:hover, .main-link_quick ul li a:focus {
  transform: scale(1);
  transition: all 0.2s ease 0s;
}

.main-link_button {
  width: 100%;
  position: absolute;
  top: calc(-180px - 75px);
}

.main-link_button ul {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.main-link_button ul li {
  display: block;
  width: 13%;
  height: 180px;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
}

@media only screen and (max-width: 770px) {
  .main-link_button ul li {
    width: 33.33333%;
  }
}

@media only screen and (max-width: 550px) {
  .main-link_button ul li {
    width: 50%;
  }
}

.main-link_button ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding: 0 5px;
}

@media only screen and (max-width: 550px) {
  .main-link_button ul li a {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.main-link_button ul li a:hover, .main-link_button ul li a:focus {
  background: linear-gradient(-140deg, rgba(209, 24, 32, 0.7) 0%, rgba(255, 124, 0, 0.7) 100%);
  border: none;
}

.main-link_button ul li a span {
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding-top: 20px;
}

@media (max-width: 1300px) {
  .main-link_button ul li {
    width: 14%;
    height: 140px;
  }
  .main-link_button ul li a img {
    max-width: 50%;
  }
  .main-link_button ul li a span {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 1000px) {
  .main-link_button ul li {
    width: 15%;
    height: 140px;
  }
  .main-link_button ul li a img {
    height: 40px;
  }
  .main-link_button ul li a span {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 768px) {
  .main-link_button {
    position: relative;
    top: inherit;
  }
  .main-link_button ul {
    flex-wrap: wrap;
  }
  .main-link_button ul li {
    width: 30%;
    height: 120px;
    margin: 5px 0;
  }
  .main-link_button ul li a {
    background-color: #dc4813;
  }
  .main-link_button ul li a span {
    font-size: 16px;
    padding-top: 10px;
    line-height: 1.2;
  }
}

@media (max-width: 500px) {
  .main-link_button ul li {
    height: 100px;
  }
  .main-link_button ul li a {
    background-color: #dc4813;
  }
  .main-link_button ul li a img {
    height: 30px;
  }
  .main-link_button ul li a span {
    font-size: 14px;
  }
}

.news {
  padding-top: 80px;
  padding-bottom: 70px;
}

@media (max-width: 768px) {
  .news {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.news .news_wrap {
  display: flex;
  justify-content: space-between;
}

.news .news_wrap .news_cont {
  width: calc(100% - 324px);
  padding-right: 60px;
  box-sizing: border-box;
}

.news .news_wrap .news_cont .news-tab .news_cate_box {
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: space-between;
}

.news .news_wrap .news_cont .news-tab .news_cate_box.on {
  z-index: 101;
}

.news .news_wrap .news_cont .news-tab__h3 {
  position: relative;
  font-size: 35px;
  color: #111;
  font-family: "yangjin", "Noto Sans KR", sans-serif;
  z-index: 1;
}

.news .news_wrap .news_cont .news-tab__h3::before {
  content: '';
  display: block;
  width: 46px;
  height: 46px;
  background-color: #faee00;
  border-radius: 50%;
  position: absolute;
  top: -18px;
  left: -20px;
  z-index: -1;
}

.news .news_wrap .news_cont .news-tab__cont {
  z-index: 100;
}

.news .news_wrap .news_cont .news-tab__label {
  display: none;
}

.news .news_wrap .news_cont .news-tab__ul {
  position: relative;
  display: flex;
  padding-right: 70px;
}

.news .news_wrap .news_cont .news-tab__ul::before {
  content: '';
  display: block;
  width: 200px;
  height: 1px;
  background-color: #ddd;
  position: absolute;
  top: 16px;
  left: -200px;
}

@media (max-width: 1500px) {
  .news .news_wrap .news_cont .news-tab__ul::before {
    display: none;
  }
}

.news .news_wrap .news_cont .news-tab__ul__li {
  padding-left: 28px;
}

.news .news_wrap .news_cont .news-tab__ul__li a {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -2px;
  color: #555;
}

.news .news_wrap .news_cont .news-tab__ul__li a:focus {
  border: none;
}

.news .news_wrap .news_cont .news-tab__ul__li a::before {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  background-color: #c7c7c7;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 2px);
  left: -10px;
}

.news .news_wrap .news_cont .news-tab__ul__li.on a, .news .news_wrap .news_cont .news-tab__ul__li :hover a {
  font-weight: 900;
  color: #f04c1b;
}

.news .news_wrap .news_cont .news-tab__ul__li.on a:focus, .news .news_wrap .news_cont .news-tab__ul__li :hover a:focus {
  border: none;
}

.news .news_wrap .news_cont .news-tab__ul__li.on a::before, .news .news_wrap .news_cont .news-tab__ul__li :hover a::before {
  background-color: #f04c1b;
}

@media (max-width: 768px) {
  .news .news_wrap .news_cont .news-tab__h3 {
    font-size: 28px;
    max-width: 200px;
  }
  .news .news_wrap .news_cont .news-tab__h3::before {
    width: 30px;
    height: 30px;
    top: -10px;
    left: -10px;
  }
}

.news .news_wrap .news_cont .news-tab__cont__more {
  position: absolute;
  top: -30px;
  right: 0;
}

.news .news_wrap .news_cont .news-tab__cont__more:focus {
  border: none;
}

.news .news_wrap .news_cont .news-tab__cont__more::before {
  content: '';
  display: block;
  background-image: url(../../resources/images/site/ico_news_more.png);
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
  width: 19px;
  height: 19px;
  position: absolute;
  top: -8px;
  right: 6px;
}

.news .news_wrap .news_cont .news-tab__cont__ul {
  display: flex;
  justify-content: space-between;
  padding-top: 38px;
}

.news .news_wrap .news_cont .news-tab__cont__ul::after {
  display: none;
}

.news .news_wrap .news_cont .news-tab__cont__ul__li {
  width: 27%;
}

.news .news_wrap .news_cont .news-tab__cont__ul__li a .news-category {
  display: inline-block;
  background-color: #f3ede6;
  border-radius: 14px;
  padding: 0 16px;
  margin-bottom: 20px;
}

.news .news_wrap .news_cont .news-tab__cont__ul__li a .news-category span {
  font-size: 16px;
  font-weight: 700;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  line-height: 29px;
  color: #f04c1b;
}

.news .news_wrap .news_cont .news-tab__cont__ul__li a .news-tit {
  font-size: 25px;
  font-weight: 600;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  line-height: 1.2;
  letter-spacing: -1px;
  color: #111;
  margin-bottom: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
}

.news .news_wrap .news_cont .news-tab__cont__ul__li a .news-txt {
  font-size: 18px;
  font-weight: 400;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  line-height: 1.2;
  letter-spacing: -1px;
  color: #444;
  margin-bottom: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
}

.news .news_wrap .news_cont .news-tab__cont__ul__li a .news-date {
  position: relative;
  font-size: 17px;
  font-weight: 600;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  color: #777;
  padding-left: 26px;
}

.news .news_wrap .news_cont .news-tab__cont__ul__li a .news-date::before {
  content: '';
  display: block;
  background-image: url(../../resources/images/site/ico_date.png);
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 0;
}

@media (max-width: 1300px) {
  .news .news_wrap {
    flex-direction: column;
  }
  .news .news_wrap .news_cont {
    width: 100%;
    padding-right: 0;
  }
  .news .news_wrap .side_link {
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
  }
  .news .news_wrap .side_link .right_bg_item_ul_li {
    width: 24%;
  }
}

@media (max-width: 1000px) {
  .news .news_wrap .news_cont .news-tab__ul__li a {
    font-size: 18px;
  }
  .news .news_wrap .news_cont .news-tab__cont__more {
    top: -34px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-category span {
    font-size: 14px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-tit {
    font-size: 20px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-txt {
    font-size: 16px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-date {
    font-size: 14px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-date::before {
    top: 2px;
  }
  .news .news_wrap .side_link .right_bg_item_ul_li {
    width: 24%;
  }
}

.news .news-tab__select_mob {
  display: none;
}

@media (max-width: 768px) {
  .news .news_wrap .news_cont .news-tab .news_cate_box {
    flex-direction: column;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select * {
    box-sizing: border-box;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select {
    position: relative;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    background-color: #ebebeb;
    border: none;
    cursor: pointer;
    margin-top: 30px;
    display: none;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select:after {
    content: '';
    display: block;
    width: 15px;
    height: 9px;
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% + 0.5px));
    right: 20px;
    background-image: url(../../resources/images/site/ico_select_box_arr.png);
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: 100% 100%;
    transition: .3s ease-in;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select.active:after {
    transform: rotate(180deg) translateY(calc(50% - 0.5px));
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .news-tab__label {
    display: flex;
    align-items: center;
    width: inherit;
    height: inherit;
    border: 0 none;
    outline: 0 none;
    padding-left: 15px;
    background: transparent;
    cursor: pointer;
    font-size: 22px;
    font-weight: 700;
    font-family: "Pretendard", "Noto Sans KR", sans-serif;
    color: #111;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .news-tab__ul {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    background: #fff;
    color: #fff;
    list-style-type: none;
    padding: 0;
    border: 2px solid transparent;
    border-radius: 6px;
    overflow: hidden;
    max-height: 0;
    transition: .3s ease-in;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select.active .news-tab__ul {
    border: 2px solid #999;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .news-tab__ul::-webkit-scrollbar {
    width: 6px;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .news-tab__ul::-webkit-scrollbar-track {
    background: transparent;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .news-tab__ul::-webkit-scrollbar-thumb {
    background: #303030;
    border-radius: 45px;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .news-tab__ul::-webkit-scrollbar-thumb:hover {
    background: #303030;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select.active .news-tab__ul {
    max-height: 500px;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .optionItem {
    border-bottom: 1px dashed black;
    padding: 10px 15px 10px;
    transition: .1s;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .optionItem:hover {
    background: #fff;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .optionItem:last-child {
    border-bottom: 0 none;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select_mob {
    display: block;
    position: relative;
    width: 100%;
    height: 40px;
    margin-top: 30px;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select_mob select {
    width: inherit;
    height: inherit;
    background: #ebebeb;
    border: 0 none;
    outline: 0 none;
    padding: 0 10px;
    position: relative;
    border-radius: 5px;
    z-index: 3;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select_mob option {
    background: #ddd;
    color: #000;
    padding: 3px 0;
    font-size: 16px;
  }
  .news .news_wrap .news_cont .news-tab__label {
    display: block;
  }
  .news .news_wrap .news_cont .news-tab__ul {
    width: 100%;
    flex-direction: column;
    padding-right: 0;
  }
  .news .news_wrap .news_cont .news-tab__ul::before {
    display: block;
  }
  .news .news_wrap .news_cont .news-tab__ul__li {
    width: 100%;
  }
  .news .news_wrap .news_cont .news-tab__cont {
    z-index: 100;
  }
  .news .news_wrap .news_cont .news-tab__cont__more {
    top: -110px;
  }
  .news .news_wrap .news_cont .news-tab__cont__more::before {
    top: 12px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul {
    padding-top: 30px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li {
    width: 50%;
    padding: 0 20px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li:nth-child(3) {
    display: none;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-tit {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-txt {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .news .news_wrap .side_link .right_bg_item_ul_li {
    width: 49%;
  }
  .news .news_wrap .side_link .right_bg_item_ul_li a {
    height: auto;
    padding: 30px 0;
  }
  .news .news_wrap .side_link .right_bg_item_ul_li a span {
    padding-top: 20px;
  }
}

@media (max-width: 450px) {
  .news .news_wrap .news_cont .news-tab .news-tab__select {
    height: 40px;
    margin-top: 20px;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .news-tab__label {
    font-size: 16px;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select * {
    box-sizing: border-box;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .news-tab__ul {
    top: 46px;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select.active .news-tab__ul {
    border: 2px solid #999;
  }
  .news .news_wrap .news_cont .news-tab .news-tab__select .optionItem {
    border-bottom: 1px dashed black;
    padding: 10px 15px 10px;
    transition: .1s;
  }
  .news .news_wrap .news_cont .news-tab__ul__li a {
    font-size: 14px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul {
    padding-top: 30px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li {
    padding: 0 10px 0 0;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li:nth-child(3) {
    display: none;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-tit {
    font-size: 16px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-txt {
    font-size: 12px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-date {
    font-size: 12px;
  }
  .news .news_wrap .news_cont .news-tab__cont__ul__li a .news-date::before {
    width: 14px;
    height: 14px;
  }
  .news .news_wrap .side_link .right_bg_item_ul_li {
    width: 49%;
  }
  .news .news_wrap .side_link .right_bg_item_ul_li a {
    padding: 15px 0;
  }
  .news .news_wrap .side_link .right_bg_item_ul_li a span {
    font-size: 16px;
    padding-top: 15px;
  }
}

.side_link {
  display: flex;
  width: 324px;
}

.side_link .right_bg_item_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.side_link .right_bg_item_ul_li {
  margin-bottom: 7px;
  width: 48.5%;
}

.side_link .right_bg_item_ul_li a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 168px;
  border-radius: 5px;
}

.side_link .right_bg_item_ul_li a span {
  font-size: 20px;
  font-family: "yangjin", "Noto Sans KR", sans-serif;
  color: #fff;
  padding-top: 30px;
}

.side_link .right_bg_item_ul_li:nth-child(1) a {
  background-color: #4767b4;
}

.side_link .right_bg_item_ul_li:nth-child(2) a {
  background-color: #2d97be;
}

.side_link .right_bg_item_ul_li:nth-child(3) a {
  background-color: #ee6c6c;
}

.side_link .right_bg_item_ul_li:nth-child(4) a {
  background-color: #f4bc10;
}

.map-info {
  background-color: #f5e9d9;
  background-image: url("../../resources/images/site/bg_map_info_01.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 160px;
  margin-bottom: 10px;
}

@media (max-width: 1300px) {
  .map-info {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .map-info {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.map-info:before {
  content: '';
  display: block;
  background-image: url("../../resources/images/site/bg_map_info_02.png");
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
  width: 100%;
  height: 140px;
  position: absolute;
  bottom: 0;
}

.map-info .map_info_wrap {
  display: flex;
}

.map-info .map_info_left {
  width: 50%;
  padding-right: 74px;
}

.map-info .map_info_right {
  width: 50%;
}

.map-info .map_info_txt_box h3 {
  position: relative;
  font-size: 35px;
  color: #111;
  font-family: "yangjin", "Noto Sans KR", sans-serif;
  z-index: 1;
}

.map-info .map_info_txt_box h3:before {
  content: '';
  display: block;
  width: 46px;
  height: 46px;
  background-color: #faee00;
  border-radius: 50%;
  position: absolute;
  top: -18px;
  left: -20px;
  z-index: -1;
}

.map-info .map_info_txt_box p {
  font-size: 18px;
  color: #888;
  padding-top: 20px;
  padding-bottom: 30px;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
}

.map-info .map_info_data_wrap {
  background-color: #fff;
  border-radius: 37px;
  padding: 30px;
  -webkit-box-shadow: 7px 7px 9px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 7px 7px 9px 0 rgba(0, 0, 0, 0.1);
}

.map-info .map_info_value_box {
  display: flex;
  justify-content: space-between;
}

.map-info .map_value {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 31.8%;
  background-color: #f5f5f5;
  border-radius: 25px;
  padding-top: 30px;
  padding-bottom: 20px;
  cursor: pointer;
}

.map-info .map_value h4 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.0;
  color: #777;
  padding-bottom: 20px;
}

.map-info .map_value p {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.0;
  color: #ee6c6c;
}

.map-info .map_value p strong {
  font-size: 40px;
  font-weight: 900;
}

.map-info .map_value.active {
  background-color: #ee6c6c;
}

.map-info .map_value.active h4 {
  color: #fff;
}

.map-info .map_value.active p {
  color: #fff;
}

.map-info .map_info_data_txt {
  position: relative;
  border-bottom: 1px solid #9e9e9e;
  padding-bottom: 14px;
  margin-top: 30px;
  margin-bottom: 18px;
}

.map-info .map_info_data_txt h5 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.4;
  color: #111;
}

.map-info .map_info_data_txt a {
  position: absolute;
  top: 6px;
  right: 0;
}

.map-info .map_info_data_txt a:focus {
  border: none;
}

.map-info .map_info_data_txt a span {
  position: relative;
  font-size: 16px;
  font-weight: 800;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  color: #ba4f5f;
  padding-right: 20px;
}

.map-info .map_info_data_txt a span::before {
  content: '';
  display: block;
  width: 15px;
  height: 14px;
  background-image: url(../../resources/images/site/ico_map_info_more.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50% 50%;
  position: absolute;
  top: calc(50% - 7px);
  right: 0px;
}

.map-info .map_graph {
  display: none;
}

.map-info .map_graph.active {
  display: block;
}

.map-info .map_info_img_box {
  padding-top: 50px;
  text-align: right;
}

.map-info .map_img {
  display: none;
  text-align: right;
}

.map-info .map_img.active {
  display: block;
}

@media (max-width: 1300px) {
  .map-info:before {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  .map-info {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .map-info .map_info_txt_box h3 {
    font-size: 28px;
  }
  .map-info .map_info_txt_box p {
    font-size: 14px;
  }
  .map-info .map_value h4 {
    font-size: 16px;
  }
  .map-info .map_value p {
    font-size: 14px;
  }
  .map-info .map_value p strong {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .map-info .map_info_wrap {
    flex-direction: column;
  }
  .map-info .map_info_left {
    width: 100%;
    padding-right: 0;
  }
  .map-info .map_info_right {
    width: 100%;
  }
  .map-info .map_img {
    text-align: center;
  }
  .map-info .map_info_txt_box h3 {
    font-size: 28px;
  }
  .map-info .map_info_txt_box h3::before {
    width: 30px;
    height: 30px;
    top: -10px;
    left: -10px;
  }
  .map-info .map_info_txt_box p {
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .map-info .map_info_txt_box p {
    font-size: 14px;
  }
  .map-info .map_info_data_wrap {
    border-radius: 30px;
    padding: 10px;
  }
  .map-info .map_value {
    padding: 15px;
    box-sizing: border-box;
  }
  .map-info .map_value h4 {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .map-info .map_value p {
    font-size: 14px;
  }
  .map-info .map_value p strong {
    font-size: 30px;
  }
  .map-info .map_info_data_txt {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .map-info .map_info_data_txt h5 {
    font-size: 20px;
  }
  .map-info .map_info_data_txt a span {
    font-size: 14px;
  }
}

.main-visual {
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #fff;
}

@media only screen and (max-width: 1900px) {
  .main-visual {
    background-size: cover;
  }
}

@media only screen and (max-width: 910px) {
  .main-visual {
    height: auto;
  }
}

.main-visual_wrap {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .main-visual_wrap {
    width: 100%;
  }
}

@media only screen and (max-width: 910px) {
  .main-visual_wrap {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.main-visual .main-slide {
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 910px) {
  .main-visual .main-slide {
    width: 100%;
    float: none;
  }
}

/*메인슬라이드*/
.main-visual {
  position: relative;
  width: 100%;
}

.main-visual .slick-arrow {
  border: 0px;
  top: 220px;
}

.main-visual .slick-prev {
  position: absolute;
  left: 10px;
  z-index: 1;
  display: none !important;
}

.main-visual .slick-next {
  position: absolute;
  right: 10px;
  z-index: 1;
  display: none !important;
}

.main-visual {
  position: relative;
  width: 100%;
}

.main-visual .slick-arrow {
  border: 0px;
  top: 220px;
}

.main-visual .slick-prev {
  position: absolute;
  left: 10px;
  z-index: 1;
  display: none !important;
}

.main-visual .slick-next {
  position: absolute;
  right: 10px;
  z-index: 1;
  display: none !important;
}

/*이미지의 상하사이즈로 설정*/
.main-slide__item {
  height: 800px;
  background-position: 15% 0%;
  background-repeat: no-repeat;
}

.main-slide__item.main-slide_01 {
  background-image: url("../../resources/images/site/section_main_bg_01_pc.png");
}

.main-slide__item.main-slide_02 {
  background-image: url("../../resources/images/site/section_main_bg_01_pc.png");
}

.main-slide__item a:focus {
  border: 1px solid #000;
  display: block;
  box-sizing: border-box;
}

.slick-dots-wrap-outwrap {
  width: 100%;
  position: absolute;
  bottom: 320px;
}

@media only screen and (max-width: 1200px) {
  .slick-dots-wrap-outwrap {
    width: 100%;
  }
}

@media only screen and (max-width: 910px) {
  .slick-dots-wrap-outwrap {
    text-align: center;
    top: auto;
    position: relative;
    height: 0;
  }
}

.slick-dots-wrap {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

@media only screen and (max-width: 910px) {
  .slick-dots-wrap {
    margin-left: 0;
    border-radius: 0;
  }
}

.myslickcarousel {
  display: inline-block;
}

.slick-dots-on-off {
  display: inline-block;
}

.slick-dots-on-off li {
  background: #e26223;
  width: 26px;
  line-height: 23px;
  height: 26px;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.slick-dots-on-off li a {
  color: gold;
}

.slick-dots-on-off li:focus, .slick-dots-on-off li:hover {
  opacity: 0.8;
}

* + html .myslickcarousel {
  display: inline;
}

* + html .slick-dots-on-off {
  display: inline;
}

* + html .slick-dots-on-off li {
  display: inline;
}

.slick-dots-list {
  display: inline-block !important;
  vertical-align: middle;
}

.slick-dots-list li {
  display: inline-block;
  margin-right: 5px;
}

.slick-dots-list li button {
  background-color: #fff;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #1d5280;
  overflow: visible;
  width: 21px;
  height: 21px;
  line-height: 22px;
  border-radius: 22px;
  font-size: 0;
}

.slick-dots-list li.slick-active button {
  background-color: #1d5280;
}

.slick-dots-list li button:focus {
  background-color: #fff;
  box-sizing: border-box;
  border: 3px solid #1d5280;
}

.slick-dots-list li button:hover {
  background-color: #1d5280;
}

* + html .slick-dots-list li {
  display: inline;
}

* + html .slick-dots-list li button {
  display: inline;
}

.slick-dots-on-off img {
  vertical-align: middle;
}

.main-slide-prev {
  display: block;
  width: 26px;
  height: 26px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
}

.main-slide-prev:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -404px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.main-slide-prev:focus {
  background-color: gold;
  border-radius: 20px;
}

.main-slide-next {
  display: block;
  width: 26px;
  height: 26px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
}

.main-slide-next:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -136px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.main-slide-next:focus {
  background-color: gold;
  border-radius: 20px;
}

.main-slide-pause {
  display: block;
  width: 26px;
  height: 26px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
}

.main-slide-pause:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -203px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.main-slide-pause:focus {
  background-color: gold;
  border-radius: 20px;
}

.main-slide-play {
  display: block;
  width: 22px;
  height: 22px;
  font-size: 0;
  text-indent: -9999px;
  vertical-align: top;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -227px -752px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
}

.slick-dots-wrap-outwrap {
  display: none;
}

.slick-dots-wrap-outwrap.on {
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 1500px) {
  .main-visual {
    width: 100%;
    padding-top: 0;
  }
  .main-visual .slick-arrow {
    top: 150px;
  }
  .main-slide__item > img {
    width: 1500px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .main-slide__item > img {
    width: 1500px;
  }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .main-slide__item > img {
    width: 1250px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-visual {
    margin-top: 0;
  }
  .main-visual .slick-arrow {
    top: 70px;
  }
  .main-slide__item {
    height: 476px;
    background-position: 0% 0%;
    background-size: cover;
  }
  .main-slide__item.main-slide_01 {
    background-image: url("../../resources/images/site/section_main_bg_01_mob.png");
  }
  .main-slide__item.main-slide_02 {
    background-image: url("../../resources/images/site/section_main_bg_01_mob.png");
  }
  .main-slide__item > img {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .main-slide__item {
    height: 300px;
  }
  .main_visual__info {
    top: 250px !important;
  }
}

/*video*/
.main-visual {
  overflow: hidden;
}

.video-box {
  position: relative;
}

.video-box__over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../resources/images/basic/video-over-img.png");
  background-repeat: repeat;
  background-position: 0 0;
}

.video-box video {
  width: 1900px;
}

.video-box__pause {
  position: absolute;
  top: 825px;
  right: 50%;
  margin-right: -740px;
  z-index: 99999;
  background-color: #fff;
  font-weight: bold;
  display: block;
  padding: 3px;
  border-radius: 10px;
  border: 2px solid #000;
}

.video-box__pause:focus {
  background-color: gold;
}

.main_visual__content .main_visual__content_wrap .slide-controls .top-wrapper .slider-progress__container {
  width: 200px;
  display: inline-block;
  margin-right: 15px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.main_visual__content .main_visual__content_wrap .slide-controls .top-wrapper .slider-progress__container .slider-progress {
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  display: block;
}

.main_visual__content .main_visual__content_wrap .slide-controls .top-wrapper .slider-progress__container .slider-progress .progress {
  display: flex;
  width: 0%;
  height: 2px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #15baff;
  border-radius: 0.25rem;
}

.main_visual__content .main_visual__content_wrap .slide-controls .top-wrapper .section-slide__paging span {
  font-size: 16px;
  color: #c1c1c1;
}

.main_visual__content .main_visual__content_wrap .slide-controls .top-wrapper .section-slide__paging .paging_active {
  color: #fff;
}

.main_visual__info__wrap {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1;
}

.main_visual__info {
  position: absolute;
  top: 460px;
  display: flex;
  align-items: center;
}

.main_visual__info .slick-dots {
  display: flex !important;
  margin-right: 40px;
}

@media (max-width: 500px) {
  .main_visual__info .slick-dots {
    margin-right: 20px;
  }
}

.main_visual__info .slick-dots li {
  width: auto;
  height: auto;
  margin: 0;
}

.main_visual__info .slick-dots li button {
  display: inline-block;
  vertical-align: middle;
  width: 27px;
  font-size: 16px;
  font-weight: 300;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  color: #fff;
  padding: 0;
  outline: none;
  background: none;
}

.main_visual__info .slick-dots li.slick-active .progressBar {
  width: 100px;
  margin-right: 6px;
  margin-left: 6px;
}

@media (max-width: 500px) {
  .main_visual__info .slick-dots li.slick-active .progressBar {
    width: 60px;
  }
}

.main_visual__info .slick-dots .progressBar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-left: 0;
  overflow: hidden;
  -moz-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.main_visual__info .slick-dots .progressBar .inProgress {
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #fff;
}

.main_visual__info .slick-arrow {
  display: block;
  width: 7px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.main_visual__info .slick-arrow.main_visual__prev {
  background-image: url(../../resources/images/site/btn_slide_prev.png);
  margin-right: 20px;
}

.main_visual__info .slick-arrow.main_visual__next {
  background-image: url(../../resources/images/site/btn_slide_next.png);
  margin-left: 20px;
}

@media (max-width: 500px) {
  .main_visual__info .slick-arrow.main_visual__prev {
    margin-right: 15px;
  }
  .main_visual__info .slick-arrow.main_visual__next {
    margin-left: 15px;
  }
}

.main_visual__info .main_visual__btn_pause, .main_visual__info .main_visual__btn_play {
  display: block;
  width: 9px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 0;
}

.main_visual__info .main_visual__btn_pause {
  background-image: url(../../resources/images/site/btn_slide_pause.png);
}

.main_visual__info .main_visual__btn_play {
  background-image: url(../../resources/images/site/btn_slide_play.png);
}

@media (max-width: 768px) {
  .main_visual__info {
    top: 400px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1500px) {
  .video-box video {
    width: 1500px;
  }
  .video-box__pause {
    top: 800px;
    margin-right: -42%;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .video-box video {
    width: 1200px;
  }
  .video-box__pause {
    top: 620px;
    margin-right: -40%;
  }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .video-box video {
    width: 900px;
  }
  .video-box__pause {
    top: 460px;
    margin-right: -40%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .video-box video {
    width: 100%;
  }
  .video-box__over {
    display: none;
  }
  .video-box__pause {
    display: none;
    top: unset;
    bottom: 10%;
    margin-right: -44%;
  }
}

.main-notice__tit {
  font-family: 'Do Hyeon','Noto Sans KR', sans-serif;
  font-size: 28px;
  position: relative;
}

.main-notice__tit:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 140px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -136px;
  background-image: url("../images/sprite.png");
  width: 10px;
  min-width: 10px;
  height: 16px;
}

.main-notice__ul {
  margin-left: 200px;
  margin-top: -30px;
  margin-right: 50px;
}

.main-notice__ul__li {
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 43px;
}

.main-notice__ul__li:last-child {
  margin-bottom: 0;
}

.main-notice__ul__li__a {
  display: block;
  margin-bottom: -12px;
  position: relative;
  z-index: 99;
  font-size: 16px;
}

.main-notice__ul__li__a:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .main-notice__ul__li__a {
  display: inline-block;
}

.main-notice__ul__li__a:hover, .main-notice__ul__li__a:focus {
  color: #fc6410;
}

.main-notice__ul__li__a__date {
  font-family: 'Noto Sans KR';
  color: #888888;
  background-color: #fff;
  display: block;
  float: left;
  padding-right: 10px;
}

.main-notice__ul__li__a__title {
  float: right;
  width: 70%;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
}

@media screen and (min-width: 1px) and (max-width: 1500px) {
  .main-notice__ul {
    margin-right: 0;
    margin-top: 30px;
    margin-left: 0;
  }
  .main-notice__ul__li {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .main-notice__ul {
    margin-top: 5px;
  }
  .main-notice__ul__li {
    border-bottom: 0px;
    margin-bottom: 18px;
  }
  .main-notice__ul__li__a {
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .main-notice__ul__li__a__date {
    float: none;
    display: none;
  }
  .main-notice__ul__li__a__title {
    float: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  .main-notice__ul__li {
    display: none;
  }
  .main-notice__ul__li:nth-child(1),
  .main-notice__ul__li:nth-child(2),
  .main-notice__ul__li:nth-child(3),
  .main-notice__ul__li:nth-child(4) {
    display: block;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-notice__ul {
    margin-top: 10px;
  }
  .main-notice__tit:after {
    left: 105px;
  }
}

/*팝업존*/
.popup {
  position: relative;
  max-width: 733px;
  height: 260px;
  border: 0 solid #e5e5e5;
  overflow: hidden;
  margin: 0 auto;
}

.popup__h3 {
  font-size: 0;
  height: 0;
  width: 0;
  text-indent: -9999px;
}

.popup-list__item a {
  display: block;
  color: gold;
  width: 733px;
  height: 260px;
  margin: 0 auto;
}

.popup-list__item a img {
  width: 733px;
  height: 260px;
}

.popup-dots-wrap li {
  float: left;
  margin-left: 5px;
}

.popup-dots-wrap button {
  border: 0px;
  height: 23px;
  font-size: 0;
  display: block;
  width: 23px;
  background: transparent;
  border-radius: 30px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -154px -752px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 23px;
}

.popup-dots-list .slick-active button {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -81px -752px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 23px;
}

.popup-dots-list button:focus {
  border: 1px solid gold;
}

.popup-dots-on-off {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.popup-dots-on-off > a {
  display: inline-block;
  width: 11px;
  height: 14px;
  margin: 1px;
  margin-left: 5px;
  vertical-align: top;
  font-size: 0;
  text-indent: -9999px;
}

.popup-dots-on-off li {
  float: left;
  margin-left: 5px;
}

.mypopupcarousel {
  display: inline-block;
  vertical-align: middle;
}

.popup-dots-on-off a:focus {
  border: 1px solid gold;
  margin: 0;
}

.popup-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -266px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 14px;
}

.popup-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -202px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 14px;
}

.popup-pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -739px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 19px;
}

.popup-play {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -234px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.popup-total {
  display: none;
}

.popup-more {
  display: none !important;
}

/*더보기 지울시*/
.popup-dots-wrap {
  background: #333;
}

.myslickcarouse1 {
  display: inline-block;
}

* + html .myslickcarouse1 {
  display: inline;
}

.popup-dots-list button {
  font-size: 12px;
  text-indent: 0;
  color: #fff;
  background-image: none;
  display: none;
}

.popup-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  color: gold;
}

.popup-total {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 21px;
}

* + html .popup-total {
  display: inline;
}

.popup-total-num {
  padding-left: 4px;
}

/*숫자형 네비 추가 끝*/
@media screen and (min-width: 1px) and (max-width: 1200px) {
  .popup-list__item a {
    width: auto;
  }
  .popup-list__item a img {
    max-width: 100%;
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .popup {
    height: auto;
  }
  .popup-list__item a {
    height: auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .popup-dots-wrap {
    top: 10px;
  }
}

@media screen and (min-width: 561px) and (max-width: 768px) {
  .popup {
    height: auto;
    padding-bottom: 0px;
  }
  .popup-list__item a {
    height: auto;
  }
  .popup-list__item a img {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .popup {
    height: auto;
    padding-bottom: 0px;
  }
  .popup-list__item a {
    height: auto;
  }
  .popup-list__item a img {
    width: 100%;
    height: auto;
  }
}

.fastmenu {
  background-color: #ebedef;
  width: 1500px;
  margin: 0 auto;
}

.fastmenu__h3 {
  font-family: 'Do Hyeon','Noto Sans KR', sans-serif;
  font-size: 28px;
  position: relative;
  margin-bottom: 40px;
}

.fastmenu__ul {
  margin-left: -35px;
}

.fastmenu__ul__li {
  float: left;
  width: 25%;
}

.fastmenu__ul__li__a {
  margin-left: 35px;
  position: relative;
  display: block;
  height: 273px;
}

.fastmenu__ul__li__a__imgbox {
  overflow: hidden;
}

.fastmenu__ul__li__a__imgbox img {
  transition: all ease 1s;
  max-width: 100%;
  max-height: 273px;
}

.fastmenu__ul__li__a__tit {
  font-size: 35px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  bottom: 80px;
  left: 20px;
}

.fastmenu__ul__li__a__detail {
  font-size: 14px;
  color: #c6c6c6;
  border: 1px solid #c6c6c6;
  display: block;
  padding: 5px 15px;
  position: absolute;
  bottom: 30px;
  left: 20px;
}

.fastmenu__ul__li__a:hover img, .fastmenu__ul__li__a:focus img {
  transform: scale(1.2);
  opacity: 0.7;
}

@media screen and (min-width: 1px) and (max-width: 1500px) {
  .fastmenu {
    width: auto;
  }
  .fastmenu__ul__li__a {
    height: auto;
  }
  .fastmenu__ul__li__a__imgbox img {
    height: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .fastmenu__ul {
    margin-left: -2%;
  }
  .fastmenu__ul__li {
    width: 50%;
    margin-bottom: 2%;
  }
  .fastmenu__ul__li__a {
    margin-left: 4%;
  }
  .fastmenu__ul__li__a__imgbox img {
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .fastmenu__h3 {
    margin-bottom: 2%;
  }
  .fastmenu__ul__li__a__tit {
    left: 0px;
    width: 100%;
    text-align: center;
  }
  .fastmenu__ul__li__a__detail {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .fastmenu__ul__li__a__tit {
    font-size: 23px;
  }
}

/*유동적 가로세로 비율 박스*/
.movie-wrap__h3 {
  height: 35px;
  font-family: 'Do Hyeon','Noto Sans KR', sans-serif;
  font-size: 28px;
  position: relative;
  margin-bottom: 40px;
}

.movie-wrap__h3:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -409px -668px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-right: 5px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .movie-wrap__h3 {
    margin-bottom: 20px;
  }
}

.movie-player {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  /*16:9*/
  background-color: #00235d;
  color: #fff;
}

.movie-player__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.movie-player__in iframe {
  width: 100%;
  height: 100%;
}

.main-sns__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .main-sns__in {
  display: inline-block;
}

.main-sns__in > img {
  width: 100%;
}

.main-sns__in__box__tit {
  height: 35px;
  font-family: 'Do Hyeon','Noto Sans KR', sans-serif;
  font-size: 28px;
  position: relative;
  margin-bottom: 40px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .main-sns__in__box__tit {
    margin-bottom: 20px;
  }
}

.main-sns__in__box__tit__a {
  display: inline-block;
  width: auto;
  height: 35px;
}

.main-sns__in__box__tit__a--1:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -249px -668px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-right: 5px;
}

.main-sns__in__box__tit__a--2:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -329px -668px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-right: 5px;
}

.main-sns__in__box1 {
  float: left;
  width: 50%;
}

.main-sns__in__box2 {
  float: right;
  width: 50%;
}

/*facebook*/
.variable-box {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 114.3%;
  /*16:9*/
  background-color: #fff;
  color: #fff;
}

.variable-box__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.variable-box__in__loading {
  display: block;
  width: 106px;
  height: 106px;
  background-image: url("../../resources/images/basic/loading.gif");
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
}

.variable-box__in iframe {
  width: 100%;
  height: 100%;
}

.fb_iframe_widget span {
  width: 100% !important;
  height: 100% !important;
  vertical-align: top !important;
}

@media screen and (min-width: 1px) and (max-width: 1500px) {
  .fb_iframe_widget {
    width: 100% !important;
  }
  .fb-page iframe {
    width: 100% !important;
    max-width: 100%;
  }
}

/*instagram*/
.instagram {
  overflow: hidden;
  padding-left: 9px;
}

.instagram__item {
  float: left;
  width: 33.33%;
}

.instagram__item > a {
  display: block;
  width: 100%;
  padding-top: 115%;
  position: relative;
  background: no-repeat center center;
  background-size: cover;
}

.instagram__item > a:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  background: rgba(0, 0, 0, 0.8);
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 1;
  opacity: 0;
  -webkit-transition: opacity 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.instagram__item > a:hover:before {
  opacity: 1;
}

.instagram__item > a:hover p {
  opacity: 1;
}

.instagram__item p {
  width: 100%;
  padding: 19px 5px;
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 0;
  box-sizing: border-box;
  -webkit-transition: opacity 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
@media screen and (min-width:1px) and (max-width:1500px) {
	.instagram__item > a {padding-top:118.7%;}
	.fb_iframe_widget {height:100% !important;}
	.fb_iframe_widget iframe  {height:100% !important;}
}
@media screen and (min-width:1px) and (max-width:560px) {
	.main-sns__in__box1 {float:none;width:100%;margin-bottom:2%;}
	.main-sns__in__box2 {float:none;width:100%;}

	.instagram__item > a {padding-top:100%;}


	.variable-box {height:auto;padding-bottom:0;}
	.variable-box__in {position: relative;}
	.instagram {padding-left:0;}
	.fb_iframe_widget iframe {position: relative !important;}
	.fb_iframe_widget span {width:auto !important;height:auto !important;display: block !important;margin:0 auto;width:370px !important}
}
*/
@media screen and (min-width: 1px) and (max-width: 1500px) {
  .instagram__item > a {
    padding-top: 118.7%;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .main-sns__in__box1 {
    float: none;
    width: 100%;
    margin-bottom: 2%;
  }
  .main-sns__in__box2 {
    float: none;
    width: 100%;
  }
  .instagram__item > a {
    padding-top: 100%;
  }
  .variable-box {
    height: auto;
    padding-bottom: 0;
  }
  .variable-box__in {
    position: relative;
  }
  .instagram {
    padding-left: 0;
  }
  .fb_iframe_widget {
    height: 100%;
  }
  .fb_iframe_widget iframe {
    position: relative !important;
  }
  .fb_iframe_widget span {
    width: auto !important;
    height: auto !important;
    display: block !important;
    margin: 0 auto;
    width: 355px !important;
  }
}

.sub-visual {
  height: 195px;
  transition: all 0.3s ease 0s;
  background-image: url("../../resources/images/site/visual-1.png");
  background-repeat: repeat;
  background-position: center top;
  background-size: auto 100%;
  font-family: "Nanum Gothic";
}

.sub-visual__tit {
  color: #fff;
  font-size: 60px;
  font-weight: bold;
  padding-top: 100px;
  text-align: center;
}

.sub-visual__desc {
  color: #fff;
  font-size: 15px;
  padding-top: 10px;
  text-align: center;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .sub-visual__tit {
    font-size: 40px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-visual {
    display: none;
  }
}

/*배너존*/
.banner_zone_outwrap {
  font-family: 'Noto Sans KR';
  margin: 0 auto;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}

.banner_zone_wrap {
  position: relative;
  overflow: hidden;
  width: 1440px;
  height: 50px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.banner_zone {
  width: 990px;
  overflow: hidden;
  z-index: 0;
  position: relative;
  text-align: center;
  margin-left: 210px;
}

.banner_zone a {
  padding: 0 0;
}

.banner_zone_outwrap a:focus {
  box-sizing: border-box;
  border: 1px solid #000;
}

.selectbox_title_focus {
  border: 1px solid #ffc169 !important;
}

.listwrap {
  position: relative;
  left: 0;
  overflow: hidden;
}

* + html .listwrap {
  overflow: hidden;
  height: 50px;
  white-space: nowrap;
}

.listwrap li {
  float: left;
  width: 198px;
  height: 50px;
}

.listwrap li a {
  display: block;
}

.listwrap li a img {
  width: 186px;
  height: 48px;
  border: 1px solid #ddd;
}

.navi {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 18px;
}

.navi .on {
  background-color: red;
}

.banner_zone_wrap h3 {
  margin-left: 0px;
  margin-bottom: 0px;
  font-size: 18px;
  color: #333333;
  position: absolute;
  top: 11px;
  left: 2px;
}

.foot_roll_nav2 {
  position: relative;
  z-index: 1;
}

.foot_roll_nav2 .r_prev {
  left: 83px;
  top: 13px;
}

.foot_roll_nav2 .r_pause {
  left: 110px;
  top: 13px;
}

.foot_roll_nav2 .r_next {
  left: 137px;
  top: 13px;
}

.foot_roll_nav2 .r_plus {
  left: 164px;
  top: 13px;
}

.foot_roll_nav2 .r_start {
  left: 34px;
  top: 29px;
}

.foot_roll_nav2 span {
  float: left;
  position: absolute;
}

.foot_roll_nav2 span.go_all_banner {
  padding-left: 3px;
}

.banner_zone_wrap .pos_banner {
  position: absolute;
  top: 15px;
  left: 133px;
}

.r_plus a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px 0px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_pause a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -646px -668px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_start a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -312px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 25px;
}

.r_prev a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -568px -668px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_next a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -770px -78px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin-right: -1px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .banner_zone_outwrap {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .banner_zone_wrap {
    width: auto;
  }
  .banner_zone {
    width: 790px;
  }
}

@media screen and (min-width: 851px) and (max-width: 1000px) {
  /*배너존*/
  .banner_zone {
    width: 595px;
  }
}

@media screen and (min-width: 769px) and (max-width: 850px) {
  /*배너존*/
  .banner_zone {
    width: 392px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .banner_zone_wrap {
    height: auto;
    padding-left: 10px;
  }
  .banner_zone_wrap h3 {
    top: 0px;
    left: 10px;
  }
  .foot_roll_nav2 .r_prev {
    left: 83px;
    top: 0px;
  }
  .foot_roll_nav2 .r_pause {
    left: 110px;
    top: 0px;
  }
  .foot_roll_nav2 .r_next {
    left: 137px;
    top: 0px;
  }
  .foot_roll_nav2 .r_plus {
    left: 164px;
    top: 0px;
  }
  .foot_roll_nav2 .r_start {
    left: 34px;
    top: 0px;
  }
  .banner_zone {
    width: 392px;
    margin: 0 auto;
    padding-top: 40px;
  }
}

@media screen and (min-width: 1px) and (max-width: 470px) {
  .banner_zone {
    width: 200px;
  }
}

/*		sidebar
=========================*/
/* sidebar */
.sidebar {
  width: 220px;
  float: left;
  padding-bottom: 40px;
  font-family: 'Noto Sans KR';
}

.sidebar__wrap {
  background-color: #fff;
}

.sidebar__h2 {
  text-align: center;
  padding-top: 75px;
  font-size: 32px;
  font-size: 3.2rem;
  position: relative;
  padding-bottom: 75px;
  color: #fff;
  background-image: url("../../resources/images/site/menu_sbj.png");
  background-repeat: no-repeat;
  position: relative;
}

.sidebar__h2 span {
  display: block;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.sidebar__h2 a {
  display: none;
}

/*메뉴 이름 길경우*/
.sidebar__h2.long-type {
  font-size: 20px;
  padding-top: 75px;
}

.side-list__li > span > a {
  display: block;
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: normal;
  transition: all 0.3s ease 0s;
  background-position: 213px center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.side-list__li:first-child > span > a {
  border-top: 1px solid #e0e0e0;
}

.side-list__li.open-type > span > a {
  position: relative;
}

.side-list__li.open-type > span > a:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -639px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 11px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.side-list__li.open-type > span > a:hover:after,
.side-list__li.open-type > span > a:focus:after,
.side-list__li.open-type > span.on > a:after,
.side-list__li.open-type > span > a.on:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -696px -826px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.side-list__li.open-type.on > span > a:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -696px -826px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
  position: absolute;
}

.side-list__li.on > span {
  display: block;
}

.side-list__li.on > span a {
  color: #fff;
}

.side-list__li > span > a:hover,
.side-list__li > span > a:focus,
.side-list__li > span.on > a,
.side-list__li > span > a.on,
.side-list__li.selected > span > a {
  font-weight: normal;
  opacity: 0.9;
  color: #e26223;
  border-bottom: 1px solid #e26223;
}

.side-list__li.on > span > a {
  font-weight: normal;
  opacity: 0.9;
  color: #e26223;
  border-bottom: 1px solid #e26223;
}

.side-list__li__inbox {
  display: none;
}

.open-type.on > .side-list__li__inbox {
  display: block;
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.spp__in > a {
  display: block;
  font-size: 15px;
  color: #565656;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 13px;
  /*
	&:before {
		content: '';
		display: inline-block;
		@include sprite($side-list-inbox);
		padding-right:8px;
	}
*/
}

.spp__in > a:hover,
.spp__in > a:focus,
.spp__in.on > a,
.spp__in > .on,
.spp__in.selected > a,
.spp__in > .selected {
  color: #e26223;
}

.spp__in__small {
  display: none;
}

.on > .spp__in__small {
  display: block;
  padding: 3px 10px 10px 15px;
}

.spp__in__small a {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-size: 13px;
  font-size: 1.3rem;
}

.spp__in__small .on,
.spp__in__small a:hover,
.spp__in__small a:focus {
  text-decoration: underline;
}

.sp-open-type {
  position: relative;
}

.sp-open-type:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -756px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
  position: absolute;
  top: 13px;
  right: 15px;
}

.sp-open-type.on:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -638px -826px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
  position: absolute;
  top: 15px;
  right: 15px;
}

/*4차*/
.spp__in__small__4th {
  display: none;
}

.on > .spp__in__small__4th {
  display: block;
  padding: 10px 5px 10px 10px;
  background-color: #eff3f7;
}

.spp__in__small__4th a {
  display: block;
  padding: 3px 0;
  min-height: 17px;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: normal;
  padding-left: 15px;
}

.spp__in__small__4th > li.on > a,
.spp__in__small__4th > li > .on,
.spp__in__small__4th a:hover,
.spp__in__small__4th a:focus {
  text-decoration: underline;
}

/*2019 사이드 메뉴 수정 추가 */
.sidebar {
  position: relative;
  top: -100px;
}

.mobile-submenu-btn {
  display: none;
  background-color: #f05023;
  padding: 5px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 98;
}

.mobile-submenu-btn .type1 {
  display: block;
}

.mobile-submenu-btn .type2 {
  display: none;
}

.selected.mobile-submenu-btn .type1 {
  display: none;
}

.selected.mobile-submenu-btn .type2 {
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .sidebar {
    position: relative;
    z-index: 1;
    margin-top: 0px;
    float: none;
    width: 100%;
    padding-bottom: 0;
    top: 0;
  }
  .sidebar.selected {
    border-bottom: 2px solid #013f88;
  }
  .side_in_wrap {
    display: none;
    border-bottom: 2px solid #009590;
    opacity: 0;
  }
  .sidebar h2 {
    display: block;
    text-align: left;
    background-image: none;
    background-color: #f79723;
    padding-left: 10px;
    font-size: 1.5rem;
    height: 32px;
    padding-top: 5px;
    line-height: 1.6;
  }
  .sidebar h2 {
    color: #fff;
    min-height: 10px;
    height: 30px;
    border-radius: 0px;
  }
  .sidebar h2 a {
    display: none;
  }
  .sidebar__h2 {
    padding-bottom: 0px;
  }
  .sidebar__h2.long-type {
    padding-bottom: 0px;
    font-size: 16px;
    padding-top: 5px;
    height: 32px;
  }
  .sidebar h2 {
    text-align: left;
    padding-left: 20px;
    font-size: 16px;
  }
  .side-list__li > span > a {
    height: 30px;
    line-height: 30px;
  }
  .side-list__li.open-type > span > a {
    background-position: 96% center;
  }
  .side-list__li.open-type.on > span > a {
    background-position: 96% center;
  }
  .sp-open-type {
    background-position: 97% center;
  }
  .sp-open-type.on {
    background-position: 97% center;
  }
  .side-list__li:first-child > span > a {
    border-top: 0px;
  }
  /**/
  .sidebar__wrap {
    opacity: 1.0 !important;
    margin-left: 0px !important;
  }
  .side_in_wrap {
    display: block;
    border-bottom: 2px solid #000;
    opacity: 1;
  }
  .side_in_wrap li.selected span a {
    background-color: #fff !important;
    color: #f6804e !important;
  }
  .mobile-submenu-btn {
    display: block;
  }
  .open-type.on > .side-list__li__inbox {
    padding: 0 10px;
    background-color: #eff3f7;
  }
  .sidebar .side-list > li {
    overflow: hidden;
    height: 0;
  }
  /*.sidebar .side-list > li.on {display:block;}*/
  .sidebar .side-list > li.on {
    height: auto;
  }
  .sidebar.selected .side-list > li {
    height: auto;
  }
  .side-list__li > span > a {
    padding: 0px 0 0px 20px;
    transition: all 0.3s ease 0s;
  }
  .sidebar.selected .side-list__li > span > a {
    padding: 5px 0 5px 20px;
  }
  .sidebar.selected .side-list > li.open-type {
    height: auto;
  }
  .spp__in > a {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 18px;
  }
  .spp__in > a:hover, .spp__in > a:focus, .spp__in.on > a, .spp__in > .on, .spp__in.selected > a, .spp__in > .selected {
    color: #013f88;
  }
}

/*sub-page-container*/
.sub-page-container__in {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  background-color: #fff;
}

.sub-contents {
  float: right;
  width: 940px;
  padding-bottom: 50px;
  padding-top: 40px;
}

.cont-in {
  padding-bottom: 40px;
  font-family: 'noto sans kr';
}

.cont-in img {
  max-width: 100%;
  height: auto !important;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .sub-page-container__in {
    width: auto;
  }
  .sub-contents {
    width: auto;
    float: none;
    padding: 10px;
    padding-top: 5px;
  }
  .cont-in img {
    height: auto !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-page-container {
    padding-top: 51px;
  }
}

.sub-contents__top {
  position: relative;
  border-bottom: 2px dotted #e0e0e0;
  font-family: 'Noto Sans KR';
  /*background-image:url("../../commons/images/global/sub-visual-bg1.jpg");*/
  background-position: right top;
  background-repeat: no-repeat;
  margin-bottom: 40px;
}

.sub-contents__top h2.sub-tit {
  font-size: 30px;
  color: #222;
  padding-top: 5px;
  margin-bottom: 0;
  height: 60px;
  font-weight: 700;
}

/* location */
.location-box {
  position: absolute;
  right: 0;
  top: 15px;
}

.location-box__cont span {
  display: inline-block;
  font-size: 14px;
  font-size: 1.4rem;
  height: 28px;
  line-height: 28px;
  color: #666;
}

.location-box__cont span:before {
  content: '';
  display: inline-block;
  font-size: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -123px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 7px;
  margin: 0 17px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .location-box__cont span:before {
    margin: 0 8px;
  }
}

.location-box__cont span:first-child::before {
  display: none;
}

.location-box__cont .loc-home {
  display: block;
  padding-left: 30px;
  font-size: 14px;
  font-size: 1.4rem;
  height: 28px;
  line-height: 27px;
  background-image: url("../../resources/images/icons/home.png");
  background-position: 0 center;
  background-repeat: no-repeat;
  color: #666;
}

.location-box__cont .loc-home:focus {
  border: 2px solid gold;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .location-box__cont .loc-home {
    padding-left: 20px;
  }
}

/* 프린트, url 버튼 등*/
.special-box {
  position: absolute;
  top: 10px;
  right: 0;
}

.special-box__print {
  margin-right: 4px;
  text-indent: -9999px;
}

.special-box__url {
  text-indent: -9999px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .location-box__cont {
    position: relative;
    top: 3px;
    width: auto;
    text-align: right;
  }
  .special-box {
    top: 38px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-contents__top {
    background-image: none !important;
    margin-bottom: 10px;
  }
  .sub-contents__top h2.sub-tit {
    font-size: 21px;
    margin-bottom: 8px;
    padding-top: 5px;
    height: auto;
  }
  .location-box {
    position: relative;
    top: 0;
    right: 0;
    padding-bottom: 0px;
  }
  .location-box__cont {
    position: relative;
    white-space: normal;
    width: auto;
    text-align: left;
    overflow: visible;
    top: 0;
    left: 0;
    padding-top: 0px;
  }
  .special-box {
    display: none;
  }
}

/* checkbox */
input[class="check-type"] + label {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid #cccccc;
  background-color: #dddddd;
  cursor: pointer;
  border-radius: 30px;
  position: relative;
}

input[class="check-type"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -580px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 9px;
  position: absolute;
  top: 10px;
  left: 10px;
}

input[class="check-type"]:checked + label {
  background-color: #666666;
}

input[class="check-type"]:focus + label {
  border: 2px solid gold;
}

input[class="check-type"] {
  position: absolute;
  left: -9999px;
}

/* 2 */
input[class="check-type2"] + label {
  display: inline-block;
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: relative;
  border: 1px solid #ddd;
}

input[class="check-type2"]:checked + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -513px -826px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 11px;
  position: absolute;
  top: 0px;
  left: 0px;
}

input[class="check-type2"]:focus + label {
  border: 1px solid #bbbbbb;
}

input[class="check-type2"] {
  position: absolute;
  left: -9999px;
}

.editor_view.on .editor_view__cont {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  /*16:9*/
  background-color: #00235d;
  color: #fff;
}

.editor_view.on .editor_view__cont > p {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editor_view.on .editor_view__cont > p iframe {
  width: 100%;
  height: 100%;
}

/*box style*/
.box-style01__wrap {
  position: relative;
  margin: 0 auto;
  max-width: 900px;
}

.box-style01__wrap:before {
  content: '';
  background-image: url(../../resources/images/cont/box-img1.png);
  z-index: 9;
  background-position: top center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  position: absolute;
}

.box-style01 {
  padding: 40px;
  border: 6px solid #ebebeb;
  border-top: 0;
  border-radius: 10px;
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .box-style01 {
    padding: 20px 15px;
  }
}

/*탭*/
.js-box {
  display: none;
  position: relative;
}

.js-box.on {
  display: block;
}

/*메인탭*/
.main-tab__ul {
  border-bottom: 2px solid #26348b;
}

.main-tab__ul__li {
  float: left;
}

@media only screen and (max-width: 770px) {
  .main-tab__ul__li {
    width: 20%;
  }
}

.main-tab__ul__li a {
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
  height: 56px;
  line-height: 56px;
  display: block;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  padding-right: 15px;
  color: #222;
  opacity: 0.7;
  font-weight: 500;
}

@media only screen and (max-width: 770px) {
  .main-tab__ul__li a {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.main-tab__ul__li.on a {
  color: #333;
  opacity: 1;
  font-weight: 700;
}

.main-tab__ul__li.on a:focus, .main-tab__ul__li.on a:hover {
  border: 0px;
  text-decoration: none;
}

.main-tab__ul__li a:hover,
.main-tab__ul__li a:focus {
  border: 0px;
  font-weight: 700;
  text-decoration: underline;
}

.main-tab__cont__ul__li {
  border-top: 1px solid #ccc;
}

.main-tab__cont__ul__li {
  border-top: 1px solid #ccc;
}

.main-tab__cont__ul__li:first-child {
  border-top: 0;
}

.main-tab__cont__ul__li a {
  display: block;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}

.main-tab__cont__ul__li a:hover,
.main-tab__cont__ul__li a:focus {
  background-color: #eee;
}

.main-tab__cont__more {
  position: absolute;
  right: 18px;
  top: -42px;
  display: block;
  padding: 2px;
}

@media only screen and (max-width: 770px) {
  .main-tab__cont__more {
    top: -75px;
  }
}

.main-tab__cont__more:focus {
  border: 2px solid gold;
  padding: 0;
}

.mtcul-cont {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  width: 70%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000;
}

.mtcul-date {
  display: block;
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 12px;
  color: #333;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mtcul-cont {
    width: auto;
  }
  .mtcul-date {
    display: none;
  }
}

/*서브탭*/
.sub-tab__ul {
  display: flex;
  margin-bottom: 40px;
}

.sub-tab__ul > li {
  flex: 1;
  text-align: center;
}

.sub-tab__ul > li > a {
  display: block;
  height: 70px;
  line-height: 70px;
  white-space: nowrap;
  border: 1px solid #dcdcdc;
  border-left: 0;
  font-size: 16px;
  font-weight: bold;
  color: #666;
}

.sub-tab__ul > li:first-child > a {
  border-left: 1px solid #dcdcdc;
}

.sub-tab__ul > li.on > a {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  border-left: 0;
}

.sub-tab__ul > li > a:hover,
.sub-tab__ul > li > a:focus {
  background-color: #333;
  color: #fff;
  border: 1px solid #000;
  border-left: 0;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-tab__ul {
    display: block;
    margin-left: -10px;
  }
  .sub-tab__ul:after {
    content: ' ';
    clear: both;
    display: block;
    visibility: hidden;
  }
  * + html .sub-tab__ul {
    display: inline-block;
  }
  .sub-tab__ul > li {
    float: left;
    width: 50%;
  }
  .sub-tab__ul > li > a {
    margin-bottom: 10px;
    margin-left: 10px;
    border-left: 1px solid #dcdcdc;
  }
}

/*
.sub-tab__ul {}
.sub-tab__ul:after {content:' ';clear:both;visibility: hidden;display: block;}
	*+ html .sub-tab__ul {display:inline-block;}
.sub-tab__ul__li {float:left;}
.sub-tab__ul__li__a {display:block;text-align:center;font-size:15px;font-weight: bold;background-color:fff;height:30px;line-height:30px;}
.sub-tab__ul__li.on .sub-tab__ul__li__a {background-color:#009590;color:#fff;}
*/
.color_1 {
  color: #e3006a;
}

.download {
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.download a {
  border: 1px solid;
  border-top-left-radius: 15px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 30px;
  background: #f36d20;
  color: #fff;
  min-width: 200px;
  line-height: 60px;
  height: 60px;
  text-align: center;
  display: inline-block;
  font-size: 21px;
  transform: scale(0.97);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 770px) {
  .download a {
    width: 70%;
    font-size: 18px;
  }
}

.download a:hover, .download a:focus {
  transform: scale(1);
  transition: all 0.3s ease 0s;
  background: #e71c0f;
}

.download a:nth-child(1) {
  padding-left: 50px;
}

@media only screen and (max-width: 770px) {
  .download a:nth-child(1) {
    padding-left: 30px;
  }
}

.download a:nth-child(1):after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -668px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 34px;
}

.download a:nth-child(2) {
  margin-left: 40px;
  padding-left: 50px;
}

@media only screen and (max-width: 770px) {
  .download a:nth-child(2) {
    margin-left: 0px;
    padding-left: 30px;
  }
}

.download a:nth-child(2):after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -752px;
  background-image: url("../images/sprite.png");
  width: 31px;
  min-width: 31px;
  height: 24px;
}

.greeting {
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
}

.greeting_wrap {
  overflow: hidden;
}

.greeting_wrap_left {
  float: left;
  width: 40%;
}

@media only screen and (max-width: 1024px) {
  .greeting_wrap_left {
    width: 100%;
    float: none;
    text-align: center;
  }
}

.greeting_wrap_right {
  float: right;
  width: 60%;
}

@media only screen and (max-width: 1024px) {
  .greeting_wrap_right {
    width: 100%;
    float: none;
  }
}

.greeting_wrap_right h2 {
  font-size: 15px;
  padding-bottom: 40px;
  padding-top: 30px;
  background-size: 100%;
  background-position: center;
  line-height: 1.5;
  font-weight: 600;
}

.greeting_wrap_right p {
  margin-bottom: 20px;
  color: #0e0e0e;
  font-weight: 400;
  line-height: 1.6;
  font-size: 14px;
}

.history_wrap_ul_li {
  padding: 8px 0 60px 120px;
  position: relative;
}

.history_wrap_ul_li b {
  position: absolute;
  left: 0;
  top: -4px;
  font-size: 21px;
  letter-spacing: -1px;
  font-weight: 700;
  color: #fa783c;
  font-size: 30px;
}

.history_wrap_ul_li span {
  display: inline-block;
  margin-right: 10px;
  font-size: 16px;
  color: #000;
  min-width: 60px;
}

.history_wrap_ul_li:before {
  display: block;
  position: absolute;
  left: 0;
  top: 12px;
  z-index: 2;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid #fa7b41;
  background: #fff;
  box-sizing: border-box;
  content: '';
  left: 85px;
}

.history_wrap_ul_li:after {
  content: '';
  background: #e0e0e0;
  width: 3px;
  height: 100%;
  position: absolute;
  top: 14px;
  left: 91px;
}

.history_wrap_ul_li:last-child:after {
  display: none;
}

.history_wrap_ul_li ul li {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.hservice_wrap {
  color: #484848;
  font-size: 16px;
}

.hservice_wrap_cont {
  margin-top: 50px;
  margin-bottom: 50px;
}

.ci_wrap_s1 {
  width: 500px;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (max-width: 550px) {
  .ci_wrap_s1 {
    width: 100%;
  }
}

.ci_wrap_s1_cont {
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 1.5;
}

.ci_wrap_s1_btn {
  overflow: hidden;
}

.ci_wrap_s1_btn a {
  border: 1px solid;
  border-top-left-radius: 15px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 30px;
  background: #f36d20;
  color: #fff;
  min-width: 200px;
  line-height: 50px;
  height: 50px;
  text-align: center;
  font-size: 21px;
  transform: scale(0.97);
  transition: all 0.3s ease 0s;
}

.ci_wrap_s1_btn a:hover, .ci_wrap_s1_btn a:focus {
  transform: scale(1);
  transition: all 0.3s ease 0s;
  background: #e71c0f;
}

@media only screen and (max-width: 550px) {
  .ci_wrap_s1_btn a {
    float: none  !important;
    display: block;
    font-size: 16px;
  }
}

.ci_wrap_s1_btn a:nth-child(1) {
  float: left;
}

.ci_wrap_s1_btn a:nth-child(2) {
  float: right;
}

.ci_wrap_s2 {
  overflow: hidden;
  margin-top: 100px;
}

.ci_wrap_s2_col2 {
  float: left;
  width: 50%;
}

@media only screen and (max-width: 770px) {
  .ci_wrap_s2_col2 {
    width: 100%;
    float: none;
    margin-top: 40px;
  }
}

.ci_wrap_s3 {
  margin-top: 100px;
}

@media only screen and (max-width: 770px) {
  .ci_wrap_s3 {
    margin-top: 40px;
  }
}

.ci_wrap_s4 {
  margin-top: 100px;
}

@media only screen and (max-width: 770px) {
  .ci_wrap_s4 {
    margin-top: 40px;
  }
}

.ci_wrap_s4_col2 {
  float: left;
  width: 50%;
}

@media only screen and (max-width: 770px) {
  .ci_wrap_s4_col2 {
    width: 100%;
    float: none;
    margin-top: 40px;
  }
}

.merchant_wrap_cont {
  margin-bottom: 50px;
  margin-top: 50px;
}

.merchant_wrap .basicList {
  margin-top: 50px;
}

.custom_wrap_cont {
  margin-bottom: 50px;
  margin-top: 50px;
}

.custom_wrap .basicList {
  margin-top: 50px;
}

.graduate_wrap_cont {
  margin-bottom: 50px;
  margin-top: 50px;
}

.graduate_wrap .basicList {
  margin-top: 50px;
}

.business_wrap_cont {
  margin-bottom: 50px;
  margin-top: 50px;
}

.business_wrap .basicList {
  margin-top: 50px;
}

.commercial_wrap {
  overflow: hidden;
  margin-bottom: 100px;
}

.commercial_wrap ul li {
  width: 33.33333%;
  float: left;
  display: table;
}

@media only screen and (max-width: 770px) {
  .commercial_wrap ul li {
    width: 50%;
  }
}

.commercial_wrap ul li a {
  display: table-cell;
  vertical-align: middle;
  height: 80px;
  line-height: 1.5;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #3b3d4a;
  color: #fff;
  font-weight: bold;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.commercial_wrap ul li a:hover, .commercial_wrap ul li a:focus {
  background: #e26223;
}

.se_1 > div {
  float: left;
  min-height: 250px;
}

@media (max-width: 768px) {
  .se_1 > div {
    width: 100% !important;
    float: none;
    padding-bottom: 30px;
    padding-top: 30px;
    border-bottom: 1px solid #ddd;
  }
  .se_1 > div div > div > div {
    margin: 0 auto;
  }
  .se_1 > div div > div {
    margin: 0 auto;
  }
}

.se_1 > div > div {
  margin: 0 auto;
}

.se_1 .col1 {
  width: 33.33%;
}

.se_1 .col2 {
  width: 33.33%;
}

.se_1 .col3 {
  width: 33.33%;
}

.se_2 > div {
  float: left;
  min-height: 250px;
}

@media (max-width: 768px) {
  .se_2 > div {
    width: 100% !important;
    float: none;
    padding-bottom: 30px;
    padding-top: 30px;
    border-bottom: 1px solid #ddd;
  }
  .se_2 > div div > div > div {
    margin: 0 auto;
  }
  .se_2 > div div > div {
    margin: 0 auto;
  }
}

.se_2 > div > div {
  margin: 0 auto;
}

.se_2 .img {
  width: 30%;
}

.se_2 .col1 {
  width: 70%;
}

.se_2 .col2 {
  width: 50%;
}

.se_2 .col3 {
  width: 50%;
}

.se_2 .col4 {
  width: 48%;
}

.se_3 > div {
  float: left;
  min-height: 250px;
}

@media (max-width: 768px) {
  .se_3 > div {
    width: 100% !important;
    float: none;
    padding-bottom: 30px;
    padding-top: 30px;
    border-bottom: 1px solid #ddd;
  }
  .se_3 > div div > div > div {
    margin: 0 auto;
  }
  .se_3 > div div > div {
    margin: 0 auto;
  }
}

.se_3 .col1 {
  width: 25%;
}

.se_3 .col2 {
  width: 25%;
}

.se_4 > div {
  float: left;
  min-height: 250px;
}

@media (max-width: 768px) {
  .se_4 > div {
    width: 100% !important;
    float: none;
    padding-bottom: 30px;
    padding-top: 30px;
    border-bottom: 1px solid #ddd;
  }
  .se_4 > div div > div > div {
    margin: 0 auto;
  }
  .se_4 > div div > div {
    margin: 0 auto;
  }
}

.se_4 .col1 {
  width: 50%;
}

.se_4 .col2 {
  width: 50%;
}

.se_5 > div {
  float: left;
  min-height: 250px;
}

@media (max-width: 768px) {
  .se_5 > div {
    width: 100% !important;
    float: none;
    padding-bottom: 30px;
    padding-top: 30px;
    border-bottom: 1px solid #ddd;
  }
  .se_5 > div div > div > div {
    margin: 0 auto;
  }
  .se_5 > div div > div {
    margin: 0 auto;
  }
}

.se_5 .col1 {
  width: 50%;
}

.se_5 .col2 {
  width: 50%;
}

.se_5 .col3 {
  width: 50%;
}

.se_5 .col4 {
  width: 50%;
}

.unit {
  font-size: 12px;
  margin-top: 5px;
}

.i_right span {
  margin-left: 10px;
}

.i_right span a {
  font-size: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -373px -189px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.i_right span a:hover, .i_right span a:focus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -374px -101px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.i_right:before {
  top: 10px !important;
}

.year {
  margin-bottom: 30px;
  position: relative;
}

@media only screen and (max-width: 770px) {
  .year {
    margin-top: 30px;
  }
}

.year_wrap_s1 {
  width: 100px;
}

@media only screen and (max-width: 770px) {
  .year_wrap_s1 {
    width: 48%;
  }
}

.year_wrap_s2 {
  width: 150px;
}

@media only screen and (max-width: 770px) {
  .year_wrap_s2 {
    width: 48%;
  }
}

.year_wrap_s3 {
  width: 100px;
}

@media only screen and (max-width: 770px) {
  .year_wrap_s3 {
    width: 48%;
  }
}

.year_wrap_s4 {
  width: 150px;
}

@media only screen and (max-width: 770px) {
  .year_wrap_s4 {
    width: 48%;
  }
}

.year_wrap_mo {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 24px;
  vertical-align: middle;
}

@media only screen and (max-width: 770px) {
  .year_wrap_mo {
    width: 95%;
    display: block;
    margin-left: 0;
  }
}

.year_text {
  margin-bottom: 30px;
}

.year_text_ul_li {
  float: left;
  width: 180px;
  border: 1px solid #999;
  margin-left: 10px;
  text-align: center;
  min-height: 35px;
  line-height: 35px;
}

@media only screen and (max-width: 770px) {
  .year_text_ul_li {
    width: 50%;
    box-sizing: border-box;
    margin-left: 0;
  }
  .year_text_ul_li:first-child {
    border-right: 0;
  }
  .year_text_ul_li:last-child {
    width: 100%;
    border-top: 0;
  }
}

.year_text_ul_li:first-child {
  margin-left: 0;
}

.year_text_s1 {
  padding-left: 15px;
  width: 130px;
}

@media only screen and (max-width: 770px) {
  .year_text_s1 {
    display: block;
    margin-bottom: 10px;
    width: 90%;
  }
}

.year_text_s2 {
  padding-left: 15px;
}

@media only screen and (max-width: 770px) {
  .year_text_s2 {
    display: block;
    margin-bottom: 10px;
    width: 90%;
  }
}

.year_text_s3 {
  padding-left: 15px;
}

@media only screen and (max-width: 770px) {
  .year_text_s3 {
    display: block;
    margin-bottom: 10px;
    width: 90%;
  }
}

.year_text input {
  min-height: 35px;
  border: 1px solid #999;
  margin-right: 5px;
}

.year select {
  border: 1px solid #999;
  font-family: inherit;
  padding-right: 30px;
  padding-left: 10px;
  min-height: 35px;
  background: url("../../resources/images/basic/select_arrow.png") no-repeat 95% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.year select::-ms-expand {
  display: none;
}

.year input::placeholder {
  color: #333;
}

.year input:focus {
  outline: none;
}

.bill {
  width: 90%;
  margin: 0 auto;
}

@media print {
  .bill {
    width: 100%;
  }
}

.bill .basicTable5 .bill_title {
  background: #e26223;
  color: #fff;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 700;
}

@media print {
  .bill .basicTable5 .bill_title {
    font-size: 18px;
  }
}

.bill .basicTable5 .name span {
  font-weight: bold;
  color: #e26223;
}

.bill .basicTable5 .list ul {
  margin-top: 10px;
}

.bill .basicTable5 .list li {
  margin-top: 3px;
}

.bill .basicTable5 .list span {
  color: #e26223;
}

.bill .basicTable5 .last_title {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 80px;
  text-align: center;
}

@media print {
  .bill .basicTable5 .last_title {
    font-size: 18px;
  }
}

.bill .basicTable5 .last_title img {
  margin-left: 10px;
}

.bill .basicTable5 .table_all {
  width: 95%;
  margin: 0 auto;
}

@media print {
  .bill .basicTable5 .table_all {
    width: 90% !important;
  }
}

@media (max-width: 768px) {
  .bill .basicTable5 .table_all {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .bill .basicTable5 .table_all .in_table {
    padding-top: 0;
  }
}

.bill .basicTable5 .table_all .in_table tr:nth-child(1) {
  border-top: 1px solid #333;
}

.bill .basicTable5 .table_all .in_table tr:nth-child(3) {
  border-top: 1px solid #333;
  margin-top: 30px;
}

.bill .basicTable5 .table_all .in_table tr:nth-child(5) {
  border-top: 1px solid #333;
  margin-top: 30px;
}

.bill .basicTable5 .table_all .in_table tr:nth-child(7) {
  border-top: 1px solid #333;
  margin-top: 30px;
}

.receipt {
  width: 90%;
  margin: 0 auto;
}

@media print {
  .receipt {
    width: 100%;
  }
}

.receipt .basicTable5 .bill_title {
  background: #e26223;
  color: #fff;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 700;
}

@media print {
  .receipt .basicTable5 .bill_title {
    font-size: 18px;
  }
}

.receipt .basicTable5 .bill_title span {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
}

.receipt .basicTable5 .name span {
  font-weight: bold;
  color: #e26223;
}

.receipt .basicTable5 .list ul {
  margin-top: 10px;
}

.receipt .basicTable5 .list li {
  margin-top: 3px;
}

.receipt .basicTable5 .list span {
  color: #e26223;
}

.receipt .basicTable5 .last_title {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 80px;
  text-align: center;
}

@media print {
  .receipt .basicTable5 .last_title {
    font-size: 18px;
  }
}

.receipt .basicTable5 .last_title img {
  margin-left: 10px;
}

.receipt .basicTable5 .receipt_wrap {
  width: 95%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .receipt .basicTable5 .receipt_wrap {
    width: 100%;
  }
}

.receipt .basicTable5 .receipt_wrap .receipt_wrap_total {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .receipt .basicTable5 .receipt_wrap .receipt_wrap_total {
    margin-bottom: 0;
  }
}

.receipt .basicTable5 .receipt_wrap .receipt_wrap_total span {
  color: #e26223;
  font-weight: 700;
}

@media print {
  .receipt .basicTable5 .receipt_wrap .in_table {
    width: 90% !important;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .receipt .basicTable5 .receipt_wrap .in_table {
    padding-top: 0;
  }
}

.receipt .basicTable5 .receipt_wrap .in_table tr td:nth-child(1) span.colors {
  color: #e26223;
  font-weight: 700;
}

@media (max-width: 768px) {
  .receipt .basicTable5 .receipt_wrap .in_table tr td:nth-child(1) {
    margin-top: 20px;
    border-top: 1px solid #999;
  }
}

.receipt .basicTable5 .receipt_wrap .in_table tr td:nth-child(4) span.colors {
  color: #e26223;
  font-weight: 700;
}

@media (max-width: 768px) {
  .receipt .basicTable5 .receipt_wrap .in_table tr td:nth-child(4) {
    margin-top: 20px;
    border-top: 1px solid #999;
  }
}

.receipt .basicTable5 .receipt_wrap .in_table .noclor tr td {
  color: #333;
}

.receipt .basicTable5 .text_day {
  text-align: center;
}

.receipt .basicTable5 .text_day .text {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 40px;
  margin-top: 50px;
}

.receipt .basicTable5 .text_day .day {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 40px;
}

.purport_wrap {
  margin-bottom: 50px;
}

@media only screen and (max-width: 1024px) {
  .purport_wrap_col2 {
    margin-bottom: 50px;
  }
}

.purport_wrap_col2.img {
  float: right;
  width: 35%;
  margin-left: 2%;
  margin-top: 40px;
}

@media only screen and (max-width: 1024px) {
  .purport_wrap_col2.img {
    float: none;
    width: 100%;
    text-align: center;
  }
}

.purport_wrap_col2.table {
  float: left;
  width: 60%;
}

@media only screen and (max-width: 1024px) {
  .purport_wrap_col2.table {
    float: none;
    width: 100%;
    text-align: center;
  }
}

.purport_wrap_col2_in_c {
  font-size: 11px;
  font-size: 1.1rem;
  text-align: left;
}

.purport_wrap_col4 {
  float: left;
  width: 50%;
}

@media only screen and (max-width: 770px) {
  .purport_wrap_col4 {
    width: 100%;
    margin-left: 0px;
  }
}

.purport_wrap_col4:nth-child(2n) .purport_wrap_col4_in {
  margin-left: 0;
}

.purport_wrap_col4_in {
  margin-left: 20px;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background-image: url("../images/site/purport_col4_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 770px) {
  .purport_wrap_col4_in {
    margin-left: 0;
  }
}

.purport_wrap_swot {
  background-image: url("../images/site/purport_07.png");
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 792px;
  padding-top: 25px;
}

@media (max-width: 880px) {
  .purport_wrap_swot {
    width: 100%;
    background: none;
  }
}

.purport_wrap_swot_col4 {
  width: 50%;
}

@media (max-width: 880px) {
  .purport_wrap_swot_col4 {
    width: 100% !important;
    margin-left: 0 !important;
    border-radius: 0 !important;
    border: 5px solid #9ee8ca !important;
    box-sizing: border-box;
    padding-top: 33px;
    padding-bottom: 30px !important;
    margin-top: 50px !important;
  }
  .purport_wrap_swot_col4 ul {
    padding-left: 30px !important;
  }
}

.purport_wrap_swot_col4:nth-child(1) {
  margin-left: 48.7%;
  border: 5px solid #b5bbef;
  border-left: 0;
  margin-top: -2px;
  padding-top: 31px;
  padding-bottom: 37px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.purport_wrap_swot_col4:nth-child(1) ul {
  padding-left: 100px;
}

.purport_wrap_swot_col4:nth-child(2) {
  border: 5px solid #9ee8ca;
  width: 50.3%;
  border-right: 0;
  padding-top: 34px;
  padding-bottom: 34px;
  margin-top: 35px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.purport_wrap_swot_col4:nth-child(2) ul {
  padding-left: 60px;
}

.purport_wrap_swot_col4:nth-child(3) {
  margin-left: 48.7%;
  margin-top: 35px;
  border: 5px solid #b5bbef;
  border-left: 0;
  padding-top: 22px;
  padding-bottom: 23px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.purport_wrap_swot_col4:nth-child(3) ul {
  padding-left: 100px;
}

.purport_wrap_swot_col4:nth-child(4) {
  border: 5px solid #9ee8ca;
  width: 50.3%;
  border-right: 0;
  padding-top: 36px;
  padding-bottom: 34px;
  margin-top: 34px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.purport_wrap_swot_col4:nth-child(4) ul {
  padding-left: 60px;
}

.purport_wrap_co2 {
  float: left;
  width: 50%;
}

@media (max-width: 900px) {
  .purport_wrap_co2 {
    width: 100%;
  }
}

.purport_wrap_co2_title {
  font-size: 21px;
  font-weight: 700;
  background: #c3c1b8;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}

.purport_wrap_co2_in {
  position: relative;
}

.purport_wrap_co2_in:last-child .purport_wrap_co2_in_cont {
  background: no-repeat;
}

.purport_wrap_co2_in_img {
  position: absolute;
  left: 0;
  top: 40%;
  transform: translateY(-40%);
}

@media (max-width: 900px) {
  .purport_wrap_co2_in_img {
    width: 100%;
    position: relative;
    top: 0;
    transform: translateY(0);
    text-align: center;
    margin-bottom: 20px;
  }
}

.purport_wrap_co2_in_cont {
  margin-left: 140px;
  background-image: url("../images/site/swot_li_bg.png");
  background-position: -12px bottom;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 11px;
}

@media (max-width: 900px) {
  .purport_wrap_co2_in_cont {
    width: 100%;
    background: none;
    margin-left: 0;
  }
}

.purport_wrap_co2_in_cont ul {
  background-color: #86e2bd;
  padding: 20px 15px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

@media (max-width: 900px) {
  .purport_wrap_co2_in_cont ul {
    border-radius: 20px;
  }
}

.purport_wrap_co2_in_cont ul li {
  font-size: 13px;
  font-size: 1.3rem;
}

.purport_wrap_co2_in_cont ul li::before {
  top: 8px;
}

.purport_wrap_co2_t2 {
  position: relative;
  width: 90%;
  margin: 0 auto;
  background-image: url("../images/site/swot_li_bg_t2.png");
  background-position: -6px bottom;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 17px;
}

@media (max-width: 900px) {
  .purport_wrap_co2_t2 {
    width: 100%;
    background: none;
  }
}

.purport_wrap_co2_t2:last-child {
  background: none;
}

.purport_wrap_co2_t2_title {
  position: absolute;
  top: 35%;
  transform: translateY(-35%);
  width: 140px;
}

@media (max-width: 900px) {
  .purport_wrap_co2_t2_title {
    width: 100%;
    position: relative;
    top: 0;
    transform: translateY(0);
    text-align: center;
  }
}

.purport_wrap_co2_t2_title h2 {
  text-align: center;
}

@media (max-width: 900px) {
  .purport_wrap_co2_t2_title h2 {
    border: 3px solid #86e2bd;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 700;
  }
}

.purport_wrap_co2_t2_cont {
  background-color: #86e2bd;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 20px 20px 20px 140px;
}

@media (max-width: 900px) {
  .purport_wrap_co2_t2_cont {
    padding: 20px;
  }
}

.purport_wrap_co2_t2_cont ul li {
  font-size: 13px;
  font-size: 1.3rem;
}

.purport_wrap_co2_t2_cont ul li::before {
  top: 8px;
}

.purport:last-child .basicTable4 tr:last-child td {
  vertical-align: top;
}

.purport:last-child .basicTable4 tr:last-child td .basicList1 li::before {
  top: -7px;
  background: none;
  content: '·';
  font-size: 21px;
}

.organ_img {
  margin-bottom: 100px;
}

@media (max-width: 960px) {
  .alley_wrap {
    overflow: scroll;
  }
}

.alley_wrap_img {
  background-image: url("../../resources/images/site/alley_map.png");
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 912px;
  margin-bottom: 100px;
  width: 920px;
  display: block;
}

@media (max-width: 960px) {
  .alley_wrap_img {
    display: none;
  }
}

.alley_wrap_img_ping {
  position: relative;
}

.alley_wrap_img_ping_ul_li {
  position: absolute;
  text-align: center;
}

.alley_wrap_img_ping_ul_li.on .alley_wrap_img_ping_ul_li_block {
  visibility: visible;
}

.alley_wrap_img_ping_ul_li_block {
  visibility: hidden;
  padding: 10px;
  background: #fff;
  border: 3px solid #0079b7;
  position: relative;
  text-align: left;
  z-index: 10;
}

.alley_wrap_img_ping_ul_li_block::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  right: 50%;
  transform: translateX(50%);
  border: 9px solid;
  border-color: #0079b7 transparent transparent transparent;
  z-index: 8;
}

.alley_wrap_img_ping_ul_li_block h2 {
  font-size: 14px;
  color: #0079b7;
  font-weight: 700;
}

.alley_wrap_img_ping_ul_li_block p {
  font-size: 12px;
  color: #333;
}

.alley_wrap_img_ping_ul_li_a {
  display: inline-block;
  margin-top: 15px;
  z-index: 9;
  position: relative;
  width: 16px;
  height: 15px;
  color: #fff;
  background-image: url("../images/site/map_ping.png");
  background-repeat: no-repeat;
  line-height: 28px;
  text-align: center;
  font-size: 0;
}

.alley_wrap_img_ping_ul_li:nth-child(1) {
  left: 442px;
  top: 124px;
}

.alley_wrap_img_ping_ul_li:nth-child(2) {
  left: 399px;
  top: 138px;
}

.alley_wrap_img_ping_ul_li:nth-child(3) {
  left: 504px;
  top: 103px;
}

.alley_wrap_img_ping_ul_li:nth-child(4) {
  left: 486px;
  top: 78px;
}

.alley_wrap_img_ping_ul_li:nth-child(5) {
  left: 504px;
  top: 94px;
}

.alley_wrap_img_ping_ul_li:nth-child(6) {
  left: 461px;
  top: 80px;
}

.alley_wrap_img_ping_ul_li:nth-child(7) {
  left: 442px;
  top: 100px;
}

.alley_wrap_img_ping_ul_li:nth-child(8) {
  left: 429px;
  top: -46px;
}

.alley_wrap_img_ping_ul_li:nth-child(9) {
  left: 609px;
  top: -36px;
}

.alley_wrap_img_ping_ul_li:nth-child(10) {
  left: 523px;
  top: -34px;
}

.alley_wrap_img_ping_ul_li:nth-child(11) {
  left: 540px;
  top: -61px;
}

.alley_wrap_img_ping_ul_li:nth-child(12) {
  left: 50px;
  top: 221px;
}

.alley_wrap_img_ping_ul_li:nth-child(13) {
  left: 554px;
  top: -64px;
}

.alley_wrap_img_ping_ul_li:nth-child(14) {
  left: 540px;
  top: 121px;
}

.alley_wrap_img_ping_ul_li:nth-child(15) {
  left: 624px;
  top: 57px;
}

.alley_wrap_img_ping_ul_li:nth-child(16) {
  left: 636px;
  top: 129px;
}

.alley_wrap_img_ping_ul_li:nth-child(17) {
  left: 381px;
  top: 173px;
}

.alley_wrap_img_ping_ul_li:nth-child(18) {
  left: 652px;
  top: -34px;
}

.alley_wrap_img_ping_ul_li:nth-child(19) {
  left: 565px;
  top: 108px;
}

.alley_wrap_img_ping_ul_li:nth-child(20) {
  left: 452px;
  top: 149px;
}

.alley_wrap_img_ping_ul_li:nth-child(21) {
  left: 550px;
  top: 121px;
}

.alley_wrap_img_ping_ul_li:nth-child(22) {
  left: 400px;
  top: 243px;
}

.alley_wrap_img_ping_ul_li:nth-child(23) {
  left: 550px;
  top: 243px;
}

.alley_wrap_img_ping_ul_li:nth-child(24) {
  left: 550px;
  top: 121px;
}

.alley_wrap_img_ping_ul_li:nth-child(25) {
  left: 355px;
  top: 759px;
}

.alley_wrap_img_ping_ul_li:nth-child(26) {
  left: 368px;
  top: 736px;
}

.alley_wrap_img_ping_ul_li:nth-child(27) {
  left: 448px;
  top: 483px;
}

.alley_wrap_img_ping_ul_li:nth-child(28) {
  left: 481px;
  top: 507px;
}

.alley_wrap_img_ping_ul_li:nth-child(29) {
  left: 369px;
  top: 517px;
}

.alley_wrap_img_ping_ul_li:nth-child(30) {
  left: 452px;
  top: 337px;
}

.alley_wrap_img_ping_ul_li:nth-child(31) {
  left: 492px;
  top: 387px;
}

.alley_wrap_img_ping_ul_li:nth-child(32) {
  left: 333px;
  top: 615px;
}

.alley_wrap_img_ping_ul_li:nth-child(33) {
  left: 343px;
  top: 645px;
}

.alley_wrap_img_ping_ul_li:nth-child(34) {
  left: 67px;
  top: 443px;
}

.alley_wrap_img_ping_ul_li:nth-child(35) {
  left: 268px;
  top: 466px;
}

.alley_wrap_img_ping_ul_li:nth-child(36) {
  left: 291px;
  top: 443px;
}

.alley_table thead tr th {
  background: #5e5e5e;
  color: #fff;
}

.alley_table tbody tr td {
  background: #fff;
  transition: 0.3s all;
}

.alley_table tbody tr.on td {
  background: #ffe8bd;
  transition: 0.3s all;
}

@media (max-width: 960px) {
  .alley2_wrap {
    overflow: scroll;
  }
}

.alley2_wrap_img {
  background-image: url("../../resources/images/site/alley_map.png");
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 912px;
  margin-bottom: 100px;
  width: 920px;
  display: block;
}

@media (max-width: 960px) {
  .alley2_wrap_img {
    display: none;
  }
}

.alley2_wrap_img_ping {
  position: relative;
}

.alley2_wrap_img_ping_ul_li {
  position: absolute;
  text-align: center;
}

.alley2_wrap_img_ping_ul_li.on .alley2_wrap_img_ping_ul_li_block {
  visibility: visible;
}

.alley2_wrap_img_ping_ul_li_block {
  visibility: hidden;
  padding: 10px;
  background: #fff;
  border: 3px solid #0079b7;
  position: relative;
  text-align: left;
  z-index: 10;
}

.alley2_wrap_img_ping_ul_li_block::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  right: 50%;
  transform: translateX(50%);
  border: 9px solid;
  border-color: #0079b7 transparent transparent transparent;
  z-index: 8;
}

.alley2_wrap_img_ping_ul_li_block h2 {
  font-size: 14px;
  color: #0079b7;
  font-weight: 700;
}

.alley2_wrap_img_ping_ul_li_block p {
  font-size: 12px;
  color: #333;
}

.alley2_wrap_img_ping_ul_li_a {
  display: inline-block;
  margin-top: 15px;
  z-index: 9;
  position: relative;
  width: 16px;
  height: 15px;
  clear: both;
  color: #fff;
  background-image: url("../images/site/map_ping_2.png");
  background-repeat: no-repeat;
  line-height: 28px;
  text-align: center;
  font-size: 0;
  text-indent: -9999px;
}

.alley2_wrap_img_ping_ul_li_a:focus {
  border: 2px solid gold;
}

.alley2_wrap_img_ping_ul_li:nth-child(1) {
  left: 520px;
  top: 105px;
}

.alley2_wrap_img_ping_ul_li:nth-child(2) {
  left: 387px;
  top: 105px;
}

.alley2_wrap_img_ping_ul_li:nth-child(3) {
  left: 576px;
  top: 91px;
}

.alley2_wrap_img_ping_ul_li:nth-child(4) {
  left: 551px;
  top: 151px;
}

.alley2_wrap_img_ping_ul_li:nth-child(5) {
  left: 355px;
  top: 189px;
}

.alley2_wrap_img_ping_ul_li:nth-child(6) {
  left: 380px;
  top: 178px;
}

.alley2_wrap_img_ping_ul_li:nth-child(7) {
  left: 622px;
  top: 25px;
}

.alley2_wrap_img_ping_ul_li:nth-child(8) {
  left: 619px;
  top: -0px;
}

.alley2_wrap_img_ping_ul_li:nth-child(9) {
  left: 472px;
  top: 151px;
}

.alley2_wrap_img_ping_ul_li:nth-child(10) {
  left: 616px;
  top: 58px;
}

.alley2_wrap_img_ping_ul_li:nth-child(11) {
  left: 495px;
  top: 141px;
}

.alley2_wrap_img_ping_ul_li:nth-child(12) {
  left: 391px;
  top: 195px;
}

.alley2_wrap_img_ping_ul_li:nth-child(13) {
  left: 332px;
  top: 733px;
}

.alley2_wrap_img_ping_ul_li:nth-child(14) {
  left: 453px;
  top: 571px;
}

.alley2_wrap_img_ping_ul_li:nth-child(15) {
  left: 333px;
  top: 512px;
}

.alley2_wrap_img_ping_ul_li:nth-child(16) {
  left: 333px;
  top: 512px;
}

.alley2_wrap_img_ping_ul_li:nth-child(17) {
  left: 379px;
  top: 564px;
}

.alley2_wrap_img_ping_ul_li:nth-child(18) {
  left: 364px;
  top: 564px;
}

.alley2_wrap_img_ping_ul_li:nth-child(19) {
  left: 355px;
  top: 550px;
}

.alley2_wrap_img_ping_ul_li:nth-child(20) {
  left: 417px;
  top: 532px;
}

.alley2_wrap_img_ping_ul_li:nth-child(21) {
  left: 495px;
  top: 474px;
}

.alley2_wrap_img_ping_ul_li:nth-child(22) {
  left: 437px;
  top: 540px;
}

.alley2_wrap_img_ping_ul_li:nth-child(23) {
  left: 470px;
  top: 554px;
}

.alley2_wrap_img_ping_ul_li:nth-child(24) {
  left: 490px;
  top: 570px;
}

.alley2_wrap_img_ping_ul_li:nth-child(25) {
  left: 448px;
  top: 574px;
}

.alley2_wrap_img_ping_ul_li:nth-child(26) {
  left: 429px;
  top: 595px;
}

.alley2_wrap_img_ping_ul_li:nth-child(27) {
  left: 358px;
  top: 550px;
  display: none;
}

.alley2_table thead tr th {
  background: #5e5e5e;
  color: #fff;
}

.alley2_table tbody tr td {
  background: #fff;
  transition: 0.3s all;
}

.alley2_table tbody tr.on td {
  background: #ffe8bd;
  transition: 0.3s all;
}

@media (max-width: 960px) {
  .alley3_wrap {
    overflow: scroll;
  }
}

.alley3_wrap_img {
  background-image: url("../../resources/images/site/alley_map.png");
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 912px;
  margin-bottom: 100px;
  width: 920px;
  display: block;
}

@media (max-width: 960px) {
  .alley3_wrap_img {
    display: none;
  }
}

.alley3_wrap_img_ping {
  position: relative;
}

.alley3_wrap_img_ping_ul_li {
  position: absolute;
  text-align: center;
}

.alley3_wrap_img_ping_ul_li.on .alley3_wrap_img_ping_ul_li_block {
  visibility: visible;
}

.alley3_wrap_img_ping_ul_li_block {
  visibility: hidden;
  padding: 10px;
  background: #fff;
  border: 3px solid #0079b7;
  position: relative;
  text-align: left;
  z-index: 10;
}

.alley3_wrap_img_ping_ul_li_block::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  right: 50%;
  transform: translateX(50%);
  border: 9px solid;
  border-color: #0079b7 transparent transparent transparent;
  z-index: 8;
}

.alley3_wrap_img_ping_ul_li_block h2 {
  font-size: 14px;
  color: #0079b7;
  font-weight: 700;
}

.alley3_wrap_img_ping_ul_li_block p {
  font-size: 12px;
  color: #333;
}

.alley3_wrap_img_ping_ul_li_a {
  display: inline-block;
  margin-top: 15px;
  z-index: 9;
  position: relative;
  width: 16px;
  height: 15px;
  clear: both;
  color: #fff;
  background-image: url("../images/site/map_ping_3.png");
  background-repeat: no-repeat;
  line-height: 28px;
  text-align: center;
  font-size: 0;
  text-indent: -9999px;
}

.alley3_wrap_img_ping_ul_li:nth-child(1) {
  left: 440px;
  top: 100px;
}

.alley3_wrap_img_ping_ul_li:nth-child(2) {
  left: 423px;
  top: 110px;
}

.alley3_wrap_img_ping_ul_li:nth-child(3) {
  left: 341px;
  top: 192px;
}

.alley3_table thead tr th {
  background: #5e5e5e;
  color: #fff;
}

.alley3_table tbody tr td {
  background: #fff;
  transition: 0.3s all;
}

.alley3_table tbody tr.on td {
  background: #ffe8bd;
  transition: 0.3s all;
}

.alley_wrap_img_mo {
  display: none;
}

@media (max-width: 960px) {
  .alley_wrap_img_mo {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.rent_table .red {
  color: red;
  margin-left: 5px;
}

.rent_table .next_go {
  text-align: center;
}

.rent_table .basicTable2 {
  border: 0;
}

.rent_table_input {
  border: 1px solid #d8d8d8;
}

.rent_table_textarea {
  border: 1px solid #d8d8d8;
  width: 90%;
  height: 200px;
}

.last_rent {
  text-align: center;
  border: 1px solid #d8d8d8;
  padding: 20px;
}

.last_rent_img {
  margin-top: 30px;
  margin-bottom: 30px;
}

.last_rent_cont {
  margin-bottom: 20px;
  font-size: 18px;
  color: #484848;
}

.last_rent_bt .btn3 {
  font-size: 16px;
  padding: 10px 60px;
  margin: 0 auto;
}

.comm_list {
  line-height: 1.6;
}

.comm_list_box1 {
  padding: 40px;
  border: 3px dotted #ddd;
  line-height: 1.8;
  margin-bottom: 40px;
}

.comm_list_info h2 {
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 21px;
  font-size: 2.1rem;
  color: #e26223;
}

.comm_list_info h3 {
  font-weight: bold;
  margin-bottom: 20px;
  color: #e26223;
}

.comm_list_info p {
  margin-bottom: 20px;
}

.comm_list_info .basicList1 > li {
  margin-bottom: 10px;
}

.comm_list_info ul > li {
  margin-bottom: 10px;
}

.comm_list a:focus {
  border: 2px solid gold;
  text-decoration: underline;
}

.rent_guid_wrap {
  margin-bottom: 80px;
}

.rent_guid_list {
  text-align: center;
}

.rent_guid_list > li {
  float: left;
  text-align: center;
  width: 25%;
}

@media only screen and (max-width: 770px) {
  .rent_guid_list > li {
    width: 50%;
    margin-bottom: 30px;
  }
}

.rent_place_list {
  margin-bottom: 80px;
}

.rent_place_list > ul > li {
  float: left;
  width: 33.33333%;
  display: table;
}

.rent_place_list > ul > li.on a {
  background: #e95025;
  transition: 0.3s all;
}

.rent_place_list > ul > li a {
  display: table-cell;
  vertical-align: middle;
  height: 80px;
  line-height: 1.5;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #3b3d4a;
  color: #fff;
  font-weight: bold;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.rent_place_map {
  margin-bottom: 60px;
}

.online_print {
  width: 98%;
  margin: 0 auto;
}

@media print {
  .online_print {
    width: 21cm;
    min-height: 29.7cm;
    padding: 2cm;
    margin: 0 auto;
    background: #fff;
  }
}

.online_print h2 {
  font-size: 24px;
  border-top: 2px solid #c1c1c1;
  border-bottom: 2px solid #c1c1c1;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-weight: bold;
}

.online_print h2 span {
  margin-right: 10px;
}

.online_print .d_1 {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 770px) {
  .online_print .d_1 .align_right {
    display: block;
    text-align: center;
  }
}

.online_print .d_2 {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 770px) {
  .online_print .d_2 .align_right {
    display: block;
    text-align: center;
  }
}

@media only screen and (max-width: 770px) {
  .online_print .d_mo_title {
    width: 100%;
    display: block;
  }
}

@media only screen and (max-width: 770px) {
  .online_print .d_mo {
    display: inline-block;
    width: 49%;
  }
}

.online_print .chk_box {
  border: 1px solid #ddd;
}

@media print {
  .online_print .chk_box {
    border: none;
    color: #333;
  }
}

.online_print .chk_box.num {
  width: 25px;
}

@media print {
  .online_print .chk_box.num {
    border: none;
    color: #333;
  }
}

.online_print .chk_box.text {
  width: 80%;
}

@media print {
  .online_print .chk_box.text {
    border: none;
    color: #333;
  }
}

.online_print .f_l {
  font-size: 21px;
  font-weight: bold;
}

.online_print .apply-box__ok {
  margin: 0 auto;
  display: block;
  max-width: 150px;
}

@media print {
  .online_print .apply-box__ok {
    display: none;
  }
}

.online_print .basicTable6 tr.left-td td, .online_print .basicTable6 td.left-td {
  padding: 10px 10px;
}

.online_print2 {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 0 auto;
  background: #fff;
}

.online_print2 h2 {
  font-size: 24px;
  border-top: 2px solid #c1c1c1;
  border-bottom: 2px solid #c1c1c1;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.online_print2 h2 span {
  margin-right: 10px;
}

.online_print2 p.las_p {
  margin-bottom: 10px;
  text-decoration: underline;
}

.online_print_t2 {
  padding: 30px;
}

.online_print_t2_title h2 {
  font-size: 24px;
  border-top: 2px solid #c1c1c1;
  border-bottom: 2px solid #c1c1c1;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-weight: bold;
}

.online_print_t2_cont h3 {
  font-weight: bold;
}

.online_print_t2_cont p {
  margin-bottom: 20px;
}

.online_print_t2_cont ul li {
  margin-bottom: 20px;
}

.online_print_t3 {
  padding: 30px;
}

.online_print_t3_title h2 {
  font-size: 24px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-weight: bold;
}

.online_print_t3_cont {
  border: 1.5px solid;
  padding: 20px;
}

.online_print_t3_cont .list_input {
  margin-bottom: 10px;
}

.online_print_t3_cont .list_input label {
  width: 55px;
  display: inline-block;
}

.online_print_t3_cont .list_input input {
  border: 1px solid #ddd;
}

@media print {
  .online_print_t3_cont .list_input input {
    display: none;
  }
}

.online_print_t3_cont h3 {
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.online_print_t3_cont h2 {
  font-size: 24px;
  font-weight: bold;
}

.online_print_t3_cont p {
  margin-bottom: 20px;
}

.online_print_t3_cont ul li {
  margin-bottom: 20px;
}

/* 구글챠트 대체 상권현황 탭 */
.subTab__list {
  margin-bottom: 100px;
}

.subTab__list.type1 li {
  width: 50%;
  float: left;
  display: table;
}

@media only screen and (max-width: 770px) {
  .subTab__list.type1 li {
    width: 50%;
  }
}

.subTab__list.type2 li {
  width: 33.33333%;
  float: left;
  display: table;
}

@media only screen and (max-width: 770px) {
  .subTab__list.type2 li {
    width: 50%;
  }
}

.subTab__list.type3 li {
  width: 25%;
  float: left;
  display: table;
}

@media only screen and (max-width: 770px) {
  .subTab__list.type3 li {
    width: 50%;
  }
}

.subTab__list li a {
  display: table-cell;
  vertical-align: middle;
  height: 80px;
  line-height: 1.5;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #3b3d4a;
  color: #fff;
  font-weight: bold;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.subTab__list li a:hover, .subTab__list li a:focus {
  background: #e26223;
}

.subTab__list li.on a {
  background: #e26223;
}

.subTab__cont__box {
  display: none;
}

.subTab__cont__box.on {
  display: block;
}

.jiwon__top {
  padding-bottom: 40px;
  margin-bottom: 70px;
  border-bottom: 1px solid #333;
}

.jiwon__top:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .jiwon__top {
  display: inline-block;
}

.jiwon__top__image {
  float: left;
  width: 35%;
}

.jiwon__top__image img {
  max-width: 100%;
}

.jiwon__top__right {
  float: right;
  width: 60%;
}

.jiwon__top__right__title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.jiwon__top__right__info {
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.8;
}

.jiwon__top__right__ul {
  font-size: 16px;
  font-size: 1.6rem;
}

.jiwon__top__right__ul__li {
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  padding-left: 100px;
}

.jiwon__top__right__ul__li__tit {
  position: absolute;
  top: 18px;
  left: 0;
}

.jiwon__top__right__ul__li__cont__table {
  margin-bottom: 0px !important;
}

.jiwon__top__right__ul__li__cont__table {
  margin-bottom: 10px;
  border-top: 2px solid #4b4b4b;
  border-bottom: 1px solid #ddd;
}

.jiwon__top__right__ul__li__cont__table table {
  border-collapse: collapse;
  width: 100%;
  margin-left: -1px;
}

@media only screen and (max-width: 550px) {
  .jiwon__top__right__ul__li__cont__table table {
    font-size: 8px;
  }
}

.jiwon__top__right__ul__li__cont__table th {
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 13px 0;
  background-color: #f6f6f6;
  text-align: center;
  color: #000;
}

.jiwon__top__right__ul__li__cont__table tbody th {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

@media only screen and (max-width: 550px) {
  .jiwon__top__right__ul__li__cont__table tbody th {
    padding: 3px 3px;
  }
}

.jiwon__top__right__ul__li__cont__table td {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  vertical-align: middle;
}

@media only screen and (max-width: 550px) {
  .jiwon__top__right__ul__li__cont__table td {
    padding: 3px 3px;
  }
}

.jiwon__top__right__ul__li__cont__table td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.jiwon__top__right__ul__li__cont__table td.sbj-line > a:hover, .jiwon__top__right__ul__li__cont__table td.sbj-line > a:focus {
  text-decoration: underline;
}

.jiwon__top__right__ul__li__cont__table td > a:hover, .jiwon__top__right__ul__li__cont__table td > a:focus {
  text-decoration: underline;
}

.jiwon__top__right__ul__li__cont__table td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .jiwon__top__right__ul__li__cont__table.res {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .jiwon__top__right__ul__li__cont__table.res table {
    min-width: auto;
    margin-left: 0px;
  }
  .jiwon__top__right__ul__li__cont__table.res colgroup {
    display: none;
  }
  .jiwon__top__right__ul__li__cont__table.res thead {
    display: none;
  }
  .jiwon__top__right__ul__li__cont__table.res tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .jiwon__top__right__ul__li__cont__table.res th {
    display: none;
  }
  .jiwon__top__right__ul__li__cont__table.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .jiwon__top__right__ul__li__cont__table.res td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .jiwon__top__right__ul__li__cont__table.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
  .jiwon__top__right__ul__li__cont__table.res td.align-left {
    padding-left: 10px;
  }
}

.jiwon__mid {
  padding-bottom: 60px;
}

.jiwon__mid__table {
  margin-bottom: 10px;
  border-top: 2px solid #4b4b4b;
  border-bottom: 1px solid #ddd;
}

.jiwon__mid__table table {
  border-collapse: collapse;
  width: 100%;
  margin-left: -1px;
}

@media only screen and (max-width: 550px) {
  .jiwon__mid__table table {
    font-size: 8px;
  }
}

.jiwon__mid__table th {
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 13px 0;
  background-color: #f6f6f6;
  text-align: center;
  color: #000;
}

.jiwon__mid__table tbody th {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

@media only screen and (max-width: 550px) {
  .jiwon__mid__table tbody th {
    padding: 3px 3px;
  }
}

.jiwon__mid__table td {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  vertical-align: middle;
}

@media only screen and (max-width: 550px) {
  .jiwon__mid__table td {
    padding: 3px 3px;
  }
}

.jiwon__mid__table td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.jiwon__mid__table td.sbj-line > a:hover, .jiwon__mid__table td.sbj-line > a:focus {
  text-decoration: underline;
}

.jiwon__mid__table td > a:hover, .jiwon__mid__table td > a:focus {
  text-decoration: underline;
}

.jiwon__mid__table td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .jiwon__mid__table.res {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .jiwon__mid__table.res table {
    min-width: auto;
    margin-left: 0px;
  }
  .jiwon__mid__table.res colgroup {
    display: none;
  }
  .jiwon__mid__table.res thead {
    display: none;
  }
  .jiwon__mid__table.res tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .jiwon__mid__table.res th {
    display: none;
  }
  .jiwon__mid__table.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .jiwon__mid__table.res td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .jiwon__mid__table.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
  .jiwon__mid__table.res td.align-left {
    padding-left: 10px;
  }
}

.jiwon__bot__table {
  margin-bottom: 10px;
  border-top: 2px solid #4b4b4b;
  border-bottom: 1px solid #ddd;
}

.jiwon__bot__table table {
  border-collapse: collapse;
  width: 100%;
  margin-left: -1px;
}

@media only screen and (max-width: 550px) {
  .jiwon__bot__table table {
    font-size: 8px;
  }
}

.jiwon__bot__table th {
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 13px 0;
  background-color: #f6f6f6;
  text-align: center;
  color: #000;
}

.jiwon__bot__table tbody th {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

@media only screen and (max-width: 550px) {
  .jiwon__bot__table tbody th {
    padding: 3px 3px;
  }
}

.jiwon__bot__table td {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  vertical-align: middle;
}

@media only screen and (max-width: 550px) {
  .jiwon__bot__table td {
    padding: 3px 3px;
  }
}

.jiwon__bot__table td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.jiwon__bot__table td.sbj-line > a:hover, .jiwon__bot__table td.sbj-line > a:focus {
  text-decoration: underline;
}

.jiwon__bot__table td > a:hover, .jiwon__bot__table td > a:focus {
  text-decoration: underline;
}

.jiwon__bot__table td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .jiwon__bot__table.res {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .jiwon__bot__table.res table {
    min-width: auto;
    margin-left: 0px;
  }
  .jiwon__bot__table.res colgroup {
    display: none;
  }
  .jiwon__bot__table.res thead {
    display: none;
  }
  .jiwon__bot__table.res tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .jiwon__bot__table.res th {
    display: none;
  }
  .jiwon__bot__table.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .jiwon__bot__table.res td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .jiwon__bot__table.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
  .jiwon__bot__table.res td.align-left {
    padding-left: 10px;
  }
}

.jiwon-btn {
  display: inline-block;
  color: #fff;
  width: 140px;
  background-color: #3a8dcb;
  font-size: 14px;
  height: 33px;
  text-align: center;
  line-height: 33px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.jiwon-btn:hover, .jiwon-btn:focus {
  background-color: #51a5e3;
  color: gold;
}

.h3-type3 {
  border-left: 5px solid #3a8dcb;
  font-weight: bold;
  font-size: 28px;
  height: 23px;
  line-height: 19px;
  padding-left: 10px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .h3-type3 {
    font-size: 22px;
  }
}

/*지난사업결과 버튼*/
.go-result {
  position: relative;
  text-align: right;
}

.go-result__btn {
  position: absolute;
  top: -40px;
  right: 0;
}

.go-result__btn span {
  display: inline-block;
  padding-bottom: 0px;
  border-bottom: 1px solid #000;
  font-weight: bold;
}

.go-result__btn:before {
  margin-right: 4px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -673px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 16px;
}

@media screen and (min-width: 1px) and (max-width: 800px) {
  .jiwon__top__image {
    float: none;
    width: auto;
    text-align: center;
    max-width: 360px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .jiwon__top__right {
    float: none;
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .jiwon__top__right__ul__li {
    padding-left: 0px;
  }
  .jiwon__top__right__ul__li:last-child {
    border-bottom: 0px;
  }
  .jiwon__top__right__ul__li__tit {
    position: relative;
    top: 0;
  }
}

/*스쿨락 지원서*/
.support-box {
  text-align: center;
}

.support-box__tit {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
}

.support-box__tit:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px 0px;
  background-image: url("../images/sprite.png");
  width: 19px;
  min-width: 19px;
  height: 19px;
}

@media (max-width: 768px) {
  .support-box__tit {
    font-size: 18px;
  }
}

.support-box__ok {
  margin: 0 auto;
}

.support-box__ok {
  display: block;
  color: #fff;
  background-color: #e26223;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
}

.support-box__ok:hover, .support-box__ok:focus {
  background-color: #ff9a5c;
  color: #fff;
}

.support-box__table {
  margin-bottom: 30px;
}

.support-box__table table {
  border-collapse: collapse;
  width: 100%;
}

.support-box__table thead th {
  border-bottom: 0px;
}

.support-box__table th {
  border: 3px solid #fff;
  padding: 13px 0;
  background-color: #e26223;
  color: #fff;
}

.support-box__table td {
  border: 3px solid #fff;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff4ec;
}

.support-box__table tr.left-td td,
.support-box__table td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .support-box__table {
    padding-top: 10px;
  }
  .support-box__table colgroup {
    display: none;
  }
  .support-box__table thead {
    display: none;
  }
  .support-box__table tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #fff;
  }
  .support-box__table th {
    display: none;
  }
  .support-box__table td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #fff;
  }
  .support-box__table td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.support-box__table td {
  text-align: left;
  padding-left: 20px;
}

.support-box__table__input {
  border: 1px solid #000;
  height: 60px;
  width: 50%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.support-box__table__input.low-height {
  height: 35px !important;
  line-height: 35px !important;
}

.support-box__table__input2 {
  border: 1px solid #000;
  height: 60px;
  width: 80%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.support-box__table__input3 {
  border: 1px solid #000;
  height: 60px;
  width: 95%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.support-box__table__input-sp {
  border: 0px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-bottom: 1px solid #ddd !important;
}

.support-box__table__input-sp {
  border: 1px solid #000;
  height: 60px;
  width: 70px;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.support-box__table__input-sp2 {
  border: 0px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-bottom: 1px solid #ddd !important;
}

.support-box__table__input-sp2 {
  border: 1px solid #000;
  height: 60px;
  width: 100px;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.support-box__table__input-sp3 {
  border: 0px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-bottom: 1px solid #ddd !important;
}

.support-box__table__input-sp3 {
  border: 1px solid #000;
  height: 60px;
  width: 130px;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.support-box__table__input-small {
  height: 35px !important;
  line-height: 35px !important;
  margin-bottom: 3px;
}

.support-box__table__input-small {
  border: 1px solid #000;
  height: 60px;
  width: 95%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.support-box__table__input-songs {
  height: 35px !important;
  line-height: 35px !important;
  margin-bottom: 3px;
  margin-right: 20px;
}

.support-box__table__input-songs {
  border: 1px solid #000;
  height: 60px;
  width: 35%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.support-box__table__textarea {
  border: 1px solid #000;
  height: 130px;
  width: 80%;
  line-height: 1.6;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.support-box__table__textarea2 {
  height: 60px !important;
}

.support-box__table__textarea2 {
  border: 1px solid #000;
  height: 130px;
  width: 95%;
  line-height: 1.6;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.support-box__table__select {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.support-box__table__select:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.support-box__table__select select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 330px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.support-box__table__select select::-ms-expand {
  display: none;
}

.lte-ie9 .support-box__table__select select {
  width: 125%;
  vertical-align: top;
}

.support-box__table__select select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .support-box__table__select select {
    min-width: 300px;
  }
}

.support-box__table .songs-box {
  margin-bottom: 10px;
}

.support-box__table .mobile-br {
  display: none;
}

.support-box__table .underline {
  text-decoration: underline;
}

@media (max-width: 1100px) {
  .support-box__table .songs-box {
    margin-bottom: 20px;
  }
  .support-box__table .mobile-br {
    display: block;
  }
  .support-box__table .mobile-block {
    display: block;
  }
}

@media (max-width: 800px) {
  .support-box__table td:before {
    display: block;
    content: attr(data-th) "  ";
    margin-bottom: 10px;
  }
  .support-box__table__input {
    border: 1px solid #000;
    height: 60px;
    width: 95%;
    line-height: 60px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .support-box__table__input2 {
    border: 1px solid #000;
    height: 60px;
    width: 95%;
    line-height: 60px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .support-box__table__textarea {
    border: 1px solid #000;
    height: 130px;
    width: 95%;
    line-height: 1.6;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    box-sizing: border-box;
  }
}

.academy__top {
  padding-bottom: 40px;
  margin-bottom: 70px;
  border-bottom: 1px solid #333;
}

.academy__top:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .academy__top {
  display: inline-block;
}

.academy__top__image {
  float: left;
  width: 30%;
}

.academy__top__image img {
  max-width: 100%;
}

.academy__top__right {
  float: right;
  width: 65%;
}

.academy__top__right__title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.academy__top__right__ul {
  font-size: 16px;
  font-size: 1.6rem;
}

.academy__top__right__ul__li {
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  padding-left: 100px;
}

.academy__top__right__ul__li__tit {
  position: absolute;
  top: 18px;
  left: 0;
}

.academy__top__right__ul__li__cont__table {
  margin-bottom: 0px !important;
}

.academy__top__right__ul__li__cont__table {
  margin-bottom: 10px;
  border-top: 2px solid #4b4b4b;
  border-bottom: 1px solid #ddd;
}

.academy__top__right__ul__li__cont__table table {
  border-collapse: collapse;
  width: 100%;
  margin-left: -1px;
}

@media only screen and (max-width: 550px) {
  .academy__top__right__ul__li__cont__table table {
    font-size: 8px;
  }
}

.academy__top__right__ul__li__cont__table th {
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 13px 0;
  background-color: #f6f6f6;
  text-align: center;
  color: #000;
}

.academy__top__right__ul__li__cont__table tbody th {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

@media only screen and (max-width: 550px) {
  .academy__top__right__ul__li__cont__table tbody th {
    padding: 3px 3px;
  }
}

.academy__top__right__ul__li__cont__table td {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  vertical-align: middle;
}

@media only screen and (max-width: 550px) {
  .academy__top__right__ul__li__cont__table td {
    padding: 3px 3px;
  }
}

.academy__top__right__ul__li__cont__table td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.academy__top__right__ul__li__cont__table td.sbj-line > a:hover, .academy__top__right__ul__li__cont__table td.sbj-line > a:focus {
  text-decoration: underline;
}

.academy__top__right__ul__li__cont__table td > a:hover, .academy__top__right__ul__li__cont__table td > a:focus {
  text-decoration: underline;
}

.academy__top__right__ul__li__cont__table td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .academy__top__right__ul__li__cont__table.res {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .academy__top__right__ul__li__cont__table.res table {
    min-width: auto;
    margin-left: 0px;
  }
  .academy__top__right__ul__li__cont__table.res colgroup {
    display: none;
  }
  .academy__top__right__ul__li__cont__table.res thead {
    display: none;
  }
  .academy__top__right__ul__li__cont__table.res tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .academy__top__right__ul__li__cont__table.res th {
    display: none;
  }
  .academy__top__right__ul__li__cont__table.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .academy__top__right__ul__li__cont__table.res td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .academy__top__right__ul__li__cont__table.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
  .academy__top__right__ul__li__cont__table.res td.align-left {
    padding-left: 10px;
  }
}

.academy__mid {
  border-top: 1px solid #000;
  padding-bottom: 60px;
}

.academy__mid__list {
  min-height: 110px;
  border-bottom: 1px solid #000;
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;
}

.academy__mid__list:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .academy__mid__list {
  display: inline-block;
}

.academy__mid__list__num {
  font-size: 25px;
  float: left;
  height: 110px;
  display: block;
  line-height: 110px;
  padding-left: 30px;
  padding-right: 70px;
  border-right: 1px solid #dcdcdc;
  margin-right: 50px;
}

.academy__mid__list__img {
  float: left;
  width: 115px;
  height: 115px;
  border-radius: 90px;
  overflow: hidden;
}

.academy__mid__list__img img {
  width: 100%;
}

.academy__mid__list__cont {
  float: left;
  margin-left: 35px;
  max-width: 30%;
}

.academy__mid__list__cont__tit {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.academy__mid__list__cont__detail {
  font-size: 16px;
}

.academy__mid__list__total {
  position: absolute;
  top: 50%;
  right: 180px;
  transform: translateY(-50%);
  display: inline-block;
  color: #000;
  border: 1px solid #000;
  width: 200px;
  background-color: #fff;
  font-size: 18px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 120px;
  box-sizing: border-box;
  text-align: center;
}

.academy__mid__list__btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.academy__mid__list__btn {
  display: inline-block;
  color: #fff;
  width: 150px;
  background-color: #000;
  font-size: 18px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.academy__mid__list__btn:hover, .academy__mid__list__btn:focus {
  background-color: #666;
  color: gold;
}

.academy__bot__table {
  margin-bottom: 10px;
  border-top: 2px solid #4b4b4b;
  border-bottom: 1px solid #ddd;
}

.academy__bot__table table {
  border-collapse: collapse;
  width: 100%;
  margin-left: -1px;
}

@media only screen and (max-width: 550px) {
  .academy__bot__table table {
    font-size: 8px;
  }
}

.academy__bot__table th {
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 13px 0;
  background-color: #f6f6f6;
  text-align: center;
  color: #000;
}

.academy__bot__table tbody th {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

@media only screen and (max-width: 550px) {
  .academy__bot__table tbody th {
    padding: 3px 3px;
  }
}

.academy__bot__table td {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  vertical-align: middle;
}

@media only screen and (max-width: 550px) {
  .academy__bot__table td {
    padding: 3px 3px;
  }
}

.academy__bot__table td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.academy__bot__table td.sbj-line > a:hover, .academy__bot__table td.sbj-line > a:focus {
  text-decoration: underline;
}

.academy__bot__table td > a:hover, .academy__bot__table td > a:focus {
  text-decoration: underline;
}

.academy__bot__table td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .academy__bot__table.res {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .academy__bot__table.res table {
    min-width: auto;
    margin-left: 0px;
  }
  .academy__bot__table.res colgroup {
    display: none;
  }
  .academy__bot__table.res thead {
    display: none;
  }
  .academy__bot__table.res tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .academy__bot__table.res th {
    display: none;
  }
  .academy__bot__table.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .academy__bot__table.res td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .academy__bot__table.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
  .academy__bot__table.res td.align-left {
    padding-left: 10px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .academy__mid__list__total {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    right: 155px;
  }
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .academy__mid__list {
    padding: 20px 10px;
  }
  .academy__mid__list__num {
    float: none;
    height: auto;
    line-height: 1.6;
    border-right: 0px;
    position: absolute;
    top: 10px;
    left: 20px;
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }
  .academy__mid__list__img {
    margin-bottom: 20px;
    padding-left: 80px;
    margin-right: 20px;
  }
  .academy__mid__list__cont {
    margin-bottom: 20px;
    margin-left: 0px;
  }
  .academy__mid__list__total {
    position: relative;
    top: 0;
    right: 0;
    transform: translateY(0);
    height: 40px;
    line-height: 40px;
    width: 100%;
    margin-bottom: 5px;
  }
  .academy__mid__list__btn {
    position: relative;
    top: 0;
    right: 0;
    transform: translateY(0);
    height: 40px;
    line-height: 40px;
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 800px) {
  .academy__top__image {
    float: none;
    width: auto;
    max-width: 360px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .academy__top__right {
    float: none;
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .academy__top__right__ul__li {
    padding-left: 0px;
  }
  .academy__top__right__ul__li:last-child {
    border-bottom: 0px;
  }
  .academy__top__right__ul__li__tit {
    position: relative;
    top: 0;
  }
}

/*
	할인선택페이지 부분
*/
.academy-detail {
  position: relative;
  text-align: center;
}

.academy-detail__title {
  text-align: left;
  background-color: #ec6159;
  height: 70px;
  line-height: 70px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding-left: 20px;
}

.academy-detail__reback {
  position: absolute;
  top: 14px;
  right: 20px;
  color: #fff;
  border: 1px solid #fff;
  display: block;
  padding: 9px 10px;
  border-radius: 5px;
  font-size: 13px;
  white-space: nowrap;
}

.academy-detail__reback span {
  padding-left: 30px;
  display: block;
  position: relative;
}

.academy-detail__reback span:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -542px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 16px;
}

.academy-detail__reback:hover, .academy-detail__reback:focus {
  text-decoration: underline;
}

.academy-detail__wrap {
  text-align: left;
  background-color: #fff;
  min-height: 380px;
  border-left: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  box-sizing: border-box;
}

.academy-detail__wrap__choose {
  padding: 20px;
  font-size: 17px;
  border-bottom: 1px solid #ddd;
}

.academy-detail__wrap__choose__pos {
  color: #000;
}

.academy-detail__wrap__choose__num {
  color: #ec6159;
}

.academy-detail__wrap__ul__li {
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.academy-detail__wrap__ul__li div {
  min-height: 38px;
  line-height: 38px;
  vertical-align: top;
}

.academy-detail__wrap__ul__li__item1 {
  flex: 1;
  text-align: center;
}

.academy-detail__wrap__ul__li__item2 {
  flex: 2;
  text-align: left;
  padding-left: 20px;
}

.academy-detail__wrap__ul__li__item3 {
  flex: 1;
  text-align: center;
}

.academy-detail__wrap__ul__li__item4 {
  flex: 1;
  text-align: center;
}

.academy-detail__wrap__ul__li__item4__selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.academy-detail__wrap__ul__li__item4__selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.academy-detail__wrap__ul__li__item4__selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 90px;
  vertical-align: middle;
  color: #000;
  font-size: 14px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.academy-detail__wrap__ul__li__item4__selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .academy-detail__wrap__ul__li__item4__selectbox select {
  width: 125%;
  vertical-align: top;
}

.academy-detail__wrap__ul__li__item4__selectbox select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .academy-detail__wrap__ul__li__item4__selectbox select {
    min-width: 60px;
  }
}

.lte-ie9 .academy-detail__wrap__ul__li:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .lte-ie9 .academy-detail__wrap__ul__li {
  display: inline-block;
}

.lte-ie9 .academy-detail__wrap__ul__li__item1 {
  width: 20%;
  float: left;
}

.lte-ie9 .academy-detail__wrap__ul__li__item2 {
  width: 40%;
  float: left;
  padding-left: 0;
}

.lte-ie9 .academy-detail__wrap__ul__li__item3 {
  width: 20%;
  float: left;
}

.lte-ie9 .academy-detail__wrap__ul__li__item4 {
  width: 20%;
  float: left;
}

@media screen and (min-width: 1px) and (max-width: 880px) {
  .academy-detail__wrap__ul__li {
    flex-wrap: wrap;
  }
  .academy-detail__wrap__ul__li div {
    line-height: 1.6;
  }
  .academy-detail__wrap__ul__li__item1 {
    flex: 1;
    min-width: 300px;
  }
  .academy-detail__wrap__ul__li__item2 {
    flex: 2;
    min-width: 300px;
    text-align: center;
    padding-left: 0;
  }
  .academy-detail__wrap__ul__li__item3 {
    flex: 2;
    min-width: 300px;
  }
  .academy-detail__wrap__ul__li__item4 {
    flex: 1;
    min-width: 300px;
  }
  .academy-detail__wrap__ul__li__item4__selectbox {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    text-align: left;
    border: 1px solid #000;
  }
  .academy-detail__wrap__ul__li__item4__selectbox:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    content: ' ';
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -119px -891px;
    background-image: url("../images/sprite.png");
    width: 9px;
    min-width: 9px;
    height: 5px;
  }
  .academy-detail__wrap__ul__li__item4__selectbox select {
    width: 100%;
    background-color: transparent;
    appearance: none;
    min-width: 290px;
    vertical-align: middle;
    color: #000;
    font-size: 14px;
    height: 38px;
    margin-top: 0;
    padding: 2px 2px 2px 10px;
    position: relative;
    border: 0px;
    /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /*ie10~11 에서 셀렉트 기본디자인 무력화*/
  }
  .academy-detail__wrap__ul__li__item4__selectbox select::-ms-expand {
    display: none;
  }
  .lte-ie9 .academy-detail__wrap__ul__li__item4__selectbox select {
    width: 125%;
    vertical-align: top;
  }
  .academy-detail__wrap__ul__li__item4__selectbox select option {
    font-family: "Nanum Gothic";
  }
}

@media screen and (min-width: 1px) and (max-width: 880px) and (min-width: 1px) and (max-width: 768px) {
  .academy-detail__wrap__ul__li__item4__selectbox select {
    min-width: 260px;
  }
}

.academy-detail__wrap__total__tit {
  padding: 20px;
  padding-top: 50px;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.academy-detail__wrap__total__h4 {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  width: 20%;
  box-sizing: border-box;
  padding-left: 40px;
}

.academy-detail__wrap__total__info {
  display: inline-block;
  width: 75%;
  box-sizing: border-box;
}

.academy-detail__wrap__total__info__seat {
  font-size: 0;
}

.academy-detail__wrap__total__info__seat span {
  margin-right: 5px;
  font-size: 14px;
  display: inline-block;
  padding: 2px 5px;
  color: #000;
  border: 1px solid #ddd;
}

.academy-detail__wrap__total__info__cancel {
  color: #da4453;
}

.academy-detail__wrap__total__result {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
  padding: 20px;
  padding-right: 40px;
  font-size: 22px;
  font-weight: bold;
  color: #000;
  min-height: 50px;
  line-height: 50px;
  position: relative;
  text-align: right;
}

.academy-detail__wrap__total__result__text {
  padding-right: 20px;
}

.academy-detail__wrap__total__result__num {
  color: #000;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .academy-detail__wrap__total__h4 {
    width: auto;
    display: block;
    padding-left: 20px;
  }
  .academy-detail__wrap__total__info {
    width: auto;
    display: block;
    padding-left: 30px;
    margin-bottom: 10px;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 5px;
  }
  .academy-detail__wrap__total__info:last-child {
    border-bottom: 0px;
  }
  .academy-detail__wrap__total__result {
    border-top: 0px;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .academy-detail__reback {
    right: 10px;
  }
}

/*신청*/
/*스쿨락 지원서*/
.academy-form {
  text-align: center;
}

.academy-form__tit {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
}

.academy-form__tit:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px 0px;
  background-image: url("../images/sprite.png");
  width: 19px;
  min-width: 19px;
  height: 19px;
}

@media (max-width: 768px) {
  .academy-form__tit {
    font-size: 18px;
  }
}

.academy-form__ok {
  margin: 0 auto;
}

.academy-form__ok {
  display: block;
  color: #fff;
  background-color: #e26223;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
}

.academy-form__ok:hover, .academy-form__ok:focus {
  background-color: #ff9a5c;
  color: #fff;
}

.academy-form__table {
  margin-bottom: 30px;
}

.academy-form__table table {
  border-collapse: collapse;
  width: 100%;
}

.academy-form__table thead th {
  border-bottom: 0px;
}

.academy-form__table th {
  border: 3px solid #fff;
  padding: 13px 0;
  background-color: #e26223;
  color: #fff;
}

.academy-form__table td {
  border: 3px solid #fff;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff4ec;
}

.academy-form__table tr.left-td td,
.academy-form__table td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .academy-form__table {
    padding-top: 10px;
  }
  .academy-form__table colgroup {
    display: none;
  }
  .academy-form__table thead {
    display: none;
  }
  .academy-form__table tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #fff;
  }
  .academy-form__table th {
    display: none;
  }
  .academy-form__table td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #fff;
  }
  .academy-form__table td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.academy-form__table td {
  text-align: left;
  padding-left: 20px;
}

.academy-form__table__input {
  border: 1px solid #000;
  height: 60px;
  width: 50%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.academy-form__table__input2 {
  border: 1px solid #000;
  height: 60px;
  width: 80%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.academy-form__table__textarea {
  border: 1px solid #000;
  height: 130px;
  width: 80%;
  line-height: 1.6;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.academy-form__table__select.type1 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.academy-form__table__select.type1:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.academy-form__table__select.type1 select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 110px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.academy-form__table__select.type1 select::-ms-expand {
  display: none;
}

.lte-ie9 .academy-form__table__select.type1 select {
  width: 125%;
  vertical-align: top;
}

.academy-form__table__select.type1 select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .academy-form__table__select.type1 select {
    min-width: 80px;
  }
}

.academy-form__table__select.type2 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.academy-form__table__select.type2:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.academy-form__table__select.type2 select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 90px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.academy-form__table__select.type2 select::-ms-expand {
  display: none;
}

.lte-ie9 .academy-form__table__select.type2 select {
  width: 125%;
  vertical-align: top;
}

.academy-form__table__select.type2 select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .academy-form__table__select.type2 select {
    min-width: 60px;
  }
}

.academy-form__table__select.type3 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.academy-form__table__select.type3:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.academy-form__table__select.type3 select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 90px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.academy-form__table__select.type3 select::-ms-expand {
  display: none;
}

.lte-ie9 .academy-form__table__select.type3 select {
  width: 125%;
  vertical-align: top;
}

.academy-form__table__select.type3 select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .academy-form__table__select.type3 select {
    min-width: 60px;
  }
}

@media (max-width: 800px) {
  .academy-form__table td:before {
    display: block;
    content: attr(data-th) "  ";
    margin-bottom: 10px;
  }
  .academy-form__table__input {
    border: 1px solid #000;
    height: 60px;
    width: 95%;
    line-height: 60px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .academy-form__table__input2 {
    border: 1px solid #000;
    height: 60px;
    width: 95%;
    line-height: 60px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .academy-form__table__textarea {
    border: 1px solid #000;
    height: 130px;
    width: 95%;
    line-height: 1.6;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    box-sizing: border-box;
  }
}

.show-list__top {
  position: relative;
  min-height: 120px;
  margin-bottom: 50px;
  background-color: #f7f7f7;
}

.show-list__top:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .show-list__top {
  display: inline-block;
}

.show-list__top__info {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.show-list__top__info strong {
  color: #da4453;
}

.show-list__top__search {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.show-list__top__search__selectbox {
  margin-right: 30px;
}

.show-list__top__search__selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.show-list__top__search__selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.show-list__top__search__selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 200px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.show-list__top__search__selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .show-list__top__search__selectbox select {
  width: 125%;
  vertical-align: top;
}

.show-list__top__search__selectbox select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .show-list__top__search__selectbox select {
    min-width: 170px;
  }
}

.show-list__top__search__selectbox label {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  height: 0;
  overflow: hidden;
}

.show-list__top__search__inputbox {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.show-list__top__search__inputbox input {
  width: 300px;
  border: 1px solid #000;
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
}

.show-list__top__search__inputbox a {
  font-size: 0;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -421px -550px;
  background-image: url("../images/sprite.png");
  width: 50px;
  min-width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
}

.show-list__top__search__inputbox a:focus {
  border: 2px solid gold;
  box-sizing: border-box;
}

.show-list__top.ver2 {
  background-color: #fff;
}

.ver2 .show-list__top__info {
  left: 0;
}

.ver2 .show-list__top__search {
  right: 0;
}

.ver2 .show-list__top__search .show-list__top__search__selectbox {
  border: 1px solid #dbdbdb;
}

.ver2 .show-list__top__search input {
  border: 1px solid #dbdbdb;
}

/* show list top */
@media screen and (min-width: 1px) and (max-width: 1200px) {
  .show-list__top__search__selectbox {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    text-align: left;
    border: 1px solid #000;
  }
  .show-list__top__search__selectbox:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    content: ' ';
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -119px -891px;
    background-image: url("../images/sprite.png");
    width: 9px;
    min-width: 9px;
    height: 5px;
  }
  .show-list__top__search__selectbox select {
    width: 100%;
    background-color: transparent;
    appearance: none;
    min-width: 130px;
    vertical-align: middle;
    color: #000;
    font-size: 16px;
    height: 58px;
    margin-top: 0;
    padding: 2px 2px 2px 10px;
    position: relative;
    border: 0px;
    /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /*ie10~11 에서 셀렉트 기본디자인 무력화*/
  }
  .show-list__top__search__selectbox select::-ms-expand {
    display: none;
  }
  .lte-ie9 .show-list__top__search__selectbox select {
    width: 125%;
    vertical-align: top;
  }
  .show-list__top__search__selectbox select option {
    font-family: "Nanum Gothic";
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) and (min-width: 1px) and (max-width: 768px) {
  .show-list__top__search__selectbox select {
    min-width: 100px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .show-list__top__search__inputbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .show-list__top__search__inputbox input {
    width: 200px;
    border: 1px solid #000;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
  }
  .show-list__top__search__inputbox a {
    font-size: 0;
    text-indent: -9999px;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -421px -550px;
    background-image: url("../images/sprite.png");
    width: 50px;
    min-width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
  }
  .show-list__top__search__inputbox a:focus {
    border: 2px solid gold;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .show-list__top {
    padding: 20px;
  }
  .show-list__top__info {
    transform: translateY(0);
    top: 0;
    left: 0;
    position: relative;
    margin-bottom: 10px;
  }
  .show-list__top__search {
    transform: translateY(0);
    top: 0;
    right: 0;
    position: relative;
  }
  .show-list__top__search__selectbox {
    margin-bottom: 10px;
  }
  .show-list__top__search__selectbox {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    text-align: left;
    border: 1px solid #000;
  }
  .show-list__top__search__selectbox:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    content: ' ';
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -119px -891px;
    background-image: url("../images/sprite.png");
    width: 9px;
    min-width: 9px;
    height: 5px;
  }
  .show-list__top__search__selectbox select {
    width: 100%;
    background-color: transparent;
    appearance: none;
    min-width: 200px;
    vertical-align: middle;
    color: #000;
    font-size: 16px;
    height: 58px;
    margin-top: 0;
    padding: 2px 2px 2px 10px;
    position: relative;
    border: 0px;
    /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /*ie10~11 에서 셀렉트 기본디자인 무력화*/
  }
  .show-list__top__search__selectbox select::-ms-expand {
    display: none;
  }
  .lte-ie9 .show-list__top__search__selectbox select {
    width: 125%;
    vertical-align: top;
  }
  .show-list__top__search__selectbox select option {
    font-family: "Nanum Gothic";
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) and (min-width: 1px) and (max-width: 768px) {
  .show-list__top__search__selectbox select {
    min-width: 170px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .show-list__top__search__inputbox {
    margin-bottom: 10px;
  }
  .show-list__top__search__inputbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .show-list__top__search__inputbox input {
    width: 280px;
    border: 1px solid #000;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
  }
  .show-list__top__search__inputbox a {
    font-size: 0;
    text-indent: -9999px;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -421px -550px;
    background-image: url("../images/sprite.png");
    width: 50px;
    min-width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
  }
  .show-list__top__search__inputbox a:focus {
    border: 2px solid gold;
    box-sizing: border-box;
  }
  .show-list__top.ver2 {
    margin-bottom: 0px;
  }
}

.show-list__mid__item {
  border: 1px solid #dbdbdb;
  position: relative;
  margin-bottom: 20px;
}

.show-list__mid__item:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .show-list__mid__item {
  display: inline-block;
}

.show-list__mid__item__img {
  float: left;
}

.show-list__mid__item__img img {
  max-width: 210px;
}

.show-list__mid__item__cont {
  float: left;
  margin-left: 50px;
  padding-top: 50px;
}

.show-list__mid__item__cont__tit {
  font-size: 25px;
}

.show-list__mid__item__cont__date {
  color: #999;
  margin-bottom: 40px;
}

.show-list__mid__item__btn-box {
  position: absolute;
  bottom: 40px;
  right: 30px;
}

.buy-btn-ok {
  display: inline-block;
  color: #fff;
  background-color: #ec2533;
  border-radius: 30px;
  font-size: 15px;
  padding: 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
}

.buy-btn-ok:hover, .buy-btn-ok:focus {
  background-color: #c7131f;
  color: gold;
}

.buy-btn-detail {
  display: inline-block;
  color: #fff;
  background-color: #333333;
  border-radius: 30px;
  font-size: 15px;
  padding: 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
}

.buy-btn-detail:hover, .buy-btn-detail:focus {
  background-color: #000;
  color: gold;
}

.dl_type:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .dl_type {
  display: inline-block;
}

.dl_type dt {
  display: block;
  min-width: 80px;
  font-weight: bold;
  float: left;
  margin-bottom: 10px;
}

.dl_type dd {
  display: block;
  margin-bottom: 10px;
}

/* show list mid */
@media screen and (min-width: 1px) and (max-width: 1200px) {
  .show-list__mid__item__cont {
    padding-top: 20px;
    margin-left: 20px;
  }
  .show-list__mid__item__cont__date {
    margin-bottom: 20px;
  }
  .show-list__mid__item__btn-box {
    bottom: 10px;
    right: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .show-list__mid__item__img {
    margin: 20px;
  }
  .show-list__mid__item__cont {
    margin-bottom: 20px;
  }
  .show-list__mid__item__btn-box {
    position: relative;
    width: 100%;
    bottom: 0;
    right: 0;
    float: left;
    text-align: center;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 430px) {
  .show-list__mid__item__img {
    margin: 0;
    padding-top: 20px;
    width: 100%;
    text-align: center;
  }
  .show-list__mid__item__img img {
    max-width: 100%;
  }
}

/* view */
.show-view__tit {
  font-size: 30px;
  padding-bottom: 40px;
  border-bottom: 2px solid #000;
  margin-bottom: 50px;
  font-weight: bold;
}

.show-view__top {
  margin-bottom: 50px;
}

.show-view__top:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .show-view__top {
  display: inline-block;
}

.show-view__top__imgbox {
  width: 35%;
  float: left;
}

.show-view__top__cont {
  width: 60%;
  float: right;
}

.show-view__top__cont__ul {
  padding-bottom: 30px;
}

.show-view__top__cont__ul__li {
  margin-bottom: 20px;
  position: relative;
}

.show-view__top__cont__ul__li__sub {
  position: absolute;
  top: 0;
  left: 0;
}

.show-view__top__cont__ul__li__result {
  padding-left: 100px;
}

.show-view__top__cont__ul__li__result__cost {
  background-color: #f6f6f6;
  padding: 10px 20px;
}

.show-view__top__cont__ul__li__result__cost .cost-length {
  color: #da4453;
  font-weight: bold;
}

.show-view__top__cont__ul__li__result__cost .cost-detail {
  border: 1px solid #000;
  display: inline-block;
  background-color: #fff;
  padding-left: 7px;
  padding-right: 7px;
  margin-left: 10px;
}

.show-view__top__cont__ul__li__result__cost .cost-detail:hover, .show-view__top__cont__ul__li__result__cost .cost-detail:focus {
  border-color: #ec7d2c;
}

.show-view__top__cont__ul__li__result__cost.type2 {
  border-top: 1px solid #eeeeee;
  font-size: 12px;
  font-size: 1.2rem;
}

.show-view__top__cont__btnbox {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
}

.show-view__top__cont__btnbox__btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.show-view__top__cont__btnbox__btn {
  display: inline-block;
  color: #fff;
  width: 170px;
  background-color: #f43142;
  font-size: 19px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.show-view__top__cont__btnbox__btn:hover, .show-view__top__cont__btnbox__btn:focus {
  background-color: #ff0016;
  color: gold;
}

.show-view__info__h3 {
  padding-top: 20px;
  padding-bottom: 20px;
  display: inline-block;
  border-top: 3px solid #ec7d2c;
  text-align: center;
  width: 180px;
  font-size: 18px;
  font-weight: bold;
}

.show-view__info__in {
  padding-top: 40px;
  border-top: 2px solid #000;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .show-view__top__imgbox {
    width: 70%;
    float: none;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .show-view__top__cont {
    width: 100%;
    float: none;
    margin: 0 auto;
  }
  .show-view__top__cont__ul__li__result {
    padding-left: 80px;
  }
  .show-view__top__cont__ul__li__result__cost .cost-detail {
    margin-left: 0;
  }
}

.yeyak-first {
  position: relative;
  text-align: center;
  width: 88%;
}

.yeyak-first__title {
  text-align: left;
  background-color: #e26223;
  height: 70px;
  line-height: 70px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding-left: 20px;
}

.yeyak-first__reback {
  position: absolute;
  top: 14px;
  right: 20px;
  color: #fff;
  border: 1px solid #fff;
  display: block;
  padding: 9px 10px;
  border-radius: 5px;
  font-size: 13px;
  white-space: nowrap;
}

.yeyak-first__reback span {
  padding-left: 30px;
  display: block;
  position: relative;
}

.yeyak-first__reback span:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -542px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 16px;
}

.yeyak-first__reback:hover, .yeyak-first__reback:focus {
  text-decoration: underline;
}

.yeyak-first__wrap {
  text-align: left;
  background-color: #fbfbfb;
  min-height: 580px;
  border-left: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  box-sizing: border-box;
  padding: 15px 5px;
}

.yeyak-first__wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .yeyak-first__wrap {
  display: inline-block;
}

.yeyak-first__wrap__cal {
  float: left;
  position: relative;
  width: 57%;
  box-sizing: border-box;
  padding: 0 40px 0 0;
  border-right: 1px solid #ddd;
}

.yeyak-first__wrap__cal__psb {
  margin-left: 15px;
  padding-top: 20px;
}

.yeyak-first__wrap__cal__psb span {
  display: inline-block;
  background-color: #ffcaa0;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin-right: 5px;
}

.yeyak-first__wrap__cal__date {
  font-size: 30px;
  margin-left: 20px;
  font-weight: 800;
  margin-bottom: 30px;
}

.yeyak-first__wrap__cal__prev {
  position: absolute;
  top: 10px;
  right: 100px;
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  border: 1px solid #e26223;
}

.yeyak-first__wrap__cal__prev:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -68px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translateY(-50%);
}

.yeyak-first__wrap__cal__prev:focus {
  border: 1px solid gold;
}

.yeyak-first__wrap__cal__next {
  position: absolute;
  top: 10px;
  right: 60px;
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  border: 1px solid #e26223;
}

.yeyak-first__wrap__cal__next:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px 0px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 18px;
  position: absolute;
  top: 50%;
  right: 35%;
  transform: translateY(-50%);
}

.yeyak-first__wrap__cal__next:focus {
  border: 1px solid gold;
}

.yeyak-first__wrap__cal table {
  width: 100%;
  border-collapse: collapse;
  box-sizing: border-box;
  text-align: center;
}

.yeyak-first__wrap__cal table th {
  color: #bbb;
  height: 54px;
  vertical-align: middle;
}

.yeyak-first__wrap__cal table td {
  font-weight: bold;
  font-size: 14px;
  height: 54px;
  vertical-align: middle;
  padding: 10px 0;
  color: #bbb;
}

.yeyak-first__wrap__cal table td.sun {
  color: #bbb;
}

.yeyak-first__wrap__cal table td a {
  display: block;
  height: 54px;
  line-height: 54px;
  margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid #fff;
  margin-left: 1px;
}

.yeyak-first__wrap__cal table td a.today.posb {
  color: #fff;
  background-color: #ffcaa0;
  border: 1px solid #ffcaa0;
}

.yeyak-first__wrap__cal table td a.posb {
  border: 1px solid #ffcaa0;
  background-color: #ffcaa0;
  color: #fff;
}

.yeyak-first__wrap__cal table td a.selected {
  background-color: #fff !important;
  color: #333 !important;
  border: 1px solid red;
}

.yeyak-first__wrap__cal table td a.on {
  color: #000;
  background-color: #fff;
  border: 1px solid #ec6159;
}

.ver-show .yeyak-first__wrap__cal table td a.on.posb {
  background-color: #f0afb5;
  border: 1px solid #f0afb5;
}

.yeyak-first__wrap__cal table td a:hover, .yeyak-first__wrap__cal table td a:focus {
  color: #fff;
  background-color: #ffcaa0;
  border: 1px solid #ffcaa0;
}

.yeyak-first__wrap__detail {
  width: 40%;
  float: right;
  box-sizing: border-box;
}

.yeyak-first__wrap__detail:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .yeyak-first__wrap__detail {
  display: inline-block;
}

.yeyak-first__wrap__detail__time {
  display: block;
  border: 1px solid #bbb;
  color: #666;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.yeyak-first__wrap__detail__time:hover, .yeyak-first__wrap__detail__time:focus {
  /*text-decoration: underline;*/
}

.yeyak-first__wrap__detail__time.on {
  background-color: #ededed;
}

.yeyak-first__wrap__detail__time2 {
  display: block;
  border: 1px solid #bbb;
  color: #666;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.yeyak-first__wrap__detail__time2:hover, .yeyak-first__wrap__detail__time2:focus {
  /*text-decoration: underline;*/
}

.yeyak-first__wrap__detail__time2.on {
  background-color: #4a90e2;
  border: 1px solid #4a90e2;
  color: #fff;
}

.yeyak-first__wrap__detail__time3 {
  display: block;
  border: 1px solid #bbb;
  color: #666;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  float: none;
  width: 90%;
  margin-left: 2.5%;
}

.yeyak-first__wrap__detail__time3:hover, .yeyak-first__wrap__detail__time3:focus {
  /*text-decoration: underline;*/
}

.yeyak-first__wrap__detail__time3.on {
  background-color: #db4421;
  border: 1px solid #db4421;
  font-weight: bold;
  color: #fff;
}

@media screen and (min-width: 1px) and (max-width: 880px) {
  .yeyak-first__wrap__cal {
    width: 100%;
    margin-bottom: 30px;
    border-right: 0px;
    padding-right: 0;
  }
  .yeyak-first__wrap__cal__date {
    margin-left: 0;
    text-align: center;
  }
  .yeyak-first__wrap__cal__prev {
    right: unset;
    left: 0px;
    top: 5px;
  }
  .yeyak-first__wrap__cal__next {
    right: 0px;
    top: 5px;
  }
  .yeyak-first__wrap__detail {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
  }
  .yeyak-first__wrap__detail__time {
    flex: 1;
    margin-left: 5px;
    margin-right: 5px;
    min-width: 100px;
  }
  .yeyak-first__wrap__detail__time2 {
    flex: 1;
    margin-left: 5px;
    margin-right: 5px;
    min-width: 100px;
  }
  .yeyak-first__wrap__detail__time3 {
    float: none;
    margin-left: 0;
    flex: 1;
    margin-left: 5px;
    margin-right: 5px;
    min-width: 100px;
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .yeyak-first__reback {
    right: 10px;
  }
  .yeyak-first__wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
  .yeyak-first__wrap__cal table td {
    height: 50px;
    padding: 3px 0;
  }
  .yeyak-first__wrap__cal table td a {
    height: 30px;
    line-height: 30px;
  }
}

/* 다음 이전 스텝 이동 버튼 */
.yeyak-step-btn__prev {
  position: absolute;
  top: 50%;
  left: -110px;
}

.yeyak-step-btn__prev__btn {
  display: block;
  position: relative;
  width: 90px;
  padding-top: 55px;
  height: 35px;
  color: #333;
  text-align: center;
  font-size: 13px;
}

.yeyak-step-btn__prev__btn:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -550px;
  background-image: url("../images/sprite.png");
  width: 43px;
  min-width: 43px;
  height: 33px;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.yeyak-step-btn__prev__btn:hover, .yeyak-step-btn__prev__btn:focus {
  background-color: #ddd;
}

.yeyak-step-btn__next {
  position: absolute;
  top: 50%;
  right: -110px;
}

.yeyak-step-btn__next__btn {
  display: block;
  position: relative;
  background-color: #e26223;
  width: 90px;
  padding-top: 55px;
  height: 35px;
  color: #fff;
  text-align: center;
  font-size: 13px;
  transition: 0.3s all;
}

.yeyak-step-btn__next__btn:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -84px -668px;
  background-image: url("../images/sprite.png");
  width: 35px;
  min-width: 35px;
  height: 33px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.yeyak-step-btn__next__btn:hover, .yeyak-step-btn__next__btn:focus {
  background-color: #ffcaa0;
  transition: 0.3s all;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .yeyak-step-btn__prev {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: 30%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .yeyak-step-btn__prev__btn {
    width: 100%;
  }
  .yeyak-step-btn__next {
    position: relative;
    top: 0;
    right: 0;
    display: inline-block;
    width: 30%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .yeyak-step-btn__next__btn {
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .yeyak-first {
    width: auto;
  }
}

.yeyak-second {
  position: relative;
  text-align: center;
  margin: 0 auto;
  width: 80%;
}

.yeyak-second__title {
  text-align: left;
  background-color: #ec6159;
  height: 70px;
  line-height: 70px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding-left: 20px;
}

.yeyak-second__reback {
  position: absolute;
  top: 14px;
  right: 20px;
  color: #fff;
  border: 1px solid #fff;
  display: block;
  padding: 9px 10px;
  border-radius: 5px;
  font-size: 13px;
  white-space: nowrap;
}

.yeyak-second__reback span {
  padding-left: 30px;
  display: block;
  position: relative;
}

.yeyak-second__reback span:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -542px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 16px;
}

.yeyak-second__reback:hover, .yeyak-second__reback:focus {
  text-decoration: underline;
}

.yeyak-second__wrap {
  text-align: left;
  background-color: #fff;
  min-height: 580px;
  border-left: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  box-sizing: border-box;
}

.yeyak-second__wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .yeyak-second__wrap {
  display: inline-block;
}

.yeyak-second__wrap__top-left {
  padding: 20px;
  color: #999;
  width: 50%;
  box-sizing: border-box;
  float: left;
}

.yeyak-second__wrap__top-left__tit {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .yeyak-second__wrap__top-left__tit {
    font-size: 22px;
  }
}

.yeyak-second__wrap__top-left__seat {
  font-size: 0;
}

.yeyak-second__wrap__top-left__seat span {
  margin-right: 5px;
  font-size: 14px;
  display: inline-block;
  padding: 2px 5px;
  color: #000;
  border: 1px solid #ddd;
  margin-bottom: 4px;
}

.yeyak-second__wrap__top-left__seat em {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.yeyak-second__wrap__top {
  padding: 20px;
  color: #999;
  width: 50%;
  box-sizing: border-box;
  float: left;
}

.yeyak-second__wrap__top__tit {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .yeyak-second__wrap__top__tit {
    font-size: 22px;
  }
}

.yeyak-second__wrap__top__seat {
  font-size: 0;
}

.yeyak-second__wrap__top__seat span {
  margin-right: 5px;
  font-size: 14px;
  display: inline-block;
  padding: 2px 5px;
  color: #000;
  margin-bottom: 4px;
  border: 1px solid #ddd;
}

.yeyak-second__wrap__mid {
  margin-top: 10px;
  padding-bottom: 80px;
  min-height: 300px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 170px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;
  float: left;
  background-image: url("../../resources/images/cont/stage-bg.png");
  background-position: center 70px;
  background-repeat: no-repeat;
}

.yeyak-second__wrap__bot {
  padding: 20px;
  position: relative;
  min-height: 90px;
  width: 100%;
  box-sizing: border-box;
  float: left;
}

.yeyak-second__wrap__bot__info:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .yeyak-second__wrap__bot__info {
  display: inline-block;
}

.yeyak-second__wrap__bot__info li {
  float: left;
  position: relative;
  padding-left: 20px;
  margin-right: 30px;
}

.yeyak-second__wrap__bot__info li:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.yeyak-second__wrap__bot__info .type1:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -448px -826px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 11px;
}

.yeyak-second__wrap__bot__info .type2:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -383px -826px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 11px;
}

.yeyak-second__wrap__bot__info .type3:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -318px -826px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 11px;
}

.yeyak-second__wrap__bot__info2 {
  position: absolute;
  top: 20px;
  right: 20px;
}

.yeyak-second__wrap__bot__info2 {
  margin-bottom: 10px;
}

.yeyak-second__wrap__bot__info2 li {
  text-align: left;
  position: relative;
  padding-left: 13px;
  line-height: 1.6;
}

.yeyak-second__wrap__bot__info2 li:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -355px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.seat_row-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #fff;
}

.seat_row-wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .seat_row-wrap {
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .yeyak-second {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 880px) {
  .yeyak-second__wrap__bot__info2 {
    position: relative;
    padding: 0 0 20px 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .yeyak-second__reback {
    right: 10px;
  }
  .yeyak-second__wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.yeyak-third {
  position: relative;
  text-align: center;
  margin: 0 auto;
  width: 80%;
}

.yeyak-third__title {
  text-align: left;
  background-color: #ec6159;
  height: 70px;
  line-height: 70px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding-left: 20px;
}

.yeyak-third__reback {
  position: absolute;
  top: 14px;
  right: 20px;
  color: #fff;
  border: 1px solid #fff;
  display: block;
  padding: 9px 10px;
  border-radius: 5px;
  font-size: 13px;
  white-space: nowrap;
}

.yeyak-third__reback span {
  padding-left: 30px;
  display: block;
  position: relative;
}

.yeyak-third__reback span:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -542px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 16px;
}

.yeyak-third__reback:hover, .yeyak-third__reback:focus {
  text-decoration: underline;
}

.yeyak-third__wrap {
  text-align: left;
  background-color: #fff;
  min-height: 380px;
  border-left: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  box-sizing: border-box;
}

.yeyak-third__wrap__choose {
  padding: 20px;
  font-size: 17px;
  border-bottom: 1px solid #ddd;
}

.yeyak-third__wrap__choose__pos {
  color: #000;
}

.yeyak-third__wrap__choose__num {
  color: #ec6159;
}

.yeyak-third__wrap__ul__li {
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.yeyak-third__wrap__ul__li div {
  min-height: 38px;
  line-height: 38px;
  vertical-align: top;
}

.yeyak-third__wrap__ul__li__item1 {
  flex: 1;
  text-align: center;
}

.yeyak-third__wrap__ul__li__item2 {
  flex: 2;
  text-align: left;
  padding-left: 20px;
}

.yeyak-third__wrap__ul__li__item3 {
  flex: 1;
  text-align: center;
}

.yeyak-third__wrap__ul__li__item4 {
  flex: 1;
  text-align: center;
}

.yeyak-third__wrap__ul__li__item4__selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.yeyak-third__wrap__ul__li__item4__selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.yeyak-third__wrap__ul__li__item4__selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 90px;
  vertical-align: middle;
  color: #000;
  font-size: 14px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.yeyak-third__wrap__ul__li__item4__selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .yeyak-third__wrap__ul__li__item4__selectbox select {
  width: 125%;
  vertical-align: top;
}

.yeyak-third__wrap__ul__li__item4__selectbox select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .yeyak-third__wrap__ul__li__item4__selectbox select {
    min-width: 60px;
  }
}

.lte-ie9 .yeyak-third__wrap__ul__li:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .lte-ie9 .yeyak-third__wrap__ul__li {
  display: inline-block;
}

.lte-ie9 .yeyak-third__wrap__ul__li__item1 {
  width: 20%;
  float: left;
}

.lte-ie9 .yeyak-third__wrap__ul__li__item2 {
  width: 40%;
  float: left;
  padding-left: 0;
}

.lte-ie9 .yeyak-third__wrap__ul__li__item3 {
  width: 20%;
  float: left;
}

.lte-ie9 .yeyak-third__wrap__ul__li__item4 {
  width: 20%;
  float: left;
}

@media screen and (min-width: 1px) and (max-width: 880px) {
  .yeyak-third__wrap__ul__li {
    flex-wrap: wrap;
  }
  .yeyak-third__wrap__ul__li div {
    line-height: 1.6;
  }
  .yeyak-third__wrap__ul__li__item1 {
    flex: 1;
    min-width: 300px;
  }
  .yeyak-third__wrap__ul__li__item2 {
    flex: 2;
    min-width: 300px;
    text-align: center;
    padding-left: 0;
  }
  .yeyak-third__wrap__ul__li__item3 {
    flex: 2;
    min-width: 300px;
  }
  .yeyak-third__wrap__ul__li__item4 {
    flex: 1;
    min-width: 300px;
  }
  .yeyak-third__wrap__ul__li__item4__selectbox {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    text-align: left;
    border: 1px solid #000;
  }
  .yeyak-third__wrap__ul__li__item4__selectbox:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    content: ' ';
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -119px -891px;
    background-image: url("../images/sprite.png");
    width: 9px;
    min-width: 9px;
    height: 5px;
  }
  .yeyak-third__wrap__ul__li__item4__selectbox select {
    width: 100%;
    background-color: transparent;
    appearance: none;
    min-width: 290px;
    vertical-align: middle;
    color: #000;
    font-size: 14px;
    height: 38px;
    margin-top: 0;
    padding: 2px 2px 2px 10px;
    position: relative;
    border: 0px;
    /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /*ie10~11 에서 셀렉트 기본디자인 무력화*/
  }
  .yeyak-third__wrap__ul__li__item4__selectbox select::-ms-expand {
    display: none;
  }
  .lte-ie9 .yeyak-third__wrap__ul__li__item4__selectbox select {
    width: 125%;
    vertical-align: top;
  }
  .yeyak-third__wrap__ul__li__item4__selectbox select option {
    font-family: "Nanum Gothic";
  }
}

@media screen and (min-width: 1px) and (max-width: 880px) and (min-width: 1px) and (max-width: 768px) {
  .yeyak-third__wrap__ul__li__item4__selectbox select {
    min-width: 260px;
  }
}

.yeyak-third__wrap__total__tit {
  padding: 20px;
  padding-top: 50px;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.yeyak-third__wrap__total__h4 {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  width: 20%;
  box-sizing: border-box;
  padding-left: 40px;
}

.yeyak-third__wrap__total__info {
  display: inline-block;
  width: 75%;
  box-sizing: border-box;
}

.yeyak-third__wrap__total__info__seat {
  font-size: 0;
}

.yeyak-third__wrap__total__info__seat span {
  margin-right: 5px;
  font-size: 14px;
  display: inline-block;
  padding: 2px 5px;
  color: #000;
  border: 1px solid #ddd;
}

.yeyak-third__wrap__total__info__cancel {
  color: #da4453;
}

.yeyak-third__wrap__total__result {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
  padding: 20px;
  padding-right: 40px;
  font-size: 22px;
  font-weight: bold;
  color: #000;
  min-height: 50px;
  line-height: 50px;
  position: relative;
  text-align: right;
}

.yeyak-third__wrap__total__result__text {
  padding-right: 20px;
}

.yeyak-third__wrap__total__result__num {
  color: #000;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .yeyak-third {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .yeyak-third__wrap__total__h4 {
    width: auto;
    display: block;
    padding-left: 20px;
  }
  .yeyak-third__wrap__total__info {
    width: auto;
    display: block;
    padding-left: 30px;
    margin-bottom: 10px;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 5px;
  }
  .yeyak-third__wrap__total__info:last-child {
    border-bottom: 0px;
  }
  .yeyak-third__wrap__total__result {
    border-top: 0px;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .yeyak-third__reback {
    right: 10px;
  }
}

/* 회원가입 인트로 */
.join-wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-wrap {
  display: inline-block;
}

.join-wrap__desc {
  font-size: 17px;
  color: #333;
  margin-bottom: 30px;
  font-weight: bold;
}

.join-wrap__desc strong {
  font-size: 40px;
  color: #0093d8;
  display: block;
  margin-bottom: 10px;
}

.join-wrap__box {
  float: left;
  box-sizing: border-box;
}

.join-wrap__box__in {
  padding-top: 60px;
  padding-bottom: 10px;
  min-height: 300px;
  border-radius: 5px;
  text-align: center;
}

.join-wrap__box__in__tit {
  display: block;
  font-size: 23px;
  margin-bottom: 35px;
}

.join-wrap__box__in__desc {
  margin-bottom: 10px;
  position: relative;
  padding-top: 145px;
}

.join-wrap__box--1 {
  width: 50%;
}

.join-wrap__box--1 p:before {
  display: block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -133px;
  background-image: url("../images/sprite.png");
  width: 51px;
  min-width: 51px;
  height: 73px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.join-wrap__box--2 {
  width: 50%;
  border-left: 1px dashed #e0e0e0;
}

.join-wrap__box--2 p:before {
  display: block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -471px -129px;
  background-image: url("../images/sprite.png");
  width: 88px;
  min-width: 88px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-wrap {
    margin-left: 0;
  }
  .join-wrap__box {
    float: none;
    margin: 0 20px;
    margin-bottom: 10px;
    width: auto;
  }
  .join-wrap__box__in {
    margin-left: 0;
  }
}

/* 회원가입 스탭
===========================*/
/*input checkbox 설정*/
input[class="check-type"] + label {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid #cccccc;
  background-color: #dddddd;
  cursor: pointer;
  border-radius: 30px;
  position: relative;
  font-size: 0;
  text-indent: -9999px;
}

input[class="check-type"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -580px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 9px;
  position: absolute;
  top: 10px;
  left: 10px;
}

input[class="check-type"]:checked + label {
  background-color: #666666;
}

input[class="check-type"]:focus + label {
  border: 2px solid gold;
}

input[class="check-type"] {
  position: absolute;
  left: -9999px;
}

/* step top */
.join-step__top {
  margin-bottom: 50px;
}

.join-step__top__ul {
  margin-left: -7px;
}

.join-step__top__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-step__top__ul {
  display: inline-block;
}

.join-step__top__ul__li {
  float: left;
  width: 33%;
}

.join-step__top__ul__li:first-child {
  width: 34%;
}

.join-step__top__ul__li p {
  white-space: nowrap;
  background-color: #f7f8fc;
  border: 1px solid #d6dde7;
  padding: 10px 20px;
  margin-left: 7px;
  position: relative;
}

.join-step__top__ul__li p:after {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -456px -752px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 1px;
}

.join-step__top__ul__li.on p {
  color: #fff;
  background-color: #444444;
  border: 1px solid #444444;
}

.join-step__top__ul__li.on p:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -129px -826px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .join-step__top__ul__li p {
    font-size: 13px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-step__top__ul__li {
    display: none;
  }
  .join-step__top__ul__li.on {
    display: block;
    width: 100%;
  }
}

/* step1 */
.join-step--1__tit {
  margin-bottom: 20px;
}

.join-step--1__tit .label-text {
  font-size: 16px;
  margin-left: 5px;
}

.join-step--1__tit.allcheck {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 60px;
}

.join-step--1__textbox {
  height: 140px;
  overflow: auto;
  margin-bottom: 30px;
  border: 1px solid #d8d8d8;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #484848;
  background: #fafafa;
}

.join-step__confirm {
  background-color: #eeeeee;
  border-top: 1px solid #000;
  padding: 50px 30px;
  text-align: center;
  margin-bottom: 40px;
}

.join-step__confirm__tit {
  display: block;
  font-size: 40px;
  margin-bottom: 20px;
}

.join-step__confirm__desc {
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.join-step__confirm__btn {
  margin-left: 24% !important;
  margin-right: 24% !important;
  margin-top: 100px !important;
  position: relative;
}

.join-step__confirm__btn {
  display: block;
  color: #fff;
  background-color: #b8591f;
  margin: 0 20%;
  font-size: 19px;
  padding: 20px 0;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  text-align: center;
}

.join-step__confirm__btn:hover, .join-step__confirm__btn:focus {
  background-color: #ff9a5c;
  color: #fff;
}

.join-step__confirm__btn:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -383px;
  background-image: url("../images/sprite.png");
  width: 49px;
  min-width: 49px;
  height: 71px;
  position: absolute;
  top: -83px;
  left: 50%;
  transform: translateX(-50%);
}

.join-step__info {
  margin-bottom: 20px;
}

.join-step__info__tit {
  position: relative;
  padding-left: 22px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.join-step__info__tit:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px -471px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 21px;
  position: absolute;
  left: 0;
  top: -2px;
}

.join-step__info__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-step__info__ul {
  display: inline-block;
}

.join-step__info__ul {
  margin-bottom: 10px;
}

.join-step__info__ul li {
  text-align: left;
  position: relative;
  padding-left: 13px;
  line-height: 1.6;
}

.join-step__info__ul li:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -355px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-step__confirm__tit {
    font-size: 28px;
  }
}

/* step2 */
.serius {
  color: #da4453;
}

.join-form {
  margin: 0 5%;
  margin-bottom: 30px;
}

.join-form__line {
  border-bottom: 1px solid #d1d1d1;
  padding: 20px 0;
  position: relative;
}

.join-form__line__tit {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 16px;
  transform: translateY(-50%);
}

.join-form__line__in {
  padding-left: 220px;
}

.join-form__line__in__input {
  border: 1px solid #000;
  height: 60px;
  width: 50%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input2 {
  border: 1px solid #000;
  height: 60px;
  width: 15%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input3 {
  border: 1px solid #000;
  height: 60px;
  width: 25%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input4 {
  border: 1px solid #000;
  height: 60px;
  width: 35%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.join-form__line__in__selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.join-form__line__in__selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 130px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.join-form__line__in__selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .join-form__line__in__selectbox select {
  width: 125%;
  vertical-align: top;
}

.join-form__line__in__selectbox select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-form__line__in__selectbox select {
    min-width: 100px;
  }
}

.join-form__line__in__btn {
  color: #fff;
  background-color: #b8591f;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.join-form__line__in__btn:focus {
  background-color: #333;
  color: gold;
}

.join-subs {
  margin-bottom: 30px;
}

.join-subs__box {
  background-color: #f6f6f6;
  margin-bottom: 7px;
  font-size: 15px;
  height: 58px;
  line-height: 58px;
  position: relative;
  padding-left: 20px;
}

.join-subs__box__radiobox {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

.join-subs__box__radiobox .in_check {
  margin-left: 20px;
}

.ok-btn {
  display: block;
  color: #fff;
  background-color: #e26223;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
}

.ok-btn:hover, .ok-btn:focus {
  background-color: #ff9a5c;
  color: #fff;
}

.cancel-btn {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 19px;
  padding: 19px 59px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.cancel-btn:hover, .cancel-btn:focus {
  background-color: #eee;
  color: #000;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-form__line__tit {
    position: relative;
    top: 0;
    left: 0;
    font-size: 16px;
    transform: translateY(0);
    margin-bottom: 5px;
  }
  .join-form__line__in {
    padding-left: 0;
  }
  .join-form__line__in__input {
    width: 100%;
    box-sizing: border-box;
  }
  .join-form__line__in__input3 {
    width: 50%;
    box-sizing: border-box;
  }
  .join-form__line__in__input2 {
    width: 30%;
    box-sizing: border-box;
  }
  .join-form__line__in__input4 {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .join-form__line__in__btn {
    margin-top: 10px;
    display: block;
    width: auto;
    text-align: center;
  }
  .join-subs__box {
    height: auto;
    line-height: 1.6;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .join-subs__box__radiobox {
    position: relative;
    top: 0;
    right: 0;
    transform: translateY(0);
  }
}

/* step 3 */
.join-step__final {
  text-align: center;
  padding-top: 30px;
}

.join-step__final__text1 {
  position: relative;
  padding-top: 60px;
  font-size: 22px;
  margin-bottom: 20px;
}

.join-step__final__text1 span {
  color: #da4453;
}

.join-step__final__text1:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -275px -440px;
  background-image: url("../images/sprite.png");
  width: 63px;
  min-width: 63px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.join-step__final__text2 {
  font-size: 15px;
  color: #666;
  margin-bottom: 80px;
}

.join-step__final__text2 strong {
  color: #000;
}

.ap_btn {
  margin-top: 20px;
}

.ap_btn a {
  text-align: center;
  background: #b8591f;
  width: 100px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  color: #fff;
  display: block;
}

.join_wrap_img {
  text-align: center;
  padding-top: 40px;
  margin-bottom: 20px;
}

.join_wrap_text {
  text-align: center;
  margin-bottom: 50px;
}

.join_wrap_text p {
  margin-bottom: 5px;
}

.join_wrap_box {
  border: 1px solid #d8d8d8;
  text-align: center;
  background: #fafafa;
  padding: 20px;
}

.join_wrap_box h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.join_wrap_box p {
  color: #484848;
  font-size: 16px;
}

.join_wrap_box a {
  color: #fff;
  font-size: 16px;
  background: #b8591f;
  padding: 10px;
  display: inline-block;
  margin-top: 30px;
}

.login-wrap {
  padding-top: 100px;
  text-align: center;
}

.login-wrap__desc {
  font-size: 15px;
  color: #666;
  margin-bottom: 30px;
}

.login-wrap__form {
  margin-bottom: 20px;
}

.login-wrap__form__input {
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
  width: 500px;
  border: 1px solid #000;
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.login-wrap__form__submit {
  color: #fff;
  background-color: #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  width: 500px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
}

.login-wrap__form__submit:focus {
  background-color: #333;
  color: gold;
}

.login-wrap__form--bottom {
  width: 500px;
  margin: 0 auto;
  display: flex;
}

.login-wrap__form--bottom a {
  flex: 1;
  position: relative;
}

.login-wrap__form--bottom a:before {
  position: absolute;
  top: 5px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -180px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.login-wrap__form--bottom a:first-child:before {
  display: none !important;
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .login-wrap {
    padding-top: 50px;
  }
  .login-wrap__desc {
    font-size: 14px;
  }
  .login-wrap__form__input {
    width: 100%;
  }
  .login-wrap__form__submit {
    width: 100%;
  }
  .login-wrap__form--bottom {
    width: 100%;
  }
}

.idpass-wrap {
  border: 1px solid #333;
  width: 760px;
  box-sizing: border-box;
  margin: 0 auto;
}

.idpass-wrap__top {
  background-color: #f7f7f7;
  border-bottom: 1px solid #333;
  text-align: center;
  padding: 10px;
}

.idpass-wrap__top__list {
  margin-right: 10px;
  padding-left: 20px;
  position: relative;
  display: inline-block;
}

.idpass-wrap__top__list:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -330px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.idpass-wrap__top__list.on:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -392px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.idpass-wrap__form {
  padding: 60px;
  text-align: center;
}

.idpass-wrap__form .line-box {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.idpass-wrap__form__label {
  width: 100px;
  display: inline-block;
  font-size: 15px;
  text-align: left;
  font-weight: bold;
}

.idpass-wrap__form__input {
  margin: 0 auto;
  border: 1px solid #000;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.idpass-wrap__form__input--1 {
  width: 476px;
}

.idpass-wrap__form__input--2 {
  width: 150px;
}

.idpass-wrap__form__select {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.idpass-wrap__form__select:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.idpass-wrap__form__select select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 150px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.idpass-wrap__form__select select::-ms-expand {
  display: none;
}

.lte-ie9 .idpass-wrap__form__select select {
  width: 125%;
  vertical-align: top;
}

.idpass-wrap__form__select select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .idpass-wrap__form__select select {
    min-width: 120px;
  }
}

.idpass-wrap__form__submit {
  margin-top: 30px;
}

.idpass-wrap__form__submit {
  color: #fff;
  background-color: #b8591f;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.idpass-wrap__form__submit:focus {
  background-color: #333;
  color: gold;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .idpass-wrap {
    width: 100%;
    text-align: left;
  }
  .idpass-wrap__form {
    padding: 30px 14px;
    text-align: left;
  }
  .idpass-wrap__form__label {
    width: 100%;
  }
  .idpass-wrap__form__input--1 {
    width: 100%;
  }
  .idpass-wrap__form__input--2 {
    width: 50%;
    margin-bottom: 5px;
  }
  .idpass-wrap__form__select {
    margin-bottom: 5px;
  }
}

.mem-tab__ul {
  display: flex;
  margin-bottom: 40px;
}

.mem-tab__ul > li {
  flex: 1;
  text-align: center;
}

.mem-tab__ul > li > a {
  display: block;
  height: 70px;
  line-height: 70px;
  white-space: nowrap;
  border: 1px solid #dcdcdc;
  border-left: 0;
  font-size: 16px;
  font-weight: bold;
  color: #666;
}

.mem-tab__ul > li:first-child > a {
  border-left: 1px solid #dcdcdc;
}

.mem-tab__ul > li.on > a {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  border-left: 0;
}

.mem-tab__ul > li > a:hover,
.mem-tab__ul > li > a:focus {
  background-color: #333;
  color: #fff;
  border: 1px solid #000;
  border-left: 0;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mem-tab__ul {
    display: block;
    margin-left: -10px;
  }
  .mem-tab__ul:after {
    content: ' ';
    clear: both;
    display: block;
    visibility: hidden;
  }
  * + html .mem-tab__ul {
    display: inline-block;
  }
  .mem-tab__ul > li {
    float: left;
    width: 50%;
  }
  .mem-tab__ul > li > a {
    margin-bottom: 10px;
    margin-left: 10px;
    border-left: 1px solid #dcdcdc;
  }
}

.mem-tab__cont__table {
  margin-bottom: 30px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.mem-tab__cont__table table {
  border-collapse: collapse;
  width: 100%;
}

.mem-tab__cont__table th {
  border: 1px solid #dcdcdc;
  border-left: 0;
  padding: 13px 0;
  background-color: #f4f4f4;
  text-align: left;
  padding-left: 30px;
}

.mem-tab__cont__table td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 13px 5px;
  text-align: left;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .mem-tab__cont__table {
    padding-top: 10px;
  }
  .mem-tab__cont__table colgroup {
    display: none;
  }
  .mem-tab__cont__table thead {
    display: none;
  }
  .mem-tab__cont__table tr {
    display: block;
  }
  .mem-tab__cont__table th {
    display: none;
  }
  .mem-tab__cont__table td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    color: #333;
    position: relative;
  }
  .mem-tab__cont__table td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) "*";
    display: block;
    margin-bottom: 10px;
  }
}

.mem-tab__cont__table input[type=text] {
  border: 1px solid #000;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  min-width: 30%;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.mem-tab__cont__table .join-form__line__in__btn {
  height: 34px;
  line-height: 34px;
}

.mem-tab__cont__bottom {
  text-align: center;
}

.mem-tab__cont__bottom__edit {
  display: block;
  color: #fff;
  background-color: #e26223;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
}

.mem-tab__cont__bottom__edit:hover, .mem-tab__cont__bottom__edit:focus {
  background-color: #ff9a5c;
  color: #fff;
}

.mem-tab__cont__bottom__cancel {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 19px;
  padding: 19px 59px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.mem-tab__cont__bottom__cancel:hover, .mem-tab__cont__bottom__cancel:focus {
  background-color: #eee;
  color: #000;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mem-tab__cont__table td:before {
    display: block;
    margin-bottom: 5px;
    content: attr(data-th) " ";
  }
}

.mem-tab2__ul {
  display: flex;
  margin-bottom: 40px;
}

.mem-tab2__ul > li {
  flex: 1;
  text-align: center;
}

.mem-tab2__ul > li > a {
  display: block;
  height: 70px;
  line-height: 70px;
  white-space: nowrap;
  border: 1px solid #dcdcdc;
  border-left: 0;
  font-size: 16px;
  font-weight: bold;
  color: #666;
}

.mem-tab2__ul > li:first-child > a {
  border-left: 1px solid #dcdcdc;
}

.mem-tab2__ul > li.on > a {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  border-left: 0;
}

.mem-tab2__ul > li > a:hover,
.mem-tab2__ul > li > a:focus {
  background-color: #333;
  color: #fff;
  border: 1px solid #000;
  border-left: 0;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mem-tab2__ul {
    display: block;
    margin-left: -10px;
  }
  .mem-tab2__ul:after {
    content: ' ';
    clear: both;
    display: block;
    visibility: hidden;
  }
  * + html .mem-tab2__ul {
    display: inline-block;
  }
  .mem-tab2__ul > li {
    float: left;
    width: 50%;
  }
  .mem-tab2__ul > li > a {
    margin-bottom: 10px;
    margin-left: 10px;
    border-left: 1px solid #dcdcdc;
  }
}

.mem-tab2__cont__table {
  margin-bottom: 10px;
  border-top: 2px solid #4b4b4b;
  border-bottom: 1px solid #ddd;
}

.mem-tab2__cont__table table {
  border-collapse: collapse;
  width: 100%;
  margin-left: -1px;
}

@media only screen and (max-width: 550px) {
  .mem-tab2__cont__table table {
    font-size: 8px;
  }
}

.mem-tab2__cont__table th {
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 13px 0;
  background-color: #f6f6f6;
  text-align: center;
  color: #000;
}

.mem-tab2__cont__table tbody th {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

@media only screen and (max-width: 550px) {
  .mem-tab2__cont__table tbody th {
    padding: 3px 3px;
  }
}

.mem-tab2__cont__table td {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  vertical-align: middle;
}

@media only screen and (max-width: 550px) {
  .mem-tab2__cont__table td {
    padding: 3px 3px;
  }
}

.mem-tab2__cont__table td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.mem-tab2__cont__table td.sbj-line > a:hover, .mem-tab2__cont__table td.sbj-line > a:focus {
  text-decoration: underline;
}

.mem-tab2__cont__table td > a:hover, .mem-tab2__cont__table td > a:focus {
  text-decoration: underline;
}

.mem-tab2__cont__table td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .mem-tab2__cont__table.res {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .mem-tab2__cont__table.res table {
    min-width: auto;
    margin-left: 0px;
  }
  .mem-tab2__cont__table.res colgroup {
    display: none;
  }
  .mem-tab2__cont__table.res thead {
    display: none;
  }
  .mem-tab2__cont__table.res tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .mem-tab2__cont__table.res th {
    display: none;
  }
  .mem-tab2__cont__table.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .mem-tab2__cont__table.res td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .mem-tab2__cont__table.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
  .mem-tab2__cont__table.res td.align-left {
    padding-left: 10px;
  }
}

.mem-tab2__cont__table input[type=text] {
  border: 1px solid #000;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  min-width: 30%;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.mem-tab2__cont__table .join-form__line__in__btn {
  height: 34px;
  line-height: 34px;
}

.mem-tab2__cont__bottom {
  text-align: center;
}

.mem-tab2__cont__bottom__edit {
  display: block;
  color: #fff;
  background-color: #e26223;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
}

.mem-tab2__cont__bottom__edit:hover, .mem-tab2__cont__bottom__edit:focus {
  background-color: #ff9a5c;
  color: #fff;
}

.mem-tab2__cont__bottom__cancel {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 19px;
  padding: 19px 59px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.mem-tab2__cont__bottom__cancel:hover, .mem-tab2__cont__bottom__cancel:focus {
  background-color: #eee;
  color: #000;
}

.my-bills {
  width: 80%;
  margin: 0 auto;
}

.my-bills__tit {
  text-align: center;
}

.my-bills__tit {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
}

.my-bills__tit:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px 0px;
  background-image: url("../images/sprite.png");
  width: 19px;
  min-width: 19px;
  height: 19px;
}

@media (max-width: 768px) {
  .my-bills__tit {
    font-size: 18px;
  }
}

.my-bills__tb {
  margin-bottom: 30px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.my-bills__tb table {
  border-collapse: collapse;
  width: 100%;
}

.my-bills__tb th {
  border: 1px solid #dcdcdc;
  border-left: 0;
  padding: 13px 0;
  background-color: #f4f4f4;
  text-align: left;
  padding-left: 30px;
}

.my-bills__tb td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 13px 5px;
  text-align: left;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .my-bills__tb {
    padding-top: 10px;
  }
  .my-bills__tb colgroup {
    display: none;
  }
  .my-bills__tb thead {
    display: none;
  }
  .my-bills__tb tr {
    display: block;
  }
  .my-bills__tb th {
    display: none;
  }
  .my-bills__tb td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    color: #333;
    position: relative;
  }
  .my-bills__tb td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) "*";
    display: block;
    margin-bottom: 10px;
  }
}

.my-bills__tb .col-size-1 {
  width: 35%;
}

.my-bills__tb .tit-line th {
  background-color: #333;
  color: #fff;
}

.my-bills__tb .tit-line td {
  background-color: #333;
  color: #fff;
}

.my-bills__tb tr:nth-child(2n) th {
  background-color: #e7e7e7;
  border-right: 1px solid #cdcdcd;
}

.my-bills__tb tr:nth-child(2n) td {
  background-color: #e7e7e7;
}

.my-bills__tb th {
  text-align: center;
}

.my-bills__print {
  text-align: right;
}

.my-bills__print__btn {
  display: inline-block;
  color: #fff;
  background-color: #333333;
  border-radius: 30px;
  font-size: 15px;
  padding: 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
}

.my-bills__print__btn:hover, .my-bills__print__btn:focus {
  background-color: #000;
  color: gold;
}

.sitemap__box {
  border-top: 1px solid #c6c6c6;
  padding-bottom: 40px;
  min-height: 300px;
}

.sitemap__box:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sitemap__box {
  display: inline-block;
}

.sitemap__box__tit {
  border-top: 1px solid #000;
  width: 23%;
  float: left;
  margin-top: -1px;
  padding-top: 40px;
  font-size: 40px;
  font-weight: 600;
}

.sitemap__box__2nd {
  float: left;
  width: 77%;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
}

.sitemap__box__2nd:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sitemap__box__2nd {
  display: inline-block;
}

.sitemap__box__2nd__li {
  float: left;
  flex: 1;
  padding-left: 20px;
  min-width: 150px;
  padding-bottom: 40px;
}

.sitemap__box__2nd__li__a {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
  color: #000;
}

.sitemap__box__2nd__li__a:focus {
  font-weight: 700;
}

.sitemap__box__2nd__li__3rd__li__a {
  padding-left: 10px;
  position: relative;
}

.sitemap__box__2nd__li__3rd__li__a:before {
  position: absolute;
  top: 8px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -513px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.sitemap__box__2nd__li__3rd__li__a:hover, .sitemap__box__2nd__li__3rd__li__a:focus {
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sitemap__box__tit {
    width: auto;
    padding-left: 10px;
    float: none;
  }
  .sitemap__box__2nd {
    width: auto;
    padding-left: 0px;
    float: none;
  }
  .sitemap__box__2nd__li {
    min-width: 120px;
  }
}

.sisul__top {
  margin-bottom: 50px;
  text-align: center;
}

.sisul__tit {
  border-left: 5px solid #3a8dcb;
  font-weight: bold;
  font-size: 28px;
  height: 23px;
  line-height: 19px;
  padding-left: 10px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .sisul__tit {
    font-size: 22px;
  }
}

.sisul__tit--1 {
  margin-bottom: 50px !important;
}

.sisul__tit-little {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .sisul__tit-little {
    font-size: 22px;
  }
}

.sisul__tit-little:before {
  content: ' ';
  position: absolute;
  top: 14px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -407px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.sisul__table--1 {
  margin-bottom: 30px;
}

.sisul__table--1 table {
  border-collapse: collapse;
  width: 100%;
}

.sisul__table--1 thead th {
  border-bottom: 0px;
}

.sisul__table--1 th {
  border: 3px solid #fff;
  padding: 13px 0;
  background-color: #e26223;
  color: #fff;
}

.sisul__table--1 td {
  border: 3px solid #fff;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff4ec;
}

.sisul__table--1 tr.left-td td,
.sisul__table--1 td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .sisul__table--1 {
    padding-top: 10px;
  }
  .sisul__table--1 colgroup {
    display: none;
  }
  .sisul__table--1 thead {
    display: none;
  }
  .sisul__table--1 tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #fff;
  }
  .sisul__table--1 th {
    display: none;
  }
  .sisul__table--1 td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #fff;
  }
  .sisul__table--1 td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.sisul__table--1 tbody tr td {
  vertical-align: middle;
}

.sisul__table--1 .basic-list {
  margin-left: 20px;
}

.sisul__table--1 .basic-list {
  margin-bottom: 10px;
}

.sisul__table--1 .basic-list li {
  text-align: left;
  position: relative;
  padding-left: 13px;
  line-height: 1.6;
}

.sisul__table--1 .basic-list li ul {
  margin-bottom: 0;
}

.sisul__table--1 .basic-list li:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -56px -953px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.sisul__table--2 {
  margin-bottom: 50px !important;
}

.sisul__table--2 {
  margin-bottom: 30px;
}

.sisul__table--2 table {
  border-collapse: collapse;
  width: 100%;
}

.sisul__table--2 thead th {
  border-bottom: 0px;
}

.sisul__table--2 th {
  border: 3px solid #fff;
  padding: 13px 0;
  background-color: #e26223;
  color: #fff;
}

.sisul__table--2 td {
  border: 3px solid #fff;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff4ec;
}

.sisul__table--2 tr.left-td td,
.sisul__table--2 td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .sisul__table--2 {
    padding-top: 10px;
  }
  .sisul__table--2 colgroup {
    display: none;
  }
  .sisul__table--2 thead {
    display: none;
  }
  .sisul__table--2 tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #fff;
  }
  .sisul__table--2 th {
    display: none;
  }
  .sisul__table--2 td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #fff;
  }
  .sisul__table--2 td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.sisul__table--2 tbody tr th {
  background-color: #fff;
  font-weight: bold;
  padding-top: 30px;
  padding-bottom: 30px;
}

.sisul__table--2 tbody tr td {
  vertical-align: middle;
  padding-top: 30px;
  padding-bottom: 30px;
}

.sisul__table--3 {
  margin-bottom: 50px !important;
}

.sisul__table--3 {
  margin-bottom: 30px;
}

.sisul__table--3 table {
  border-collapse: collapse;
  width: 100%;
}

.sisul__table--3 thead th {
  border-bottom: 0px;
}

.sisul__table--3 th {
  border: 3px solid #fff;
  padding: 13px 0;
  background-color: #e26223;
  color: #fff;
}

.sisul__table--3 td {
  border: 3px solid #fff;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff4ec;
}

.sisul__table--3 tr.left-td td,
.sisul__table--3 td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .sisul__table--3 {
    padding-top: 10px;
  }
  .sisul__table--3 colgroup {
    display: none;
  }
  .sisul__table--3 thead {
    display: none;
  }
  .sisul__table--3 tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #fff;
  }
  .sisul__table--3 th {
    display: none;
  }
  .sisul__table--3 td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #fff;
  }
  .sisul__table--3 td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.sisul__table--3 tbody tr th {
  background-color: #fff;
  font-weight: bold;
  padding-top: 30px;
  padding-bottom: 30px;
}

.sisul__table--3 tbody tr td {
  vertical-align: middle;
  padding-top: 30px;
  padding-bottom: 30px;
}

.sisul__table--4 {
  margin-bottom: 50px !important;
}

.sisul__table--4 {
  margin-bottom: 30px;
}

.sisul__table--4 table {
  border-collapse: collapse;
  width: 100%;
}

.sisul__table--4 thead th {
  border-bottom: 0px;
}

.sisul__table--4 th {
  border: 3px solid #fff;
  padding: 13px 0;
  background-color: #e26223;
  color: #fff;
}

.sisul__table--4 td {
  border: 3px solid #fff;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff4ec;
}

.sisul__table--4 tr.left-td td,
.sisul__table--4 td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .sisul__table--4 {
    padding-top: 10px;
  }
  .sisul__table--4 colgroup {
    display: none;
  }
  .sisul__table--4 thead {
    display: none;
  }
  .sisul__table--4 tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #fff;
  }
  .sisul__table--4 th {
    display: none;
  }
  .sisul__table--4 td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #fff;
  }
  .sisul__table--4 td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.sisul__table--4 tbody tr th {
  background-color: #fff;
  font-weight: bold;
  padding-top: 30px;
  padding-bottom: 30px;
}

.sisul__table--4 tbody tr td {
  vertical-align: middle;
  padding-top: 30px;
  padding-bottom: 30px;
}

.sisul__strong {
  font-size: 15px;
  margin-bottom: 8px;
  display: block;
}

.sisul__list {
  margin-bottom: 50px !important;
}

.sisul__list {
  margin-bottom: 10px;
}

.sisul__list li {
  text-align: left;
  position: relative;
  padding-left: 13px;
  line-height: 1.6;
}

.sisul__list li:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -355px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.sisul__box {
  padding-left: 20px;
  font-size: 16px;
  padding-top: 10px;
  margin-bottom: 30px;
}

.sisul__box__down {
  display: inline-block;
  color: #0c5493;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 40px 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
  border: 1px solid #0c5493;
  position: relative;
}

.sisul__box__down:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -517px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.sisul__box__down:hover, .sisul__box__down:focus {
  background-color: #1e79c8;
  color: gold;
}

.sisul .basic-list {
  margin-left: 20px;
}

.sisul .basic-list {
  margin-bottom: 10px;
}

.sisul .basic-list li {
  text-align: left;
  position: relative;
  padding-left: 13px;
  line-height: 1.6;
}

.sisul .basic-list li ul {
  margin-bottom: 0;
}

.sisul .basic-list li:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -56px -953px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.sisul .basic-list3 {
  margin-left: 20px;
  margin-bottom: 50px !important;
}

.sisul .basic-list3 {
  margin-bottom: 10px;
}

.sisul .basic-list3 li {
  text-align: left;
  position: relative;
  padding-left: 13px;
  line-height: 1.6;
}

.sisul .basic-list3 li:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -355px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.sisul__ol-list {
  margin-bottom: 70px;
  margin-left: -20px;
}

.sisul__ol-list:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sisul__ol-list {
  display: inline-block;
}

.sisul__ol-list__li {
  position: relative;
  width: 20%;
  float: left;
}

.sisul__ol-list__li:before {
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -63px -891px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 9px;
}

.sisul__ol-list__li:first-child:before {
  display: none;
}

.sisul__ol-list__li__in {
  border: 1px solid #dddddd;
  margin-left: 20px;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 30px;
  font-size: 15px;
  color: #000;
  font-weight: bold;
}

.sisul__ol-list__li__in strong {
  display: block;
  font-size: 12px;
  color: #3a8dcb;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.ver2 .sisul__ol-list__li__in {
  min-height: 75px;
}

.sisul__down-list {
  margin-left: -20px;
  padding-top: 20px;
}

.sisul__down-list:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sisul__down-list {
  display: inline-block;
}

.sisul__down-list__li {
  float: left;
  width: 25%;
}

.sisul__down-list__li a {
  background-color: #fff;
  transition: all 0.3s ease 0s;
  display: block;
  text-align: center;
  padding: 25px;
  min-height: 200px;
  margin-left: 20px;
  border: 1px solid #ddd;
}

.sisul__down-list__li a:hover, .sisul__down-list__li a:focus {
  background-color: #ededed;
}

.sisul__down-list__li a strong {
  font-size: 18px;
  display: block;
  padding-bottom: 150px;
  position: relative;
}

.sisul__down-list__li a strong:after {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.sisul__down-list__li a strong.type1:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -326px -277px;
  background-image: url("../images/sprite.png");
  width: 64px;
  min-width: 64px;
  height: 70px;
}

.sisul__down-list__li a strong.type2:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -471px 0px;
  background-image: url("../images/sprite.png");
  width: 97px;
  min-width: 97px;
  height: 79px;
}

.sisul__down-list__li a strong.type3:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -256px;
  background-image: url("../images/sprite.png");
  width: 47px;
  min-width: 47px;
  height: 77px;
}

.sisul__down-list__li a strong.type4:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -550px;
  background-image: url("../images/sprite.png");
  width: 53px;
  min-width: 53px;
  height: 68px;
}

.sisul__down-list__li a span {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  width: 65%;
  height: 40px;
  line-height: 40px;
  display: block;
  margin: 0 auto;
  background-color: #000;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .sisul__down-list__li {
    width: 50%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sisul__ol-list {
    padding: 30px;
  }
  .sisul__ol-list__li {
    width: 50%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .sisul__down-list__li {
    width: 100%;
    margin-bottom: 20px;
  }
}

/*신청서 시작화면*/
.facility-first__item {
  border: 1px solid #cccccc;
  border-top: 4px solid #333;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 20px;
  position: relative;
  width: 48%;
  float: left;
  margin-left: 1%;
  min-height: 220px;
  box-sizing: border-box;
}

@media only screen and (max-width: 550px) {
  .facility-first__item {
    width: 100%;
    float: none;
    margin-left: 0;
  }
}

.facility-first__item:nth-child(2n+1) {
  margin-left: 0;
}

.facility-first__item__tit {
  font-weight: bold;
  font-size: 18px;
  height: 23px;
  line-height: 23px;
  padding-left: 30px;
  margin-bottom: 15px;
}

.facility-first__item__info {
  padding-left: 30px;
  font-size: 16px;
  margin-bottom: 10px;
}

.facility-first__item__info.type2 {
  display: none;
}

.imposb .facility-first__item__info.type1 {
  display: none;
}

.imposb .facility-first__item__info.type2 {
  display: block;
  font-size: 16px;
}

.facility-first__item__date-tit {
  margin-left: 30px;
  color: #fff;
  display: inline-block;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 20px;
  background-color: #000;
}

.imposb .facility-first__item__date-tit {
  display: none;
}

.facility-first__item__date {
  padding-left: 30px;
}

.imposb .facility-first__item__date {
  display: none;
}

.facility-first__item__btn {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  min-width: 150px;
}

.facility-first__item__btn.type1 {
  display: block;
  color: #fff;
  border: 1px solid #f56d36;
  background: #f56d36;
  box-sizing: border-box;
}

.imposb .facility-first__item__btn.type1.type1 {
  display: none;
}

.facility-first__item__btn.type1:hover, .facility-first__item__btn.type1:focus {
  color: #fff;
  background: #f56d36;
  border: 1px solid #f56d36;
}

.facility-first__item__btn.type2 {
  display: none;
  color: #000;
  border: 1px solid #000;
  box-sizing: border-box;
}

.imposb .facility-first__item__btn.type2.type2 {
  display: block;
}

.facility-first__item.imposb {
  background-color: #f4f4f4;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .facility-first__item__tit {
    padding-left: 20px;
  }
  .facility-first__item__info {
    padding-left: 20px;
    padding-right: 10px;
  }
  .facility-first__item__date-tit {
    display: block;
    width: 100px;
    text-align: center;
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .facility-first__item__date {
    margin-left: 20px;
    margin-bottom: 10px;
    display: block;
    padding-left: 0;
  }
  .facility-first__item__btn {
    position: relative;
    top: 0;
    right: 0;
    transform: translateY(0);
    margin-right: 20px;
    margin-left: 20px;
  }
}

/*신청서*/
.apply-box {
  text-align: center;
}

.apply-box__tit {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
}

.apply-box__tit:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px 0px;
  background-image: url("../images/sprite.png");
  width: 19px;
  min-width: 19px;
  height: 19px;
}

@media (max-width: 768px) {
  .apply-box__tit {
    font-size: 18px;
  }
}

.apply-box__ok {
  margin: 0 auto;
}

.apply-box__ok {
  display: block;
  color: #fff;
  background-color: #e26223;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
}

.apply-box__ok:hover, .apply-box__ok:focus {
  background-color: #ff9a5c;
  color: #fff;
}

.apply-box__radiobox-list:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .apply-box__radiobox-list {
  display: inline-block;
}

.apply-box__radiobox-list li {
  float: left;
  margin-right: 25px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .apply-box__radiobox-list li {
    float: none;
    margin-right: 0;
  }
}

.apply-box__table {
  margin-bottom: 30px;
}

.apply-box__table table {
  border-collapse: collapse;
  width: 100%;
}

.apply-box__table thead th {
  border-bottom: 0px;
}

.apply-box__table th {
  border: 3px solid #fff;
  padding: 13px 0;
  background-color: #e26223;
  color: #fff;
}

.apply-box__table td {
  border: 3px solid #fff;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff4ec;
}

.apply-box__table tr.left-td td,
.apply-box__table td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .apply-box__table {
    padding-top: 10px;
  }
  .apply-box__table colgroup {
    display: none;
  }
  .apply-box__table thead {
    display: none;
  }
  .apply-box__table tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #fff;
  }
  .apply-box__table th {
    display: none;
  }
  .apply-box__table td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #fff;
  }
  .apply-box__table td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.apply-box__table td {
  text-align: left;
  padding-left: 20px;
}

.apply-box__table__input {
  border: 1px solid #000;
  height: 60px;
  width: 40%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.apply-box__table__input2 {
  border: 1px solid #000;
  height: 60px;
  width: 60%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.apply-box__table__input-small {
  height: 30px !important;
}

.apply-box__table__input-small {
  border: 1px solid #000;
  height: 60px;
  width: 120px;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.apply-box__table__textarea {
  border: 1px solid #000;
  height: 130px;
  width: 80%;
  line-height: 1.6;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.apply-box__table__select {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.apply-box__table__select:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -119px -891px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.apply-box__table__select select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 330px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.apply-box__table__select select::-ms-expand {
  display: none;
}

.lte-ie9 .apply-box__table__select select {
  width: 125%;
  vertical-align: top;
}

.apply-box__table__select select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .apply-box__table__select select {
    min-width: 300px;
  }
}

@media (max-width: 800px) {
  .apply-box__table td:before {
    display: block;
    content: attr(data-th) "  ";
    margin-bottom: 10px;
  }
  .apply-box__table__input {
    border: 1px solid #000;
    height: 60px;
    width: 95%;
    line-height: 60px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .apply-box__table__input2 {
    border: 1px solid #000;
    height: 60px;
    width: 95%;
    line-height: 60px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .apply-box__table__textarea {
    border: 1px solid #000;
    height: 130px;
    width: 95%;
    line-height: 1.6;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    box-sizing: border-box;
  }
}

.apply-box__mid {
  border: 1px solid #ddd;
  padding: 20px;
  text-align: left;
  margin-bottom: 30px;
}

.apply-box__mid__tit {
  margin-bottom: 30px;
}

.apply-box__bottom {
  border: 1px solid #ddd;
  padding: 20px 40px 20px 20px;
  text-align: right;
  margin-bottom: 30px;
  font-size: 20px;
}

.apply-box__bottom__cost strong {
  display: inline-block;
  min-width: 150px;
  font-size: 24px;
}

/*약관 동의 페이지*/
.apply-agree__tit {
  margin-bottom: 20px;
}

.apply-agree__tit .label-text {
  font-size: 16px;
  margin-left: 5px;
}

.apply-agree__tit.allcheck {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 60px;
}

.apply-agree__textbox {
  height: 330px;
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border: 1px solid #eee;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid #000;
  color: #666;
}

.apply-agree .tips {
  margin-bottom: 20px;
}

.survey-type td.result {
  padding: 5px;
}

.survey-type .result__btn {
  font-size: 15px !important;
  background-color: #da4453 !important;
  height: 45px !important;
  line-height: 45px !important;
}

.survey-type .result__btn {
  display: inline-block;
  color: #fff;
  width: 100px;
  background-color: #f43142;
  font-size: 19px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.survey-type .result__btn:hover, .survey-type .result__btn:focus {
  background-color: #ff0016;
  color: gold;
}

.survey-type .stat .ing {
  color: #1062dc;
}

.survey-type .stat .comp {
  color: #111;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .board_list.survey-type tbody td {
    margin-right: 20px;
  }
  .board_list.survey-type tbody td:before {
    display: none;
  }
  .board_list.survey-type tbody td.result {
    width: 100%;
  }
}

.survey {
  width: 80%;
  margin: 0 auto;
}

.survey__top {
  border: 3px solid #e9ecf0;
  padding: 40px 120px 40px 120px;
  margin-bottom: 50px;
  font-size: 19px;
  font-weight: bold;
  color: #000;
}

.survey__top__in {
  position: relative;
}

.survey__top__in:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -521px -550px;
  background-image: url("../images/sprite.png");
  width: 47px;
  min-width: 47px;
  height: 47px;
  position: absolute;
  top: -5px;
  left: -60px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .survey__top {
    padding: 20px;
  }
  .survey__top__in:before {
    display: none;
  }
}

.survey__cont {
  width: 80%;
  margin: 0 auto;
}

.survey__cont__ul__li__title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.survey__cont__ul__li__choose {
  margin-left: 10px;
  margin-bottom: 30px;
}

.survey__cont__ul__li__choose__item {
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 4px;
}

.survey__cont__ul__li__choose__item.on {
  background-color: #e9ecf0;
  border: 1px solid #d5d9dd;
}

.survey__bottom {
  text-align: center;
}

.survey__bottom__btn {
  display: block;
  color: #fff;
  background-color: #e26223;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
}

.survey__bottom__btn:hover, .survey__bottom__btn:focus {
  background-color: #ff9a5c;
  color: #fff;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .survey {
    width: auto;
  }
  .survey__cont__ul__li__title {
    font-size: 16px;
  }
  .survey__cont {
    width: auto;
  }
}

.survey-result-total {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.survey-result-total__cont {
  position: relative;
}

.survey-result-total__cont:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -826px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 15px;
  position: absolute;
  top: 2px;
  left: -22px;
}

.survey-result {
  width: 80%;
  margin: 0 auto;
}

.survey-result__grph {
  padding: 15px;
  border: 1px solid #ddd;
  border-top: 0px;
  position: relative;
}

.survey-result__grph__tit {
  margin-bottom: 7px;
}

.survey-result__grph__num {
  position: absolute;
  top: 15px;
  right: 15px;
}

.survey-result__grph__bar {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  height: 28px;
}

.survey-result__grph__bar__in {
  width: 0px;
  transition: all 2s ease 0s;
  height: 30px;
  background-color: #ed374c;
  margin-top: -1px;
}

.survey-result__grph:nth-child(2) .survey-result__grph__bar__in {
  background-color: #e77224;
}

.survey-result__grph:nth-child(3) .survey-result__grph__bar__in {
  background-color: #ffc516;
}

.survey-result__grph:nth-child(4) .survey-result__grph__bar__in {
  background-color: #5db75d;
}

.survey-result__grph:nth-child(5) .survey-result__grph__bar__in {
  background-color: #2769ee;
}

.survey-result__grph:nth-child(6) .survey-result__grph__bar__in {
  background-color: #14367b;
}

.survey-result__grph:nth-child(7) .survey-result__grph__bar__in {
  background-color: #b42fec;
}

.survey-result__grph:nth-child(8) .survey-result__grph__bar__in {
  background-color: #e63871;
}

.survey-result__grph:nth-child(9) .survey-result__grph__bar__in {
  background-color: #29dba7;
}

.survey-result__grph:nth-child(10) .survey-result__grph__bar__in {
  background-color: #3a8871;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .survey-result-total {
    width: auto;
  }
  .survey-result {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .survey-result__grph__num {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 5px;
    display: block;
  }
}

/*input checkbox 설문조사쪽 디자인 설정*/
/*체크박스*/
input[class="check-type-survey"] + label {
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 2px solid #cccccc;
  background-color: #dddddd;
  cursor: pointer;
  border-radius: 30px;
  position: relative;
  font-size: 0;
  text-indent: -9999px;
}

input[class="check-type-survey"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -580px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 9px;
  position: absolute;
  top: 3px;
  left: 3px;
}

input[class="check-type-survey"]:checked + label {
  background-color: #666666;
}

input[class="check-type-survey"]:focus + label {
  border: 2px solid gold;
}

input[class="check-type-survey"] {
  position: absolute;
  left: -9999px;
}

/*라디오버튼*/
input[class="radio-type-survey"] + label {
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 0;
  cursor: pointer;
  position: relative;
  font-size: 0;
  text-indent: -9999px;
}

input[class="radio-type-survey"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -192px -826px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
  position: absolute;
  top: 3px;
  left: 4px;
}

input[class="radio-type-survey"]:checked + label:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -255px -826px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

input[class="radio-type-survey"] {
  position: absolute;
  left: -9999px;
}

.choose-label-text {
  margin-left: 5px;
  color: #7d7d7d;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
}

.choose-textarea {
  display: block;
  box-sizing: border-box;
  border: 1px solid #d5d9dd;
  background-color: #e9ecf0;
  padding: 20px;
  height: 100px;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
  color: #768393;
}

.choose-textarea.long {
  height: 150px;
}

/*공연예매 완료페이지*/
.complete-page {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 230px;
  background-image: url("../../resources/images/cont/complete-icon.png");
  background-position: center top;
  background-repeat: no-repeat;
  text-align: center;
}

.complete-page__item1 {
  font-weight: 800;
  font-size: 24px;
  color: #007476;
  margin-bottom: 10px;
}

.complete-page__item2 {
  font-size: 13px;
  margin-bottom: 20px;
}

.complete-page__btn1 {
  display: inline-block;
  color: #fff;
  background-color: #207fe5;
  font-size: 13px;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  padding: 0px 15px;
  border: 1px solid #4a99ee;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  vertical-align: middle;
}

.complete-page__btn1:hover, .complete-page__btn1:focus {
  background-color: #4a99ee;
  color: #fff;
}

.complete-page__btn2 {
  display: inline-block;
  color: #666;
  background-color: #fff;
  font-size: 13px;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  padding: 0px 15px;
  border: 1px solid #d2d2d2;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  vertical-align: middle;
}

.complete-page__btn2:hover, .complete-page__btn2:focus {
  background-color: #eee;
  color: #000;
}

/*faq*/
.faq-box {
  width: 80%;
  margin: 0 auto;
}

.faq-box__list {
  margin-top: -1px;
}

.faq-box__list:first-child {
  margin-top: 0px;
}

.faq-box__list__tit {
  padding: 30px;
  border: 1px solid #000;
  font-size: 18px;
  position: relative;
}

.faq-box__list__tit__num {
  font-weight: bold;
  display: inline-block;
  margin-right: 35px;
  margin-left: 30px;
  text-align: center;
}

.faq-box__list__tit__much {
  font-weight: bold;
  display: inline-block;
  width: 120px;
}

.faq-box__list__tit__opener {
  position: absolute;
  right: 30px;
  top: 20px;
  height: 51px !important;
  line-height: 51px !important;
}

.faq-box__list__tit__opener {
  color: #fff;
  background-color: #b8591f;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.faq-box__list__tit__opener:focus {
  background-color: #333;
  color: gold;
}

.faq-box__list__tit__opener.type1 {
  display: block;
}

.faq-box__list__tit__opener.type2 {
  display: none;
}

.faq-box__list.on .faq-box__list__tit__opener.type1 {
  display: none;
}

.faq-box__list.on .faq-box__list__tit__opener.type2 {
  display: block;
}

.faq-box__list__answer {
  display: none;
  border: 1px solid #000;
  border-top: 0px;
  background-color: #f7f7f7;
  padding: 30px 40px;
  font-size: 16px;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .faq-box {
    width: auto;
  }
  .faq-box__list__tit {
    padding: 10px 20px;
    font-size: 15px;
  }
  .faq-box__list__tit__num {
    margin-right: 20px;
    margin-left: 10px;
  }
  .faq-box__list__tit__much {
    margin-right: 20px;
  }
  .faq-box__list__tit__opener {
    position: relative;
    top: 0;
    right: 0;
    height: 35px !important;
    line-height: 35px !important;
    background-color: #666;
    margin-top: 4px;
  }
  .faq-box__list__tit__sbj {
    display: block;
    font-size: 16px;
    margin-top: 4px;
    margin-left: 5px;
  }
}

.guide-box__table {
  margin-bottom: 30px;
}

.guide-box__table table {
  border-collapse: collapse;
  width: 100%;
}

.guide-box__table thead th {
  border-bottom: 0px;
}

.guide-box__table th {
  border: 3px solid #fff;
  padding: 13px 0;
  background-color: #e26223;
  color: #fff;
}

.guide-box__table td {
  border: 3px solid #fff;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff4ec;
}

.guide-box__table tr.left-td td,
.guide-box__table td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .guide-box__table {
    padding-top: 10px;
  }
  .guide-box__table colgroup {
    display: none;
  }
  .guide-box__table thead {
    display: none;
  }
  .guide-box__table tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #fff;
  }
  .guide-box__table th {
    display: none;
  }
  .guide-box__table td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #fff;
  }
  .guide-box__table td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.guide-box__table table colgroup .col-1 {
  width: 40%;
}

.guide-box__table table colgroup .col-2 {
  width: 30%;
}

.guide-box__table table colgroup .col-3 {
  width: 30%;
}

.guide-box__table table td {
  text-align: left;
}

.guide-box__box {
  border: 1px solid #ddd;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 20px;
  margin-bottom: 20px;
}

.guide-box__box__item {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}

.guide-box__box__item:last-child {
  margin-bottom: 0px;
}

.h3Type1 {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
}

.h3Type1:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -848px 0px;
  background-image: url("../images/sprite.png");
  width: 19px;
  min-width: 19px;
  height: 19px;
}

@media (max-width: 768px) {
  .h3Type1 {
    font-size: 18px;
  }
}

.h3Type2 {
  color: #399bd8;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .h3Type2 {
    font-size: 22px;
  }
}

.h3Type3 {
  border-left: 5px solid #3a8dcb;
  font-weight: bold;
  font-size: 28px;
  height: 23px;
  line-height: 19px;
  padding-left: 10px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .h3Type3 {
    font-size: 22px;
  }
}

.h4Type1 {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .h4Type1 {
    font-size: 22px;
  }
}

.h4Type2 {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .h4Type2 {
    font-size: 22px;
  }
}

.h4Type2:before {
  content: ' ';
  position: absolute;
  top: 14px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -407px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.btn1 {
  display: block;
  color: #fff;
  background-color: #b8591f;
  margin: 0 20%;
  font-size: 19px;
  padding: 20px 0;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  text-align: center;
}

.btn1:hover, .btn1:focus {
  background-color: #ff9a5c;
  color: #fff;
}

.btn2 {
  color: #fff;
  background-color: #b8591f;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.btn2:focus {
  background-color: #333;
  color: gold;
}

.btn3 {
  display: block;
  color: #fff;
  background-color: #e26223;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
}

.btn3:hover, .btn3:focus {
  background-color: #ff9a5c;
  color: #fff;
}

.btn4 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 19px;
  padding: 19px 59px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn4:hover, .btn4:focus {
  background-color: #eee;
  color: #000;
}

.btn5 {
  display: inline-block;
  color: #fff;
  width: 200px;
  background-color: #f43142;
  font-size: 19px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn5:hover, .btn5:focus {
  background-color: #ff0016;
  color: gold;
}

.btn6 {
  display: inline-block;
  color: #fff;
  width: 200px;
  background-color: #3a8dcb;
  font-size: 14px;
  height: 33px;
  text-align: center;
  line-height: 33px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn6:hover, .btn6:focus {
  background-color: #51a5e3;
  color: gold;
}

.btn7 {
  display: inline-block;
  color: #fff;
  width: 200px;
  background-color: #000;
  font-size: 18px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn7:hover, .btn7:focus {
  background-color: #666;
  color: gold;
}

.btn8 {
  display: inline-block;
  color: #fff;
  background-color: #da4453;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  padding: 0px 15px;
  border: 1px solid #da4453;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  vertical-align: middle;
}

.btn8:hover, .btn8:focus {
  background-color: #e98b94;
  color: #fff;
}

.btn9 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  padding: 0px 15px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  vertical-align: middle;
}

.btn9:hover, .btn9:focus {
  background-color: #eee;
  color: #000;
}

.btn10 {
  display: inline-block;
  color: #747474;
  background-color: #e6e6e6;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  padding: 0px 15px;
  border: 1px solid #d2d2d2;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  vertical-align: middle;
}

.btn10:hover, .btn10:focus {
  background-color: #eee;
  color: #000;
}

.btn11 {
  display: inline-block;
  color: #fff;
  background-color: #000000;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  padding: 0px 15px;
  border: 1px solid #d2d2d2;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  vertical-align: middle;
}

.btn11:hover, .btn11:focus {
  background-color: #000000;
  color: gold;
}

.downBtn1 {
  display: inline-block;
  color: #fff;
  background-color: #0c5493;
  font-size: 15px;
  padding: 10px 40px 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
  border: 1px solid #0c5493;
  position: relative;
}

.downBtn1:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -454px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.downBtn1:hover, .downBtn1:focus {
  background-color: #1e79c8;
  color: gold;
}

.downBtn2 {
  display: inline-block;
  color: #0c5493;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 40px 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
  border: 1px solid #0c5493;
  position: relative;
}

.downBtn2:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -917px -517px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.downBtn2:hover, .downBtn2:focus {
  background-color: #1e79c8;
  color: gold;
}

.btnSmall-1 {
  display: inline-block;
  color: #fff;
  background-color: #ec2533;
  border-radius: 30px;
  font-size: 15px;
  padding: 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
}

.btnSmall-1:hover, .btnSmall-1:focus {
  background-color: #c7131f;
  color: gold;
}

.btnSmall-2 {
  display: inline-block;
  color: #fff;
  background-color: #333333;
  border-radius: 30px;
  font-size: 15px;
  padding: 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
}

.btnSmall-2:hover, .btnSmall-2:focus {
  background-color: #000;
  color: gold;
}

.basicList {
  margin-bottom: 10px;
}

.basicList li {
  text-align: left;
  position: relative;
  padding-left: 13px;
  line-height: 1.6;
}

.basicList li ul {
  margin-bottom: 0;
}

.basicList li:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -56px -953px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.basicList1 {
  margin-bottom: 10px;
}

.basicList1 li {
  text-align: left;
  position: relative;
  padding-left: 13px;
  line-height: 1.6;
}

.basicList1 li ul {
  margin-bottom: 0;
}

.basicList1 li:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -953px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.basicList2 {
  margin-bottom: 10px;
}

.basicList2 li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.basicList2 li:before {
  position: absolute;
  top: 10px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -56px -953px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.basicList3 {
  margin-bottom: 10px;
}

.basicList3 li {
  text-align: left;
  position: relative;
  padding-left: 13px;
  line-height: 1.6;
}

.basicList3 li:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -355px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.basicTable1 {
  margin-bottom: 30px;
}

.basicTable1 table {
  border-collapse: collapse;
  width: 100%;
}

.basicTable1 thead th {
  border-bottom: 0px;
}

.basicTable1 th {
  border: 3px solid #fff;
  padding: 13px 0;
  background-color: #e26223;
  color: #fff;
}

.basicTable1 td {
  border: 3px solid #fff;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff4ec;
}

.basicTable1 tr.left-td td,
.basicTable1 td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .basicTable1 {
    padding-top: 10px;
  }
  .basicTable1 colgroup {
    display: none;
  }
  .basicTable1 thead {
    display: none;
  }
  .basicTable1 tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #fff;
  }
  .basicTable1 th {
    display: none;
  }
  .basicTable1 td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #fff;
  }
  .basicTable1 td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.basicTable1_1 {
  margin-bottom: 30px;
}

.basicTable1_1 table {
  border-collapse: collapse;
  width: 100%;
}

.basicTable1_1 thead th {
  border-bottom: 0px;
}

.basicTable1_1 th {
  border: 1px solid #333;
  padding: 13px 0;
  background-color: #fff;
  color: #333;
}

.basicTable1_1 td {
  border: 1px solid #333;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff;
}

.basicTable1_1 tr.left-td td,
.basicTable1_1 td.left-td {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .basicTable1_1 {
    padding-top: 10px;
  }
  .basicTable1_1 colgroup {
    display: none;
  }
  .basicTable1_1 thead {
    display: none;
  }
  .basicTable1_1 tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #333;
  }
  .basicTable1_1 th {
    display: none;
  }
  .basicTable1_1 td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #333;
  }
  .basicTable1_1 td:before {
    color: #000;
    font-weight: bold;
    display: block;
    content: attr(data-th) " ";
  }
}

.basicTable2 {
  margin-bottom: 30px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.basicTable2 table {
  border-collapse: collapse;
  width: 100%;
}

.basicTable2 th {
  border: 1px solid #dcdcdc;
  border-left: 0;
  padding: 13px 0;
  background-color: #f4f4f4;
  text-align: left;
  padding-left: 30px;
}

.basicTable2 td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 13px 5px;
  text-align: left;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .basicTable2 {
    padding-top: 10px;
  }
  .basicTable2 colgroup {
    display: none;
  }
  .basicTable2 thead {
    display: none;
  }
  .basicTable2 tr {
    display: block;
  }
  .basicTable2 th {
    display: none;
  }
  .basicTable2 td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    color: #333;
    position: relative;
  }
  .basicTable2 td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) "*";
    display: block;
    margin-bottom: 10px;
  }
}

.basicTable3 {
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #000;
}

.basicTable3 table {
  border-collapse: collapse;
  width: 100%;
}

.basicTable3 thead th {
  background-color: #333;
  color: #fff;
  border: 1px solid #333;
}

.basicTable3 th {
  border: 1px solid #dcdcdc;
  border-left: 0;
  padding: 18px 0;
  background-color: #f4f4f4;
  padding-left: 30px;
}

.basicTable3 td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 18px 5px;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .basicTable3 {
    padding-top: 10px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable3 colgroup {
    display: none;
  }
  .basicTable3 thead {
    display: none;
  }
  .basicTable3 tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable3 th {
    display: none;
  }
  .basicTable3 td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable3 td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.basicTable4 {
  margin-bottom: 10px;
  border-top: 2px solid #4b4b4b;
  border-bottom: 1px solid #ddd;
}

.basicTable4 table {
  border-collapse: collapse;
  width: 100%;
  margin-left: -1px;
}

@media only screen and (max-width: 550px) {
  .basicTable4 table {
    font-size: 8px;
  }
}

.basicTable4 th {
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 13px 0;
  background-color: #f6f6f6;
  text-align: center;
  color: #000;
}

.basicTable4 tbody th {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

@media only screen and (max-width: 550px) {
  .basicTable4 tbody th {
    padding: 3px 3px;
  }
}

.basicTable4 td {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  vertical-align: middle;
}

@media only screen and (max-width: 550px) {
  .basicTable4 td {
    padding: 3px 3px;
  }
}

.basicTable4 td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.basicTable4 td.sbj-line > a:hover, .basicTable4 td.sbj-line > a:focus {
  text-decoration: underline;
}

.basicTable4 td > a:hover, .basicTable4 td > a:focus {
  text-decoration: underline;
}

.basicTable4 td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .basicTable4.res {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable4.res table {
    min-width: auto;
    margin-left: 0px;
  }
  .basicTable4.res colgroup {
    display: none;
  }
  .basicTable4.res thead {
    display: none;
  }
  .basicTable4.res tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .basicTable4.res th {
    display: none;
  }
  .basicTable4.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .basicTable4.res td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .basicTable4.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
  .basicTable4.res td.align-left {
    padding-left: 10px;
  }
}

.basicTable5 {
  margin-bottom: 30px;
}

.basicTable5 table {
  border-collapse: collapse;
  width: 100%;
}

.basicTable5 thead th {
  border-bottom: 0px;
}

.basicTable5 th {
  border: 1px solid #999;
  padding: 13px 0;
  background-color: #fff;
  color: #333;
}

@media print {
  .basicTable5 th {
    font-size: 12px;
    padding: 1px;
  }
}

.basicTable5 td {
  border: 1px solid #999;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff;
}

@media print {
  .basicTable5 td {
    font-size: 12px;
    padding: 1px;
  }
}

.basicTable5 tr.left-td td,
.basicTable5 td.left-td {
  text-align: left;
  padding-left: 15px;
}

.basicTable5 td.right-td {
  text-align: right;
  padding-right: 15px;
}

.basicTable6 {
  margin-bottom: 30px;
}

.basicTable6 table {
  border-collapse: collapse;
  width: 100%;
}

.basicTable6 thead th {
  border-bottom: 0px;
}

.basicTable6 th {
  border: 1px solid #999;
  padding: 13px 0;
  background-color: #ddd;
  color: #333;
}

.basicTable6 td {
  border: 1px solid #999;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff;
}

.basicTable6 tr.left-td td,
.basicTable6 td.left-td {
  text-align: left;
  padding-left: 15px;
}

.basicTable6 td.right-td {
  text-align: right;
  padding-right: 15px;
}

.basicTable7 {
  margin-bottom: 10px;
  border-top: 2px solid #4b4b4b;
  border-bottom: 1px solid #ddd;
}

.basicTable7 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 600px;
  margin-left: -1px;
}

.basicTable7 th {
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 13px 0;
  background-color: #f6f6f6;
  text-align: center;
  color: #000;
}

.basicTable7 tbody th {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

.basicTable7 td {
  border: 1px solid #cdcdcd;
  padding: 13px 5px;
  text-align: center;
  border-bottom: 0px;
  vertical-align: middle;
}

.basicTable7 td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.basicTable7 td.sbj-line > a:hover, .basicTable7 td.sbj-line > a:focus {
  text-decoration: underline;
}

.basicTable7 td > a:hover, .basicTable7 td > a:focus {
  text-decoration: underline;
}

.basicTable7 td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 600px) {
  .basicTable7 {
    overflow: auto;
  }
  .basicTable7 {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable7 table {
    min-width: auto;
    margin-left: 0px;
  }
  .basicTable7 colgroup {
    display: none;
  }
  .basicTable7 thead {
    display: none;
  }
  .basicTable7 tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .basicTable7 th {
    display: block;
  }
  .basicTable7 td {
    display: block;
    margin-bottom: 0px;
    text-align: center;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .basicTable7 td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .basicTable7 td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) "  ";
  }
  .basicTable7 td.align-left {
    padding-left: 10px;
  }
}

.artist__tit {
  text-align: center;
  color: #000;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 2px solid #000;
  margin-bottom: 70px;
  font-weight: bold;
  font-size: 25px;
  position: relative;
}

.artist__tit > a {
  display: block;
  height: 40px;
  line-height: 40px;
  width: 35px;
  text-align: center;
  border-radius: 10px;
  transition: all 0.3s ease 0s;
}

.artist__tit > a:hover, .artist__tit > a:focus {
  background-color: #eee;
}

.artist__tit__left {
  position: absolute;
  top: 30px;
  left: 5px;
}

.artist__tit__right {
  position: absolute;
  top: 30px;
  right: 5px;
}

.artist__top {
  padding-bottom: 40px;
  margin-bottom: 70px;
}

.artist__top:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .artist__top {
  display: inline-block;
}

.artist__top__image {
  float: left;
  width: 36%;
}

.artist__top__image img {
  max-width: 100%;
}

.artist__top__right {
  float: right;
  width: 58%;
}

.artist__top__right__ul {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.artist__top__right__ul__li {
  padding-top: 14px;
  padding-bottom: 14px;
  position: relative;
  padding-left: 100px;
}

.artist__top__right__ul__li__tit {
  position: absolute;
  top: 14px;
  left: 0;
}

.artist__top__right__sns {
  padding-top: 28px;
  padding-bottom: 28px;
  position: relative;
  padding-left: 100px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  font-size: 16px;
  font-size: 1.6rem;
}

.artist__top__right__sns__tit {
  position: absolute;
  top: 28px;
  left: 0;
}

.artist__top__right__sns__cont {
  position: relative;
}

.artist__top__right__sns__cont__btn {
  position: absolute;
  top: 0px;
  right: 0;
}

.artist__top__right__sns__cont__btn {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  padding: 0px 15px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
  vertical-align: middle;
}

.artist__top__right__sns__cont__btn:hover, .artist__top__right__sns__cont__btn:focus {
  background-color: #eee;
  color: #000;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .artist__top {
    margin-bottom: 30px;
  }
}

.artist__mid__tit {
  color: #000;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

.artist__mid__cont {
  line-height: 1.7;
  color: #666;
  margin-bottom: 70px;
}

.artist__mid__add-file {
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  background-color: #f7f7f7;
}

.artist__mid__add-file:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .artist__mid__add-file {
  display: inline-block;
}

.artist__mid__add-file__tit {
  float: left;
  width: 20%;
  box-sizing: border-box;
  padding: 20px 20px 20px 20px;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .artist__mid__add-file__tit {
    width: 35%;
    padding: 10px 10px 10px 20px;
  }
}

.artist__mid__add-file > a {
  display: block;
  background-color: #fff;
  padding: 20px;
  float: right;
  box-sizing: border-box;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .artist__mid__add-file > a {
    width: 65%;
    padding: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 800px) {
  .artist__top__image {
    float: none;
    width: auto;
    max-width: 360px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .artist__top__right {
    float: none;
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .artist__top__right__ul__li {
    padding-left: 0px;
  }
  .artist__top__right__ul__li:last-child {
    border-bottom: 0px;
  }
  .artist__top__right__ul__li__tit {
    position: relative;
    top: 0;
  }
}

.orgchart {
  position: relative;
}

.orgchart .orgchart_top_right {
  position: absolute;
  right: 20px;
}

.orgchart .orgchart_top_right .top {
  font-weight: bold;
}

@media only screen and (max-width: 770px) {
  .orgchart .orgchart_top_right {
    position: relative;
    right: 0;
    margin: 0 auto;
    width: 112px;
  }
}

.orgchart_list_item_t1 {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 300px;
  text-align: center;
  font-weight: 700;
  z-index: 2;
  /*&:before {
					position: absolute;
					display: block;
					content: "";
					background-image: url(../../resources/images/site/orgchart_bg.png);
					width: 264px;
					height: 247px;
					top: -9999px;
					bottom: -9999px;
					left: -9999px;
					right: -9999px;
					margin: auto;
					z-index: 1;
				}*/
}

.orgchart_list_item_t1_in {
  border: 2px solid #f3653a;
}

.orgchart_list_item_t1_in_top {
  background: #f3653a;
  color: #fff;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.orgchart_list_item_t1_in_bottom {
  color: #333;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.orgchart_list_item_t1_in_bottom span {
  color: #666666;
  margin-right: 10px;
}

.orgchart_list_item_t2 {
  display: flex;
  justify-content: space-around;
  padding-top: 50px;
  position: relative;
}

.orgchart_list_item_t2:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ccc;
}

.orgchart_list_item_t2_cont {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.orgchart_list_item_t2_cont:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 1px;
  background-color: #ccc;
  margin: auto;
  z-index: 1;
}

.orgchart_list_item_t2_cont:first-child a {
  border: 2px solid #308146;
  color: #308146;
}

.orgchart_list_item_t2_cont:first-child:before {
  width: 50%;
  left: 50%;
}

.orgchart_list_item_t2_cont:last-child a {
  border: 2px solid #0154b0;
  color: #0154b0;
}

.orgchart_list_item_t2_cont:last-child:before {
  width: 50%;
  right: 50%;
}

.orgchart_list_item_t2_cont a {
  display: block;
  padding: 15px;
  width: 190px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid;
  position: relative;
  z-index: 1;
  font-size: 21px;
  font-width: 700;
  background: #fff;
}

@media only screen and (max-width: 550px) {
  .orgchart_list_item_t2_cont a {
    width: 120px;
    padding: 10px;
  }
}

.orgchart_list_item_t3 {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.orgchart_list_item_t3:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ccc;
}

.orgchart_list_item_t3_cont {
  width: 100%;
}

.orgchart_list_item_t3_cont > a {
  width: 190px;
  margin: 0 auto;
  background: #f27a2a;
  display: block;
  position: relative;
  z-index: 2;
  font-size: 21px;
  font-width: 700;
  text-align: center;
  color: #fff;
  padding: 15px;
}

.orgchart_list_item_t3_cont:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 1px;
  background-color: #ccc;
  margin: auto;
  z-index: -1;
}

.orgchart_list_item_t4 {
  position: relative;
  width: 100%;
  margin-top: 80px;
  padding-top: 15px;
  position: relative;
  display: flex;
  background: #fff;
  justify-items: center;
  justify-content: center;
}

@media only screen and (max-width: 770px) {
  .orgchart_list_item_t4 {
    flex-wrap: wrap;
    padding-top: 0;
  }
}

.orgchart_list_item_t4_cont {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 770px) {
  .orgchart_list_item_t4_cont {
    margin-bottom: 10px;
  }
}

.orgchart_list_item_t4_cont:last-child .orgchart_list_item_t4_cont_wrap:after {
  width: 0%;
}

.orgchart_list_item_t4_cont_wrap {
  width: 80%;
  margin: 0 auto;
  padding-top: 50px;
}

@media only screen and (max-width: 770px) {
  .orgchart_list_item_t4_cont_wrap {
    padding-top: 0;
  }
}

.orgchart_list_item_t4_cont_wrap_link_in {
  border: 2px solid #2c2c61;
  position: relative;
}

.orgchart_list_item_t4_cont_wrap_link_in:before {
  content: '';
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 17px;
  height: 17px;
  background: #2c2c61;
  border-radius: 100%;
  border: 3px solid #fff;
  box-sizing: border-box;
}

.orgchart_list_item_t4_cont_wrap_link_in_top {
  background: #2c2c61;
  color: #fff;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.orgchart_list_item_t4_cont_wrap_link_in_top > a:focus {
  border: 2px dashed #fff;
}

.orgchart_list_item_t4_cont_wrap_link_in_bottom {
  color: #333;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.orgchart_list_item_t4_cont_wrap_link_in_bottom span {
  color: #666666;
  margin-right: 10px;
}

.orgchart_list_item_t4_cont_wrap > a {
  display: block;
}

.orgchart_list_item_t4_cont_wrap > div ul li {
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #ddd;
}

.orgchart_list_item_t4_cont_wrap > div ul li span {
  color: #666;
  margin-right: 5px;
}

.orgchart_list_item_t4_cont_wrap > div ul li a {
  display: block;
}

.orgchart_list_item_t4_cont_wrap:before {
  display: block;
  content: "";
  position: absolute;
  top: -1.5rem;
  left: 1px;
  right: 0;
  width: 1px;
  height: 5.5rem;
  margin: auto;
  background-color: #ccc;
}

@media only screen and (max-width: 770px) {
  .orgchart_list_item_t4_cont_wrap:before {
    display: none;
  }
}

.orgchart_list_item_t4_cont_wrap:after {
  display: block;
  content: "";
  position: absolute;
  top: -15px;
  left: 50%;
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-index: 1;
}

@media only screen and (max-width: 770px) {
  .orgchart_list_item_t4_cont_wrap:after {
    display: none;
  }
}

.organ {
  margin-top: 50px;
}

/*fixed 디자인 첨가*/
.mainpage.ver-mini .header {
  position: fixed;
  top: 0;
  height: 60px;
  background-color: #fff;
}

.mainpage.ver-mini .header__wrap__h1 h1 {
  top: 23px;
}

.ver-mini .header__wrap__h1 h1 a img {
  max-height: 34px;
}

.ver-mini .ver2.down-all .gnb {
  background-position: 0 -49px;
}

.mainpage.ver-mini .ver2.down-all .gnb {
  background-position: 0 -97px;
}

.subpage .gnb .gnb-menu > a {
  transition: all 0.3s ease 0s;
}

.ver-mini .ver2 .gnb .gnb-menu > a {
  height: 60px;
  line-height: 60px;
  transition: all 0.3s ease 0s;
}

.ver-mini.mainpage .ver2 .gnb .gnb-menu > a {
  height: 60px;
  line-height: 60px;
  transition: all 0.3s ease 0s;
}

.ver-mini.mainpage .gnb-menu {
  border-right: 0px !important;
}

.ver-mini .main-section {
  margin-top: 109px;
}

.ver-mini .main-section {
  margin-top: 109px;
}

.ver-mini.subpage .btn_all {
  top: 2px;
}

.ver-mini.mainpage .btn_all {
  top: 2px;
}

@media screen and (min-width: 769px) and (max-width: 1500px) {
  .subpage.ver-mini .header {
    height: 108px;
  }
  .mainpage.ver-mini .header {
    height: 108px;
  }
  .ver-mini.mainpage .ver2 .gnb .gnb-menu > a {
    transition: all 0.0s ease 0s;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .ver-mini .main-section {
    margin-top: 0;
  }
  .ver-mini .main-section {
    margin-top: 0;
  }
  .sub-page-container {
    padding-top: 51px;
  }
  .ver-mini .header__wrap__h1 h1 {
    top: 9px;
  }
}

.icon-test {
  padding: 0px;
  background-color: #000;
  height: 28px;
  display: inline-block;
}

.icon-test:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -513.33333px -104px;
  background-image: url("../images/sprite.png");
  background-size: 656px auto;
  width: 18.66667px;
  min-width: 18.66667px;
  height: 18.66667px;
}

.flex {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 500px;
  height: 200px;
  border: 1px solid red;
}

.flex-item {
  flex: 1;
  order: 0;
  width: 100px;
  height: 100px;
  border: 1px solid blue;
  background-color: #ccc;
}

.flex-item:nth-child(3) {
  position: absolute;
  right: 50%;
  top: 50%;
  width: 50px;
  height: auto;
  min-height: 40px;
  min-width: 40px;
  transform: translateY(-50%);
  transform: translateX(50%);
  flex: 1;
  order: 1;
  background-color: red;
}

.sample {
  font-size: 20px;
}

/*pages 가이드*/
.pages a {
  display: block;
  margin-bottom: 15px;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
}

.pages a span {
  position: absolute;
  left: 195px;
  top: 0px;
  border: 1px solid #666;
  display: inline-block;
  padding: 2px;
  margin-left: 10px;
  width: 400px;
  padding-left: 10px;
}

.pages a em {
  color: #da4453;
  font-weight: bold;
}

.pages a:hover span,
.pages a:focus span {
  background-color: #eee;
}

.pages a:hover, .pages a:focus {
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .pages a span {
    width: auto;
    position: relative;
    left: 0;
    display: block;
  }
}

/*	footer
==========*/
#footer {
  color: #333;
  background-color: #ffffff;
  position: relative;
  font-family: 'Noto Sans KR';
}

.footer_top {
  border-bottom: 1px solid #e5e5e5;
}

.footer_top .footer_cont {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1440px;
  height: 66px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.footer_top .footer_cont .copyright {
  position: relative;
}

.footer_top .footer_cont .foot_img {
  position: relative;
  display: inline-block;
}

.footer_top .footer_cont .foot_img img {
  width: 258px;
}

.footer_bottom .footer_cont {
  position: relative;
  width: 100%;
  max-width: 1440px;
  height: 156px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.footer_bottom .footer_cont address ul {
  display: flex;
  flex-direction: column;
}

.footer_bottom .footer_cont address ul li {
  font-size: 15px;
  font-weight: 400;
  color: #888;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  text-transform: uppercase;
}

.footer_bottom .footer_cont address ul li strong {
  display: block;
  color: #444;
  padding-bottom: 8px;
}

@media (max-width: 1300px) {
  .footer_bottom .footer_cont {
    flex-direction: column;
    padding: 30px 0;
  }
  .footer_bottom .footer_cont div:last-child {
    display: none;
  }
  .footer_bottom .footer_cont address {
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .footer_bottom .footer_cont address ul li {
    font-size: 12px;
    text-align: center;
  }
}

.footer_in_wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .footer_in_wrap {
  display: inline-block;
}

.copy_box {
  position: relative;
  display: inline-block;
}

.copy_box li {
  float: left;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  padding-left: 40px;
}

.copy_box li:before {
  position: absolute;
  left: 10px;
  top: 7px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -60px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 13px;
}

.copy_box li:first-child:before {
  display: none;
}

.copy_box li:first-child a {
  background-image: none;
}

.copy_box li.persnal-rule a {
  color: #f04c1b;
}

.copy_box li.sns-li {
  background-image: none;
}

.copy_box a {
  font-size: 18px;
  color: #111;
}

.copy_box a strong {
  color: #000;
}

.copy_box a:hover,
.copy_box2 a:hover,
.copy_box a:focus,
.copy_box2 a:focus {
  text-decoration: underline;
}

.copy_box2 {
  position: relative;
  width: auto;
  border-top: 0px solid #e7e7e7;
  padding-top: 20px;
  font-size: 12px;
}

.copy_box2 .item1 {
  margin-bottom: 5px;
  font-style: normal;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.5;
  color: #000;
}

.copy_box2 .item1 h3 {
  float: left;
  width: 65px;
  height: 26px;
  padding-top: 9px;
  margin-right: 5px;
  background-color: #676767;
  color: #000;
  text-align: center;
}

.copy_box2 .item1 li:first-child {
  padding-left: 0;
  background-image: none;
}

.copy_box2 .item1 li strong {
  color: #666;
  margin-right: 4px;
  display: inline-block;
  min-width: 62px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .copy_box2 .item1 li strong {
    display: block;
  }
}

.copy_box2 .item2 {
  font-style: normal;
  margin-top: 5px;
  margin-bottom: 4px;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.6;
  color: #000;
}

.copy_box3 {
  position: absolute;
  right: 20px;
  top: 13px;
}

.footer-select {
  display: inline-block;
}

.sel_go_btn {
  border-left: 0px;
}

.sel_go_btn:focus {
  text-decoration: underline;
}

.footer_sns {
  position: absolute;
  right: 0;
  top: 50px;
  padding-top: 20px;
}

.footer_sns a {
  text-indent: -9999px;
  margin-left: 5px;
}

.footer_sns a img {
  vertical-align: middle;
}

/*통계*/
.total-count {
  background-color: #399e90;
  padding: 10px 30px 10px 10px;
  border-radius: 30px;
}

.total-count__dt {
  display: inline-block;
  color: #fff;
  margin-right: 5px;
  padding-left: 20px;
}

.total-count__dt:before {
  content: '';
  padding-right: 10px;
  padding-left: 14px;
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -979px -240px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.total-count__dt:first-child:before {
  display: none;
}

.total-count__dd {
  display: inline-block;
  color: #fff;
}

/*푸더 셀렉트*/
.footer-select {
  background-color: #fff;
}

.footer-select__item {
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  position: relative;
  background-color: transparent;
  text-align: left;
  border: 0px solid #717171;
}

.footer-select__item:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -576px -826px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 4px;
}

.footer-select__item select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 230px;
  vertical-align: top;
  color: #888;
  font-size: 16px;
  height: 40px;
  margin-top: 0;
  padding: 2px 2px 2px 15px;
  position: relative;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  font-family: "Pretendard", "Noto Sans KR", sans-serif;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.footer-select__item select::-ms-expand {
  display: none;
}

.lte-ie9 .footer-select__item select {
  width: 125%;
  vertical-align: top;
}

.footer-select__item select option {
  color: #000;
  font-family: "Nanum Gothic";
}

.footer-select__item select:focus {
  border: 1px solid gold;
  border-radius: 4px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .footer-select__item select {
    min-width: 200px;
  }
}

* + html .sel_go_btn {
  display: inline;
}

/*푸더 셀렉트*/
.sel_go_btn {
  display: inline-block;
  height: 40px;
  width: 50px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background-color: #6a6a6a;
  border-left: 1px solid #717171;
  border-radius: 5px;
}

* + html .sel_go_btn {
  display: inline;
}

.footer-select .select_design {
  border: 0px;
  background-color: transparent;
  float: left;
  margin-top: 6px;
}

.footer-select .select_design select {
  color: #bababa;
  font-size: 13px;
}

.footer-select .select_design select option {
  color: #000;
}

.lte-ie9 .footer-select .select_design select option {
  color: #949494;
}

/* 유관기관 바로가기 */
.foot-links {
  width: 230px;
  position: relative;
  height: 52px;
  z-index: 999;
}

.foot-links__a {
  height: 52px;
  line-height: 52px;
  padding-left: 20px;
  font-size: 16px;
  color: #fff;
  display: block;
  background-color: #1b5394;
  position: relative;
}

.foot-links__a:focus {
  color: gold;
}

.foot-links__a:after {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.foot-links__list {
  position: absolute;
  bottom: 52px;
  left: 0;
  background-color: #1b5394;
  width: 1200px;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  display: none;
}

.foot-links__list.on {
  display: block;
}

.foot-links__list a {
  color: #fff;
  display: block;
  float: left;
  width: 25%;
  box-sizing: border-box;
  padding: 8px 20px 3px 20px;
}

.foot-links__list a:hover, .foot-links__list a:focus {
  color: gold;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .foot-links__list a {
    width: 50%;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .foot-links__list a {
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1300px) {
  /*footer*/
  #footer {
    height: auto;
    min-height: 100px;
    background-image: none;
    position: relative;
    z-index: 3;
  }
  .footer_top .footer_cont {
    height: auto;
    flex-direction: column;
    padding: 30px 0;
  }
  .footer_bottom .footer_cont {
    height: auto;
    flex-direction: column;
    padding: 30px 0;
  }
  .footer_in_wrap {
    width: 100%;
  }
  .foot_img {
    display: none;
  }
  .copy_box {
    padding-top: 20px;
  }
  .copy_box li {
    background-image: none;
    padding: 4px 10px;
  }
  .copy_box li:before {
    display: none;
  }
  .copy_box2 {
    position: relative;
    left: 0;
    top: 0;
    padding-top: 10px;
  }
  .select_bottom {
    position: relative;
    right: 0;
    top: 0;
  }
  .select_bottom p {
    float: left;
  }
  .copy_box2 .item1 li {
    padding-left: 0;
    background-image: none;
    float: none;
  }
  .copy_box3 {
    display: none;
  }
  .copy_box4 {
    position: relative;
    top: 0;
  }
  .total-count {
    padding: 0;
    border-radius: 0;
    padding: 5px;
  }
  .total-count__dt:first-child {
    padding-left: 0;
  }
  .footer-select {
    display: inline-block;
  }
  .sel_go_btn {
    border-left: 0px;
  }
  .footer_sns {
    top: 90px;
  }
  /*유관기관바로가기*/
  .foot-links {
    width: auto;
  }
  .foot-links__a {
    border-radius: 10px;
  }
  .foot-links__list {
    border-radius: 10px 10px 0 0;
    bottom: 44px;
  }
  .foot-links__list {
    width: 100%;
  }
}

@media screen and (min-width: 851px) and (max-width: 1000px) {
  /*footer*/
  .copy_box4 {
    position: absolute;
    top: 39px;
    right: 10px;
  }
  .copy_box4 p {
    float: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  #footer {
    padding-top: 10px;
  }
  .copy_box a {
    font-size: 14px;
  }
  .copy_box2 .item1 li strong {
    margin-top: 5px;
  }
  .footer_sns {
    bottom: 0px;
    top: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 470px) {
  .copy_box li {
    padding: 0;
    float: left;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .footer_sns {
    display: none;
  }
  .copy_box2 .item1 li {
    padding-left: 0 !important;
    background-image: none;
    width: 100%;
  }
  .footer_cont {
    padding: 30px 12px !important;
  }
}

.total_list {
  position: absolute;
  right: 30px;
  bottom: 20px;
}

@media screen and (min-width: 1px) and (max-width: 550px) {
  .total_list {
    position: relative;
    right: auto;
    bottom: auto;
  }
}

.total_list ul {
  display: flex;
}

.total_list ul li {
  color: #888;
  font-size: 1.3rem;
}

.total_list ul li ~ li {
  margin-left: 20px;
}
