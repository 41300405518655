/*공연예매 완료페이지*/
.complete-page {
	margin-top:50px;
	margin-bottom:50px;
	padding-top:230px;
	background-image:url("../../resources/images/cont/complete-icon.png");
	background-position:center top;
	background-repeat: no-repeat;
	text-align:center;
	&__item1 {font-weight: 800;font-size:24px;color:#007476;margin-bottom:10px;}
	&__item2 {font-size:13px;margin-bottom:20px;}

	&__btn1 {@include btn12}
	&__btn2 {@include btn13}
}





