.fastmenu {
	background-color:#ebedef;
	width:1500px; margin:0 auto;

	&__h3 {
		font-family: 'Do Hyeon','Noto Sans KR', sans-serif; font-size:28px;
		position: relative;
		margin-bottom: 40px;
	}
	&__ul {
		margin-left:-35px;
		&__li {

			float:left;
			width:25%;

			&__a {
				margin-left:35px;
				position: relative;
				display: block;
				height:273px;
				&__imgbox {
					overflow: hidden;
					img  {
						transition : all ease 1s;
						max-width: 100%;
						max-height: 273px;
					}
				}

				&__tit {
					font-size: 35px;
					font-weight: bold;
					color:#fff;
					position: absolute;
					bottom: 80px;
					left:20px;
				}

				&__detail {
					font-size: 14px;
					color:#c6c6c6;
					border: 1px solid #c6c6c6;
					display: block;
					padding:5px 15px;
					position: absolute;
					bottom: 30px;
					left:20px;

				}

			}

			&__a:hover,
			&__a:focus {
				 img {transform: scale(1.2);
					opacity: 0.7;
				}
			}
		}
	}
}


@media screen and (min-width:1px) and (max-width:1500px) {
	.fastmenu {width:auto;}

	.fastmenu__ul__li__a {height: auto;}
	.fastmenu__ul__li__a__tit {}
	.fastmenu__ul__li__a__imgbox img {height: 100%}
}
@media screen and (min-width:1px) and (max-width:1000px) {
	.fastmenu__ul {margin-left:-2%}
	.fastmenu__ul__li {width: 50%;margin-bottom:2%}
	.fastmenu__ul__li__a {margin-left:4%}
	.fastmenu__ul__li__a__imgbox img {width:100%;}

}
@media screen and (min-width:1px) and (max-width:1000px) {
	.fastmenu__h3 {margin-bottom:2%}
	.fastmenu__ul__li__a__tit {left:0px;width:100%;text-align: center;}
	.fastmenu__ul__li__a__detail {left:50%;transform: translateX(-50%);}
}
@media screen and (min-width:1px) and (max-width:560px) {
	.fastmenu__ul__li__a__tit {font-size:23px;}
}